import {Request} from "../interfaces/request.interface";

export const RequestAdapter = (request: any, direction: "in" | "out") => {
    if (direction === "out"){
        let adaptedRequest = request
        if (request.status == "Draft"){
            adaptedRequest.status = "DRF"
        } else if (request.status == "Shared") {
            adaptedRequest.status = "SHD"
        } else if (request.status == "Accepted") {
            adaptedRequest.status = "ACP"
        } else if (request.status == "Rejected") {
            adaptedRequest.status = "RJD"
        }
        adaptedRequest.collaborator_from = request.author
        adaptedRequest.completion_date = JSON.parse(request.completionDate)
        return adaptedRequest
    } else {
        let adaptedRequest = request as Request
        if (request.status == "DRF"){
            adaptedRequest.status = "Draft"
        } else if (request.status == "SHD") {
            adaptedRequest.status = "Shared"
        } else if (request.status == "ACP") {
            adaptedRequest.status = "Accepted"
        } else if (request.status == "RJD") {
            adaptedRequest.status = "Rejected"
        }
        adaptedRequest.author = request.collaborator_from
        adaptedRequest.completionDate = JSON.stringify(request.completion_date)
        return adaptedRequest
    }
}

export const RequestAdapterList = (requests: any, direction: "in" | "out") => {
    requests.forEach((goal: any) => RequestAdapter(goal, direction))
    if (direction === "in"){
        return requests as Request[]
    } else {
        return requests
    }
}