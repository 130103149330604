import api from "../utils/http-common"
import {Request, RequestParameters} from "../interfaces/request.interface"
import { RequestAdapter } from "../adapters/request.adapter"

export default class RequestServices {
    createRequest(token: string, request: Request){
        return api.post("/requests/", RequestAdapter(request, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    getRequestDetails(token: string, requestId: string){
        return api.get("/requests/" + requestId,{headers: {Authorization: `Bearer ${token}`}})
    }
    getAllRequests(token: string){
        return api.get("/requests/", {headers: {Authorization: `Bearer ${token}`}})
    }
    updateRequest(token: string, request: Request){
        return api.put("/requests/" + request.id, RequestAdapter(request, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    deleteRequest(token: string, request: {id:number}){
        return api.delete("/requests/" + request.id, {headers: {Authorization: `Bearer ${token}`}})
    }
    getAllManagerRequests(token: string, parameters: RequestParameters){
        let url : string = "/manager/requests?";
        for (const [key, value] of Object.entries(parameters)) {
            url += `${key}=${value}&`
        }
        return api.get(url,{headers: {Authorization: `Bearer ${token}`}});
    }
    getManagerRequestDetails(token: string, requestId: string){
        return api.get("/manager/requests/" + requestId,{headers: {Authorization: `Bearer ${token}`}})
    }
    updateManagerRequest(token: string, request: Request){
        return api.put("/manager/requests/" + request.id, RequestAdapter(request, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
}