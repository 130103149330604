import {Campaign} from "../interfaces/campaign.interface";

export const CampaignAdapter = (campaign: any, direction: "in" | "out") => {
    if (direction === "out"){
        let adaptedCampaign = campaign
        if (campaign.status === "Draft"){
            adaptedCampaign.status = "DRF"
        } else if (campaign.status === "Shared"){
            adaptedCampaign.status = "SHD"
        } else if (campaign.status === "Finished"){
            adaptedCampaign.status = "FNS"
        }

        return adaptedCampaign
    } else {
        let adaptedCampaign = campaign as Campaign
        if (campaign.status === "DRF"){
            adaptedCampaign.status = "Draft"
        } else if (campaign.status === "SHD"){
            adaptedCampaign.status = "Shared"
        } else if (campaign.status === "FNS"){
            adaptedCampaign.status = "Finished"
        }

        return adaptedCampaign
    }
}

export const CampaignAdapterList = (campaigns: any, direction: "in" | "out") => {
    campaigns.forEach((campaign: any) => CampaignAdapter(campaign, direction))
    if (direction === "in"){
        return campaigns as Campaign[]
    } else {
        return campaigns
    }
}