import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {AppDispatch, RootState} from "../store/store";
import {useEffect} from "react";
import DashboardManager from "./dashboardManager";
import {Sidebar} from "../components/sidebar";
import * as React from "react";
import { useTranslation } from "react-i18next";
import HeaderManager from "../components/header/headerManager";
import {getManagerPerformanceReviewDetails} from "../store/reducers/performanceReviewsReducer";
import {PerformanceReview} from "../interfaces/performanceReview.interface";
import ManagerPerformanceReviewDetails from "../components/ManagerPerformanceReviewDetails";

export default function ViewManagerPerformanceReviewDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.performanceReviews.status)

    useEffect(() => {
        if(params.performanceReviewId){
            dispatch(getManagerPerformanceReviewDetails(params.performanceReviewId));
        }
    }, [dispatch]);
    const performanceReview = useSelector((state: RootState) => state.performanceReviews.performanceReviews.find((performanceReview : PerformanceReview) => performanceReview.id == params.performanceReviewId));

    if(status != "error") {
        return (
            <>
                <Sidebar />
                <main>
                    <HeaderManager title={t("goal.h1")}/>
                    {performanceReview &&
                        <ManagerPerformanceReviewDetails performanceReview={performanceReview}/>
                    }
                </main>
            </>
        );
    }
    else {
        return <DashboardManager/>
    }
}