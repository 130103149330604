import * as React from "react";
import {Information} from "../../interfaces/campaignAnswer.interface"

interface InformationProps {
    answer : Information
}

const InformationComponent: React.SFC<InformationProps> = ({answer}) => {

    return (
        <p dangerouslySetInnerHTML={{ __html: answer.title}}></p>
    );
};
export default InformationComponent;