import * as React from "react";
import {Textarea} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface TextareaProps {
    answer: Textarea
    changeHandler: Function
    managerAnswer: Textarea
    isManager: boolean
    status: "Draft" | "SharedByCollaborator" | "SharedByManager" | "SharedByBoth" | "ValidatedByManager" | "ValidatedByBoth"
    readonly?: boolean
}

const TextareaComponent: React.SFC<TextareaProps> = ({answer, readonly, changeHandler, managerAnswer,isManager,status}) => {
    const { t } = useTranslation();
    const [userAnswer, setUserAnswer] = useState<string>(answer.answer);
    const [managerAnswerLocal, setManagerAnswer] = useState<string>(managerAnswer.answer);

    useEffect(() => {
        if(!isManager){
            changeHandler(userAnswer)
        }
    }, [userAnswer])

    useEffect(() => {
        if(isManager){
            changeHandler(managerAnswerLocal)
        }
    }, [managerAnswerLocal])

    return (
        <div className={styles.titleInput}>
            <label htmlFor={answer.title}>{answer.title}</label>
            {answer.visibility === "Both" ?
                <>
                    {(status === "Draft" || (status === "SharedByCollaborator" && !isManager ) || (status === "SharedByManager" && isManager) || ((status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") && answer.visibility !== "Both" )) &&
                        <>
                            {isManager ?
                                <textarea
                                    name={answer.title}
                                    value={managerAnswerLocal}
                                    readOnly={readonly}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setManagerAnswer(event.target.value)
                                        }}>
                                </textarea>
                                :
                                <textarea
                                    name={answer.title}
                                    value={userAnswer}
                                    readOnly={readonly}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setUserAnswer(event.target.value)
                                        }}>
                                </textarea>
                            }
                        </>
                    }
                    {status === "SharedByCollaborator" && isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <textarea
                                    name={answer.title}
                                    value={userAnswer}
                                    readOnly={true}>
                                </textarea>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <textarea
                                    name={answer.title}
                                    value={managerAnswerLocal}
                                    readOnly={readonly}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setManagerAnswer(event.target.value)
                                        }}>
                                </textarea>
                            </div>
                        </div>
                    }
                    {status === "SharedByManager" && !isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <textarea
                                    name={answer.title}
                                    value={userAnswer}
                                    readOnly={readonly}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setUserAnswer(event.target.value)
                                        }}>
                                </textarea>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <textarea
                                    name={answer.title}
                                    value={managerAnswerLocal}
                                    readOnly={true}>
                                </textarea>
                            </div>
                        </div>
                    }
                    {(status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <textarea
                                    name={answer.title}
                                    value={userAnswer}
                                    readOnly={readonly || isManager}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            if(!isManager){
                                                setUserAnswer(event.target.value)
                                            }
                                        }}>
                                </textarea>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <textarea
                                    name={answer.title}
                                    value={managerAnswerLocal}
                                    readOnly={readonly || !isManager}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            if(isManager){
                                                setManagerAnswer(event.target.value)
                                            }
                                        }}>
                                </textarea>
                            </div>
                        </div>
                    }
                </>
                :
                <>
                    {answer.visibility === "Manager" ?
                        <>
                            {((status === "SharedByManager" && !isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.manager")}</h5>
                            }
                            <textarea
                                name={answer.title}
                                value={managerAnswerLocal}
                                readOnly={readonly}
                                onChange={
                                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setManagerAnswer(event.target.value)
                                    }}>
                            </textarea>
                        </>
                        :
                        <>
                            {((status === "SharedByCollaborator" && isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.collaborator")}</h5>
                            }
                            <textarea
                                name={answer.title}
                                value={userAnswer}
                                readOnly={readonly}
                                onChange={
                                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setUserAnswer(event.target.value)
                                    }}>
                            </textarea>
                        </>
                    }
                </>
            }
        </div>
    );
};
export default TextareaComponent;