import {Goal, Milestone} from "../interfaces/goal.interface";

export const GoalAdapter = (goal: any, direction: "in" | "out") => {
    if (direction === "out"){
        let adaptedGoal = goal
        if (goal.status == "Draft"){
            adaptedGoal.status = "DRF"
        } else if (goal.status == "Shared") {
            adaptedGoal.status = "SHD"
        } else if (goal.status == "Active") {
            adaptedGoal.status = "ACT"
        } else if (goal.status == "Achieved") {
            adaptedGoal.status = "ACD"
        }
        adaptedGoal.completion_date = JSON.parse(goal.completionDate)
        if(goal.milestones){
            goal.milestones.map((milestone: Milestone) => {
                if(milestone){
                    if(milestone.id){
                        if (milestone.id.toString().startsWith('Tmp')) {
                            delete milestone.id
                        }
                    }
                    return milestone
                }
            })
        }
        return adaptedGoal
    } else {
        let adaptedGoal = goal as Goal
        if (goal.status == "DRF"){
            adaptedGoal.status = "Draft"
        } else if (goal.status == "SHD") {
            adaptedGoal.status = "Shared"
        } else if (goal.status == "ACT") {
            adaptedGoal.status = "Active"
        } else if (goal.status == "ACD") {
            adaptedGoal.status = "Achieved"
        }
        adaptedGoal.author = goal.created_by
        adaptedGoal.completionDate = JSON.stringify(goal.completion_date)
        return adaptedGoal
    }
}

export const GoalAdapterList = (goals: any, direction: "in" | "out") => {
    goals.forEach((goal: any) => GoalAdapter(goal, direction))
    if (direction === "in"){
        return goals as Goal[]
    } else {
        return goals
    }
}