import {useTranslation} from "react-i18next";

const Support: React.SFC<any> = () => {
    const {t} = useTranslation();

    return (
        <button className={"supportButton"}>
            <a href="mailto:support@papirus.app?subject=Support&body=Bonjour,%2C%0D%0AJ'ai%20un%20problème%3A%0D%0A%0D%0A(s'il%20vous%20plaît%20expliquez%20nous%20votre%20problème%20et%20attachez%20une%20pièce%20jointe%20si%20possible)" target="_blank">
                {t("common.contactSupport")}
            </a>
        </button>
    );
};
export default Support;