import * as React from "react";
import styles from './viewManagerConversation.module.css';
import SidebarHR from "../components/sidebarHR";
import HeaderManager from "../components/header/headerManager";
import {HRCampaignsList} from "../components/HRCampaignsList";

export default function AllHRCampaigns() {
    return (
        <>
            <SidebarHR />
            <main>
                <HeaderManager />
                <section className={styles.section} >
                    <HRCampaignsList parameters={{ limit: 10 }} />
                </section>
            </main>
        </>
    );
}