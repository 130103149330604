import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import styles from "./managerConversationCard.module.css";
import {useDispatch, useSelector} from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { getTeamMembers } from "../store/reducers/teamReducer";
import { Avatar } from "@mui/material";
import {stringAvatar} from "../utils/avatar";
import noAvatar from "../assets/avatar.svg"
import {PerformanceReview} from "../interfaces/performanceReview.interface";

interface ReviewProps {
    review: PerformanceReview
}

const ManagerPerformanceReviewCard: React.SFC<ReviewProps> = ({ review }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    let status = "Draft"
    if (review.status) {
        status = "common." + review.status.toLowerCase()
    }
    let date = new Date()
    if (review.created_at){
        date = new Date(review.created_at)
    }
    
    useEffect(() =>{
        dispatch(getTeamMembers())
    }, [dispatch])
    const members = useSelector((state: RootState) => state.team.members)
    const member = members.find(Collaborator => Collaborator.id === review.collaborator)

    let fullName = ""
    if (member) {
        if (member.first_name) {
            fullName += member.first_name
        }
        if (member.last_name) {
            fullName = fullName + " " + member.last_name
        }
    }

    let evaluationTranslation = ""
    if (review.evaluation) {
        evaluationTranslation = "reviews." + review.evaluation
    }

    return (
        <Fragment>
            <li>
                <a href={"/manager/performance-reviews/" + review.id} className={styles.LineLink}>
                    <div className={styles.Collaborator}>
                        {member && member.avatar &&
                            <img src={process.env.REACT_APP_BACK_END_URL + "media/" + member.avatar} />
                        }
                        {member && !member.avatar &&
                            <Avatar {...stringAvatar(fullName)} />
                        }
                        {!member &&
                            <img src={noAvatar} />
                        }
                    </div>
                    <div className={styles.Title}>
                        <p>{t(evaluationTranslation)}</p>
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Status}>
                        <span className={review.status}></span>
                        {t(status)}
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default ManagerPerformanceReviewCard;