import HeaderNotConnected from '../components/header/headerNotConnected';
import styles from "./login.module.css";
import {useTranslation} from "react-i18next";
import Button from "../components/button";
import {useState} from "react";
import {passwordForgotten} from "../store/reducers/activeUserReducer";
import {AppDispatch, RootState} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import CustomAlerts from "../components/customAlerts";
import background from "../assets/login-bg.png";


export default function PasswordForgotten() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const dispatch = useDispatch<AppDispatch>();
    const passwordForgottenStatus = useSelector((state: RootState) => state.activeUser.status);
    const errors = useSelector((state: RootState) => state.activeUser.error)

    return (
        <>
            <main className={styles.loginMain} style={{backgroundImage: `url(${background})`}}>
                <div>
                    <h2>{t("common.forgotPassword")}</h2>
                    <input
                        name="email"
                        placeholder="obiwan@kenobi.com"
                        defaultValue={email}
                        onChange={
                            (e) => setEmail(e.target.value)
                        }>
                    </input>
                    {passwordForgottenStatus === "error" && errors.email &&
                        <CustomAlerts errors={errors.email}/>
                    }
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.sendInstructions")}
                        submit={
                            () => {
                                dispatch(passwordForgotten(email))
                                if (passwordForgottenStatus === "success"){
                                    navigate("/login")
                                }
                            }
                        }/>
                </div>
            </main>
        </>
    );
}