import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HRCommentServices from "../../services/HRComments.service";
import {HRComment as HRCommentInterface} from "../../interfaces/hrComment.interface";
import {RootState} from "../store";

const initialState = {
    status: 'idle',
    error: {} as any,
    HRComments : [] as HRCommentInterface[],
}
const hrCommentService : HRCommentServices = new HRCommentServices();

export const getHRComments = createAsyncThunk(
    'hrcomments/get',
    async (data: number, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let HRComments = initialState.HRComments
        try {
            const response = await hrCommentService.getHRComments(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                HRComments = response.data.results
            }
            return HRComments
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const createHRComment = createAsyncThunk(
    'hrcomments/create',
    async (data: HRCommentInterface, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let HRComments = {} as HRCommentInterface
        try {
            const response = await hrCommentService.createHRComment(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                HRComments = response.data
            }
            return HRComments
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })



const HRCommentsSlice = createSlice({
    name: "hrcomments",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getHRComments.fulfilled, (state, action) => {
            state.HRComments = action.payload
            state.status = "success"
        })
        .addCase(getHRComments.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getHRComments.rejected, (state, action) => {
            state.error = action.payload!
            state.status = "error"
        })
    },
});

export default HRCommentsSlice.reducer;