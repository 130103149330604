import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import styles from "./goalcard.module.css";
import { PerformanceReview } from "../interfaces/performanceReview.interface";

interface PerformanceReviewProps {
    performanceReview: PerformanceReview,
}

const PerformanceReviewCard: React.SFC<PerformanceReviewProps> = ({ performanceReview }) => {
    const { t } = useTranslation();
    let status = "Draft"
    if (performanceReview.status) {
        status = "common." + performanceReview.status.toLowerCase()
    }
    let date = new Date()
    if (performanceReview.created_at) {
        date = new Date(performanceReview.created_at)
    }

    let evaluationTranslation = ""
    if (performanceReview.evaluation) {
        evaluationTranslation = "reviews." + performanceReview.evaluation
    }

    return (
        <Fragment>
            <li>
                <a href={"/performance-reviews/" + performanceReview.id} className={styles.LineLink}>
                    <div className={styles.Title}>
                        <p>{t(evaluationTranslation)}</p>
                    </div>
                    <div className={styles.Status}>
                        <div className={performanceReview.status}></div>
                        {t(status)}
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                </a>
            </li>
        </Fragment>
    );
};
export default PerformanceReviewCard;