import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {CampaignAnswer, CampaignAnswerParameters} from "../../interfaces/campaignAnswer.interface";
import CampaignAnswerServices from "../../services/campaignAnswer.service";
import {CampaignAnswerAdapter, CampaignAnswerAdapterList} from "../../adapters/campaignAnswers.adapter";

interface ValidationErrors {}

const initialState = {
    campaignAnswers: [] as CampaignAnswer[],
    status: "idle",
    error: {} as ValidationErrors,
    count: 0,
    limit: 8,
    offset: 0
};

const campaignAnswerService : CampaignAnswerServices = new CampaignAnswerServices();
    
export const getAllCampaignAnswers = createAsyncThunk(
    'campaignAnswers/allCampaignAnswers',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let campaignAnswerData = [] as CampaignAnswer[]
        try {
            const response = await campaignAnswerService.getAllCampaignAnswers(state.activeUser.activeUser.token)
            if (response.status == 200) {
                campaignAnswerData = CampaignAnswerAdapterList(response.data.results, "in") as CampaignAnswer[]
            }
            return campaignAnswerData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getCampaignAnswerDetails = createAsyncThunk(
    'campaignAnswers/getDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let campaignAnswerData = {} as CampaignAnswer
        try {
            const response = await campaignAnswerService.getCampaignAnswerDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                campaignAnswerData =  CampaignAnswerAdapter(response.data, "in") as CampaignAnswer
            }
            return campaignAnswerData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const updateCampaignAnswerDetails = createAsyncThunk(
    'campaignAnswers/update',
    async (data: CampaignAnswer, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let campaignAnswerData = {} as CampaignAnswer
        try {
            const response = await campaignAnswerService.updateCampaignAnswerDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                campaignAnswerData =  CampaignAnswerAdapter(response.data, "in") as CampaignAnswer
            }
            return campaignAnswerData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getAllManagerCampaignAnswers = createAsyncThunk(
    'campaignAnswers/allManagerCampaignAnswers',
    async (parameters: CampaignAnswerParameters, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let campaignAnswerData = {campaignAnswers: [] as CampaignAnswer[], count: 0, offset:0}
        try {
            const response = await campaignAnswerService.getAllManagerCampaignAnswers(state.activeUser.activeUser.token, parameters)
            if (response.status == 200) {
                campaignAnswerData.campaignAnswers = CampaignAnswerAdapterList(response.data.results, "in") as CampaignAnswer[]
                campaignAnswerData.count = response.data.count
                if(parameters.offset){
                    campaignAnswerData.offset = parameters.offset
                }
            }
            return campaignAnswerData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getManagerCampaignAnswerDetails = createAsyncThunk(
    'campaignAnswers/getManagerDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let campaignAnswerData = {} as CampaignAnswer
        try {
            const response = await campaignAnswerService.getManagerCampaignAnswerDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                campaignAnswerData =  CampaignAnswerAdapter(response.data, "in") as CampaignAnswer
            }
            return campaignAnswerData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const updateManagerCampaignAnswerDetails = createAsyncThunk(
    'campaignAnswers/managerUpdate',
    async (data: CampaignAnswer, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let campaignAnswerData = {} as CampaignAnswer
        try {
            const response = await campaignAnswerService.updateManagerCampaignAnswerDetails(state.activeUser.activeUser.token, data)
            if (response.status === 200) {
                campaignAnswerData =  CampaignAnswerAdapter(response.data, "in") as CampaignAnswer
            }
            return campaignAnswerData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

const campaignAnswersSlice = createSlice({
    name: "campaignAnswers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCampaignAnswers.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllCampaignAnswers.fulfilled, (state, action) => {
            state.campaignAnswers = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getAllCampaignAnswers.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(getCampaignAnswerDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getCampaignAnswerDetails.fulfilled, (state, action) => {
            state.campaignAnswers.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getCampaignAnswerDetails.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(updateCampaignAnswerDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(updateCampaignAnswerDetails.fulfilled, (state, action) => {
            const index = state.campaignAnswers.findIndex((campaignAnswer) => campaignAnswer.id == action.payload.id)
            state.campaignAnswers[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(updateCampaignAnswerDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(getAllManagerCampaignAnswers.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllManagerCampaignAnswers.fulfilled, (state, action) => {
            state.campaignAnswers = action.payload.campaignAnswers
            state.count = action.payload.count
            state.offset = action.payload.offset
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getAllManagerCampaignAnswers.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(getManagerCampaignAnswerDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getManagerCampaignAnswerDetails.fulfilled, (state, action) => {
            state.campaignAnswers.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getManagerCampaignAnswerDetails.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(updateManagerCampaignAnswerDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(updateManagerCampaignAnswerDetails.fulfilled, (state, action) => {
            const index = state.campaignAnswers.findIndex((campaignAnswer) => campaignAnswer.id == action.payload.id)
            state.campaignAnswers[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(updateManagerCampaignAnswerDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
    }
});

export default campaignAnswersSlice.reducer;