import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import Header from '../components/header/header';
import FeedbackDetails from '../components/feedbackDetails';
import { Feedback as FeedbackInterface} from '../interfaces/feedback.interface';
import {AppDispatch, RootState} from "../store/store";
import Dashboard from "./dashboard";
import {useEffect} from "react";
import {getFeedbackDetails} from "../store/reducers/feedbacksReducer";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function ViewFeedbackDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.feedbacks.status);

    useEffect(() => {
        if(params.feedbackId){
            dispatch(getFeedbackDetails(params.feedbackId));
        }
    }, [dispatch]);
    const feedback = useSelector((state: RootState) => state.feedbacks.feedbacks.find((feedback : FeedbackInterface) => feedback.id == params.feedbackId));

    if (status != "error") {
        return (
            <>
                <SidebarTalent />
                <main>
                    <Header title={t("feedback.h1")}/>
                    {feedback &&
                        <FeedbackDetails feedback={feedback}/>
                    }
                </main>
            </>
        );
    } else {
        return <Dashboard />;
    }
}