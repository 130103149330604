import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {AppDispatch, RootState} from "../store/store";
import {useEffect} from "react";
import {getManagerGoalDetails} from "../store/reducers/goalsReducer";
import {Goal as GoalInterface} from "../interfaces/goal.interface";
import DashboardManager from "./dashboardManager";
import {Sidebar} from "../components/sidebar";
import * as React from "react";
import { useTranslation } from "react-i18next";
import GoalDetailsManager from "../components/goalDetailsManager";
import HeaderManager from "../components/header/headerManager";

export default function ViewManagerGoalDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.goals.status)

    useEffect(() => {
        if(params.goalId){
            dispatch(getManagerGoalDetails(params.goalId));
        }
    }, [dispatch]);

    const goal = useSelector((state: RootState) => state.goals.goals.find((goal : GoalInterface) => goal.id == params.goalId));

    if(status != "error") {
        return (
            <>
                <Sidebar />
                <main>
                    <HeaderManager title={t("goal.h1")}/>
                    {goal &&
                        <GoalDetailsManager goal={goal}/>
                    }
                </main>
            </>
        );
    }
    else {
        return <DashboardManager/>
    }
}