import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Header from '../components/header/header';
import Button from '../components/button';
import styles from './addgoal.module.css';
import {AppDispatch, RootState} from "../store/store";
import MenuItem from "@mui/material/MenuItem";
import {Navigate, useSearchParams} from "react-router-dom";
import { createExperience } from "../store/reducers/experiencesReducer";
import {getAllGoals} from "../store/reducers/goalsReducer";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import CustomAlerts from "../components/customAlerts";
import PopUpSharedExperience from "../components/PopUpSharedExperience";
import HeaderLogo from "../assets/experience-header-logo.svg";
import { ToolTips } from "../components/toolTips";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import validateLogo from "../assets/page-validate.svg"
import validateHoverLogo from "../assets/page-validate-hover.svg"
import shareLogo from "../assets/page-share.svg"
import shareHoverLogo from "../assets/page-share-hover.svg"

export default function AddExperience() {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>('');
    const [details, setExperienceDetails] = useState<string>('');
    const [goal, setGoal] = useState<number>(0);
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date());
    const [reflection, setReflection] = useState<string>('');
    const [redirect, setRedirect] = useState<string>("");
    const experienceStatus = useSelector((state: RootState) => state.experiences.status);
    const errors = useSelector((state: RootState) => state.experiences.error);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openPopUp, setOpenPopUp] = useState(false);


    const handleChange = (event: SelectChangeEvent) => {
        setGoal(parseInt(event.target.value));
    };
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getAllGoals())
    }, [dispatch])
    const goals = useSelector((state: RootState) => state.goals.goals);

    const preselectedGoalId = searchParams.get("goalId");
    if((preselectedGoalId !== null) && goal === 0){
        setGoal(parseInt(preselectedGoalId));
    }

    const preselectedTitle = searchParams.get("title");
    if(preselectedTitle && title === ''){
        setTitle(preselectedTitle)
    }

    if(experienceStatus === "success") {
        if(redirect === "allExp") {
            return <Navigate to="/experiences"/>;
        } else if(redirect === "newGoal"){
            return <Navigate to="/goals/new"/>;
        } else if(redirect === "newExp"){
            window.location.reload();
        }
    }
    if(redirect === "close"){
        window.history.back();
    }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if (direction) {
            setRedirect(direction);
        } else {
            setRedirect("allExp");
        }
    }


    return (
        <>
            <SidebarTalent />
            <main>
                <Header title={t("experience.h1")}/>
                <section className={styles.modalForm}>
                    <header>
                    <img src={HeaderLogo}></img>
                    <h1>{t("common.experience")}</h1>
                    </header>
                    <form className={styles.form}>
                        <div className={styles.titleInput}>
                            <label htmlFor="title">{t("experience.title")}</label>
                            <input
                                name="title"
                                placeholder={t("experience.placeholder")}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                type="text">
                            </input>
                        </div>
                        {experienceStatus === "error" && errors.title &&
                            <CustomAlerts errors={errors.title}/>
                        }
                        <label htmlFor="goal-label">{t("experience.goal")} <span className={"optional"}>{t("common.optional")}</span></label>
                        <div className={styles.Input}>
                            <Select
                                labelId="goal-label"
                                id="goal-select"
                                value={goal.toString()}
                                defaultValue={goal.toString()}
                                label="Goal"
                                onChange={handleChange}
                                renderValue={goal !== 0 ? undefined : () => <span className={styles.placeholder}>{t("experience.placeholderGoal")}</span>}
                            >
                                {
                                    goals.map(
                                        (goal) => {
                                            return <MenuItem key={goal.id} value={goal.id}>{goal.title}</MenuItem>
                                        }
                                    )
                                }
                            </Select>
                        </div>
                        {experienceStatus === "error" && errors.goal &&
                            <CustomAlerts errors={errors.goal}/>
                        }
                        <label className={styles.tooltipHandler}>{t("experience.actions")}
                        <ToolTips text={t("experience.helpContent")} parent={document.getElementById("STAR")} /></label>
                            <p className={styles.STAR}
                            id="STAR">{t("experience.helpTitle")}</p>
                        <textarea
                            name="content"
                            placeholder={t("experience.placeholderSituation")}
                            onChange={(e) => setExperienceDetails(e.target.value)}
                            defaultValue={details}>
                        </textarea>
                        <label htmlFor="reflection">{t("experience.reflection")}</label>
                        <textarea
                            name="reflection"
                            placeholder={t("experience.placeholderReflection")}
                            onChange={(e) => setReflection(e.target.value)}>
                        </textarea>
                        <label>{t("experience.date")}</label>
                        <div className={styles.DateInput}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disableFuture
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={completionDate}
                                    onChange={(newDate) => {
                                        setCompletionDate(newDate);
                                    }}
                                    renderInput={(params) => {
                                        let newParams = {...params}
                                        if(newParams.inputProps){
                                            newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                        }
                                        return(<TextField {...newParams}/>)}}
                                />
                            </LocalizationProvider>
                        </div>
                    </form>
                    <div className={styles.buttonContainer}>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.close")}
                            submit={
                                () => {
                                    setRedirect("close")
                                }
                            }
                            icon={closeLogo}
                            iconHover={closeHoverLogo}/>
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.save")}
                            submit={
                                () => {
                                    dispatch(
                                        createExperience({
                                            title,
                                            goal,
                                            details,
                                            completionDate: JSON.stringify(completionDate),
                                            reflection,
                                            status: "Draft"
                                        })
                                    )
                                    setRedirect("allExp")
                                }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo} />
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.share")}
                            submit={
                                () => {
                                    dispatch(
                                        createExperience({
                                            title,
                                            goal,
                                            details,
                                            completionDate: JSON.stringify(completionDate),
                                            reflection,
                                            status: "Shared"
                                        })
                                    )
                                    setOpenPopUp(true);
                                }
                            }
                            icon={shareLogo}
                            iconHover={shareHoverLogo} />
                            {experienceStatus !== "error" &&
                                <PopUpSharedExperience
                                    visible={openPopUp}
                                    closeHandler={HandleClosePopUp} />
                            }
                    </div>
                </section>
            </main>
        </>
    );
}