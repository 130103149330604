import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Collaborator from "../../interfaces/collaborator.interface";
import {RootState} from "../store";
import UserServices from "../../services/user.service";

const initialState = {
    collaborators: [] as Collaborator[],
    status: "idle",
    error: {}
}

const userService : UserServices = new UserServices();

export const getCollaboratorsList = createAsyncThunk(
    'feedbacks/getCollaboratorsList',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let collaboratorsData = {} as Collaborator[]
        try {
            const response = await userService.getCollaboratorsList(state.activeUser.activeUser.token)
            if (response.status == 200) {
                collaboratorsData = response.data.results
            }
            return collaboratorsData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

const collaboratorSlice = createSlice({
    name: "collaborators",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCollaboratorsList.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getCollaboratorsList.fulfilled, (state, action) => {
            state.collaborators = action.payload
            state.status = "success"
        })
        builder.addCase(getCollaboratorsList.rejected, (state, action) => {
            state.status = "error"
        })
    }
});

export default collaboratorSlice.reducer;