import {CampaignAnswer} from "../interfaces/campaignAnswer.interface";

export const CampaignAnswerAdapter = (campaignAnswer: any, direction: "in" | "out") => {
    if (direction === "out"){
        let adaptedCampaignAnswer = campaignAnswer
        if (campaignAnswer.status === "Draft"){
            adaptedCampaignAnswer.status = "DRF"
        } else if (campaignAnswer.status === "SharedByCollaborator") {
            adaptedCampaignAnswer.status = "SHDC"
        } else if (campaignAnswer.status === "SharedByManager") {
            adaptedCampaignAnswer.status = "SHDM"
        } else if (campaignAnswer.status === "SharedByBoth") {
            adaptedCampaignAnswer.status = "SHDB"
        } else if (campaignAnswer.status === "ValidatedByManager") {
            adaptedCampaignAnswer.status = "VLDM"
        } else if (campaignAnswer.status === "ValidatedByBoth") {
            adaptedCampaignAnswer.status = "VLDB"
        }

        if(campaignAnswer.answer_collaborator){
            let answerCollaborator = { "template": [...adaptedCampaignAnswer.answer_collaborator.template]}
            answerCollaborator.template.sort((a: any, b: any) =>  a.position - b.position)
            adaptedCampaignAnswer.answer_collaborator = answerCollaborator
        }

        if(campaignAnswer.answer_manager) {
            let answerManager = {"template": [...adaptedCampaignAnswer.answer_manager.template]}
            answerManager.template.sort((a: any, b: any) => a.position - b.position)
            adaptedCampaignAnswer.answer_manager = answerManager
        }

        return adaptedCampaignAnswer
    } else {
        let adaptedCampaignAnswer = campaignAnswer as CampaignAnswer
        if (campaignAnswer.status === "DRF"){
            adaptedCampaignAnswer.status = "Draft"
        } else if (campaignAnswer.status === "SHDC") {
            adaptedCampaignAnswer.status = "SharedByCollaborator"
        } else if (campaignAnswer.status === "SHDM") {
            adaptedCampaignAnswer.status = "SharedByManager"
        } else if (campaignAnswer.status === "SHDB") {
            adaptedCampaignAnswer.status = "SharedByBoth"
        } else if (campaignAnswer.status === "VLDM") {
            adaptedCampaignAnswer.status = "ValidatedByManager"
        } else if (campaignAnswer.status === "VLDB") {
            adaptedCampaignAnswer.status = "ValidatedByBoth"
        }

        return adaptedCampaignAnswer
    }
}

export const CampaignAnswerAdapterList = (campaignAnswers: any, direction: "in" | "out") => {
    campaignAnswers.forEach((campaignAnswer: any) => CampaignAnswerAdapter(campaignAnswer, direction))
    if (direction === "in"){
        return campaignAnswers as CampaignAnswer[]
    } else {
        return campaignAnswers
    }
}