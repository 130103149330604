import { useTranslation } from "react-i18next";
import HeaderHR from '../components/header/headerHR';
import SidebarHR from "../components/sidebarHR";
import styles from "./dashboardHR.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import * as React from "react";
import { useEffect } from "react";
import { getManagerDashboardData } from "../store/reducers/dashboardManagerReducer";
import { getHRDashboardData} from "../store/reducers/dashboardHRReducer";
import { getManagersStats } from "../store/reducers/dashboardHRReducer";
import AvatarIcon from "../components/avatar";
import {capitalizeFirstLetter} from "../utils/capitalize";

export default function DashboardHR() {
    const { t } = useTranslation();
    const status = useSelector((state: RootState) => state.team.status)
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getManagerDashboardData());
        dispatch(getHRDashboardData());
        dispatch(getManagersStats())
    }, [dispatch]);

    const dashboardData = useSelector((state: RootState) => state.dashboardHR.dashboardHRData);
    const managersStats = useSelector((state: RootState) => state.dashboardHR.managersStats);

    return (
        <>
            <SidebarHR/>
            <main className={styles.mainManager}>
                <HeaderHR />
                <section className={styles.firstLine}>
                    <div className={styles.card}>
                        <h3>{t("HR.companyOverview")}</h3>
                        <div className={styles.subcardContainer}>
                            <div className={styles.subcard}>
                                <p>
                                    <span>{dashboardData.engagement.less_committed}</span>
                                    {t("HR.talent", {count:dashboardData.engagement.less_committed})} {t("HR.lessCommitted")}
                                </p>
                                <p>
                                    <span>{dashboardData.engagement.committed}</span>
                                    {t("HR.talent", {count:dashboardData.engagement.committed})} {t("HR.committed")}
                                </p>
                                <p>
                                    <span>{dashboardData.engagement.very_committed}</span>
                                    {t("HR.talent", {count:dashboardData.engagement.very_committed})} {t("HR.veryCommitted")}
                                </p>
                            </div>
                            <div className={styles.subcard}>
                                <p>
                                    <span>{dashboardData.entriesCount.goals}</span>
                                    {t("HR.goals", {count:dashboardData.entriesCount.goals})}
                                </p>
                                <p>
                                    <span>{dashboardData.entriesCount.experiences}</span>
                                    {t("HR.experiences", {count:dashboardData.entriesCount.experiences})}
                                </p>
                                <p>
                                    <span>{dashboardData.entriesCount.conversations}</span>
                                    {t("HR.conversations", {count:dashboardData.entriesCount.conversations})}
                                </p>
                                <p>
                                    <span>{dashboardData.entriesCount.feedbacks}</span>
                                    {t("HR.feedbacks", {count:dashboardData.entriesCount.feedbacks})}
                                </p>
                                <p>
                                    <span>{dashboardData.entriesCount.requests}</span>
                                    {t("HR.requests", {count:dashboardData.entriesCount.requests})}
                                </p>
                            </div>
                        </div>
                        <div className={styles.graphContainer}>
                            <div className={styles.progress}>
                                <span>{dashboardData.goalCompletion}</span>%
                                <progress max="100" value={dashboardData.goalCompletion}></progress>
                            </div>
                            <p>{t("common.goalsProgression")}</p>
                        </div>
                    </div>
                    <div className={styles.card}>
                        <h3>{t("HR.performanceReviews")}</h3>
                        <div className={styles.subcardContainer}>
                            <div className={styles.subcard}>
                                <div>
                                    <p>
                                        <span>{dashboardData.performance_review_status.draft}</span>
                                        {t("HR.reviewInProgress")}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span>{dashboardData.performance_review_status.shared}</span>
                                        {t("HR.reviewDone")}
                                    </p>
                                </div>
                            </div>
                            <div className={styles.subcard}>
                                <div>
                                    <p>
                                        <span>{dashboardData.performance_review_stats.below}</span>
                                        {t("HR.reviewBelow")}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span>{dashboardData.performance_review_stats.average}</span>
                                        {t("HR.reviewAverage")}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span>{dashboardData.performance_review_stats.above}</span>
                                        {t("HR.reviewAbove")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={styles.secondLine}>
                    <h3>{t("HR.managerOverview")}</h3>
                    <div className={styles.table}>
                        <div className={styles.titleColumn}>
                            <div className={styles.avatar}></div>
                            <div>{capitalizeFirstLetter(t("HR.talent", {count:2}) + " " + t("HR.lessCommitted"))}</div>
                            <div>{capitalizeFirstLetter(t("HR.talent", {count:2}) + " " + t("HR.committed"))}</div>
                            <div>{capitalizeFirstLetter(t("HR.talent", {count:2}) + " " + t("HR.veryCommitted"))}</div>
                            <div>{t("common.goalsProgression")}</div>
                            <div>{t("HR.goalsCount")}</div>
                            <div>{t("HR.experiencesCount")}</div>
                            <div>{t("HR.conversationsCount")}</div>
                            <div>{t("HR.feedbacksCount")}</div>
                            <div>{t("HR.requestsCount")}</div>
                        </div>
                        <div className={styles.tableContent}>
                            {managersStats.map((manager, index) => (
                                <div key={index} className={styles.tableColumn}>
                                    <div className={styles.avatar}><AvatarIcon member={manager} isForHRStats={true} /></div>
                                    <div>{manager.engagement.less_committed}</div>
                                    <div>{manager.engagement.committed}</div>
                                    <div>{manager.engagement.very_committed}</div>
                                    <div>{manager.goalCompletion}</div>
                                    <div>{manager.entriesCount.goals}</div>
                                    <div>{manager.entriesCount.experiences}</div>
                                    <div>{manager.entriesCount.conversations}</div>
                                    <div>{manager.entriesCount.feedbacks}</div>
                                    <div>{manager.entriesCount.requests}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}