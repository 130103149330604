import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from '../components/button';
import styles from './hrcomments.module.css';
import {AppDispatch, RootState} from "../store/store";
import * as React from "react";
import validateLogo from "../assets/page-validate.svg"
import validateHoverLogo from "../assets/page-validate-hover.svg"
import {HRComment as HRCommentInterface} from "../interfaces/hrComment.interface";
import HRCommentComponent from "./HRComment";
import {createHRComment} from "../store/reducers/HRCommentsReducer";

interface HRCommentProps {
    HRcomments: HRCommentInterface[]
    collaboratorId: number
    updateComments: Function
}

const HRCommentsComponent: React.FunctionComponent<HRCommentProps> = ({ HRcomments, collaboratorId, updateComments}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [comment, setComment] = useState<string>("");

    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)

    return (
        <section className={styles.modalForm}>
            <h4 className={styles.modalFormTitle}>{t("HR.title")}</h4>
            <div>
                {HRcomments.map((HRcomment, index) => (
                    <HRCommentComponent HRcomment={HRcomment} key={index}/>
                ))}
            </div>
            <div className={styles.addHRComment}>
                <form className={styles.form}>
                    <input
                        type="text"
                        className={styles.input}
                        placeholder={t("HR.comment")}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)} />
                </form>
                <div className={styles.buttonContainer}>
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.add")}
                        submit={() => {
                            dispatch(
                                createHRComment({
                                    comment: comment,
                                    collaborator: collaboratorId,
                                    created_by: activeUser.id
                                })
                            )
                            updateComments(true)
                        }
                        }
                        icon={validateLogo}
                        iconHover={validateHoverLogo} />
                </div>
            </div>
        </section>
    );
}

export default HRCommentsComponent;