import api from "../utils/http-common"
import { Feedback } from "../interfaces/feedback.interface"
import { FeedbackAdapter } from "../adapters/feedback.adapter"

export default class FeedbackServices {
    createFeedback(token: string, feedback: Feedback){
        return api.post("/feedbacks/", FeedbackAdapter(feedback, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    getFeedbackDetails(token: string, feedbackId: string){
        return api.get("/feedbacks/" + feedbackId,{headers: {Authorization: `Bearer ${token}`}})
    }
    getAllFeedbacks(token: string){
        return api.get("/feedbacks/", {headers: {Authorization: `Bearer ${token}`}})
    }
    updateFeedback(token: string, feedback: Feedback){
        return api.put("/feedbacks/" + feedback.id, FeedbackAdapter(feedback, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    deleteFeedback(token: string, feedback: { id: number }){
        return api.delete("/feedbacks/" + feedback.id, {headers: {Authorization: `Bearer ${token}`}})
    }
}