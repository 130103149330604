import * as React from 'react';
import { useTranslation } from "react-i18next";
import LangHandler from './LangHandler';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import {stringAvatar} from "../../utils/avatar";
import styles from "./header.module.css";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {Link, Navigate} from "react-router-dom";
import {logout} from "../../store/reducers/activeUserReducer";
import {useState} from "react";


const ProfileMenu: React.SFC<{ managerHeader: boolean, hrHeader: boolean }> = ({managerHeader, hrHeader}) =>  {
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [redirect, setRedirect] = useState<boolean>(false);
    const [openProfileElement, setOpenProfileElement] = React.useState<null | HTMLElement>(null);
    const openProfile = Boolean(openProfileElement);

    const handleOpenProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenProfileElement(event.currentTarget);
    };
    const handleCloseProfile = () => {
        setOpenProfileElement(null);
    };
    const handleLogout = () => {
        setOpenProfileElement(null);
        dispatch(logout(null))
        setRedirect(true)
    };

    let activeUser = useSelector((state: RootState) => state.activeUser.activeUser);
    let managerSwitch = <div></div>
    if (activeUser.isManager && managerHeader){
        if(activeUser.modules_choices.includes("all")){
            managerSwitch = <MenuItem onClick={handleCloseProfile}><Link className={styles.profileButton} to="/personal-dashboard">{t('header.dashboard')}</Link></MenuItem>
        } else if (activeUser.modules_choices.includes("campaign")){
            managerSwitch = <MenuItem onClick={handleCloseProfile}><Link className={styles.profileButton} to="/campaign-answers/">{t('header.dashboard')}</Link></MenuItem>
        }
    } else if (activeUser.isManager && !managerHeader){
        managerSwitch = <MenuItem onClick={handleCloseProfile}><Link className={styles.profileButton} to="/manager-dashboard">{t('header.dashboardManager')}</Link></MenuItem>
    }

    let hrSwitch = <div></div>
    if (activeUser.isHR && hrHeader){
        if(activeUser.modules_choices.includes("all")){
            hrSwitch = <MenuItem onClick={handleCloseProfile}><Link className={styles.profileButton} to="/personal-dashboard">{t('header.dashboard')}</Link></MenuItem>
        } else if (activeUser.modules_choices.includes("campaign")){
            hrSwitch = <MenuItem onClick={handleCloseProfile}><Link className={styles.profileButton} to="/campaign-answers/">{t('header.dashboard')}</Link></MenuItem>
        }
    } else if (activeUser.isHR && !hrHeader){
        hrSwitch = <MenuItem onClick={handleCloseProfile}><Link className={styles.profileButton} to="/">{t('header.dashboardHR')}</Link></MenuItem>
    }

    let avatar = null
    if(activeUser.avatar){
        avatar = process.env.REACT_APP_BACK_END_URL + activeUser.avatar
    }

    let fullName = ""
    if(activeUser.firstName){
        fullName += activeUser.firstName
    }
    if(activeUser.lastName){
        fullName = fullName + " " + activeUser.lastName
    }

    if (redirect){
        return <Navigate to="/login" />;
    }

    return (
        <div className={styles.navProfileMenu}>
            <span
                id="profile-button"
                aria-controls={openProfile ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openProfile ? 'true' : undefined}
                onClick={handleOpenProfile}
            >
                <figure className={styles.profileImg}>
                    {avatar &&
                        <img src={avatar} />
                    }
                    {!avatar &&
                        <Avatar {...stringAvatar(fullName)} />
                    }
                </figure>
            </span>
            <Menu
                id="basic-menu"
                anchorEl={openProfileElement}
                open={openProfile}
                onClose={handleCloseProfile}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {managerSwitch}
                {hrSwitch}
                <Link className={styles.profileButton} to="/profile">
                    <MenuItem className={styles.profileButton} onClick={handleCloseProfile}>
                        {t("header.profile")}
                    </MenuItem>
                </Link>
                <MenuItem className={styles.profileButton} onClick={handleLogout}>
                    {t("header.logout")}
                </MenuItem>
                <MenuItem><LangHandler/></MenuItem>
            </Menu>
        </div>
    );
}

export default ProfileMenu;