import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import ConversationDetails from '../components/conversationDetails';
import { Conversation as ConversationInterface} from '../interfaces/conversation.interface';
import {AppDispatch, RootState} from "../store/store";
import DashboardManager from "./dashboardManager";
import {useEffect} from "react";
import {getManagerConversationDetails} from "../store/reducers/conversationsReducer";
import {Sidebar} from "../components/sidebar";
import * as React from "react";
import { useTranslation } from "react-i18next";
import HeaderManager from "../components/header/headerManager";

export default function ViewManagerConversationDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.conversations.status);

    useEffect(() => {
        if(params.conversationId){
            dispatch(getManagerConversationDetails(params.conversationId));
        }
    }, [dispatch]);
    const conversation = useSelector((state: RootState) => state.conversations.conversations.find((conversation : ConversationInterface) => conversation.id == params.conversationId));

    if (status != "error") {
        return (
            <>
                <Sidebar/>
                <main>
                    <HeaderManager title={t("conversation.h1")}/>
                    {conversation &&
                        <ConversationDetails conversation={conversation}/>
                    }
                </main>
            </>
        );
    } else {
        return <DashboardManager />;
    }
}