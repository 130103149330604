import * as React from "react";
import {FutureTrainingCondat} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface FutureTrainingCondatProps {
    answer: FutureTrainingCondat
    changeHandler: Function
    readonly?: boolean
}

const FutureTrainingCondatComponent: React.SFC<FutureTrainingCondatProps> = ({answer, readonly, changeHandler}) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>(answer.title)
    const [goal, setGoal] = useState<string>(answer.goal)
    const [program, setProgram] = useState<string>(answer.program)
    const [cost, setCost] = useState<string>(answer.cost)
    const [origin, setOrigin] = useState<string>(answer.origin)
    const [priority, setPriority] = useState<string>(answer.program)
    const [opinion, setOpinion] = useState<string>(answer.opinion)
    const [duration, setDuration] = useState<string>(answer.duration)
    const [cpf, setCpf] = useState<string>(answer.cpf)
    const [comment, setComment] = useState<string>(answer.comment)
    const [organism, setOrganism] = useState<string>(answer.organism)

    useEffect(() => {
        let newAnswer = {
            organism:organism,
            title:title,
            goal:goal,
            program:program,
            cost:cost,
            origin:origin,
            opinion:opinion,
            priority:priority,
            duration:duration,
            cpf:cpf,
            comment:comment,
            type: answer.type,
        } as FutureTrainingCondat
        changeHandler(newAnswer)
    }, [title, origin, organism, goal, program, cost, opinion, priority, duration, cpf, comment])

    return (
        <div className={styles.titleInput + " " + styles.goalTemplate}>
            <h4>{t("common.training")}</h4>
            <div className={styles.goalLine}>
                <div className={styles.goalLine_firstItem}>
                    <label htmlFor="title">{t("training.title")}</label>
                    <input
                        type="text"
                        name="title"
                        defaultValue={title}
                        readOnly={readonly}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setTitle(event.target.value)
                        }}/>
                </div>
                <div className={styles.goalLine_secondItem}>
                    <label htmlFor="organism">{t("training.organism")}</label>
                    <div className={styles.Input}>
                        <Select
                            value={organism}
                            label={answer.organism}
                            readOnly={readonly}
                            onChange={(event: SelectChangeEvent) => {setOrganism(event.target.value)}}>
                            <MenuItem value="Interne">Interne</MenuItem>
                            <MenuItem value="Externe">Externe</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
            <label htmlFor="goal">{t("training.goal")}</label>
            <textarea
                name="goal"
                defaultValue={goal}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setGoal(event.target.value)
                    }}>
            </textarea>
            <label htmlFor="program">{t("training.program")}</label>
            <textarea
                name="program"
                defaultValue={program}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setProgram(event.target.value)
                    }}>
            </textarea>
            <div className={styles.goalLine}>
                <div className={styles.goalLine_firstItem}>
                    <label htmlFor="cost">{t("training.cost")}</label>
                    <input
                        type="text"
                        name="cost"
                        defaultValue={cost}
                        readOnly={readonly}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCost(event.target.value)
                        }}/>
                </div>
                <div className={styles.goalLine_secondItem}>
                    <label htmlFor="origin">{t("training.origin")}</label>
                    <div className={styles.Input}>
                        <Select
                            value={origin}
                            label="origin"
                            readOnly={readonly}
                            onChange={(event: SelectChangeEvent) => {setOrigin(event.target.value)}}>
                            <MenuItem value="Collaborateur">Collaborateur</MenuItem>
                            <MenuItem value="Manager">Manager</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
            <div className={styles.goalLine}>
                <div className={styles.goalLine_firstItem}>
                    <label htmlFor="duration">{t("training.duration")}</label>
                    <input
                        type="text"
                        name="duration"
                        defaultValue={duration}
                        readOnly={readonly}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setDuration(event.target.value)
                        }}/>
                </div>
                <div className={styles.goalLine_secondItem}>
                    <label htmlFor="opinion">{t("training.opinion")}</label>
                    <div className={styles.Input}>
                        <Select
                            value={opinion}
                            label="opinion"
                            readOnly={readonly}
                            onChange={(event: SelectChangeEvent) => {setOpinion(event.target.value)}}>
                            <MenuItem value="Favorable">Favorable</MenuItem>
                            <MenuItem value="Défavorable">Défavorable</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
            <div className={styles.goalLine}>
                <div className={styles.goalLine_firstItem}>
                    <label htmlFor="cpf">{t("training.cpf")}</label>
                    <input
                        type="text"
                        name="cpf"
                        defaultValue={cpf}
                        readOnly={readonly}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCpf(event.target.value)
                        }}/>
                </div>
                <div className={styles.goalLine_secondItem}>
                    <label htmlFor="priority">{t("training.priority")}</label>
                    <div className={styles.Input}>
                        <Select
                            value={priority}
                            label="priority"
                            readOnly={readonly}
                            onChange={(event: SelectChangeEvent) => {setPriority(event.target.value)}}>
                            <MenuItem value="1">1 (échelle de 1 à 5 - le moins prioritaire)</MenuItem>
                            <MenuItem value="2">2 (échelle de 1 à 5 - le moins prioritaire)</MenuItem>
                            <MenuItem value="3">3 (échelle de 1 à 5 - le moins prioritaire)</MenuItem>
                            <MenuItem value="4">4 (échelle de 1 à 5 - le moins prioritaire)</MenuItem>
                            <MenuItem value="5">5 (échelle de 1 à 5 - le moins prioritaire)</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
            <label htmlFor="comment">{t("training.comment")}</label>
            <textarea
                name="comment"
                defaultValue={comment}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setComment(event.target.value)
                    }}>
            </textarea>
        </div>
    );
};
export default FutureTrainingCondatComponent;