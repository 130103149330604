import * as React from 'react';
import { useTranslation } from "react-i18next";
import styles from './sidebar.module.css';
import Logo from '../assets/logo-full-white.png';
import dasbhboardIcon from '../assets/dashboard-icon.svg';
import dasbhboardIconActive from '../assets/dashboard-icon-selected.svg';
import talentsIcon from "../assets/talents-icon.svg";
import talentsIconActive from "../assets/talents-icon-selected.svg";
import {NavLink, Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {useEffect} from "react";
import Collaborator from "../interfaces/collaborator.interface";
import Avatar from "@mui/material/Avatar";
import {stringAvatar} from "../utils/avatar";
import { useLocation } from 'react-router-dom';
import campaignActiveLogo from "../assets/campaign-icon-selected.svg";
import campaignLogo from "../assets/campaign-icon.svg";
import appreciationActiveLogo from "../assets/appreciation-icon-selected.svg";
import appreciationLogo from "../assets/appreciation-icon.svg";
import {getOrganizationManagers} from "../store/reducers/organizationReducer";


export default function SidebarHR() {
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    let location = useLocation();

    useEffect(() => {
        dispatch(getOrganizationManagers())
    }, [dispatch])

    const managers = useSelector((state: RootState) => state.organization.managers)
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)

    return (
        <aside className={styles.sidebar}>
            <figure className={styles.logo}>
                <Link to="/">
                    <img src={Logo} />
                </Link>
            </figure>
            {activeUser.modules_choices.includes("all") &&
                <NavLink
                    to="/hr-dashboard/"
                    className={() =>
                        location.pathname === "/hr-dashboard/" ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={dasbhboardIconActive} />
                    <img className={"icon"} src={dasbhboardIcon} />
                    <span>{t("header.dashboardSidebar")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("campaign")) &&
                <NavLink
                    to="/hr-dashboard/campaigns/"
                    className={() =>
                        location.pathname.includes("/hr-dashboard/campaigns") ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={campaignActiveLogo}/>
                    <img className={"icon"} src={campaignLogo}/>
                    <span>{t("common.campaigns")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("appreciation")) &&
                <NavLink
                    to="/hr-dashboard/performance-reviews/"
                    className={() =>
                        location.pathname.includes("/hr-dashboard/performance-reviews") ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={appreciationActiveLogo}/>
                    <img className={"icon"} src={appreciationLogo}/>
                    <span>{t("common.appreciations")}</span>
                </NavLink>
            }
            {activeUser.modules_choices.includes("all") &&
                <>
                    <a className={styles.menuItem}>
                        <img className={"activeIcon"} src={talentsIconActive} />
                        <img className={"icon"} src={talentsIcon} />
                        <span>{t("header.managers")}</span>
                    </a>
                    <div>
                        <ul className={styles.talentList}>
                            { managers.map(
                                (manager: Collaborator, index) =>
                                    {
                                        let fullName = ""
                                        if(manager.first_name){
                                            fullName += manager.first_name
                                        }
                                        if(manager.last_name){
                                            fullName = fullName + " " + manager.last_name
                                        }
                                        return(
                                            <NavLink key={index} to={"/hr-dashboard/managers/" + manager.id} className={styles.menuItem}>
                                                <figure>
                                                    {manager.avatar &&
                                                        <img src={process.env.REACT_APP_BACK_END_URL + "media/"+ manager.avatar}/>
                                                    }
                                                    {!manager.avatar &&
                                                        <Avatar {...stringAvatar(fullName)} />
                                                    }
                                                </figure>
                                                <span>{manager.first_name!} {manager.last_name!}</span>
                                            </NavLink>
                                        )
                                    }
                            )}
                        </ul>
                    </div>
                </>
            }
        </aside>
    );
}
