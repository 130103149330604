import {useSelector} from "react-redux";
import Header from '../components/header/header';
import Profile from '../components/Profile';
import {RootState} from "../store/store";
import Dashboard from "./dashboard";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";

export default function ViewProfile() {
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);

    if (activeUser) {
        return (
            <>
                <SidebarTalent />
                <main>
                    <Header />
                    <Profile activeUser={activeUser}/>
                </main>
            </>
        );
    } else {
        return <Dashboard />;
    }
}