import { useTranslation } from "react-i18next";
import styles from "./HRCampaignCard.module.css";
import {Campaign} from "../interfaces/campaign.interface";


interface CampaignProps {
    campaign: Campaign
}

const HRCampaignCard: React.SFC<CampaignProps> = ({ campaign }) => {
    const { t } = useTranslation();

    let titreCampaignAnswer = campaign.name!;
    if (titreCampaignAnswer.length > 60) {
        titreCampaignAnswer = titreCampaignAnswer.substring(0,60) + "..."
    }
    let status = "Draft"
    if (campaign.status) {
        status = "common." + campaign.status.toLowerCase()
    }
    let date = new Date()
    if (campaign.deadline) {
        date = new Date(campaign.deadline)
    }

    let campaignStats = campaign.stats[0]
    if (campaign.global_stats && Object.keys(campaign.global_stats).length > 0){
        campaignStats = campaign.global_stats
    }

    return (
        <>
            <li>
                <a href={"/hr-dashboard/campaigns/" + campaign.id} className={styles.LineLink}>
                    <div className={styles.Title}>
                        <p>{titreCampaignAnswer}</p>
                    </div>
                    <div className={styles.Stat}>
                        <p>{campaignStats.DRF}</p>
                    </div>
                    <div className={styles.Stat}>
                        <p>{campaignStats.SHDB + campaignStats.SHDC + campaignStats.SHDM}</p>
                    </div>
                    <div className={styles.Stat}>
                        <p>{campaignStats.VLDC + campaignStats.VLDM}</p>
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Status}>
                        <span className={campaign.status}></span>
                        {t(status)}
                    </div>
                </a>
            </li>
        </>
    );
};
export default HRCampaignCard;