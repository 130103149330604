import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import { Conversation, ConversationParameters } from "../../interfaces/conversation.interface";
import ConversationServices from "../../services/conversation.service";
import { ConversationAdapter, ConversationAdapterList } from "../../adapters/conversation.adapter";

interface ValidationErrors {
    collaborator_to: [string]
    details: [string]
    title: [string]
    completion_date: [string]
    conversation_type: [string]
}

const initialState = {
    conversations: [] as Conversation[],
    status: "idle",
    error: {} as ValidationErrors,
    count: 0,
    limit: 8,
    offset: 0
};

const conversationService : ConversationServices = new ConversationServices();

export const createConversation = createAsyncThunk(
    'conversations/create',
    async (data: Conversation, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let conversationData = {} as Conversation
        try {
            const response = await conversationService.createConversation(state.activeUser.activeUser.token, data)
            if (response.status == 201) {
                return ConversationAdapter(response.data, "in") as Conversation
            }
            return conversationData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })
    
export const getAllConversations = createAsyncThunk(
    'conversations/allConversations',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let conversationData = [] as Conversation[]
        try {
            const response = await conversationService.getAllConversations(state.activeUser.activeUser.token)
            if (response.status == 200) {
                conversationData = ConversationAdapterList(response.data.results, "in")
            }
            return conversationData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getConversationDetails = createAsyncThunk(
    'conversations/getDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let conversationData = {} as Conversation
        try {
            const response = await conversationService.getConversationDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                conversationData = ConversationAdapter(response.data, "in") as Conversation
            }
            return conversationData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const updateConversation = createAsyncThunk(
    'conversations/update',
    async (data: Conversation, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let conversationData = {} as Conversation
        try {
            const response = await conversationService.updateManagerConversation(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                conversationData = ConversationAdapter(response.data, "in") as Conversation
            }
            return conversationData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const deleteConversation = createAsyncThunk(
    'conversations/delete',
    async (data: {id: number}, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const conversationId = data.id
        try {
            const response = await conversationService.deleteConversation(state.activeUser.activeUser.token, data)
            if (response.status == 204 || response.status == 202) {
                return conversationId
            }
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getAllManagerConversations = createAsyncThunk(
        'conversations/allManagerConversations',
        async (parameters: ConversationParameters, thunkApi) => {
            const state = thunkApi.getState() as RootState
            let conversationData = {conversations:[] as Conversation[], count: 0, offset:0}
            try {
                
                const response = await conversationService.getAllManagerConversations(state.activeUser.activeUser.token, parameters)
                if (response.status == 200) {
                    conversationData.conversations = ConversationAdapterList(response.data.results, "in")
                    conversationData.count = response.data.count
                    if (parameters.offset){
                        conversationData.offset = parameters.offset;
                    }
                }
                return conversationData;
            } catch (err) {
                if (!err.response) {
                    throw err
                }
                return thunkApi.rejectWithValue(err.response.data as any);
            }
        })

export const getManagerConversationDetails = createAsyncThunk(
    'conversations/getManagerDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let conversationData = {} as Conversation
        try {
            const response = await conversationService.getManagerConversationDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                conversationData = ConversationAdapter(response.data, "in") as Conversation
            }
            return conversationData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })


const conversationSlice = createSlice({
    name: "conversations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createConversation.fulfilled, (state, action) => {
            state.status = "success"
            state.conversations.push(action.payload)
            state.error = {} as ValidationErrors
        })
        .addCase(createConversation.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(createConversation.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(updateConversation.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(updateConversation.fulfilled, (state, action) => {
            const index = state.conversations.findIndex((conversation) => conversation.id == action.payload.id)
            state.conversations[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(updateConversation.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(deleteConversation.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(deleteConversation.fulfilled, (state, action) => {
            state.conversations.filter((conversation) => conversation.id != action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(deleteConversation.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(getAllConversations.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllConversations.fulfilled, (state, action) => {
            state.conversations = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getAllConversations.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(getConversationDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getConversationDetails.fulfilled, (state, action) => {
            state.conversations.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getConversationDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(getAllManagerConversations.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllManagerConversations.fulfilled, (state, action) => {
            state.conversations = action.payload.conversations
            state.count = action.payload.count
            state.offset = action.payload.offset
            state.status = "success"
        })
        .addCase(getAllManagerConversations.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(getManagerConversationDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getManagerConversationDetails.fulfilled, (state, action) => {
            state.conversations.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getManagerConversationDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
    }
});

export default conversationSlice.reducer;