import axios from "axios";

const url = process.env.REACT_APP_BACK_END_URL + "api"

const api = axios.create({
    baseURL: url,
    headers: {
        "Content-type": "application/json"
    }
});

export default api;