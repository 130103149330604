import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {Request, RequestParameters} from "../../interfaces/request.interface";
import RequestServices from "../../services/request.service";
import { RequestAdapter, RequestAdapterList } from "../../adapters/request.adapter";

interface ValidationErrors {
    collaborator_from: [string]
    details: [string]
    title: [string]
    completion_date: [string]
}

const initialState = {
    requests: [] as Request[],
    status: "idle",
    error: {} as ValidationErrors,
    count: 0,
    limit: 8,
    offset: 0
};

const requestService : RequestServices = new RequestServices();

export const createRequest = createAsyncThunk(
    'requests/create',
    async (data: Request, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let requestData = {} as Request
        try {
            const response = await requestService.createRequest(state.activeUser.activeUser.token, data)
            if (response.status == 201) {
                return RequestAdapter(response.data, "in") as Request
            }
            return requestData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getAllRequests = createAsyncThunk(
    'requests/allRequests',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let requestData = [] as Request[]
        try {
            const response = await requestService.getAllRequests(state.activeUser.activeUser.token)
            if (response.status == 200) {
                requestData = RequestAdapterList(response.data.results, "in")
            }
            return requestData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getRequestDetails = createAsyncThunk(
    'requests/getDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let requestData = {} as Request
        try {
            const response = await requestService.getRequestDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                requestData = RequestAdapter(response.data, "in") as Request
            }
            return requestData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const updateRequest = createAsyncThunk(
    'requests/update',
    async (data: Request, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let requestData = {} as Request
        try {
            const response = await requestService.updateRequest(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                requestData = RequestAdapter(response.data, "in") as Request
            }
            return requestData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const deleteRequest = createAsyncThunk(
    'requests/delete',
    async (data: {id:number}, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const requestId = data.id
        try {
            const response = await requestService.deleteRequest(state.activeUser.activeUser.token, data)
            if (response.status == 204 || response.status == 202) {
                return requestId
            }
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getManagerRequestDetails = createAsyncThunk(
    'requests/getManagerDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let requestData = {} as Request
        try {
            const response = await requestService.getManagerRequestDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                requestData = RequestAdapter(response.data, "in") as Request
            }
            return requestData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const updateManagerRequest = createAsyncThunk(
    'requests/updateManager',
    async (data: Request, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let requestData = {} as Request
        try {
            const response = await requestService.updateManagerRequest(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                requestData = RequestAdapter(response.data, "in") as Request
            }
            return requestData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getAllManagerRequests = createAsyncThunk(
    'requests/allManagerRequests',
    async (parameters: RequestParameters, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let requestData = {requests:[] as Request[], count: 0, offset:0}
        try {
            const response = await requestService.getAllManagerRequests(state.activeUser.activeUser.token, parameters)
            if (response.status == 200) {
                requestData.requests = RequestAdapterList(response.data.results, "in")
                requestData.count = response.data.count
                if (parameters.offset){
                    requestData.offset = parameters.offset;
                }
            }
            return requestData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

const requestSlice = createSlice({
    name: "requests",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createRequest.fulfilled, (state, action) => {
            state.status = "success"
            state.requests.push(action.payload)
            state.error = {} as ValidationErrors
        })
        builder.addCase(createRequest.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(createRequest.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(deleteRequest.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(deleteRequest.fulfilled, (state, action) => {
            state.requests.filter((request) => request.id != action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(deleteRequest.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(getAllRequests.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getAllRequests.fulfilled, (state, action) => {
            state.requests = action.payload
            state.status = "success"
        })
        builder.addCase(getAllRequests.rejected, (state, action) => {
            state.status = "error"
        })
        builder.addCase(updateRequest.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(updateRequest.fulfilled, (state, action) => {
            const index = state.requests.findIndex((request) => request.id == action.payload.id)
            state.requests[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(updateRequest.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(getRequestDetails.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getRequestDetails.fulfilled, (state, action) => {
            state.requests.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(getRequestDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(getAllManagerRequests.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getAllManagerRequests.fulfilled, (state, action) => {
            state.requests = action.payload.requests
            state.count = action.payload.count
            state.offset = action.payload.offset
            state.status = "success"
        })
        builder.addCase(getAllManagerRequests.rejected, (state, action) => {
            state.status = "error"
        })
        builder.addCase(getManagerRequestDetails.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getManagerRequestDetails.fulfilled, (state, action) => {
            state.requests.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(getManagerRequestDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(updateManagerRequest.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(updateManagerRequest.fulfilled, (state, action) => {
            const index = state.requests.findIndex((request) => request.id == action.payload.id)
            state.requests[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(updateManagerRequest.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
    }
});

export default requestSlice.reducer;