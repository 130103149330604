import styles from "./HRCampaignCard.module.css";
import {CampaignStats, Organization} from "../interfaces/campaign.interface";


interface CampaignStatsProps {
    stats: CampaignStats
    organizations: Organization[]
}

const HRCampaignDetailCard: React.SFC<CampaignStatsProps> = ({ stats, organizations }) => {
    let organization = organizations.find((org) => org.pk == Number(stats.organization));
    let organizationName = organization ? organization.name : "Unknown";

    if (organization) {
        if (organization.name.length > 60) {
            organizationName = organizationName.substring(0,60) + "..."
        }
    }

    return (
        <>
            <li className={styles.LineLink}>
                <div className={styles.Organization}>
                    <p>{organizationName}</p>
                </div>
                <div className={styles.detailStats}>
                    <p>{stats.DRF}</p>
                </div>
                <div className={styles.detailStats}>
                    <p>{stats.SHDB}</p>
                </div>
                <div className={styles.detailStats}>
                    <p>{stats.SHDC}</p>
                </div>
                <div className={styles.detailStats}>
                    <p>{stats.SHDM}</p>
                </div>
                <div className={styles.detailStats}>
                    <p>{stats.VLDC}</p>
                </div>
                <div className={styles.detailStats}>
                    <p>{stats.VLDM}</p>
                </div>
            </li>
        </>
    );
};
export default HRCampaignDetailCard;