import HeaderNotConnected from '../components/header/headerNotConnected';
import styles from "./login.module.css";
import {useTranslation} from "react-i18next";
import Button from "../components/button";
import {useState} from "react";
import {userSignUp} from "../store/reducers/activeUserReducer";
import {AppDispatch, RootState} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import CustomAlerts from "../components/customAlerts";


export default function Signup() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const routerState = location.state as { email: string }
    const [email, setEmail] = useState<string>(routerState.email);
    const [password, setPassword] = useState<string>('');
    const [passwordVerified, setPasswordVerified] = useState<string>('');
    const dispatch = useDispatch<AppDispatch>();
    const signupStatus = useSelector((state: RootState) => state.activeUser.status);
    const errors = useSelector((state: RootState) => state.activeUser.error)

    return (
        <>
            <HeaderNotConnected />
            <main className={styles.loginMain}>
                <div>
                    <h2>{t("common.signUp")}</h2>
                    <input
                        name="email"
                        placeholder="obiwan@kenobi.com"
                        defaultValue={email}
                        onChange={
                            (e) => setEmail(e.target.value)
                        }>
                    </input>
                    {signupStatus === "error" && errors.email &&
                        <CustomAlerts errors={errors.email}/>
                    }
                    <input
                        name="password"
                        placeholder="*******"
                        type="password"
                        onChange={
                            (e) => setPassword(e.target.value)
                        }>
                    </input>
                    {signupStatus === "error" && errors.password1 &&
                        <CustomAlerts errors={errors.password1}/>
                    }
                    <input
                        name="passwordVerified"
                        placeholder="*******"
                        type="password"
                        onChange={
                            (e) => setPasswordVerified(e.target.value)
                        }>
                    </input>
                    {signupStatus === "error" && errors.password2 &&
                        <CustomAlerts errors={errors.password2}/>
                    }
                    {signupStatus === "error" && errors.non_field_errors &&
                        <CustomAlerts errors={errors.non_field_errors}/>
                    }
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.login")}
                        submit={
                            () => {
                                dispatch(userSignUp({email: email, password1: password, password2: passwordVerified}))
                                if (signupStatus === "success"){
                                    navigate("/")
                                }
                            }
                        }/>
                </div>
            </main>
        </>
    );
}