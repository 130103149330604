import * as React from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from "react-i18next";
import {AppDispatch, RootState} from '../store/store';
import Header from '../components/header/header';
import styles from './allgoals.module.css';
import {useEffect} from "react";
import {SidebarTalent} from "../components/sidebarTalent";
import { ToolTips } from "../components/toolTips";
import {PerformanceReview} from "../interfaces/performanceReview.interface";
import {getAllPerformanceReviews} from "../store/reducers/performanceReviewsReducer";
import PerformanceReviewCard from "../components/performanceReviewCard";

export default function AllPerformanceReviews() {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getAllPerformanceReviews());
    }, [dispatch]);
    const performanceReviews = useSelector((state: RootState) => state.performanceReviews.performanceReviews)

    return (
        <>
            <SidebarTalent />
            <main>
                <Header />
                <section className={styles.section}>
                    <h1>{t("header.appreciations")}
                    <ToolTips text={t("conversation.tooltips")} parent={document.getElementById("allConversations")}/></h1>
                    <div className={styles.Table}  id="allConversations">
                        <header>
                            <div className={styles.Title}>{t("common.evaluation")}</div>
                            <div className={styles.Status}>{t("common.status")}</div>
                            <div className={styles.Date}>{t("common.completionDate")}</div>
                        </header>
                        <ul>
                            { performanceReviews.map(
                                (performanceReview: PerformanceReview, index) =>
                                    <PerformanceReviewCard
                                        key={index}
                                        performanceReview={performanceReview}
                                    />
                            )}
                        </ul>
                    </div>
                </section>
            </main>
        </>
    );
}