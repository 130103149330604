import {useEffect, useRef, useState} from "react";
import styles from "../pages/dashboardManager.module.css";
import Avatar from '@mui/material/Avatar';
import Tooltip from "@mui/material/Tooltip";
import {NavLink} from "react-router-dom";
import Collaborator, { EngagementCollaborator } from "../interfaces/collaborator.interface";
import * as React from "react";
import {stringAvatar} from "../utils/avatar";

interface AvatarProps {
    member: Collaborator,
    tooManyAvatar?: boolean,
    talentNumber?: number,
    isForHRStats?: boolean,
}

const AvatarIcon: React.SFC<AvatarProps> = ({member,tooManyAvatar= false, talentNumber= 0, isForHRStats= false}) => {
    const avatarRef = useRef<HTMLElement | null>(null);
    const [height, setHeight] = useState<number>(0);

    const resize = () => {
        if(avatarRef.current){
            const newHeight = avatarRef.current.clientWidth;
            setHeight(newHeight);
        }
    };

    let fullName = ""
    if(member.first_name){
        fullName += member.first_name
    }
    if(member.last_name){
        fullName = fullName + " " + member.last_name
    }

    let link = "/team/member/" + member.id
    if(isForHRStats){
        link = "/hr-dashboard/managers/" + member.id
    }

    useEffect(() => {
        resize()
    }, [avatarRef]);

    useEffect(() => {
        window.addEventListener("resize", resize);
    }, []);

    return (
        <NavLink to={link} className={styles.avatarLink}>
            <Tooltip title={fullName} arrow>
                <figure ref={avatarRef} style={{height:height}}>
                    {tooManyAvatar &&
                        <Avatar {...stringAvatar("+ " + talentNumber)} />
                    }
                    {member.avatar && !tooManyAvatar &&
                            <img src={process.env.REACT_APP_BACK_END_URL + "media/"+ member.avatar}/>
                    }
                    {!member.avatar && !tooManyAvatar &&
                        <Avatar {...stringAvatar(fullName)} />
                    }
                </figure>
            </Tooltip>
        </NavLink>
    );
};
export default AvatarIcon;