import * as React from 'react';
import styles from "./header.module.css";
import NotificationsMenu from "./notificationsMenu";
import ProfileMenu from "./profileMenu";


const NavMenu: React.SFC<{ managerHeader: boolean, hrHeader: boolean }> = ({managerHeader, hrHeader}) =>  {
    return (
        <nav className={styles.navMenu}>
            <NotificationsMenu/>
            <ProfileMenu managerHeader={managerHeader} hrHeader={hrHeader}/>
        </nav>
    );
}

export default NavMenu;