import {createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import TeamServices from "../../services/team.service";
import {RootState} from '../store';
import Collaborator from "../../interfaces/collaborator.interface";
import {CollaboratorDetails} from "../../interfaces/collaborator.interface";

const initialState = {
    members: [] as Collaborator[],
    memberDetails: {} as CollaboratorDetails,
    status: "idle",
    error: {}
};

const teamService : TeamServices = new TeamServices();

export const getTeamMembers = createAsyncThunk(
    'team/getMembers',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let collaboratorData = {} as Collaborator[]
        try {
            const response = await teamService.getMembers(state.activeUser.activeUser.token)
            if (response.status == 200) {
                collaboratorData = response.data.results
            }
            return collaboratorData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const getTalentDetails = createAsyncThunk(
    'team/getTalentDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let collaboratorData = {} as CollaboratorDetails
        try {
            const response = await teamService.getTalentDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                collaboratorData = response.data
            }
            return collaboratorData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

const teamSlice = createSlice({
    name: "team",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTeamMembers.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getTeamMembers.fulfilled, (state, action) => {
            state.members = action.payload
            state.status = "success"
        })
        builder.addCase(getTeamMembers.rejected, (state, action) => {
            state.status = "error"
        })
        builder.addCase(getTalentDetails.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getTalentDetails.fulfilled, (state, action) => {
            state.memberDetails = action.payload
            state.status = "success"
        })
        builder.addCase(getTalentDetails.rejected, (state, action) => {
            state.status = "error"
        })
    },
});

export default teamSlice.reducer;