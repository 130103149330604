import ManagerStats from "../interfaces/manager.interface";

export const ManagerStatsAdapter = (managerstats: any, direction: "in" | "out") => {
    if (direction === "out"){
        let adaptedManagerStats = managerstats
        adaptedManagerStats.goal_completion = managerstats.goalCompletion
        adaptedManagerStats.entries_count = managerstats.entriesCount

        return adaptedManagerStats
    } else {
        let adaptedManagerStats = managerstats as ManagerStats
        adaptedManagerStats.goalCompletion = managerstats.goal_completion
        adaptedManagerStats.entriesCount = managerstats.entries_count

        return adaptedManagerStats
    }
}

export const ManagerStatsAdapterList = (managerstats: any, direction: "in" | "out") => {
    managerstats.forEach((campaign: any) => ManagerStatsAdapter(campaign, direction))
    if (direction === "in"){
        return managerstats as ManagerStats[]
    } else {
        return managerstats
    }
}