import * as React from "react";
import {PastTraining} from "../../interfaces/campaignAnswer.interface"
import styles from "../goaldetails.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface PastTrainingProps {
    answer: PastTraining
    changeHandler: Function
    readonly?: boolean
}

const PastTrainingComponent: React.SFC<PastTrainingProps> = ({answer, readonly, changeHandler}) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>(answer.title)
    const [target, setTarget] = useState<string>(answer.target)
    const [result, setResult] = useState<string>(answer.result)
    const [observations, setObservations] = useState<string>(answer.observations)

    useEffect(() => {
        let newAnswer = {
            title: title,
            target: target,
            result: result,
            observations: observations,
            position: answer.position,
            type: answer.type,
        } as PastTraining
        changeHandler(newAnswer)
    }, [title, target, result, observations])

    return (
        <div className={styles.titleInput + " " + styles.goalTemplate}>
            <h4>{t("common.training")}</h4>
            <label htmlFor="title">{t("training.title")}</label>
            <input
                type="text"
                name="title"
                defaultValue={title}
                readOnly={readonly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(event.target.value)
                }}/>
            <label htmlFor="target">{t("training.target")}</label>
            <input
                type="text"
                name="target"
                defaultValue={target}
                readOnly={readonly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTarget(event.target.value)
                }}/>
            <label htmlFor="result">{t("training.result")}</label>
            <textarea
                name="result"
                defaultValue={result}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setResult(event.target.value)
                    }}>
            </textarea>
            <label htmlFor="observations">{t("training.observations")}</label>
            <textarea
                name="observations"
                defaultValue={observations}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setObservations(event.target.value)
                    }}>
            </textarea>
        </div>
    );
};
export default PastTrainingComponent;