import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import styles from "./managerConversationCard.module.css";
import { CampaignAnswer } from '../interfaces/campaignAnswer.interface';
import {useDispatch, useSelector} from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { getTeamMembers } from "../store/reducers/teamReducer";
import CollaboratorSelectOption from "./collaboratorSelectOption";


interface CampaignAnswerProps {
    campaignAnswer: CampaignAnswer
}

const ManagerCampaignAnswerCard: React.SFC<CampaignAnswerProps> = ({ campaignAnswer }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    let status = "Draft"
    if (campaignAnswer.status) {
        status = "common." + campaignAnswer.status.toLowerCase()
    }
    let date = new Date()
    if (campaignAnswer.last_modified) {
        date = new Date(campaignAnswer.last_modified)
    }

    useEffect(() =>{
        dispatch(getTeamMembers())
    }, [dispatch])
    const members = useSelector((state: RootState) => state.team.members)
    const member = members.find(Collaborator => Collaborator.id === campaignAnswer.collaborator)

    let titreCampaignAnswer = campaignAnswer.campaign!;
    if (titreCampaignAnswer.length > 60) {
        titreCampaignAnswer = titreCampaignAnswer.substring(0,60) + "..."
    }

    return (
        <Fragment>
            <li>
                <a href={"/manager/campaign-answers/" + campaignAnswer.id} className={styles.LineLink}>
                    <CollaboratorSelectOption collaborator={member!}/>
                    <div className={styles.Title}>
                        <p>{titreCampaignAnswer}</p>
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Status}>
                        <span className={campaignAnswer.status}></span>
                        {t(status)}
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default ManagerCampaignAnswerCard;