import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {PerformanceReview} from "../interfaces/performanceReview.interface";
import styles from './goaldetails.module.css';
import { AppDispatch, RootState } from "../store/store";
import { getCollaboratorsList } from "../store/reducers/collaboratorReducer";
import * as React from "react";
import HeaderLogo from "../assets/appreciation-icon-page.svg";
import CollaboratorSelectOption from "./collaboratorSelectOption";
import Collaborator from "../interfaces/collaborator.interface";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "./button";
import closeLogo from "../assets/page-close.svg";
import validateLogo from "../assets/page-validate.svg";
import validateHoverLogo from "../assets/page-validate-hover.svg";
import shareLogo from "../assets/page-share-hover.svg";
import shareHoverLogo from "../assets/page-share.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import {Navigate} from "react-router-dom";
import {updateManagerPerformanceReview} from "../store/reducers/performanceReviewsReducer";

interface PerformanceReviewProps {
    performanceReview: PerformanceReview
}

const HRPerformanceReviewDetails: React.SFC<PerformanceReviewProps> = ({ performanceReview }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getCollaboratorsList())
    }, [dispatch])

    const [evaluation, setEvaluation] = useState<string>(performanceReview.evaluation);
    const [comment, setComment] = useState<string>(performanceReview.comment);
    const [performanceReviewDate, setPerformanceReviewDate] = useState<Date | null>(new Date(performanceReview.created_at));
    const [redirect, setRedirect] = useState<string>("");
    const collaborator = useSelector((state: RootState) => state.collaborators.collaborators.find((collaborator: Collaborator) => collaborator.id === performanceReview.collaborator));
    const manager = useSelector((state: RootState) => state.collaborators.collaborators.find((collaborator: Collaborator) => collaborator.id === performanceReview.manager));

    let readonly = false;
    let status = "Draft"
    let classStatus = "Draft"
    if (performanceReview.status) {
        status = "common." + performanceReview.status.toLowerCase()
        classStatus = performanceReview.status
    }

    const handleEvaluationChange = (event: SelectChangeEvent) => {
        setEvaluation(event.target.value);
    };

    if(redirect === "close"){
        window.history.back();
    }

    let evaluationTranslation = ""
    if (performanceReview.evaluation) {
        evaluationTranslation = "reviews." + evaluation
    }


    return (
        <>
            <section className={styles.modalForm}>
                <header>
                    <div className={styles.entryTitle}>
                        <img src={HeaderLogo}></img>
                        <h1>{t("common.appreciation")}</h1>
                    </div>
                    <CollaboratorSelectOption collaborator={collaborator!} displayName={true}/>
                    <div className={styles.Status}>
                        <span className={classStatus}></span>
                        <p>{t(status)}</p>
                    </div>
                </header>
                <form className={styles.form}>
                    <div className={styles.titleInput}>
                        <label htmlFor="evaluation">{t("reviews.evaluation")}</label>
                        <input
                            name="evaluation"
                            placeholder="Your evaluation"
                            readOnly={readonly}
                            defaultValue={t(evaluationTranslation)}>
                        </input>
                    </div>
                    <label htmlFor="manager">{t("reviews.manager")}</label>
                    <div className={styles.titleInput}>
                        <CollaboratorSelectOption collaborator={manager!} displayName={true}/>
                    </div>
                    <label htmlFor="comment">{t("reviews.comment")}</label>
                    <textarea
                        name="comment"
                        readOnly={readonly}
                        onChange={(e) => setComment(e.target.value)}
                        defaultValue={comment}>
                    </textarea>
                    <label>{t("reviews.date")}</label>
                    <div className={styles.DateInput}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                openTo="day"
                                readOnly={readonly}
                                views={['year', 'month', 'day']}
                                value={performanceReviewDate}
                                onChange={(newDate) => {
                                    setPerformanceReviewDate(newDate);
                                }}
                                renderInput={(params) => {
                                    let newParams = {...params}
                                    if(newParams.inputProps){
                                        newParams.inputProps.value  = t("dates.completionDate", { date: performanceReviewDate })
                                    }
                                    return(<TextField {...newParams}/>)}}
                            />
                        </LocalizationProvider>
                    </div>
                </form>
                <div className={styles.buttonContainer}>
                    <Button
                        classCss={"secondary-button"}
                        content={t("buttons.close")}
                        submit={
                            () => {
                                setRedirect("close")
                            }
                        }
                        icon={closeLogo}
                        iconHover={closeHoverLogo}/>
                </div>
            </section>
        </>
    );
}

export default HRPerformanceReviewDetails;