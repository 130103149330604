import {createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {RootState} from '../store';
import Collaborator from "../../interfaces/collaborator.interface";
import OrganizationServices from "../../services/organization.service";
import ManagerStats from "../../interfaces/manager.interface";
import {ManagerStatsAdapter} from "../../adapters/managersStats.adapter";

const initialState = {
    managers: [] as Collaborator[],
    teamDetails: {} as ManagerStats,
    status: "idle",
    error: {}
};

const organizationService : OrganizationServices = new OrganizationServices();

export const getOrganizationManagers = createAsyncThunk(
    'organization/getManagers',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let managerData = {} as Collaborator[]
        try {
            const response = await organizationService.getManagers(state.activeUser.activeUser.token)
            if (response.status == 200) {
                managerData = response.data.results
            }
            return managerData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })


export const getOrganizationManagerTeamDetails = createAsyncThunk(
    'organization/getManagerTeamDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let managerStats = {} as ManagerStats
        try {
            const response = await organizationService.getManagerTeamDetails(data, state.activeUser.activeUser.token)
            if (response.status == 200) {
                managerStats = ManagerStatsAdapter(response.data, "in")
            }
            return managerStats
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })



const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOrganizationManagers.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getOrganizationManagers.fulfilled, (state, action) => {
            state.managers = action.payload
            state.status = "success"
        })
        builder.addCase(getOrganizationManagers.rejected, (state, action) => {
            state.status = "error"
        })
        builder.addCase(getOrganizationManagerTeamDetails.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getOrganizationManagerTeamDetails.fulfilled, (state, action) => {
            state.teamDetails = action.payload
            state.status = "success"
        })
        builder.addCase(getOrganizationManagerTeamDetails.rejected, (state, action) => {
            state.status = "error"
        })
    },
});

export default organizationSlice.reducer;