import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import styles from "./managerConversationCard.module.css";
import { Conversation } from '../interfaces/conversation.interface';
import {useDispatch, useSelector} from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { getTeamMembers } from "../store/reducers/teamReducer";
import CollaboratorSelectOption from "./collaboratorSelectOption";


interface ConversationProps {
    conversation: Conversation
}

const ManagerConversationCard: React.SFC<ConversationProps> = ({ conversation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    let status = "Draft"
    if (conversation.status) {
        status = "common." + conversation.status.toLowerCase()
    }
    let date = new Date()
    if (conversation.last_modified) {
        date = new Date(conversation.last_modified)
    } else if (conversation.completionDate) {
        date = new Date(JSON.parse(conversation.completionDate))
    }
    
    useEffect(() =>{
        dispatch(getTeamMembers())
    }, [dispatch])
    const members = useSelector((state: RootState) => state.team.members)
    const member = members.find(Collaborator => Collaborator.id === conversation.collaborator)

    let titreConversation = conversation.title;
    if (titreConversation.length > 60) {
        titreConversation = titreConversation.substring(0,60) + "..."
    }

    return (
        <Fragment>
            <li>
                <a href={"/manager/conversations/" + conversation.id} className={styles.LineLink}>
                    <CollaboratorSelectOption collaborator={member!}/>
                    <div className={styles.Title}>
                        <p>{titreConversation}</p>
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Status}>
                        <span className={conversation.status}></span>
                        {t(status)}
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default ManagerConversationCard;