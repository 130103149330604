import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import {Navigate, useNavigate} from "react-router-dom";
import Button from './button';
import styles from '../pages/addgoal.module.css';
import {AppDispatch, RootState} from "../store/store";
import ActiveUser from "../interfaces/activeUser.interface";
import {updateProfile} from "../store/reducers/activeUserReducer";
import * as React from "react";
import validateLogo from "../assets/page-validate.svg";
import validateHoverLogo from "../assets/page-validate-hover.svg";
import shareLogo from "../assets/page-share.svg";
import shareHoverLogo from "../assets/page-share-hover.svg";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomAlerts from "./customAlerts";

interface ActiveUserProps {
    activeUser: ActiveUser
}

const Profile: React.FunctionComponent<ActiveUserProps> = ({activeUser}) => {
    const { t } = useTranslation();
    const status = useSelector((state: RootState) => state.activeUser.status)
    const [redirect, setRedirect] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>(activeUser.firstName ? activeUser.firstName : "");
    const [lastName, setLastName] = useState<string>(activeUser.lastName ? activeUser.lastName : "");
    const [avatar, setAvatar] = useState<any>(activeUser.avatar ? activeUser.avatar : null);
    const [emailPreferences, setEmailPreferences] = useState<string>(activeUser.email_preferences ? activeUser.email_preferences : "ALL");
    let navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    if (redirect && status === "success"){
        return <Navigate to="/" />;
    }

    const handleEmailPreferencesChange = (event: SelectChangeEvent) => {
        setEmailPreferences(event.target.value);
    };

    return (
        <>
            <section className={styles.modalForm}>
                <header>
                    <h1>{t("profile.title")}</h1>
                </header>
                <form className={styles.form}>
                    <label htmlFor="firstName">{t("profile.firstName")}</label>
                    <input
                        name="firstName"
                        defaultValue={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    >
                    </input>
                    <label htmlFor="lastName">{t("profile.lastName")}</label>
                    <input
                        name="lastName"
                        defaultValue={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    >
                    </input>
                    { avatar == activeUser.avatar &&
                        <div className={"avatar"}>
                            <img src={process.env.REACT_APP_BACK_END_URL + avatar}/>
                        </div>
                    }
                    { avatar != activeUser.avatar &&
                        <div className={"avatar"}>
                            <img src={URL.createObjectURL(avatar)}/>
                        </div>
                    }
                    <label htmlFor="avatar">{t("profile.avatar")}</label>
                    <input type="file"
                           id="image"
                           accept="image/png, image/jpeg, image/gif"
                           onChange={(e) => setAvatar(e.target.files ? e.target.files[0] : null)}
                    >
                    </input>
                    <label htmlFor="email-label">{t("profile.emailPreferences.label")}</label>
                    <div className={styles.Input}>
                        <Select
                            labelId="email-label"
                            id="email-select"
                            value={emailPreferences}
                            label="Email Preferences"
                            onChange={handleEmailPreferencesChange}
                        >
                            <MenuItem key={"ALL"} value={"ALL"}>{t("profile.emailPreferences.all")}</MenuItem>
                            <MenuItem key={"DAILY"} value={"DAILY"}>{t("profile.emailPreferences.daily")}</MenuItem>
                            <MenuItem key={"WEEKLY"} value={"WEEKLY"}>{t("profile.emailPreferences.weekly")}</MenuItem>
                            <MenuItem key={"NONE"} value={"NONE"}>{t("profile.emailPreferences.none")}</MenuItem>
                        </Select>
                    </div>
                </form>
                <div className={styles.buttonContainer}>
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.save")}
                        icon={validateLogo}
                        iconHover={validateHoverLogo}
                        submit={
                            () => {
                                dispatch(
                                    updateProfile({
                                        id: activeUser.id,
                                        firstName: firstName,
                                        lastName: lastName,
                                        avatar: avatar,
                                        email_preferences: emailPreferences
                                    })
                                )
                                setRedirect(true)
                            }
                        }/>
                    <Button
                        classCss={"secondary-button"}
                        content={t("buttons.changePassword")}
                        icon={shareLogo}
                        iconHover={shareHoverLogo}
                        submit={
                            () => {
                                navigate("/profile/password-change")
                            }
                        }/>
                </div>
            </section>
        </>
    );
}

export default Profile;