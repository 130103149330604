import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RadarIcon from '@mui/icons-material/Radar';
import WorkspaceIcon from '@mui/icons-material/WorkspacePremium';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import Donut from '../components/donut';
import styles from './dashboard.module.css';
import {AppDispatch, RootState} from "../store/store";
import {useDispatch, useSelector} from 'react-redux';
import ExperiencesList from "../components/experiencesList";
import GoalsList from "../components/goalsList";
import {getPersonalDashboardData} from "../store/reducers/dashboardReducer"
import Loader from "../components/loader";
import {useEffect} from "react";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import Header from "../components/header/header";
import { ToolTips } from "../components/toolTips";

export default function Dashboard() {
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)
    const fetchStatus = useSelector((state: RootState) => state.activeUser.status)
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getPersonalDashboardData(activeUser));
    }, [dispatch]);

    const { t } = useTranslation();
    const dashboardData = useSelector((state: RootState) => state.dashboard.dashboardData);
    const experiencesPercentage = Math.min(100, Math.max(0, (dashboardData.experiences.experiencesCount / 5 * 100)));



    return (
        <>
            <SidebarTalent />
            <main>
                <Header />
                <section className={styles.overview}>
                    <div className={styles.card} id="dashboardGoal">
                        <div className={styles.cardTitleContent}>
                            <h2 className={styles.cardTitle}>
                                <RadarIcon />
                                <Link to="/goals/">{dashboardData.goals.goalsCount}</Link> {t("common.goals")}
                                
                                <ToolTips text={t("dashboard.toolTips.goal")} parent={document.getElementById("dashboardGoal")}/>
                            </h2>
                            <p>{t("dashboard.goalAverage")}</p>
                        </div>
                        <div className={styles.donut}>
                            <Donut classCss={styles.baseDonut} value={100} size={100}/>
                            <Donut classCss={styles.donutGraph} value={dashboardData.goals.goalsAchievement} size={100}/>
                            <span className={styles.donutValue}>{dashboardData.goals.goalsAchievement.toFixed(0)}%</span>
                        </div>
                    </div>
                    <div className={styles.card} id="dashboardExperience">
                        <div className={styles.cardTitleContent}>
                            <h2 className={styles.cardTitle}>
                                <WorkspaceIcon />
                                <Link to="/experiences/">{dashboardData.experiences.experiencesCount}</Link> {t("common.experiences")}
                                <ToolTips text={t("dashboard.toolTips.experience")} parent={document.getElementById("dashboardExperience")}/>
                            </h2>
                            <p>{t("dashboard.experienceTarget")}</p>
                        </div>
                        <div className={styles.donut}>
                            <Donut classCss={styles.baseDonut} value={100} size={100}/>
                            <Donut classCss={styles.donutGraph} value={experiencesPercentage} size={100}/>
                            <span className={styles.donutValue}>{dashboardData.experiences.experiencesCount}<span>/5</span></span>
                        </div>
                    </div>
                    <div className={styles.cardsAside}>
                        <div className={styles.card}>
                            <p className={styles.feedbackReceived}>
                                <VolunteerActivismIcon />
                                <Link to="/feedbacks/">
                                    <span>{dashboardData.feedbacksReceived}</span>
                                </Link>
                                {t("dashboard.feedbacksReceived")}
                            </p>
                            <p>
                                <VolunteerActivismIcon />
                                <Link to="/feedbacks/">
                                    <span>{dashboardData.feedbacksSent}</span>
                                </Link>
                                {t("dashboard.feedbacksSent")}
                            </p>
                        </div>
                        <div className={styles.card}>
                            <p>
                                <MapsUgcIcon />
                                <Link to="/requests/">
                                    <span>{dashboardData.requestsCount}</span>
                                </Link>
                                {t("common.requests")}
                            </p>
                        </div>
                    </div>
                </section>
                <section className={styles.card}>
                    <div className={styles.cardHeader}>
                        <h2>{t("dashboard.lastGoals")}</h2>
                        <div className={styles.buttons}>
                            <Link to="/goals/" className={"secondary-button"}>{t("buttons.all")}</Link>
                            <Link to="/goals/new" className={"primary-button"}>{t("buttons.add")}</Link>
                        </div>
                    </div>
                    <div className={styles.cardContent}>
                        <GoalsList goals={dashboardData.goals.lastGoals}/>
                    </div>
                </section>
                <section className={styles.card}>
                    <div className={styles.cardHeader}>
                        <h2>{t("dashboard.lastExperiences")}</h2>
                        <div className={styles.buttons}>
                            <Link to="/experiences/" className={"secondary-button"}>{t("buttons.all")}</Link>
                            <Link to="/experiences/new" className={"primary-button"}>{t("buttons.add")}</Link>
                        </div>
                    </div>
                    <div className={styles.cardContent}>
                        <ExperiencesList experiences={dashboardData.experiences.lastExperiences}/>
                    </div>
                </section>
            </main>
            {/*<Loader loading={fetchStatus === "loading"}></Loader>*/}
        </>
    );
}