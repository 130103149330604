import { useTranslation } from "react-i18next";
import styles from "./managerConversationsList.module.css";
import sharingEmptyStage from "../assets/sharing-emptystage.png";
import dots from "../assets/dots.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import * as React from "react";
import { useEffect, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HeaderLogo from "../assets/campaign-header-logo.svg";
import {CampaignAnswer, CampaignAnswerParameters} from "../interfaces/campaignAnswer.interface";
import {getAllManagerCampaignAnswers} from "../store/reducers/campaignAnswersReducer";
import ManagerCampaignAnswerCard from "./managerCampaignAnswerCard";

interface ParametersProps {
    parameters: CampaignAnswerParameters
}

export const ManagerCampaignAnswerList: React.SFC<ParametersProps> = ({ parameters }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getAllManagerCampaignAnswers(parameters));
    }, [dispatch]);

    const campaignAnswers = useSelector((state: RootState) => state.campaignAnswers);
    const campaignAnswersList = campaignAnswers.campaignAnswers;
    const campaignAnswersOffset = campaignAnswers.offset;
    const campaignAnswersCount = campaignAnswers.count;

    const [offset, setOffset] = useState(campaignAnswersOffset);
    const limit : number  = parameters.limit || 8;

    useEffect(() => {
        setOffset(campaignAnswers.offset)
    }, [campaignAnswers])

    return (
        <div className={styles.List}>
            <div className={styles.pageTitle}>
                <img src={HeaderLogo} className={styles.conversationIcon}/>
                <h1>
                    {t("common.campaign")}
                </h1>
            </div>
            {campaignAnswersCount > 0
                ?
                <>
                    <div className={styles.Header}>
                        <div className={styles.headerTalent}>{t("common.talent")}<img src={dots} /></div>
                        <div className={styles.headerTitle}>{t("common.title")}<img src={dots} /></div>
                        <div className={styles.headerDate}>{t("common.date")}<img src={dots} /></div>
                        <div className={styles.headerStatus}>{t("common.status")}<img src={dots} /></div>
                    </div>
                    <div className={styles.Sharing}>
                        {campaignAnswersList.map(
                            (campaignAnswer: CampaignAnswer, index) =>
                                <ManagerCampaignAnswerCard
                                    key={index}
                                    campaignAnswer={campaignAnswer}
                                />
                        )}
                    </div>
                    <div className={styles.EntryPagination}>
                        {offset + 1} - {offset + limit <= campaignAnswersCount ? offset + limit : campaignAnswersCount} {t("common.on")} {campaignAnswersCount}
                        {offset != 0
                            ? <ChevronLeftIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset-limit
                                        dispatch(getAllManagerCampaignAnswers(newParameters))
                                    }
                                }/>
                            : <ChevronLeftIcon/>
                        }
                        {offset + limit < campaignAnswersCount
                            ? <ChevronRightIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset+limit
                                        dispatch(
                                            getAllManagerCampaignAnswers(newParameters)
                                        )
                                    }
                                }/>
                            : <ChevronRightIcon/>
                        }
                    </div>
                </>
                :
                <div className={styles.EmptyStage}>
                    <img src={sharingEmptyStage} />
                    <p>{t("campaign.emptyList")}</p>
                </div>
            }
        </div>
    );
}