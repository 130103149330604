import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import styles from "./managerConversationCard.module.css";
import {useDispatch, useSelector} from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { getTeamMembers } from "../store/reducers/teamReducer";
import { Avatar } from "@mui/material";
import {stringAvatar} from "../utils/avatar";
import noAvatar from "../assets/avatar.svg"
import { Request } from "../interfaces/request.interface";


interface RequestProps {
    request: Request
}

const ManagerRequestCard: React.SFC<RequestProps> = ({ request }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    let status = "Draft"
    if (request.status) {
        status = "common." + request.status.toLowerCase()
    }
    let date = new Date()
    if (request.last_modified) {
        date = new Date(request.last_modified)
    } else if (request.completionDate) {
        date = new Date(JSON.parse(request.completionDate))
    }
    
    useEffect(() =>{
        dispatch(getTeamMembers())
    }, [dispatch])
    const members = useSelector((state: RootState) => state.team.members)
    const member = members.find(Collaborator => Collaborator.id === request.author)

    let titreRequest = request.title;
    if (titreRequest.length > 60) {
        titreRequest = titreRequest.substring(0,60) + "..."
    }

    let fullName = ""
    if (member) {
        if (member.first_name) {
            fullName += member.first_name
        }
        if (member.last_name) {
            fullName = fullName + " " + member.last_name
        }
    }

    return (
        <Fragment>
            <li>
                <a href={"/manager/requests/" + request.id} className={styles.LineLink}>
                    <div className={styles.Collaborator}>
                        {member && member.avatar &&
                            <img src={process.env.REACT_APP_BACK_END_URL + "media/" + member.avatar} />
                        }
                        {member && !member.avatar &&
                            <Avatar {...stringAvatar(fullName)} />
                        }
                        {!member &&
                            <img src={noAvatar} />
                        }
                    </div>
                    <div className={styles.Title}>
                        <p>{titreRequest}</p>
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Status}>
                        <span className={request.status}></span>
                        {t(status)}
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default ManagerRequestCard;