import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import HeaderManager from '../components/header/headerManager';
import ManagerCampaignAnswerDetails from "../components/managerCampaignAnswerDetails";
import { CampaignAnswer as CampaignAnswerInterface} from '../interfaces/campaignAnswer.interface';
import {AppDispatch, RootState} from "../store/store";
import Dashboard from "./dashboard";
import {useEffect} from "react";
import {getManagerCampaignAnswerDetails} from "../store/reducers/campaignAnswersReducer";
import {Sidebar} from "../components/sidebar";
import { useTranslation } from "react-i18next";

export default function ViewManagerCampaignAnswerDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.campaignAnswers.status);

    useEffect(() => {
        if(params.campaignAnswerId){
            dispatch(getManagerCampaignAnswerDetails(params.campaignAnswerId));
        }
    }, [dispatch]);
    const campaignAnswer = useSelector((state: RootState) => state.campaignAnswers.campaignAnswers.find((campaignAnswers : CampaignAnswerInterface) => campaignAnswers.id == params.campaignAnswerId));

    if (status != "error") {
        return (
            <>
                <Sidebar />
                <main>
                    <HeaderManager title={t("conversation.h1")}/>
                    {campaignAnswer &&
                        <ManagerCampaignAnswerDetails campaignAnswer={campaignAnswer}/>
                    }
                </main>
            </>
        );
    } else {
        return <Dashboard />;
    }
}