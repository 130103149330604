import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DashboardManagerServices from "../../services/dashboardManager.service";
import {EngagementCollaborator} from "../../interfaces/collaborator.interface";
import {RootState} from "../store";

const initialState = {
    status: 'idle',
    error: {} as any,
    dashboardData : {
        goal: 0,
        talentEngagement: {
            less_committed: [] as EngagementCollaborator[],
            committed: [] as EngagementCollaborator[],
            very_committed: [] as EngagementCollaborator[]
        },
    }
}
const dashboardManagerService : DashboardManagerServices = new DashboardManagerServices();

export const getManagerDashboardData = createAsyncThunk(
    'dashboardManager/managerdata',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let dashboardData = initialState.dashboardData
        try {
            const response = await dashboardManagerService.getDashboardData(state.activeUser.activeUser.token)
            if (response.status == 200) {
                dashboardData = {
                    goal: response.data.goal,
                    talentEngagement: response.data.talentEngagement,
                }
            }
            return dashboardData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })


const dashboardManagerSlice = createSlice({
    name: "dashboardManager",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getManagerDashboardData.fulfilled, (state, action) => {
            state.dashboardData = action.payload
            state.status = "success"
        })
        .addCase(getManagerDashboardData.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getManagerDashboardData.rejected, (state, action) => {
            state.error = action.payload!
            state.status = "error"
        })
    },
});

export default dashboardManagerSlice.reducer;