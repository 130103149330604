import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Link } from 'react-router-dom';
import styles from "./goalcard.module.css";
import {CampaignAnswer} from "../interfaces/campaignAnswer.interface";
import ModeIcon from "@mui/icons-material/Mode";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface CampaignAnswerProps {
    campaignAnswer: CampaignAnswer,
}

const CampaignAnswerCard: React.SFC<CampaignAnswerProps> = ({ campaignAnswer }) => {
    const { t } = useTranslation();
    let status = "Draft"
    if (campaignAnswer.status) {
        status = "common." + campaignAnswer.status.toLowerCase()
    }
    let date = new Date()
    if (campaignAnswer.last_modified) {
        date = new Date(campaignAnswer.last_modified)
    }

    return (
        <Fragment>
            <li>
                <a href={"/campaign-answers/" + campaignAnswer.id} className={styles.LineLink}>
                    <div className={styles.Title}>
                        <p>{campaignAnswer.campaign}</p>
                    </div>
                    <div className={styles.Status}>
                        <div className={campaignAnswer.status}></div>
                        {t(status)}
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Actions}>
                        <Link to={"/campaign-answers/" + campaignAnswer.id} className={styles.link}>
                            {campaignAnswer.status === "Draft" &&
                                <ModeIcon />
                            }
                            {campaignAnswer.status != "Draft" &&
                                <VisibilityIcon />
                            }
                        </Link>
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default CampaignAnswerCard;