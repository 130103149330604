import React, { ChangeEvent, useState } from "react"
import { useTranslation } from 'react-i18next';
import { Language } from '../../i18n/Language.interface';
import styles from "./header.module.css";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {updateProfile} from "../../store/reducers/activeUserReducer";

const LangHandler = () => {
    const { i18n } = useTranslation();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);
    const dispatch = useDispatch<AppDispatch>();
    const [lang, setLang] = useState<Language>(i18n.language as Language);
    if(activeUser.lang){
        setLang(activeUser.lang)
    }

    let changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
        let language = event.target.value;

        switch (language) {
            case Language.EN:
                setLang(Language.EN);
                i18n.changeLanguage(Language.EN);
                dispatch(
                    updateProfile({
                        id:activeUser.id,
                        firstName:activeUser.firstName!,
                        lastName:activeUser.lastName!,
                        avatar:activeUser.avatar!,
                        lang:"EN"
                    })
                )
                break;
            case Language.FR:
                setLang(Language.FR);
                i18n.changeLanguage(Language.FR);
                dispatch(
                    updateProfile({
                        id:activeUser.id,
                        firstName:activeUser.firstName!,
                        lastName:activeUser.lastName!,
                        avatar:activeUser.avatar!,
                        lang:"FR"
                    })
                )
                break;
            default:
                setLang(Language.FR);
                i18n.changeLanguage(Language.FR);
                dispatch(
                    updateProfile({
                        id:activeUser.id,
                        firstName:activeUser.firstName!,
                        lastName:activeUser.lastName!,
                        avatar:activeUser.avatar!,
                        lang:"FR"
                    })
                )
                break;
        }
    }

    return (
        <>
            <select className={styles.selectLang} value={lang} name="language" onChange={changeLanguage}>
                <option value={Language.FR}>FR</option>
                <option value={Language.EN}>EN</option>
            </select>
        </>
    )
}

export default LangHandler;