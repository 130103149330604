import api from "../utils/http-common"
import {PerformanceReview, PerformanceReviewParameters} from "../interfaces/performanceReview.interface";
import {PerformanceReviewAdapter} from "../adapters/performanceReviews.adapter";


export default class PerformanceReviewServices {
    getAllPerformanceReviews(token: string){
        return api.get("/performance_reviews/",{headers: {Authorization: `Bearer ${token}`}})
    }
    getPerformanceReviewDetails(token: string, performanceReviewId: string){
        return api.get("/performance_reviews/" + performanceReviewId,{headers: {Authorization: `Bearer ${token}`}})
    }
    getManagerPerformanceReviewDetails(token: string, performanceReviewId: string){
        return api.get("/manager/performance_reviews/" + performanceReviewId,{headers: {Authorization: `Bearer ${token}`}})
    }
    updateManagerPerformanceReview(token: string, performanceReview: PerformanceReview){
        return api.put("/manager/performance_reviews/" + performanceReview.id, PerformanceReviewAdapter(performanceReview, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    getAllManagerPerformanceReviews(token: string, parameters: PerformanceReviewParameters){
        let url : string = "/manager/performance_reviews?";
        for (const [key, value] of Object.entries(parameters)) {
            url += `${key}=${value}&`
        }
        return api.get(url,{headers: {Authorization: `Bearer ${token}`}});
    }
    getAllHRPerformanceReviews(token: string, parameters: PerformanceReviewParameters){
        let url : string = "/hr/performance_reviews?";
        for (const [key, value] of Object.entries(parameters)) {
            url += `${key}=${value}&`
        }
        return api.get(url,{headers: {Authorization: `Bearer ${token}`}});
    }
    getHRPerformanceReviewDetails(token: string, performanceReviewId: string){
        return api.get("/hr/performance_reviews/" + performanceReviewId,{headers: {Authorization: `Bearer ${token}`}})
    }
}