import * as React from 'react';
import { useTranslation } from "react-i18next";
import styles from './sidebar.module.css';
import Logo from '../assets/logo-full-white.png';
import dasbhboardIcon from '../assets/dashboard-icon.svg';
import dasbhboardIconActive from '../assets/dashboard-icon-selected.svg';
import conversationIcon from '../assets/conversation-icon.svg';
import conversationIconActive from '../assets/conversation-icon-selected.svg';
import goalLogo from "../assets/goal-icon.svg";
import goalActiveLogo from "../assets/goal-icon-selected.svg";
import talentsIcon from "../assets/talents-icon.svg";
import talentsIconActive from "../assets/talents-icon-selected.svg";
import {NavLink, Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {useEffect} from "react";
import {getTeamMembers} from "../store/reducers/teamReducer";
import Collaborator from "../interfaces/collaborator.interface";
import Avatar from "@mui/material/Avatar";
import {stringAvatar} from "../utils/avatar";
import { useLocation } from 'react-router-dom';
import experienceLogo from "../assets/experience-icon.svg";
import experienceActiveLogo from "../assets/experience-icon-selected.svg";
import requestLogo from "../assets/request-icon.svg";
import requestActiveLogo from "../assets/request-icon-selected.svg";
import campaignActiveLogo from "../assets/campaign-icon-selected.svg";
import campaignLogo from "../assets/campaign-icon.svg";
import reviewActiveLogo from "../assets/appreciation-icon-selected.svg";
import reviewLogo from "../assets/appreciation-icon.svg";


export function Sidebar() {
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    let location = useLocation();

    useEffect(() => {
        dispatch(getTeamMembers())
    }, [dispatch])

    const members = useSelector((state: RootState) => state.team.members)
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)

    return (
        <aside className={styles.sidebar}>
            <figure className={styles.logo}>
                <Link to="/">
                    <img src={Logo} />
                </Link>
            </figure>
            {activeUser.modules_choices.includes("all") &&
                <NavLink
                    to="/manager-dashboard"
                    className={() =>
                        location.pathname === "/manager-dashboard" ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={dasbhboardIconActive} />
                    <img className={"icon"} src={dasbhboardIcon} />
                    <span>{t("header.dashboardSidebar")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("conversation")) &&
                <NavLink
                    to="/manager/conversations/"
                    className={() =>
                        location.pathname.includes("/manager/conversations") ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={conversationIconActive} />
                    <img className={"icon"} src={conversationIcon} />
                    <span>{t("common.conversations")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("goal")) &&
                <NavLink
                    to="/manager/goals/"
                    className={() =>
                        location.pathname.includes("/manager/goals") ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={goalLogo} />
                    <img className={"icon"} src={goalActiveLogo} />
                    <span>{t("common.goals")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("experience")) &&
                <NavLink
                    to="/manager/experiences/"
                    className={() =>
                        location.pathname.includes("/manager/experiences") ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={experienceLogo}/>
                    <img className={"icon"} src={experienceActiveLogo}/>
                    <span>{t("common.experiences")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("request")) &&
                <NavLink
                    to="/manager/requests/"
                    className={() =>
                        location.pathname.includes("/manager/requests") ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={requestLogo} />
                    <img className={"icon"} src={requestActiveLogo} />
                    <span>{t("common.requests")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("campaign")) &&
                <NavLink
                    to="/manager/campaign-answers/"
                    className={() =>
                        location.pathname.includes("/manager/campaign-answers") ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={campaignActiveLogo}/>
                    <img className={"icon"} src={campaignLogo}/>
                    <span>{t("common.campaigns")}</span>
                </NavLink>
            }
            {activeUser.modules_choices.includes("all") &&
                <NavLink
                    to="/manager/performance-reviews/"
                    className={() =>
                        location.pathname.includes("/manager/performance-reviews") ? "active " + styles.menuItem : styles.menuItem
                    }>
                    <img className={"activeIcon"} src={reviewActiveLogo}/>
                    <img className={"icon"} src={reviewLogo}/>
                    <span>{t("header.appreciations")}</span>
                </NavLink>
            }
            {activeUser.modules_choices.includes("all") &&
                <>
                    <a className={styles.menuItem}>
                        <img className={"activeIcon"} src={talentsIconActive} />
                        <img className={"icon"} src={talentsIcon} />
                        <span>{t("header.talents")}</span>
                    </a>
                    <div>
                        <ul className={styles.talentList}>
                            { members.map(
                                (member: Collaborator, index) =>
                                    {
                                        let fullName = ""
                                        if(member.first_name){
                                            fullName += member.first_name
                                        }
                                        if(member.last_name){
                                            fullName = fullName + " " + member.last_name
                                        }
                                        return(
                                            <NavLink key={index} to={"/team/member/" + member.id} className={styles.menuItem}>
                                                <figure>
                                                    {member.avatar &&
                                                        <img src={process.env.REACT_APP_BACK_END_URL + "media/"+ member.avatar}/>
                                                    }
                                                    {!member.avatar &&
                                                        <Avatar {...stringAvatar(fullName)} />
                                                    }
                                                </figure>
                                                <span>{member.first_name!} {member.last_name!}</span>
                                            </NavLink>
                                        )
                                    }
                            )}
                        </ul>
                    </div>
                </>
            }
        </aside>
    );
}
