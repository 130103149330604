import api from "../utils/http-common"
import { Conversation, ConversationParameters } from "../interfaces/conversation.interface"
import { ConversationAdapter } from "../adapters/conversation.adapter"

export default class ConversationServices {
    createConversation(token: string, conversation: Conversation){
        return api.post("/conversations/", ConversationAdapter(conversation, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    getAllConversations(token: string){
        return api.get("/conversations/",{headers: {Authorization: `Bearer ${token}`}})
    }
    getConversationDetails(token: string, conversationId: string){
        return api.get("/conversations/" + conversationId,{headers: {Authorization: `Bearer ${token}`}})
    }
    updateManagerConversation(token: string, conversation: Conversation){
        return api.put("/manager/conversations/" + conversation.id, ConversationAdapter(conversation, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    deleteConversation(token: string, conversation: {id:number}){
        return api.delete("/manager/conversations/" + conversation.id,{headers: {Authorization: `Bearer ${token}`}})
    }
    getAllManagerConversations(token: string, parameters: ConversationParameters){
        let url : string = "/manager/conversations/?";
        for (const [key, value] of Object.entries(parameters)) {
            url += `${key}=${value}&`
        }
        return api.get(url,{headers: {Authorization: `Bearer ${token}`}});
    }
    getManagerConversationDetails(token: string, conversationId: string){
        return api.get("/manager/conversations/" + conversationId,{headers: {Authorization: `Bearer ${token}`}})
    }
}