import * as React from "react";
import {GoalCondatTemplate, Evaluation, ImprovementArea} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import EvaluationComponent from "./evaluation";
import Button from "../button";
import addLogo from "../../assets/page-add.svg";
import addHoverLogo from "../../assets/page-add-hover.svg";


interface GoalCondatTemplateProps {
    answer: GoalCondatTemplate
    changeHandler: Function
    readonly?: boolean
}

const GoalCondatComponent: React.FunctionComponent<GoalCondatTemplateProps> = ({answer, readonly, changeHandler}) => {
    const { t } = useTranslation();
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date(JSON.parse(answer.completionDate)));
    const [title, setTitle] = useState<string>(answer.title)
    const [category, setCategory] = useState<string>(answer.category)
    const [description, setDescription] = useState<string>(answer.description)
    const [meansOfAction, setMeansOfAction] = useState<string>(answer.meansOfAction)
    const [evaluations, setEvaluations] = useState<Evaluation[]>(answer.evaluations)

    useEffect(() => {
        let newAnswer = {
            title: title,
            category: category,
            completionDate: JSON.stringify(completionDate),
            description: description,
            meansOfAction: meansOfAction,
            evaluations: evaluations,
            type: answer.type,
        } as GoalCondatTemplate
        changeHandler(newAnswer)
    }, [completionDate, title, category, description, meansOfAction, evaluations])

    return (
        <div className={styles.titleInput + " " + styles.goalTemplate}>
            <h4>{t("common.goal")}</h4>
            <label htmlFor="title">{t("goal.title")}</label>
            <input
                type="text"
                name="title"
                readOnly={readonly}
                defaultValue={title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(event.target.value)
                }}/>
            <div className={styles.goalLine}>
                <div className={styles.goalLine_firstItem}>
                    <label htmlFor="category">{t("goal.category")}</label>
                    <input
                        type="text"
                        name="category"
                        readOnly={readonly}
                        defaultValue={category}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCategory(event.target.value)
                        }}/>
                </div>
                <div className={styles.goalLine_secondItem}>
                    <label>{t("goal.date")}</label>
                    <div className={styles.DateInput}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                openTo="month"
                                readOnly={readonly}
                                views={['year', 'month', 'day']}
                                value={completionDate}
                                onChange={(newDate) => {
                                    setCompletionDate(newDate);
                                }}
                                renderInput={(params) => {
                                    let newParams = {...params}
                                    if(newParams.inputProps){
                                        newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                    }
                                    return(<TextField {...newParams}/>)}}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
            <label htmlFor="description">{t("goal.description")}</label>
            <textarea
                name="description"
                defaultValue={description}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setDescription(event.target.value)
                    }}>
            </textarea>
            {evaluations.map((evaluation: Evaluation, index) => {
                return <EvaluationComponent
                    evaluation={evaluation}
                    changeHandler={
                        (newEvaluation: Evaluation) => {
                            let newEval = newEvaluation
                            let newEvaluations = [...evaluations]
                            newEvaluations[index] = newEval
                            setEvaluations(newEvaluations)
                        }}
                    readonly={readonly}
                    key={index}/>
            })}
            {!readonly &&
                <div className={styles.listButton}>
                    <Button
                        classCss={"secondary-button"}
                        content={t("buttons.addEvaluationCriteria")}
                        submit={() => {
                            setEvaluations(
                                evaluations => [...evaluations, {
                                    criteria: "",
                                    indicator: "",
                                } as Evaluation]
                            )}}
                        icon={addLogo}
                        iconHover={addHoverLogo}/>
                </div>
            }
            <label htmlFor="meansOfAction">{t("goal.meansOfAction")}</label>
            <textarea
                name="meansOfAction"
                defaultValue={meansOfAction}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setMeansOfAction(event.target.value)
                    }}>
            </textarea>
        </div>
    );
};
export default GoalCondatComponent;