import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Header from '../components/header/header';
import Button from '../components/button';
import styles from './addgoal.module.css';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {createFeedback} from "../store/reducers/feedbacksReducer";
import {getCollaboratorsList} from "../store/reducers/collaboratorReducer";
import MenuItem from "@mui/material/MenuItem";
import Collaborator from "../interfaces/collaborator.interface";
import {Navigate} from "react-router-dom";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import CustomAlerts from "../components/customAlerts";
import HeaderLogo from "../assets/feedback-header-logo.svg";
import validateLogo from "../assets/page-validate.svg";
import validateHoverLogo from "../assets/page-validate-hover.svg";
import shareLogo from "../assets/page-share.svg";
import shareHoverLogo from "../assets/page-share-hover.svg";
import PopUpSharedFeedback from "../components/PopUpSharedFeedback";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import CollaboratorSelectOption from "../components/collaboratorSelectOption";


export default function AddFeedback() {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>('');
    const [details, setDetails] = useState<string>('');
    const [feedbackDate, setFeedbackDate] = useState<Date | null>(new Date());
    const [collaborator, setCollaborator] = useState<number>(0);
    const [redirect, setRedirect] = useState<string>("");
    const [openPopUp, setOpenPopUp] = useState(false);
    const feedbackStatus = useSelector((state: RootState) => state.feedbacks.status);
    const errors = useSelector((state: RootState) => state.feedbacks.error);

    const handleChange = (event: SelectChangeEvent) => {
        setCollaborator(parseInt(event.target.value));
    };

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getCollaboratorsList())
    }, [dispatch])
    const collaborators = useSelector((state: RootState) => state.collaborators.collaborators.filter((collaborator)=> collaborator.id !== state.activeUser.activeUser.id));

    if(feedbackStatus === "success"){
        switch(redirect){
            case "allFeedback":{
                return <Navigate to="/feedbacks" />;
                break;
            }
            case "newGoal":{
                return <Navigate to="/goals/new" />;
                break;
            }
            case "newFeedback":{
                window.location.reload();
                break;
            }
            default: {
                break;
            }
        }
    }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if (direction) {
            setRedirect(direction);
        } else {
            setRedirect("allFeedback");
        }
    }

    return (
        <>
            <SidebarTalent />
            <main>
                <Header title={t("feedback.h1")}/>
                <section className={styles.modalForm}>
                    <header>
                        <img src={HeaderLogo}></img>
                        <h1>{t("common.feedback")}</h1>
                    </header>
                    <form className={styles.form}>
                        <div className={styles.titleInput}>
                            <label htmlFor="title">{t("feedback.title")}</label>
                            <input
                                name="title"
                                placeholder={t("feedback.placeholder")}
                                onChange={
                                    (e) => setTitle(e.target.value)
                                }>
                            </input>
                        </div>
                        {feedbackStatus === "error" && errors.title &&
                            <CustomAlerts errors={errors.title}/>
                        }
                        <label htmlFor="goal-label">{t("feedback.person")}</label>
                        <div className={styles.Input}>
                            <Select
                                labelId="person-label"
                                id="person-select"
                                value={collaborator.toString()}
                                label="Person"
                                onChange={handleChange}
                                renderValue={collaborator !== 0 ? undefined : () => <span className={styles.placeholder}>{t("feedback.selectTalent")}</span>}
                            >
                                {
                                    collaborators.map(
                                        (collaborator: Collaborator) => {
                                            return <MenuItem key={collaborator.id} value={collaborator.id}>
                                                <CollaboratorSelectOption collaborator={collaborator} displayName={true}/>
                                            </MenuItem>
                                        }
                                    )
                                }
                            </Select>
                        </div>
                        {feedbackStatus === "error" && errors.collaborator_to &&
                            <CustomAlerts errors={errors.collaborator_to}/>
                        }
                        <label htmlFor="feedback">{t("feedback.content")}</label>
                        <p className={styles.SMART}>{t("feedback.help")}</p>
                        <textarea
                            name="feedback"
                            placeholder={t("feedback.placeholderDetails")}
                            onChange={
                                (e) => setDetails(e.target.value)
                            }>
                        </textarea>
                        {feedbackStatus === "error" && errors.details &&
                            <CustomAlerts errors={errors.details}/>
                        }
                        <label>{t("feedback.date")}</label>
                        <div className={styles.DateInput}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disableFuture
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={feedbackDate}
                                    onChange={(newDate) => {
                                        setFeedbackDate(newDate);
                                    }}
                                    renderInput={(params) => {
                                        let newParams = {...params}
                                        if(newParams.inputProps){
                                            newParams.inputProps.value  = t("dates.completionDate", { date: feedbackDate })
                                        }
                                        return(<TextField {...newParams}/>)}}
                                />
                            </LocalizationProvider>
                        </div>
                        {feedbackStatus === "error" && errors.feedback_date &&
                            <CustomAlerts errors={errors.feedback_date}/>
                        }
                    </form>
                    <div className={styles.buttonContainer}>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.close")}
                            submit={
                                () => {
                                    setRedirect("allFeedback")
                                }
                            }
                            icon={closeLogo}
                            iconHover={closeHoverLogo} />
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.save")}
                            submit={() => {
                                dispatch(
                                    createFeedback({
                                        title,
                                        status: "Draft",
                                        details,
                                        collaborator,
                                        feedbackDate: JSON.stringify(feedbackDate)
                                    })
                                )
                                setRedirect("allFeedback")
                            }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.share")}
                            submit={() => {
                                dispatch(
                                    createFeedback({
                                        title,
                                        status: "Sent",
                                        details,
                                        collaborator,
                                        feedbackDate: JSON.stringify(feedbackDate)
                                    })
                                )
                                setOpenPopUp(true);
                            }
                            }
                            icon={shareLogo}
                            iconHover={shareHoverLogo}/>
                            {feedbackStatus !== "error" &&
                                <PopUpSharedFeedback
                                    visible={openPopUp}
                                    closeHandler={HandleClosePopUp} />
                            }
                    </div>
                </section>
            </main>
        </>
    );
}