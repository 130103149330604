import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Header from '../components/header/header';
import Dashboard from "./dashboard";
import {AppDispatch, RootState} from "../store/store";
import {useEffect} from "react";
import {getGoalDetails} from "../store/reducers/goalsReducer";
import GoalDetails from "../components/goalDetails";
import {Goal as GoalInterface} from "../interfaces/goal.interface";
import * as React from "react";
import {SidebarTalent} from "../components/sidebarTalent";
import { useTranslation } from "react-i18next";

export default function ViewGoalDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.goals.status)

    useEffect(() => {
        if(params.goalId){
            dispatch(getGoalDetails(params.goalId));
        }
    }, [dispatch]);

    const goal = useSelector((state: RootState) => state.goals.goals.find((goal : GoalInterface) => goal.id == params.goalId));

    if(status !== "error") {
        return (
            <>
                <SidebarTalent />
                <main>
                    <Header title={t("goal.h1")}/>
                    {goal &&
                        <GoalDetails goal={goal}/>
                    }
                </main>
            </>
        );
    }
    else {
        return <Dashboard/>
    }
}