import styles from "./login.module.css";
import {useTranslation} from "react-i18next";
import Button from "../components/button";
import {resetPassword} from "../store/reducers/activeUserReducer";
import {AppDispatch, RootState} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useParams} from "react-router-dom";
import CustomAlerts from "../components/customAlerts";
import {useState} from "react";
import background from "../assets/login-bg.png";
import * as React from "react";


export default function PasswordReset() {
    const { t } = useTranslation();
    const params = useParams();
    const [password, setPassword] = useState<string>('');
    const [passwordVerified, setPasswordVerified] = useState<string>('');
    const dispatch = useDispatch<AppDispatch>();
    const passwordResetStatus = useSelector((state: RootState) => state.activeUser.status);
    const errors = useSelector((state: RootState) => state.activeUser.error)
    const uid = params.uid ? params.uid : ""
    const token = params.token ? params.token : ""
    const [redirect, setRedirect] = useState<boolean>(false);

    if (redirect && passwordResetStatus === "success"){
        return <Navigate to="/" />;
    }

    return (
        <>
            <main className={styles.loginMain} style={{backgroundImage: `url(${background})`}}>
                <div>
                    <h2>{t("common.resetPassword")}</h2>
                    <input
                        name="password"
                        placeholder="*******"
                        type="password"
                        onChange={
                            (e) => setPassword(e.target.value)
                        }>
                    </input>
                    {passwordResetStatus === "error" && errors.new_password1 &&
                        <span className={styles.loginError}>
                            <CustomAlerts errors={errors.new_password1}/>
                        </span>
                    }
                    <input
                        name="passwordVerified"
                        placeholder="*******"
                        type="password"
                        onChange={
                            (e) => setPasswordVerified(e.target.value)
                        }>
                    </input>
                    {passwordResetStatus === "error" && errors.new_password2 &&
                        <span className={styles.loginError}>
                            <CustomAlerts errors={errors.new_password2}/>
                        </span>
                    }
                    {passwordResetStatus === "error" && errors.non_field_errors &&
                        <span className={styles.loginError}>
                            <CustomAlerts errors={errors.non_field_errors}/>
                        </span>
                    }
                    {passwordResetStatus === "error" && errors.token &&
                        <span className={styles.loginError}>
                            <CustomAlerts errors={[t("common.tokenError")]}/>
                            <a className={styles.visited} href="/password-forgotten">{t("common.here")}</a>
                        </span>
                    }
                    {passwordResetStatus === "success" && errors.detail &&
                        <span className={styles.loginError}>
                            <CustomAlerts errors={[errors.detail]}/>
                        </span>
                    }
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.setPassword")}
                        submit={
                            () => {
                                dispatch(resetPassword({uid, token, password, password2: passwordVerified}))
                                setRedirect(true)
                            }
                        }/>
                </div>
            </main>
        </>
    );
}