import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {
    BooleanTemplate, SelectTemplate,
    CampaignAnswer as CampaignAnswerInterface,
    Information, Integer, MultipleChoice, Star,
    Text, Textarea, Title
} from '../interfaces/campaignAnswer.interface';
import styles from './campaignAnswer.module.css';
import * as React from "react";
import HeaderLogo from "../assets/campaign-header-logo.svg";
import TitleComponent from "./templates/title";
import InformationComponent from "./templates/information";
import TextComponent from "./templates/text";
import TextareaComponent from "./templates/textarea";
import SelectComponent from "./templates/selectTemplate";
import BooleanComponent from "./templates/booleanComponent";
import IntegerComponent from "./templates/integerComponent";
import ListTemplateComponent from "./templates/ListComponent";
import validateHoverLogo from "../assets/page-validate.svg";
import validateLogo from "../assets/page-validate-hover.svg";
import shareHoverLogo from "../assets/page-share.svg";
import shareLogo from "../assets/page-share-hover.svg";
import openLogo from "../assets/create-icon.svg";
import openHoverLogo from "../assets/create-icon-selected.svg";
import Button from "./button";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {
    updateManagerCampaignAnswerDetails
} from "../store/reducers/campaignAnswersReducer";
import MultiSelectComponent from "./templates/multiSelectTemplate";
import {getTeamMembers} from "../store/reducers/teamReducer";
import Collaborator from "../interfaces/collaborator.interface";
import CollaboratorSelectOption from "./collaboratorSelectOption";
import downloadLogo from "../assets/download.svg";
import downloadHoverLogo from "../assets/download-selected.svg";
import downloadBlack from "../assets/download-black.svg";
import CampaignAnswerServices from "../services/campaignAnswer.service";
import DateTemplateComponent from "./templates/dateTemplate";
import {getHRComments} from "../store/reducers/HRCommentsReducer";
import HRCommentsComponent from "./HRComments";


interface CampaignAnswerProps {
    campaignAnswer: CampaignAnswerInterface
}

const campaignAnswerService : CampaignAnswerServices = new CampaignAnswerServices();


const CampaignAnswerDetails: React.FC<CampaignAnswerProps> = ({campaignAnswer}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)
    const isManager = useSelector((state: RootState) => campaignAnswer.managers!.includes(state.activeUser.activeUser.id));
    const [collaboratorAnswers, setCollaboratorAnswers] = useState<any[]>(campaignAnswer.answer_collaborator!.template)
    const [managerAnswers, setManagerAnswer] = useState<any[]>(campaignAnswer.answer_manager!.template)
    const [getComments, setComments] = useState<boolean>(false)

    useEffect(() =>{
        dispatch(getTeamMembers())
        dispatch(getHRComments(campaignAnswer.collaborator))
    }, [dispatch])
    useEffect(() => {
        if(getComments === true){
            dispatch(getHRComments(campaignAnswer.collaborator))
            setComments(false)
        }
    }, [getComments])

    const members = useSelector((state: RootState) => state.team.members)
    const collaborator = members.find(Collaborator => Collaborator.id === campaignAnswer.collaborator) as Collaborator
    const HRComments = useSelector((state: RootState) => state.hrComments.HRComments)

    const checkDisplay = (answer: any) => {
        let display = false
        if(isManager){
            if(answer.visibility === "Manager"){
                display = true
            } else if(campaignAnswer.status === "SharedByCollaborator" || campaignAnswer.status === "SharedByBoth" || campaignAnswer.status === "ValidatedByManager" || campaignAnswer.status === "ValidatedByBoth"){
                display = true
            }
        } else {
            if(answer.visibility === "Collaborator"){
                display = true
            } else if(campaignAnswer.status === "SharedByManager" || campaignAnswer.status === "SharedByBoth" || campaignAnswer.status === "ValidatedByManager" || campaignAnswer.status === "ValidatedByBoth") {
                display = true
            }
        }
        if(answer.visibility === "Both"){
            display = true
        }
        return display
    }

    let readonly = true;
    if (campaignAnswer.status !== "ValidatedByBoth"){
        readonly = false;
    }

    let status = "Draft"
    if (campaignAnswer.status) {
        status = "common." + campaignAnswer.status.toLowerCase()
    }

    const getPDFData = async () => {
        const response = await campaignAnswerService.downloadCampaignAnswer(activeUser.token, campaignAnswer.id!.toString())
        let type = "application/pdf"
        if(response.headers["content-type"] !== "application/pdf"){
            type = "application/zip"
        }
        let filename = campaignAnswer.campaign + " - " + collaborator.first_name + " " + collaborator.last_name
        if (type === "application/pdf"){
            filename += ".pdf"
        }else if (type === "application/zip"){
            filename += ".zip"
        }
        const url: Blob = new Blob([response.data], {type: type});
        const blobUrl = window.URL.createObjectURL(url);
        const templink: any = document.createElement("a");
        templink.setAttribute("download", filename);
        templink.href = blobUrl;
        document.body.appendChild(templink);
        templink.click();
        templink.parentNode.removeChild(templink);
    }

    useEffect(() => {
        const debouncedSave = setTimeout(() => {
            if(campaignAnswer.answer_manager!.template !== managerAnswers && campaignAnswer.status !== "ValidatedByBoth" && campaignAnswer.status !== "ValidatedByManager"){
                dispatch(updateManagerCampaignAnswerDetails(
                    {
                        id: campaignAnswer.id,
                        collaborator: campaignAnswer.collaborator,
                        status: campaignAnswer.status,
                        answer_manager: {template: managerAnswers as  [Title | Information | Text | Textarea | SelectTemplate | MultipleChoice | BooleanTemplate | Integer | Star]},
                    }
                ))
            }
        }, 5000)
        return () => clearTimeout(debouncedSave)
    }, [managerAnswers])

    return (
        <>
            <section className={styles.modalForm}>
                <header>
                    <div className={styles.entryTitle}>
                        <img src={HeaderLogo}></img>
                        <h1>{campaignAnswer.campaign}</h1>
                        {(activeUser.organization === 20 || activeUser.head_company === "Wivetix Services") &&
                            <a className={styles.userGuide} href="https://drive.google.com/uc?export=download&id=1k_kHDMJt46SlljXM8xL3bYJ7k0UucVB_" download>
                                <img src={downloadBlack}/>{t("campaign.interviewGuide")}
                            </a>
                        }
                    </div>
                    <CollaboratorSelectOption collaborator={collaborator!} displayName={true}/>
                    <div className={styles.Status}>
                        <span className={campaignAnswer.status}></span>
                        <p>{t(status)}</p>
                    </div>
                </header>
                <form className={styles.form}>
                    {managerAnswers.map((managerAnswer, index) => {
                        if (managerAnswer.condition){
                            if(managerAnswer.condition.position === "external"){
                                if(managerAnswer.condition.value === "Wivetix Services"){
                                    if(activeUser.organization !== 20 && activeUser.organization !== 11){
                                        return <></>
                                    }
                                }
                            } else {
                                let checkValue = managerAnswers.find(answer => answer.position === managerAnswer.condition.position)
                                if(checkValue.answer !== managerAnswer.condition.value){
                                    return <></>
                                }
                            }
                        }
                        if (managerAnswer.type === "Title" && checkDisplay(managerAnswer)) {
                            return <TitleComponent
                                answer={managerAnswer}
                                key={index}/>
                        } else if (managerAnswer.type === "Information" && checkDisplay(managerAnswer)) {
                            return <InformationComponent
                                answer={managerAnswer}
                                key={index}/>
                        } else if (managerAnswer.type === "Text" && checkDisplay(managerAnswer)){
                            return <TextComponent
                                key={index}
                                answer={collaboratorAnswers.find(answer => answer.position === managerAnswer.position)}
                                managerAnswer={managerAnswer}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...managerAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...managerAnswers]
                                        newAnswers[index] = newAnswer
                                        setManagerAnswer(newAnswers)
                                    }}/>
                        } else if (managerAnswer.type === "Textarea" && checkDisplay(managerAnswer)){
                            return <TextareaComponent
                                key={index}
                                answer={collaboratorAnswers.find(answer => answer.position === managerAnswer.position)}
                                managerAnswer={managerAnswer}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...managerAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...managerAnswers]
                                        newAnswers[index] = newAnswer
                                        setManagerAnswer(newAnswers)
                                    }}/>
                        } else if (managerAnswer.type === "Select" && checkDisplay(managerAnswer)) {
                            return <SelectComponent
                                key={index}
                                answer={collaboratorAnswers.find(answer => answer.position === managerAnswer.position)}
                                managerAnswer={managerAnswer}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...managerAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...managerAnswers]
                                        newAnswers[index] = newAnswer
                                        setManagerAnswer(newAnswers)
                                    }}/>
                        } else if (managerAnswer.type === "Boolean" && checkDisplay(managerAnswer)) {
                            return <BooleanComponent
                                key={index}
                                answer={collaboratorAnswers.find(answer => answer.position === managerAnswer.position)}
                                managerAnswer={managerAnswer}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...managerAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...managerAnswers]
                                        newAnswers[index] = newAnswer
                                        setManagerAnswer(newAnswers)
                                    }}/>
                        } else if (managerAnswer.type === "Integer" && checkDisplay(managerAnswer)) {
                            return <IntegerComponent
                                key={index}
                                answer={collaboratorAnswers.find(answer => answer.position === managerAnswer.position)}
                                managerAnswer={managerAnswer}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...managerAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...managerAnswers]
                                        newAnswers[index] = newAnswer
                                        setManagerAnswer(newAnswers)
                                    }}/>
                        }
                        else if (managerAnswer.type === "MultipleChoice" && checkDisplay(managerAnswer)) {
                            return <MultiSelectComponent
                                key={index}
                                answer={collaboratorAnswers.find(answer => answer.position === managerAnswer.position)}
                                managerAnswer={managerAnswer}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...managerAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...managerAnswers]
                                        newAnswers[index] = newAnswer
                                        setManagerAnswer(newAnswers)
                                    }}/>
                        } else if(managerAnswer.type === "List" && checkDisplay(managerAnswer)){
                            return <ListTemplateComponent
                                key={index}
                                answer={collaboratorAnswers.find(answer => answer.position === managerAnswer.position)}
                                managerAnswer={managerAnswer}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: any[]) => {
                                        let newAnswer = {...managerAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...managerAnswers]
                                        newAnswers[index] = newAnswer
                                        setManagerAnswer(newAnswers)
                                    }}/>
                        } else if (managerAnswer.type === "Date" && checkDisplay(managerAnswer)) {
                            return <DateTemplateComponent
                                key={index}
                                answer={managerAnswer}
                                managerAnswer={collaboratorAnswers.find(answer => answer.position === managerAnswer.position)}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...managerAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...managerAnswers]
                                        newAnswers[index] = newAnswer
                                        setManagerAnswer(newAnswers)
                                    }}/>
                        }
                        // else if (collaboratorAnswer.type === "Star") {
                        //     return <div className={styles.titleInput} key={index}>
                        //         <label htmlFor="title">{collaboratorAnswer.title}</label>
                        //     </div>
                        // }
                    })}
                </form>
                <div className={styles.buttonContainer}>
                    {(campaignAnswer.status === "Draft" || campaignAnswer.status === "SharedByCollaborator" || campaignAnswer.status === "SharedByManager" || campaignAnswer.status === "SharedByBoth") &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.save")}
                            submit={() => {
                                dispatch(updateManagerCampaignAnswerDetails(
                                    {
                                        id: campaignAnswer.id,
                                        collaborator: campaignAnswer.collaborator,
                                        status: campaignAnswer.status,
                                        answer_manager: {template: managerAnswers as  [Title | Information | Text | Textarea | SelectTemplate | MultipleChoice | BooleanTemplate | Integer | Star]},
                                    }
                                ))
                            }}
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                    }
                    {(campaignAnswer.status === "Draft" || campaignAnswer.status === "SharedByCollaborator") &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.share")}
                            submit={() => {
                                dispatch(updateManagerCampaignAnswerDetails(
                                    {
                                        id: campaignAnswer.id,
                                        collaborator: campaignAnswer.collaborator,
                                        status: "SharedByManager",
                                        answer_manager: {template: managerAnswers as  [Title | Information | Text | Textarea | SelectTemplate | MultipleChoice | BooleanTemplate | Integer | Star]},
                                    }
                                ))
                            }}
                            icon={shareLogo}
                            iconHover={shareHoverLogo}/>
                    }
                    {(campaignAnswer.status === "Draft" || campaignAnswer.status === "SharedByCollaborator" || campaignAnswer.status === "SharedByBoth" || campaignAnswer.status === "SharedByManager") &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.closeInterview")}
                            submit={() => {
                                dispatch(updateManagerCampaignAnswerDetails(
                                    {
                                        id: campaignAnswer.id,
                                        collaborator: campaignAnswer.collaborator,
                                        status: "ValidatedByManager",
                                        answer_manager: {template: managerAnswers as  [Title | Information | Text | Textarea | SelectTemplate | MultipleChoice | BooleanTemplate | Integer | Star]},
                                    }
                                ))
                            }}
                            icon={validateHoverLogo}
                            iconHover={validateLogo}/>
                    }
                    {(campaignAnswer.status === "ValidatedByManager" || campaignAnswer.status === "ValidatedByBoth") &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.openInterview")}
                            submit={() => {
                                dispatch(updateManagerCampaignAnswerDetails(
                                    {
                                        id: campaignAnswer.id,
                                        collaborator: campaignAnswer.collaborator,
                                        status: "SharedByBoth",
                                    }
                                ))
                            }}
                            icon={openLogo}
                            iconHover={openHoverLogo}/>
                    }
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.download")}
                        submit={() => {getPDFData()}}
                        icon={downloadLogo}
                        iconHover={downloadHoverLogo}/>
                </div>
                {activeUser.isHR &&
                    <HRCommentsComponent HRcomments={HRComments} collaboratorId={campaignAnswer.collaborator} updateComments={(update: boolean) => {setComments(update)}}/>
                }
            </section>
        </>
    );
}

export default CampaignAnswerDetails;
