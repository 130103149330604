import * as React from 'react';
import { useTranslation } from "react-i18next";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import styles from "./header.module.css";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {useEffect} from "react";
import {getAllNotifications, deleteNotification, deleteAllNotifications} from "../../store/reducers/notificationsReducer";
import closeImg from "../../assets/measure-delete.svg";
import {useLocation} from "react-router-dom";

const NotificationsMenu: React.SFC<any> = () =>  {
    const {t} = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [openNotifElement, setOpenNotifElement] = React.useState<null | HTMLElement>(null);
    const openNotifications = Boolean(openNotifElement);
    let location = useLocation();

    const handleOpenNotifs = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenNotifElement(event.currentTarget);
    };
    const handleCloseNotifs = () => {
        setOpenNotifElement(null);
    };
    const deleteHandler = (notificationId: number) => {
        dispatch(deleteNotification(notificationId))
    }

    useEffect(()=>{
        dispatch(getAllNotifications())
    }, [dispatch])

    const notifications = useSelector((state: RootState) => state.notifications.notifications)

    return (
        <div className={styles.navNotificationMenu}>
            <span
                id="notification-button"
                aria-controls={openNotifications ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openNotifications ? 'true' : undefined}
                onClick={handleOpenNotifs}
            >
                {notifications.length > 0
                    ? <NotificationsActiveIcon className={styles.activeNotif}/>
                    : <NotificationsIcon/>
                }

            </span>
            {notifications.length > 0 &&
                <Menu
                    id="notifications-menu"
                    className={styles.notificationMenu}
                    anchorEl={openNotifElement}
                    open={openNotifications}
                    onClose={handleCloseNotifs}
                    MenuListProps={{
                        'aria-labelledby': 'notification-button',
                    }}
                >
                    {notifications.map((notification)=> {
                        if (process.env.REACT_APP_FRONT_END_URL + location.pathname === notification.url){
                            dispatch(deleteNotification(notification.id))
                        }
                        return (<MenuItem className={styles.profileButton} key={notification.id}>
                            <a href={notification.url}>
                                {notification.front_text}
                            </a>
                            <img onClick={() => {deleteHandler(notification.id)}} className={styles.close} src={closeImg}/>
                        </MenuItem>)
                    })}
                    <MenuItem className={styles.profileButton}>
                        <button className={"third-button"} onClick={() => {dispatch(deleteAllNotifications())}}>
                            {t("notifications.clearAll")}
                        </button>
                    </MenuItem>
                </Menu>
            }
        </div>
    );
}

export default NotificationsMenu;