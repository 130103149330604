import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Button from '../components/button';
import {Conversation as ConversationInterface} from '../interfaces/conversation.interface';
import Collaborator from "../interfaces/collaborator.interface";
import styles from './goaldetails.module.css';
import {AppDispatch, RootState} from "../store/store";
import {updateConversation, deleteConversation} from "../store/reducers/conversationsReducer";
import {Navigate} from "react-router-dom";
import {getCollaboratorsList} from "../store/reducers/collaboratorReducer";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import CustomAlerts from "./customAlerts";
import HeaderLogo from "../assets/feedback-header-logo.svg";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import validateLogo from "../assets/page-validate.svg";
import validateHoverLogo from "../assets/page-validate-hover.svg";
import shareHoverLogo from "../assets/page-share.svg";
import shareLogo from "../assets/page-share-hover.svg";
import deleteLogo from "../assets/page-delete.svg";
import deleteHoverLogo from "../assets/page-delete-hover.svg";
import PopUpSharedConversation from "./PopUpSharedConversation";
import CollaboratorSelectOption from "./collaboratorSelectOption";

interface ConversationProps {
    conversation: ConversationInterface
}

const ConversationDetails: React.SFC<ConversationProps> = ({conversation}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getCollaboratorsList())
    }, [dispatch])

    const [completionDate, setCompletionDate] = useState<Date | null>(new Date(JSON.parse(conversation.completionDate)));
    const [title, setTitle] = useState<string>(conversation.title);
    const [type, setType] = useState<string>(conversation.type);
    const [details, setDetails] = useState<string>(conversation.details);
    const [redirect, setRedirect] = useState<string>("");
    const [collaborator, setCollaborator] = useState<number>(conversation.collaborator);
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);
    let conversationCollaborator = useSelector((state: RootState) => state.collaborators.collaborators.find((collaborator)=>collaborator.id == conversation.collaborator))
    const conversationStatus = useSelector((state: RootState) => state.conversations.status);
    const errors = useSelector((state: RootState) => state.conversations.error);
    const [openPopUp, setOpenPopUp] = useState(false);

    useEffect(() =>{
        if(conversationCollaborator){
            setCollaborator(conversationCollaborator.id);
        }
    }, [conversationCollaborator])

    const collaborators = useSelector((state: RootState) => state.collaborators.collaborators.filter((collaborator)=> collaborator.id !== state.activeUser.activeUser.id));
    const member = collaborators.find(Collaborator => Collaborator.id === collaborator) as Collaborator

    const handleCollaboratorChange = (event: SelectChangeEvent) => {
        setCollaborator(parseInt(event.target.value));
    };

    const handleTypeChange = (event: SelectChangeEvent) => {
        setType(event.target.value);
    };

    let readonly = true;
    if (conversation.status === "Draft"){
        readonly = false;
    }

    if(redirect === "close"){
        window.history.back();
    }

    if(conversationStatus === "success") {
        if(redirect === "newConversation") {
            window.location.reload();
        } else if(redirect === "return"){
            if(conversation.collaborator == activeUser.id){
                return <Navigate to="/conversations/"/>;
            } else {
                return <Navigate to="/manager/conversations/"/>;
            }
        } else if(redirect === "newGoal"){
            if(conversation.collaborator == activeUser.id){
                return <Navigate to="/goals/new"/>;
            } else {
                return <Navigate to="/manager/goals/new"/>;
            }
        }
    }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if (direction) {
            setRedirect(direction);
        } else {
            setRedirect("return");
        }
    }

    let status = "Draft"
    if (conversation.status) {
        status = "common." + conversation.status.toLowerCase()
    }

    return (
        <>
            <section className={styles.modalForm}>
                <header>
                    <div className={styles.entryTitle}>
                        <img src={HeaderLogo}></img>
                        <h1>{t("common.conversation")}</h1>
                    </div>
                    {conversation.author === activeUser.id && member &&
                        <CollaboratorSelectOption collaborator={member} displayName={true}/>
                    }
                    <div className={styles.Status}>
                        <span className={conversation.status}></span>
                        <p>{t(status)}</p>
                    </div>
                </header>
                <form className={styles.form}>
                    <label htmlFor="person-label">{t("conversation.person")}</label>
                    <div className={styles.Input}>
                        <Select
                            labelId="person-label"
                            id="person-select"
                            readOnly={readonly}
                            value={collaborator.toString()}
                            label="Person"
                            onChange={handleCollaboratorChange}
                        >
                            {
                                collaborators.map(
                                    (collaborator: Collaborator) => {
                                        return <MenuItem key={collaborator.id} value={collaborator.id}>
                                            <CollaboratorSelectOption collaborator={collaborator} displayName={true}/>
                                        </MenuItem>
                                    }
                                )
                            }
                        </Select>
                    </div>
                    {conversationStatus === "error" && errors.collaborator_to &&
                        <CustomAlerts errors={[t("conversation.collaboratorError")]}/>
                    }
                    <label htmlFor="type-label">{t("conversation.type")}</label>
                    <div className={styles.Input}>
                        <Select
                            labelId="type-label"
                            id="type-select"
                            value={type}
                            readOnly={readonly}
                            label="Type"
                            onChange={handleTypeChange}
                        >
                            <MenuItem key={"monthly"} value={"monthly"}>{t("conversation.monthly")}</MenuItem>
                            <MenuItem key={"quarterly"} value={"quarterly"}>{t("conversation.quarterly")}</MenuItem>
                            <MenuItem key={"annually"} value={"annually"}>{t("conversation.annually")}</MenuItem>
                            <MenuItem key={"milestone"} value={"milestone"}>{t("conversation.milestone")}</MenuItem>
                        </Select>
                    </div>
                    {conversationStatus === "error" && errors.conversation_type &&
                        <CustomAlerts errors={errors.conversation_type}/>
                    }
                    <div className={styles.titleInput}>
                        <label htmlFor="title">{t("conversation.title")}</label>
                        <input
                            name="title"
                            placeholder="Your Conversation"
                            value={title}
                            readOnly={readonly}
                            onChange={
                                (e) => setTitle(e.target.value)
                            }>
                        </input>
                    </div>
                    {conversationStatus === "error" && errors.title &&
                        <CustomAlerts errors={errors.title}/>
                    }
                    <label htmlFor="conversation">{t("conversation.content")}</label>
                    <textarea
                        name="conversation"
                        value={details}
                        readOnly={readonly}
                        onChange={
                            (e) => setDetails(e.target.value)
                        }>
                    </textarea>
                    {conversationStatus === "error" && errors.details &&
                        <CustomAlerts errors={errors.details}/>
                    }
                    <label>{t("conversation.date")}</label>
                    <div className={styles.DateInput}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disableFuture
                                openTo="day"
                                views={['year', 'month', 'day']}
                                readOnly={readonly}
                                value={completionDate}
                                onChange={(newDate) => {
                                    setCompletionDate(newDate);
                                }}
                                renderInput={(params) => {
                                    let newParams = {...params}
                                    if(newParams.inputProps){
                                        newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                    }
                                    return(<TextField {...newParams}/>)}}
                            />
                        </LocalizationProvider>
                    </div>
                </form>
                    <div className={styles.buttonContainer}>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.close")}
                            submit={
                                () => {
                                    setRedirect("close")
                                }
                            }
                            icon={closeLogo}
                            iconHover={closeHoverLogo}/>
                {conversation.status == "Draft" &&
                <>
                        {conversation.author === activeUser.id &&
                            <Button
                                classCss={"secondary-button"}
                                content={t("buttons.delete")}
                                submit={
                                    () => {
                                        dispatch(
                                            deleteConversation({id: conversation.id!})
                                        )
                                        setRedirect("return")
                                    }
                                }
                                icon={deleteLogo}
                                iconHover={deleteHoverLogo}/>
                        }
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.save")}
                            submit={() => {
                                dispatch(
                                    updateConversation({
                                        id: conversation.id,
                                        title,
                                        type,
                                        status: conversation.status,
                                        details,
                                        collaborator: conversation.collaborator,
                                        completionDate: JSON.stringify(completionDate)
                                    })
                                )
                                setRedirect("return")
                            }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.share")}
                            submit={() => {
                                dispatch(
                                    updateConversation({
                                        id: conversation.id,
                                        title,
                                        type,
                                        status: "Shared",
                                        details,
                                        collaborator: conversation.collaborator,
                                        completionDate: JSON.stringify(completionDate)
                                    })
                                )
                                setOpenPopUp(true);
                            }
                            }
                            icon={shareLogo}
                            iconHover={shareHoverLogo}/>
                            </>
                        }
                        {conversationStatus !== "error" &&
                            <PopUpSharedConversation
                                visible={openPopUp}
                                closeHandler={HandleClosePopUp} />
                        }
                    </div>
            </section>
        </>
    );
}

export default ConversationDetails;