import * as React from "react";
import {SelectTemplate, Textarea} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface SelectTemplateProps {
    answer: SelectTemplate
    changeHandler: Function
    managerAnswer: SelectTemplate
    isManager: boolean
    status: "Draft" | "SharedByCollaborator" | "SharedByManager" | "SharedByBoth" | "ValidatedByManager" | "ValidatedByBoth"
    readonly?: boolean
}

const SelectComponent: React.SFC<SelectTemplateProps> = ({answer, readonly, changeHandler, managerAnswer, isManager, status}) => {
    const { t } = useTranslation();
    const [userAnswer, setUserAnswer] = useState<string>(answer.answer)
    const [managerAnswerLocal, setManagerAnswer] = useState<string>(managerAnswer.answer);

    useEffect(() => {
        if(!isManager){
            changeHandler(userAnswer)
        }
    }, [userAnswer])

    useEffect(() => {
        if(isManager){
            changeHandler(managerAnswerLocal)
        }
    }, [managerAnswerLocal])


    return (
        <div className={styles.titleInput}>
            <label htmlFor={answer.title}>{answer.title}</label>
            {answer.visibility === "Both" ?
                <>
                    {(status === "Draft" || (status === "SharedByCollaborator" && !isManager ) || (status === "SharedByManager" && isManager)) &&
                        <>
                            {isManager ?
                                <div className={styles.Input}>
                                    <Select
                                        value={managerAnswerLocal}
                                        label={answer.title}
                                        readOnly={readonly}
                                        onChange={(event: SelectChangeEvent) => {setManagerAnswer(event.target.value)}}>
                                        {answer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                    </Select>
                                </div>
                                :
                                <div className={styles.Input}>
                                    <Select
                                        value={userAnswer}
                                        label={answer.title}
                                        readOnly={readonly}
                                        onChange={(event: SelectChangeEvent) => {setUserAnswer(event.target.value)}}>
                                        {answer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                    </Select>
                                </div>
                            }
                        </>
                    }
                    {status === "SharedByCollaborator" && isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <div className={styles.Input}>
                                    <Select
                                        value={userAnswer}
                                        label={answer.title}
                                        readOnly={true}
                                        onChange={(event: SelectChangeEvent) => {setUserAnswer(event.target.value)}}>
                                        {answer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <div className={styles.Input}>
                                    <Select
                                        value={managerAnswerLocal}
                                        label={managerAnswer.title}
                                        readOnly={readonly}
                                        onChange={(event: SelectChangeEvent) => {setManagerAnswer(event.target.value)}}>
                                        {managerAnswer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    }
                    {status === "SharedByManager" && !isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <div className={styles.Input}>
                                    <Select
                                        value={userAnswer}
                                        label={answer.title}
                                        readOnly={readonly}
                                        onChange={(event: SelectChangeEvent) => {setUserAnswer(event.target.value)}}>
                                        {answer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <div className={styles.Input}>
                                    <Select
                                        value={managerAnswerLocal}
                                        label={managerAnswer.title}
                                        readOnly={true}
                                        onChange={(event: SelectChangeEvent) => {setManagerAnswer(event.target.value)}}>
                                        {managerAnswer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    }
                    {(status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <div className={styles.Input}>
                                    <Select
                                        value={userAnswer}
                                        label={answer.title}
                                        readOnly={readonly || isManager}
                                        onChange={(event: SelectChangeEvent) => {setUserAnswer(event.target.value)}}>
                                        {answer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <div className={styles.Input}>
                                    <Select
                                        value={managerAnswerLocal}
                                        label={managerAnswer.title}
                                        readOnly={readonly || !isManager}
                                        onChange={(event: SelectChangeEvent) => {setManagerAnswer(event.target.value)}}>
                                        {managerAnswer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    }
                </>
                :
                <>
                    {answer.visibility === "Manager" ?
                        <>
                            {((status === "SharedByManager" && !isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.manager")}</h5>
                            }
                            <div className={styles.Input}>
                                <Select
                                    value={managerAnswerLocal}
                                    label={answer.title}
                                    readOnly={readonly}
                                    onChange={(event: SelectChangeEvent) => {setManagerAnswer(event.target.value)}}>
                                    {answer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                </Select>
                            </div>
                        </>
                        :
                        <>
                            {((status === "SharedByCollaborator" && isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.collaborator")}</h5>
                            }
                            <div className={styles.Input}>
                                <Select
                                    value={userAnswer}
                                    label={answer.title}
                                    readOnly={readonly}
                                    onChange={(event: SelectChangeEvent) => {setUserAnswer(event.target.value)}}>
                                    {answer.options.map((option: string, index) => {return <MenuItem key={index} value={option}>{option}</MenuItem>})}
                                </Select>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    );
};
export default SelectComponent;