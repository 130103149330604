import * as React from "react";
import {MultipleChoice} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface MultiSelectTemplateProps {
    answer: MultipleChoice
    changeHandler: Function
    managerAnswer: MultipleChoice
    isManager: boolean
    status: "Draft" | "SharedByCollaborator" | "SharedByManager" | "SharedByBoth" | "ValidatedByManager" | "ValidatedByBoth"
    readonly?: boolean
}

const MultiSelectComponent: React.SFC<MultiSelectTemplateProps> = ({answer, readonly, changeHandler, managerAnswer, status, isManager}) => {
    const { t } = useTranslation();
    const [userAnswer, setUserAnswer] = useState<[]>(answer.answer);
    const [managerAnswerLocal, setManagerAnswer] = useState<[]>(managerAnswer.answer);

    useEffect(() => {
        if(!isManager){
            changeHandler(userAnswer)
        }
    }, [userAnswer])

    useEffect(() => {
        if(isManager){
            changeHandler(managerAnswerLocal)
        }
    }, [managerAnswerLocal])


    return (
        <div className={styles.titleInput}>
            <label htmlFor={answer.title}>{answer.title}</label>
            {answer.visibility === "Both" ?
                <>
                    {(status === "Draft" || (status === "SharedByCollaborator" && !isManager ) || (status === "SharedByManager" && isManager)) &&
                        <>
                            {isManager ?
                                <Autocomplete
                                    multiple
                                    options={answer.options}
                                    defaultValue={managerAnswerLocal}
                                    readOnly={readonly}
                                    onChange={(event: any, newValue: string | any) => {setManagerAnswer(newValue)}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                                :
                                <Autocomplete
                                    multiple
                                    options={answer.options}
                                    defaultValue={userAnswer}
                                    readOnly={readonly}
                                    onChange={(event: any, newValue: string | any) => {setUserAnswer(newValue)}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            }
                        </>
                    }
                    {status === "SharedByCollaborator" && isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <Autocomplete
                                    multiple
                                    options={answer.options}
                                    defaultValue={userAnswer}
                                    readOnly={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <Autocomplete
                                    multiple
                                    options={managerAnswer.options}
                                    defaultValue={managerAnswerLocal}
                                    readOnly={readonly}
                                    onChange={(event: any, newValue: string | any) => {setManagerAnswer(newValue)}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    }
                    {status === "SharedByManager" && !isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <Autocomplete
                                    multiple
                                    options={answer.options}
                                    defaultValue={userAnswer}
                                    readOnly={readonly}
                                    onChange={(event: any, newValue: string | any) => {setUserAnswer(newValue)}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <Autocomplete
                                    multiple
                                    options={managerAnswer.options}
                                    defaultValue={managerAnswerLocal}
                                    readOnly={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    }
                    {(status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <Autocomplete
                                    multiple
                                    options={answer.options}
                                    defaultValue={userAnswer}
                                    readOnly={readonly || isManager}
                                    onChange={(event: any, newValue: string | any) => {setUserAnswer(newValue)}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <Autocomplete
                                    multiple
                                    options={managerAnswer.options}
                                    defaultValue={managerAnswerLocal}
                                    readOnly={readonly || !isManager}
                                    onChange={(event: any, newValue: string | any) => {setManagerAnswer(newValue)}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    }
                </>
                :
                <>
                    {answer.visibility === "Manager" ?
                        <>
                            {((status === "SharedByManager" && !isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.manager")}</h5>
                            }
                            <Autocomplete
                                multiple
                                options={answer.options}
                                defaultValue={managerAnswerLocal}
                                readOnly={readonly}
                                onChange={(event: any, newValue: string | any) => {setManagerAnswer(newValue)}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                    />
                                )}
                            />
                        </>
                        :
                        <>
                            {((status === "SharedByCollaborator" && isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.collaborator")}</h5>
                            }
                            <Autocomplete
                                multiple
                                options={answer.options}
                                defaultValue={userAnswer}
                                readOnly={readonly}
                                onChange={(event: any, newValue: string | any) => {setUserAnswer(newValue)}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                    />
                                )}
                            />
                        </>
                    }
                </>
            }
        </div>
    );
};
export default MultiSelectComponent;