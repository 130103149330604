import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SidebarHR from "../components/sidebarHR";
import Homepage from "./homepage";
import {getOrganizationManagerTeamDetails} from "../store/reducers/organizationReducer";
import TeamManagerDetails from "../components/TeamManagerDetails";

export default function ViewTeamManagerDetails() {
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.organization.status);
    const [checkCall, setCheckCall] = useState<boolean>(false);

    if(!checkCall){
        dispatch(getOrganizationManagerTeamDetails(params.managerId!));
        setCheckCall(true);
    }

    useEffect(() => {
        setCheckCall(false)
    }, [dispatch]);

    //duplicate the useEffect because of a bug in production if with one useEffect and the two deps (dispatch and params)
    useEffect(() => {
        setCheckCall(false)
    }, [params]);

    const managerStats = useSelector((state: RootState) => state.organization.teamDetails);
    if (status != "error" && managerStats) {
        return (
            <>
                <SidebarHR />
                <TeamManagerDetails managerStats={managerStats}/>
            </>
        );
    } else {
        return <Homepage />;
    }
}