import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Experience, ExperienceParameters} from "../../interfaces/experience.interface";
import ExperienceServices from "../../services/experience.service";
import {RootState} from "../store";
import {ExperienceAdapter, ExperienceAdapterList} from "../../adapters/experience.adapter";

interface ValidationErrors {
    goal: [string]
    details: [string]
    title: [string]
    experience_date: [string]
    reflection: [string]
}

const initialState = {
    experiences: [] as Experience[],
    status: "idle",
    error: {} as ValidationErrors,
    count: 0,
    limit: 8,
    offset: 0
};

const experienceService : ExperienceServices = new ExperienceServices();

export const createExperience = createAsyncThunk(
    'experiences/create',
    async (data: Experience, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let experienceData = {} as Experience
        try {
            const response = await experienceService.createExperience(state.activeUser.activeUser.token, data)
            if (response.status == 201) {
                return ExperienceAdapter(response.data, "in") as Experience
            }
            return experienceData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getExperienceDetails = createAsyncThunk(
    'experiences/getDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let experienceData = {} as Experience
        try {
            const response = await experienceService.getExperienceDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                experienceData = ExperienceAdapter(response.data, "in") as Experience
            }
            return experienceData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getAllExperiences = createAsyncThunk(
    'experiences/getAll',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let experienceData = [] as Experience[]
        try {
            const response = await experienceService.getAllExperiences(state.activeUser.activeUser.token)
            if (response.status == 200) {
                experienceData = ExperienceAdapterList(response.data.results, "in")
            }
            return experienceData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const updateExperience = createAsyncThunk(
    'experiences/updateExperience',
    async (data: Experience, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let experienceData = {} as Experience
        try {
            const response = await experienceService.updateExperience(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                experienceData = ExperienceAdapter(response.data, "in") as Experience
            }
            return experienceData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getAllManagerExperiences = createAsyncThunk(
    'experiences/getAllManagerExperiences',
    async (parameters: ExperienceParameters, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let experienceData = {experiences: [] as Experience[], count: 0, offset:0}
        try {
            const response = await experienceService.getAllManagerExperiences(state.activeUser.activeUser.token, parameters)
            if (response.status == 200) {
                experienceData.experiences = ExperienceAdapterList(response.data.results, "in")
                experienceData.count = response.data.count
                if (parameters.offset){
                    experienceData.offset = parameters.offset;
                }
            }
            return experienceData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getManagerExperienceDetails = createAsyncThunk(
    'experiences/getManagerDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let experienceData = {} as Experience
        try {
            const response = await experienceService.getManagerExperienceDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                experienceData = ExperienceAdapter(response.data, "in") as Experience
            }
            return experienceData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const updateManagerExperience = createAsyncThunk(
    'experiences/updateManagerExperience',
    async (data: Experience, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let experienceData = {} as Experience
        try {
            const response = await experienceService.updateManagerExperience(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                experienceData = ExperienceAdapter(response.data, "in") as Experience
            }
            return experienceData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const deleteExperience = createAsyncThunk(
    'experiences/deleteExperience',
    async (data: { id:number }, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const experienceId = data.id
        try {
            const response = await experienceService.deleteExperience(state.activeUser.activeUser.token, data)
            if (response.status == 204 || response.status == 202) {
                return experienceId
            }
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

const experienceSlice = createSlice({
    name: "experiences",
    initialState,
    reducers: {
        updateGoalExperience(state, action: PayloadAction<{id : number, goal: number}>){
            const index = state.experiences.findIndex((experience) => experience.id === action.payload.id);
            state.experiences[index].goal = action.payload.goal
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createExperience.fulfilled, (state, action) => {
            state.experiences.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(createExperience.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(createExperience.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(deleteExperience.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(deleteExperience.fulfilled, (state, action) => {
            state.experiences.filter((experience) => experience.id != action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(deleteExperience.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(getExperienceDetails.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getExperienceDetails.fulfilled, (state, action) => {
            state.experiences.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(getExperienceDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(getAllExperiences.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getAllExperiences.fulfilled, (state, action) => {
            state.experiences = action.payload
            state.status = "success"
        })
        builder.addCase(getAllExperiences.rejected, (state, action) => {
            state.status = "error"
        })
        builder.addCase(updateExperience.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(updateExperience.fulfilled, (state, action) => {
            const index = state.experiences.findIndex((experience) => experience.id == action.payload.id)
            state.experiences[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(updateExperience.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(getAllManagerExperiences.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getAllManagerExperiences.fulfilled, (state, action) => {
            state.experiences = action.payload.experiences
            state.count = action.payload.count
            state.offset = action.payload.offset
            state.status = "success"
        })
        builder.addCase(getAllManagerExperiences.rejected, (state, action) => {
            state.status = "error"
        })
        builder.addCase(getManagerExperienceDetails.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getManagerExperienceDetails.fulfilled, (state, action) => {
            state.experiences.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(getManagerExperienceDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
    },
});

export const { updateGoalExperience } = experienceSlice.actions;
export default experienceSlice.reducer;