import * as React from 'react';
import { useTranslation } from "react-i18next";
import styles from './header.module.css';
import NavMenu from "./navMenu";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import titleIcon from '../../assets/title-icon.png';

interface headerProps {
    title?: string
}

const HeaderManager: React.SFC<headerProps> = ({ title }) => {
    const {t} = useTranslation();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);

    return (
        <header className={styles.headerManager}>
            {title !== undefined
                ?
                <h1>
                    {title}
                    <img src={titleIcon} />
                </h1>
                :
                <h1>
                    {t('header.welcomeDashboardManager', {name: activeUser.firstName})}
                    <img src={titleIcon} />
                </h1>
            }
            <NavMenu managerHeader={true} hrHeader={false}/>
        </header>
    );
}
export default HeaderManager;