import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import NotificationsServices from "../../services/notifications.service";
import {Notification} from "../../interfaces/notification.interface";
import {RootState} from '../store';

interface ValidationErrors {
}

const initialState = {
    notifications: [] as Notification[],
    status: "idle",
    error:  {} as ValidationErrors,
    count: 0,
    limit: 8,
    offset: 0
};

const notificationsService : NotificationsServices = new NotificationsServices();

export const getAllNotifications = createAsyncThunk(
    'notifications/allNotifications',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let notificationsData = [] as Notification[]
        try {
            const response = await notificationsService.getAllNotifications(state.activeUser.activeUser.token)
            if (response.status == 200) {
                notificationsData = response.data.results as Notification[]
            }
            return notificationsData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const deleteNotification = createAsyncThunk(
    'notifications/delete',
    async (id: number, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let notificationId = id
        try {
            const response = await notificationsService.deleteNotification(state.activeUser.activeUser.token, id)
            if (response.status == 204 || response.status == 202) {
                return notificationId
            }
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const deleteAllNotifications = createAsyncThunk(
    'notifications/deleteAllNotifications',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let notificationsData = [] as Notification[]
        try {
            const response = await notificationsService.deleteAllNotifications(state.activeUser.activeUser.token)
            if (response.status == 200) {
                notificationsData = response.data.results as Notification[]
            }
            return notificationsData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllNotifications.fulfilled, (state, action) => {
            state.status = "success"
            state.notifications = action.payload
            state.error = {} as ValidationErrors
        })
            .addCase(getAllNotifications.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(getAllNotifications.rejected, (state, action) => {
                state.status = "error"
                state.error = action.payload! as ValidationErrors
            })
            .addCase(deleteNotification.fulfilled, (state, action) => {
                state.notifications.filter((notification) => notification.id != action.payload)
                state.status = "success"
                state.error = {} as ValidationErrors
            })
            .addCase(deleteNotification.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(deleteNotification.rejected, (state, action) => {
                state.status = "error"
                state.error = action.payload! as ValidationErrors
            })
            .addCase(deleteAllNotifications.fulfilled, (state, action) => {
                state.status = "success"
                state.notifications = action.payload
                state.error = {} as ValidationErrors
            })
            .addCase(deleteAllNotifications.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(deleteAllNotifications.rejected, (state, action) => {
                state.status = "error"
                state.error = action.payload! as ValidationErrors
            })
    },
});

export default notificationsSlice.reducer;