import {Fragment, useEffect, useState} from "react";
import styles from "./milestone.module.css";
import { Milestone as MilestoneInterface }  from '../interfaces/goal.interface';
import closeImg from '../assets/measure-delete.svg'
import completedImg from '../assets/measure-completed-logo.svg'
import notCompletedImg from '../assets/measure-not-completed.svg'
import sendImg from '../assets/measure-experience.svg'
import PopUpCompletedMilestone from "./PopUpCompletedMilestone";


interface MilestoneProps {
    milestone: MilestoneInterface,
    changeHandler: Function,
    deleteHandler: Function,
    saveHandler?: Function,
    enterKey: Function,
    readonly: boolean,
    isManager: boolean,
    goalId?: number | undefined
}

const Milestone: React.SFC<MilestoneProps> = ({milestone, changeHandler, deleteHandler, saveHandler, readonly, isManager, goalId,enterKey}) => {

    const [completed, setCompleted] = useState<boolean>(milestone.completed || false);
    const [title, setTitle] = useState<string>(milestone.title || "");
    const [openPopUp, setOpenPopUp] = useState(false);

    const HandleClosePopUp = () => {
        setOpenPopUp(false);
        if(saveHandler){
            saveHandler()
        }
    }

    const experienceLink: string = "/experiences/new?goalId="+ goalId + "&title=" + title;

    return (
        <Fragment>
            <li>
                <label className={styles.container}>
                    <input
                        type="checkbox"
                        checked={completed}
                        onChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                if (!isManager){
                                    setCompleted(event.target.checked)
                                    changeHandler({id:milestone.id, completed: event.target.checked, title: title})
                                    setOpenPopUp(event.target.checked);
                                    if(saveHandler && milestone.id && !milestone.id.toString().startsWith('Tmp') ){
                                        saveHandler()
                                    }
                                }
                            }}
                    />
                    <img className={styles.checkmarkNotComplet} src={notCompletedImg}/>
                    <img className={styles.checkmarkComplet} src={completedImg}/>
                </label>
                <input
                    type="text"
                    value={title}
                    readOnly={readonly}
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>) => {
                            setTitle(event.target.value)
                            changeHandler({id:milestone.id, completed: completed, title: event.target.value})
                        }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter" && !readonly) {
                            e.preventDefault()
                            enterKey()
                        }
                    }}
                />
                {!readonly && (!completed || !saveHandler) &&
                    <img onClick={() => {deleteHandler()}} className={styles.close} src={closeImg}/>
                }
                {completed && !isManager && saveHandler &&
                    <a href={experienceLink} onClick={() => {saveHandler()}}><img src={sendImg} className={styles.close}/></a>
                }
            </li>
            {openPopUp && !isManager && saveHandler &&
                <PopUpCompletedMilestone visible={openPopUp&&!isManager} closeHandler={HandleClosePopUp} saveHandler={saveHandler} experienceLink={experienceLink}/>
            }
        </Fragment>
    );
};
export default Milestone;