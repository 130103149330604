import { useTranslation } from "react-i18next";
import HeaderHR from '../components/header/headerHR';
import styles from "../pages/dashboardHR.module.css";
import * as React from "react";
import ManagerStats from "../interfaces/manager.interface";

interface ManagerStatsProps {
    managerStats: ManagerStats
}


const TeamManagerDetails: React.FC<ManagerStatsProps> = ({managerStats}) => {
    const { t } = useTranslation();

    return (
        <main className={styles.mainManager}>
            <HeaderHR />
            <section className={styles.firstLine}>
                <div className={styles.card}>
                    <h3>{t("HR.companyOverview")}</h3>
                    <div className={styles.subcardContainer}>
                        <div className={styles.subcard}>
                            <p>
                                <span>{managerStats.engagement.less_committed}</span>
                                {t("HR.talent", {count:managerStats.engagement.less_committed})} {t("HR.lessCommitted")}
                            </p>
                            <p>
                                <span>{managerStats.engagement.committed}</span>
                                {t("HR.talent", {count:managerStats.engagement.committed})} {t("HR.committed")}
                            </p>
                            <p>
                                <span>{managerStats.engagement.very_committed}</span>
                                {t("HR.talent", {count:managerStats.engagement.very_committed})} {t("HR.veryCommitted")}
                            </p>
                        </div>
                        <div className={styles.subcard}>
                            <p>
                                <span>{managerStats.entriesCount.goals}</span>
                                {t("HR.goals", {count:managerStats.entriesCount.goals})}
                            </p>
                            <p>
                                <span>{managerStats.entriesCount.experiences}</span>
                                {t("HR.experiences", {count:managerStats.entriesCount.experiences})}
                            </p>
                            <p>
                                <span>{managerStats.entriesCount.conversations}</span>
                                {t("HR.conversations", {count:managerStats.entriesCount.conversations})}
                            </p>
                            <p>
                                <span>{managerStats.entriesCount.feedbacks}</span>
                                {t("HR.feedbacks", {count:managerStats.entriesCount.feedbacks})}
                            </p>
                            <p>
                                <span>{managerStats.entriesCount.requests}</span>
                                {t("HR.requests", {count:managerStats.entriesCount.requests})}
                            </p>
                        </div>
                    </div>
                    <div className={styles.graphContainer}>
                        <div className={styles.progress}>
                            <span>{managerStats.goalCompletion}</span>%
                            <progress max="100" value={managerStats.goalCompletion}></progress>
                        </div>
                        <p>{t("common.goalsProgression")}</p>
                    </div>
                </div>
            </section>
        </main>
    );
}


export default TeamManagerDetails;