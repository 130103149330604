import { Sidebar } from "../components/sidebar";
import * as React from "react";
import styles from './viewManagerConversation.module.css';
import { ManagerRequestsList } from "../components/ManagerRequestsList";
import HeaderManager from "../components/header/headerManager";

export default function AllManagerRequests() {
    return (
        <>
            <Sidebar />
            <main>
                <HeaderManager/>
                <section className={styles.section} >
                    <ManagerRequestsList parameters={{ limit: 8}} />
                </section>
            </main>
        </>
    );
}