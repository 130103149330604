import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from './hrcomments.module.css';
import { AppDispatch, RootState } from "../store/store";
import { getCollaboratorsList } from "../store/reducers/collaboratorReducer";
import * as React from "react";
import {HRComment as HRCommentInterface} from "../interfaces/hrComment.interface";
import AvatarIcon from "./avatar";
import CollaboratorSelectOption from "./collaboratorSelectOption";

interface HRCommentProps {
    HRcomment: HRCommentInterface
}

const HRCommentComponent: React.FunctionComponent<HRCommentProps> = ({ HRcomment }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getCollaboratorsList())
    }, [dispatch])

    let commentCollaborator = useSelector((state: RootState) => state.collaborators.collaborators.find((collaborator) => collaborator.id == HRcomment.collaborator))
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)
    let author = useSelector((state: RootState) => state.collaborators.collaborators.find((collaborator) => collaborator.id == HRcomment.created_by));

    if(activeUser.id === HRcomment.created_by) {
        author = activeUser
    }

    return (
        <div className={styles.hrcomment}>
            <CollaboratorSelectOption collaborator={author!} displayName={false}/>
            <div>
                <p>{HRcomment.comment}</p>
                <span>{new Date(HRcomment.created_at!).toLocaleDateString()}</span>
            </div>
        </div>
    );
}

export default HRCommentComponent;