import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { Language } from "./i18n/Language.interface";
import translationEN from './i18n/en.json';
import translationFR from './i18n/fr.json';
import {
    format as formatDate,
    formatRelative,
    formatDistance,
    isDate
} from "date-fns";
import { enUS as en, fr } from "date-fns/locale";

const locales = { en, fr };
let defaultLanguage = Language.FR;

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: i18n.options.lng,
        keySeparator: ".",  // to support nested translations
        interpolation: {
            // react already safes from xss
            escapeValue: false,
            format: (value, format, lng) => {
                if (isDate(value)) {
                    const locale = locales[lng];
                    if (format === "short")
                        return formatDate(value, "P", { locale });
                    if (format === "long")
                        return formatDate(value, "PPPP", { locale });
                    if (format === "relative")
                        return formatRelative(value, new Date(), { locale });
                    if (format === "ago")
                        return formatDistance(value, new Date(), {
                            locale,
                            addSuffix: true
                        });
                    return formatDate(value, format, { locale });
                }
                return value;
            }
        }
    });

export default i18n;