import * as React from "react";
import {PastTrainingCondat} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface PastTrainingCondatProps {
    answer: PastTrainingCondat
    changeHandler: Function
    readonly?: boolean
}

const PastTrainingCondatComponent: React.SFC<PastTrainingCondatProps> = ({answer, readonly, changeHandler}) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>(answer.title)
    const [result, setResult] = useState<string>(answer.result)
    const [satisfaction, setSatisfaction] = useState<string>(answer.satisfaction)

    useEffect(() => {
        let newAnswer = {
            title: title,
            result: result,
            satisfaction: satisfaction,
            position: answer.position,
            type: answer.type,
        } as PastTrainingCondat
        changeHandler(newAnswer)
    }, [title, result, satisfaction])

    return (
        <div className={styles.titleInput + " " + styles.goalTemplate}>
            <h4>{t("common.training")}</h4>
            <label htmlFor="title">{t("training.title")}</label>
            <input
                type="text"
                name="title"
                defaultValue={title}
                readOnly={readonly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(event.target.value)
                }}/>
            <label htmlFor="result">{t("training.result")}</label>
            <textarea
                name="result"
                defaultValue={result}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setResult(event.target.value)
                    }}>
            </textarea>
            <label htmlFor="satisfaction">{t("training.satisfaction")}</label>
            <div className={styles.Input}>
                <Select
                    value={satisfaction}
                    label={answer.satisfaction}
                    readOnly={readonly}
                    onChange={(event: SelectChangeEvent) => {setSatisfaction(event.target.value)}}>
                        <MenuItem value="Satisfait">Satisfait</MenuItem>
                        <MenuItem value="Non Satisfait">Non satisfait</MenuItem>
                </Select>
            </div>
        </div>
    );
};
export default PastTrainingCondatComponent;