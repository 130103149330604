import { useTranslation } from "react-i18next";
import styles from "./entries.module.css";
import {Entry} from "../interfaces/entry.interface";
import * as React from "react";

interface EntryProps {
    entry: Entry,
}

const EntryCard: React.SFC<EntryProps> = ({entry, }) => {
    const { t } = useTranslation();
    const status = "common." + entry.status.toLowerCase()
    const type = "common." + entry.entry_type.toLowerCase()
    const date = new Date(entry.last_modified)
    const link = "/manager/" + entry.entry_type + "s/" + entry.id

    return (
        <li className={styles.entry}>
            <a href={link}>
                <div className={styles.Author}>
                    <p>{entry.collaborator_from.first_name} {entry.collaborator_from.last_name}</p>
                </div>
                <div className={styles.Type}>
                    {t(type)}
                </div>
                <div className={styles.Date}>{t("dates.completionDate", {date: date})}</div>
                <div className={styles.Status}>
                    <span className={entry.status}></span>
                    {t(status)}
                </div>
            </a>
        </li>
    );
};
export default EntryCard;