import { useTranslation } from "react-i18next";
import HeaderManager from '../components/header/headerManager';
import { Sidebar } from "../components/sidebar";
import { Entries } from "../components/entries";
import styles from "./dashboardManager.module.css";
import talentEngagementEmptyStage from '../assets/talent-engagement-emptystage.png';
import developmentFeelingEmptyStage from "../assets/developmentfeeling-emptystage.png";
import goalProgressionEmptyStage from "../assets/goal-progression-emptystage.png";
import responseTimeEmpyStage from "../assets/response-time-emptystage.png";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import * as React from "react";
import { useEffect } from "react";
import { getManagerDashboardData } from "../store/reducers/dashboardManagerReducer";
import Donut from "../components/donut";
import { ToolTips } from "../components/toolTips";
import TalentEngagementList from "../components/talentEngagementList";

export default function DashboardManager() {
    const { t } = useTranslation();
    const status = useSelector((state: RootState) => state.team.status)
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getManagerDashboardData());
    }, [dispatch]);

    const dashboardData = useSelector((state: RootState) => state.dashboardManager.dashboardData);
    let talentEngagementData = false
    if (dashboardData.talentEngagement.less_committed.length > 0 || dashboardData.talentEngagement.committed.length > 0 || dashboardData.talentEngagement.very_committed.length > 0) {
        talentEngagementData = true
    }

    return (
        <>
            <Sidebar />
            <main className={styles.mainManager}>
                <HeaderManager />
                <section className={styles.firstLine}>
                    <div className={styles.card} id="dashboardManagerTalentEngagement">
                        <h3>{t("dashboardManager.talentEngagement")}
                    <ToolTips text={t("dashboardManager.toolTips.talentEngagement")} parent={document.getElementById("dashboardManagerTalentEngagement")}/></h3>
                        {talentEngagementData
                            ?
                            <div className={styles.engagement}>
                                <div className={styles.engagementSection}>
                                    <h5>
                                        <span className={styles.LessCommitted}></span>
                                        {t("common.lessCommitted")}
                                    </h5>
                                    <TalentEngagementList members={dashboardData.talentEngagement.less_committed}/>
                                </div>
                                <span className={styles.separator}></span>
                                <div>
                                    <h5>
                                        <span className={styles.Committed}></span>
                                        {t("common.committed")}
                                    </h5>
                                    <TalentEngagementList members={dashboardData.talentEngagement.committed}/>
                                </div>
                                <span className={styles.separator}></span>
                                <div>
                                    <h5>
                                        <span className={styles.VeryCommitted}></span>
                                        {t("common.veryCommitted")}
                                    </h5>
                                    <TalentEngagementList members={dashboardData.talentEngagement.very_committed}/>
                                </div>
                            </div>
                            :
                            <div>
                                <img src={talentEngagementEmptyStage} />
                                <p>{t("dashboardManager.talentEngagementEmptyStage")}</p>
                            </div>
                        }
                    </div>
                    <div className={styles.card} id="dashboardManagerDevelopmentFeeling">
                        <h3>{t("dashboardManager.developmentFeelings")}
                        <ToolTips text={t("dashboardManager.toolTips.developmentFeelings")} parent={document.getElementById("dashboardManagerDevelopmentFeeling")}/></h3>
                        <div>
                            <img src={developmentFeelingEmptyStage} />
                            <p>{t("dashboardManager.developmentFeelingsEmptyStage")}</p>
                        </div>
                    </div>
                    <div className={styles.cardsAside}>
                        <div className={styles.card} id="dashboardManagerGoalProgression">
                            <h3>{t('dashboardManager.goalProgression')}
                            <ToolTips text={t("dashboardManager.toolTips.goalProgression")} parent={document.getElementById("dashboardManagerGoalProgression")}/></h3>
                            {dashboardData.goal > 0
                                ?
                                <div className={styles.donut}>
                                    <Donut classCss={styles.baseDonut} value={100} size={80} />
                                    <Donut classCss={styles.donutGraph} value={dashboardData.goal} size={80} />
                                    <span className={styles.donutValue}>{dashboardData.goal.toFixed(0)}%</span>
                                </div>
                                :
                                <div>
                                    <img src={goalProgressionEmptyStage} />
                                </div>
                            }
                        </div>
                        <div className={styles.card} id="dashboardManagerTimeResponse">
                            <h3>{t('dashboardManager.timeResponse')}
                            <ToolTips text={t("dashboardManager.toolTips.timeResponse")} parent={document.getElementById("dashboardManagerTimeResponse")}/></h3>
                            <div>
                                <img src={responseTimeEmpyStage} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className={styles.secondLine}>
                    <Entries parameters={{ limit: 4 }} />
                    <div className={styles.cardsAside}>
                        <div className={styles.card} id="dashboardManagerAppreciation">
                            <h3>{t("dashboardManager.appreciation")}
                            <ToolTips text={t("dashboardManager.toolTips.appreciation")} parent={document.getElementById("dashboardManagerAppreciation")}/></h3>
                            <div>
                                <img src={goalProgressionEmptyStage} />
                                <p>{t("dashboardManager.appreciationEmptyStage")}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}