import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Button from '../components/button';
import { Feedback as FeedbackInterface } from '../interfaces/feedback.interface';
import Collaborator from "../interfaces/collaborator.interface";
import styles from './goaldetails.module.css';
import { AppDispatch, RootState } from "../store/store";
import { updateFeedback, deleteFeedback } from "../store/reducers/feedbacksReducer";
import { Navigate } from "react-router-dom";
import { getCollaboratorsList } from "../store/reducers/collaboratorReducer";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomAlerts from "./customAlerts";
import * as React from "react";
import HeaderLogo from "../assets/feedback-header-logo.svg";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import validateLogo from "../assets/page-validate.svg"
import validateHoverLogo from "../assets/page-validate-hover.svg"
import shareHoverLogo from "../assets/page-share.svg"
import shareLogo from "../assets/page-share-hover.svg"
import deleteLogo from "../assets/page-delete.svg"
import deleteHoverLogo from "../assets/page-delete-hover.svg"
import PopUpSharedFeedback from "./PopUpSharedFeedback";
import CollaboratorSelectOption from "./collaboratorSelectOption";

interface FeedbackProps {
    feedback: FeedbackInterface
}

const FeedbackDetails: React.SFC<FeedbackProps> = ({ feedback }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getCollaboratorsList())
    }, [dispatch])

    const [feedbackDate, setFeedbackDate] = useState<Date | null>(new Date(JSON.parse(feedback.feedbackDate)));
    const [title, setTitle] = useState<string>(feedback.title);
    const [details, setDetails] = useState<string>(feedback.details);
    const [redirect, setRedirect] = useState<string>("");
    const [collaborator, setCollaborator] = useState<number>(0);
    const feedbackStatus = useSelector((state: RootState) => state.feedbacks.status);
    const errors = useSelector((state: RootState) => state.feedbacks.error);
    const [openPopUp, setOpenPopUp] = useState(false);
    let feedbackCollaborator = useSelector((state: RootState) => state.collaborators.collaborators.find((collaborator) => collaborator.id == feedback.collaborator))

    useEffect(() => {
        if (feedbackCollaborator) {
            setCollaborator(feedbackCollaborator.id);
        }
    }, [feedbackCollaborator])

    const collaborators = useSelector((state: RootState) => state.collaborators.collaborators.filter((collaborator) => collaborator.id !== state.activeUser.activeUser.id));
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)
    const author = useSelector((state: RootState) => state.collaborators.collaborators.find((collaborator) => collaborator.id === feedback.author));

    const handleChange = (event: SelectChangeEvent) => {
        setCollaborator(parseInt(event.target.value));
    };

    let readonly = true;
    if (feedback.status === "Draft") {
        readonly = false;
    }
    let authorName = ""
    if (feedback.author && author) {
        authorName = author.first_name + " " + author.last_name
    }

    if(feedbackStatus === "success") {
        if(redirect === "newGoal") {
            return <Navigate to="/manager/goals/new" />;
        } else if(redirect === "newFeedback") {
            window.location.reload();
        } else if(redirect === "return"){
            window.history.back();
        }
    }
    if(redirect === "close"){
        window.history.back();
    }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if (direction) {
            setRedirect(direction);
        } else {
            setRedirect("return");
        }
    }
    
    let status = "Draft"
    let classStatus = "Draft"
    if (feedback.status) {
        if(feedback.collaborator === activeUser.id){
            status = "common.received"
            classStatus = "Received"
        } else {
            status = "common." + feedback.status.toLowerCase()
            classStatus = feedback.status
        }
    }

    return (
        <>
            <section className={styles.modalForm}>
                <header>
                    <div className={styles.entryTitle}>
                        <img src={HeaderLogo}></img>
                        <h1>{t("common.feedback")}</h1>
                    </div>
                    <div className={styles.Status}>
                        <span className={classStatus}></span>
                        <p>{t(status)}</p>
                    </div>
                </header>
                <form className={styles.form}>
                    <div className={styles.titleInput}>
                        <label htmlFor="title">{t("feedback.title")}</label>
                        <input
                            name="title"
                            placeholder="Your feedback"
                            readOnly={readonly}
                            onChange={
                                (e) => setTitle(e.target.value)
                            }
                            defaultValue={title}>
                        </input>
                    </div>
                    {feedbackStatus === "error" && errors.title &&
                        <CustomAlerts errors={errors.title} />
                    }
                    {activeUser.id === feedback.author && feedback.status === "Draft" ?
                        <>
                            <label htmlFor="person-label">{t("feedback.person")}</label>
                            <div className={styles.Input}>
                                <Select
                                    labelId="person-label"
                                    id="person-select"
                                    value={collaborator.toString()}
                                    label="Person"
                                    onChange={handleChange}
                                    readOnly={readonly}
                                >
                                    {
                                        collaborators.map(
                                            (collaborator: Collaborator) => {
                                                return <MenuItem key={collaborator.id} value={collaborator.id}>
                                                            <CollaboratorSelectOption collaborator={collaborator} displayName={true}/>
                                                    </MenuItem>
                                            }
                                        )
                                    }
                                </Select>
                            </div>
                        </>
                        :
                            activeUser.id != feedback.author && feedback.author
                                ? <>
                                    <label htmlFor="author-label">{t("feedback.receivedFrom")}</label>
                                    <div className={styles.Input}>
                                        <CollaboratorSelectOption collaborator={author!} displayName={true}/>
                                    </div>
                                </>
                                : <>
                                    <label htmlFor="person-label">{t("feedback.person")}</label>
                                    <div className={styles.Input}>
                                        <CollaboratorSelectOption collaborator={feedbackCollaborator!} displayName={true}/>
                                    </div>
                                </>
                    }
                    {feedbackStatus === "error" && errors.collaborator_to &&
                        <CustomAlerts errors={errors.collaborator_to} />
                    }
                    <label htmlFor="feedback">{t("feedback.content")}</label>
                    <textarea
                        name="feedback"
                        readOnly={readonly}
                        onChange={
                            (e) => setDetails(e.target.value)
                        }
                        defaultValue={details}>
                    </textarea>
                    {feedbackStatus === "error" && errors.details &&
                        <CustomAlerts errors={errors.details} />
                    }
                    <label>{t("feedback.date")}</label>
                    <div className={styles.DateInput}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disableFuture
                                openTo="day"
                                readOnly={readonly}
                                views={['year', 'month', 'day']}
                                value={feedbackDate}
                                onChange={(newDate) => {
                                    setFeedbackDate(newDate);
                                }}
                                renderInput={(params) => {
                                    let newParams = {...params}
                                    if(newParams.inputProps){
                                        newParams.inputProps.value  = t("dates.completionDate", { date: feedbackDate })
                                    }
                                    return(<TextField {...newParams}/>)}}
                            />
                        </LocalizationProvider>
                    </div>
                    {feedbackStatus === "error" && errors.feedback_date &&
                        <CustomAlerts errors={errors.feedback_date} />
                    }
                </form>
                        <div className={styles.buttonContainer}>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.close")}
                            submit={
                                () => {
                                    setRedirect("close")
                                }
                            }
                            icon={closeLogo}
                            iconHover={closeHoverLogo}/>
                            {feedback.status == "Draft" && feedback.author === activeUser.id &&
                                <Button
                                    classCss={"secondary-button"}
                                    content={t("buttons.delete")}
                                    submit={
                                        () => {
                                            dispatch(
                                                deleteFeedback({ id: feedback.id! })
                                            )
                                            setRedirect("return")
                                        }
                                    }
                                    icon={deleteLogo}
                                    iconHover={deleteHoverLogo} />
                            }
                            {feedback.status == "Draft" &&
                            <Button
                                classCss={"primary-button"}
                                content={t("buttons.save")}
                                submit={() => {
                                    dispatch(
                                        updateFeedback({
                                            id: feedback.id,
                                            title,
                                            status: feedback.status,
                                            details,
                                            collaborator: feedback.collaborator,
                                            feedbackDate: JSON.stringify(feedbackDate)
                                        })
                                    )
                                    setRedirect("return")
                                }
                                }
                                icon={validateLogo}
                                iconHover={validateHoverLogo} />
                            }
                            
                            {feedback.status == "Draft" &&
                            <Button
                                classCss={"primary-button"}
                                content={t("buttons.share")}
                                submit={() => {
                                    dispatch(
                                        updateFeedback({
                                            id: feedback.id,
                                            title,
                                            status: "Sent",
                                            details,
                                            collaborator: feedback.collaborator,
                                            feedbackDate: JSON.stringify(feedbackDate)
                                        })
                                    )
                                    setOpenPopUp(true);
                                }
                                }
                                icon={shareLogo}
                                iconHover={shareHoverLogo} />
                            }
                        </div>
                {feedbackStatus !== "error" &&
                    <PopUpSharedFeedback
                        visible={openPopUp}
                        closeHandler={HandleClosePopUp} />
                }
            </section>
        </>
    );
}

export default FeedbackDetails;