import * as React from "react";
import styles from './viewManagerConversation.module.css';
import {Sidebar} from "../components/sidebar";
import HeaderManager from "../components/header/headerManager";
import {ManagerCampaignAnswerList} from "../components/ManagerCampaignAnswerList";

export default function AllManagerCampaignAnswers() {
    return (
        <>
            <Sidebar />
            <main>
                <HeaderManager />
                <section className={styles.section} >
                    <ManagerCampaignAnswerList parameters={{ limit: 8 }} />
                </section>
            </main>
        </>
    );
}