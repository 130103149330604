import * as React from "react";
import {FutureTraining} from "../../interfaces/campaignAnswer.interface"
import styles from "../goaldetails.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";

interface FutureTrainingProps {
    answer: FutureTraining
    changeHandler: Function
    readonly?: boolean
}

const FutureTrainingComponent: React.SFC<FutureTrainingProps> = ({answer, readonly, changeHandler}) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>(answer.title)
    const [target, setTarget] = useState<string>(answer.target)
    const [targetDate, setTargetDate] = useState<Date | null>(new Date(JSON.parse(answer.targetDate)));

    useEffect(() => {
        let newAnswer = {
            title: title,
            target: target,
            targetDate: JSON.stringify(targetDate),
            position: answer.position,
            type: answer.type,
        } as FutureTraining
        changeHandler(newAnswer)
    }, [title, target, targetDate])

    return (
        <div className={styles.titleInput + " " + styles.goalTemplate}>
            <h4>{t("common.training")}</h4>
            <label htmlFor="title">{t("training.title")}</label>
            <input
                type="text"
                name="title"
                defaultValue={title}
                readOnly={readonly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(event.target.value)
                }}/>
            <label htmlFor="target">{t("training.target")}</label>
            <input
                type="text"
                name="target"
                defaultValue={target}
                readOnly={readonly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTarget(event.target.value)
                }}/>
            <label>{t("training.targetDate")}</label>
            <div className={styles.DateInput}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        openTo="month"
                        readOnly={readonly}
                        views={['year', 'month', 'day']}
                        value={targetDate}
                        onChange={(newDate) => {
                            setTargetDate(newDate);
                        }}
                        renderInput={(params) => {
                            let newParams = {...params}
                            if(newParams.inputProps){
                                newParams.inputProps.value  = t("dates.completionDate", { date: targetDate })
                            }
                            return(<TextField {...newParams}/>)}}
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
};
export default FutureTrainingComponent;