import { Fragment } from "react";
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import styles from "./goalcard.module.css";
import ModeIcon from "@mui/icons-material/Mode";
import { Experience }  from '../interfaces/experience.interface';
import VisibilityIcon from "@mui/icons-material/Visibility";

interface ExperienceProps {
    experience: Experience,
}

const ExperienceCard: React.SFC<ExperienceProps> = ({experience}) => {
    const { t } = useTranslation();
    const status = "common." + experience.status.toLowerCase()
    let date = new Date(JSON.parse(experience.completionDate))
    if (experience.last_modified){
        date = new Date(experience.last_modified)
    }

    return (
        <Fragment>
            <li>
                <a href={"/experiences/" + experience.id} className={styles.LineLink}>
                    <div className={styles.Title}>
                        <p>{experience.title}</p>
                    </div>
                    <div className={styles.Status}>
                        <div className={experience.status}></div>
                        {t(status)}
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Actions}>
                        <Link to={"/experiences/" + experience.id} className={styles.link}>
                            {experience.status === "Draft" &&
                                <ModeIcon />
                            }
                            {experience.status != "Draft" &&
                                <VisibilityIcon />
                            }
                        </Link>
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default ExperienceCard;