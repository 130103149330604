import { useTranslation } from "react-i18next";
import styles from "./managerConversationsList.module.css";
import sharingEmptyStage from "../assets/sharing-emptystage.png";
import dots from "../assets/dots.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import * as React from "react";
import { useEffect, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from "react-router-dom";
import { ToolTips } from "./toolTips";
import { Request, RequestParameters } from "../interfaces/request.interface";
import requestIconPage from '../assets/request-header-logo.svg';
import newIcon from '../assets/new-white.svg'
import newIconHover from '../assets/new-orange.svg'
import ManagerRequestCard from "./ManagerRequestsCard";
import {getAllManagerRequests} from "../store/reducers/requestReducer"

interface ParametersProps {
    parameters: RequestParameters
}

export const ManagerRequestsList: React.SFC<ParametersProps> = ({ parameters }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getAllManagerRequests(parameters));
    }, [dispatch]);

    const requests = useSelector((state: RootState) => state.requests);
    const requestsList = requests.requests;
    const requestsOffset = requests.offset;
    const requestsCount = requests.count;

    const [offset, setOffset] = useState(requestsOffset);
    const limit : number  = parameters.limit || 8;

    useEffect(() => {
        setOffset( requests.offset)
    }, [ requests])



    return (
        <div className={styles.List} id="Goal">
            <div className={styles.pageTitle}>
                <img src={requestIconPage} className={styles.goalIcon}/>
                <h1>
                    {t("common.requests")}
                    {/*<ToolTips text={t("requests.tooltips")} parent={document.getElementById("Goal")}/>*/}
                </h1>
            </div>
            {requestsCount > 0
                ?
                <>
                    <div className={styles.Header}>
                        <div className={styles.headerTalent}>{t("common.talent")}<img src={dots} /></div>
                        <div className={styles.headerTitle}>{t("common.title")}<img src={dots} /></div>
                        <div className={styles.headerDate}>{t("common.date")}<img src={dots} /></div>
                        <div className={styles.headerStatus}>{t("common.status")}<img src={dots} /></div>
                    </div>
                    <div className={styles.Sharing}>
                        {requestsList.map(
                            (request: Request, index) =>
                                <ManagerRequestCard
                                    key={index}
                                    request={request}
                                />
                        )}
                    </div>
                    <div className={styles.EntryPagination}>
                        {offset + 1} - {offset + limit <= requestsCount ? offset + limit : requestsCount} {t("common.on")} {requestsCount}
                        {offset != 0
                            ? <ChevronLeftIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset-limit
                                        dispatch(getAllManagerRequests(newParameters))
                                    }
                                }/>
                            : <ChevronLeftIcon/>
                        }
                        {offset + limit < requestsCount
                            ? <ChevronRightIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset+limit
                                        dispatch(
                                            getAllManagerRequests(newParameters)
                                        )
                                    }
                                }/>
                            : <ChevronRightIcon/>
                        }
                    </div>
                </>
                :
                <div className={styles.EmptyStage}>
                    <img src={sharingEmptyStage} />
                    <p>{t("request.emptyList")}</p>
                </div>
            }
        </div>
    );
}