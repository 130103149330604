import Header from '../components/header/header';
import styles from "./login.module.css";
import {useTranslation} from "react-i18next";
import Button from "../components/button";
import {changePassword} from "../store/reducers/activeUserReducer";
import {AppDispatch, RootState} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import CustomAlerts from "../components/customAlerts";
import {useState} from "react";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";


export default function PasswordChange() {
    const { t } = useTranslation();
    const [password, setPassword] = useState<string>('');
    const [passwordVerified, setPasswordVerified] = useState<string>('');
    const dispatch = useDispatch<AppDispatch>();
    const passwordChangeStatus = useSelector((state: RootState) => state.activeUser.status);
    const errors = useSelector((state: RootState) => state.activeUser.error)
    const success = useSelector((state: RootState) => state.activeUser.success)

    return (
        <>
            <SidebarTalent />
            <main className={styles.formContainer}>
                <Header/>
                <div>
                    <h2>{t("common.resetPassword")}</h2>
                    <input
                        name="password"
                        placeholder="*******"
                        type="password"
                        onChange={
                            (e) => setPassword(e.target.value)
                        }>
                    </input>
                    {passwordChangeStatus === "error" && errors.new_password1 &&
                        <CustomAlerts errors={errors.new_password1}/>
                    }
                    <input
                        name="passwordVerified"
                        placeholder="*******"
                        type="password"
                        onChange={
                            (e) => setPasswordVerified(e.target.value)
                        }>
                    </input>
                    {passwordChangeStatus === "error" && errors.new_password2 &&
                        <CustomAlerts errors={errors.new_password2}/>
                    }
                    {passwordChangeStatus === "success" && success.detail &&
                        <span className={"success"}>
                            {success.detail}
                        </span>
                    }
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.changePassword")}
                        submit={
                            () => {
                                dispatch(changePassword({password, password2: passwordVerified}))
                            }
                        }/>
                </div>
            </main>
        </>
    );
}