import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "../pages/dashboard.module.css";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {Link} from "react-router-dom";
import {Experience as ExperienceInterface} from "../interfaces/experience.interface";

interface ExperiencesProps {
    experiences: ExperienceInterface[]
}

const ExperiencesList: React.SFC<ExperiencesProps> = ({experiences}) => {
    const { t } = useTranslation();
    if (experiences.length > 0 ){
        return (
            <Fragment>
                {
                    experiences.map((experience: ExperienceInterface) => {
                        const date = new Date(JSON.parse(experience.completionDate));
                        const status = "common." + experience.status.toLowerCase();
                        let details = experience.details;
                        if (details.length > 60) {
                            details = details.substring(0,60) + "..."
                        }
                        let title = experience.title;
                        if (title.length > 25) {
                            title = title.substring(0,25) + "..."
                        }
                        return (
                            <article key={experience.id} className={styles.itemCard}>
                                <Link to={"/experiences/" + experience.id}>
                                    <div className={experience.status}></div>
                                    <div>
                                        <h3><WorkspacePremiumIcon />{title}</h3>
                                        <span className={styles.date}>{t("dates.completionDate", {date: date})}</span>
                                    </div>
                                    <div>
                                        <p>{details}</p>
                                        <div className={styles.detailedStatus}>
                                            {t(status)}
                                        </div>
                                    </div>
                                </Link>
                            </article>
                    )
                })
                }
            </Fragment>
        )
    } else {
        return (
            <p>{t("dashboard.noExperience")}</p>
        )
    }
}


export default ExperiencesList;