import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import Header from '../components/header/header';
import ExperienceDetails from '../components/experienceDetails';
import { Experience as ExperienceInterface} from '../interfaces/experience.interface';
import {AppDispatch, RootState} from "../store/store";
import Dashboard from "./dashboard";
import {useEffect} from "react";
import {getExperienceDetails} from "../store/reducers/experiencesReducer";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface ExperienceProps {
    experience: ExperienceInterface
}

export default function ViewExperienceDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.experiences.status)

    useEffect(() => {
        if(params.experienceId){
            dispatch(getExperienceDetails(params.experienceId));
        }
    }, [dispatch]);
    const experience = useSelector((state: RootState) => state.experiences.experiences.find((experience : ExperienceInterface) => experience.id == params.experienceId));

    if (status != "error") {
        return (
            <>
                <SidebarTalent />
                <main>
                    <Header title={t("experience.h1")}/>
                    {experience &&
                        <ExperienceDetails experience={experience}/>
                    }
                </main>
            </>
        );
    }
    return <Dashboard />;

}