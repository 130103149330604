import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ActiveUser from "../../interfaces/activeUser.interface";
import {Experience} from "../../interfaces/experience.interface";
import {Goal} from "../../interfaces/goal.interface";
import DashboardServices from "../../services/dashboard.service";
import { GoalAdapterList } from "../../adapters/goal.adapter";
import { ExperienceAdapterList } from "../../adapters/experience.adapter";

const initialState = {
    status: 'idle',
    error: {} as any,
    dashboardData : {
        goals: {
            goalsAchievement: 0,
            lastGoals: [] as Goal[],
            goalsCount: 0
        },
        experiences: {
            experiencesCount: 0,
            lastExperiences: [] as Experience[]
        },
        feedbacksReceived: 0,
        feedbacksSent: 0,
        requestsCount: 0,
    }
}
const dashboardService : DashboardServices = new DashboardServices();

export const getPersonalDashboardData = createAsyncThunk(
    'dashboard/personaldata',
    async (data: ActiveUser, thunkApi) => {
        let dashboardData = initialState.dashboardData
        try {
            const response = await dashboardService.getDashboardData(data.token)
            if (response.status == 200) {
                dashboardData = {
                    goals: {
                        goalsAchievement: response.data.goals.goalsAchievement,
                        goalsCount: response.data.goals.goalsCount,
                        lastGoals: GoalAdapterList(response.data.goals.lastGoals, "in")
                    },
                    experiences: {
                        experiencesCount: response.data.experiences.experiencesCount,
                        lastExperiences: ExperienceAdapterList(response.data.experiences.lastExperiences, "in")
                    },
                    feedbacksReceived: response.data.feedbacksReceived,
                    feedbacksSent: response.data.feedbacksSent,
                    requestsCount: response.data.requestsCount
                }
            }
            return dashboardData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })


const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPersonalDashboardData.fulfilled, (state, action) => {
            state.dashboardData = action.payload
            state.status = "success"
        })
        .addCase(getPersonalDashboardData.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getPersonalDashboardData.rejected, (state, action) => {
            state.error = action.payload!
            state.status = "error"
        })
    },
});

export default dashboardSlice.reducer;