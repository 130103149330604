import api from "../utils/http-common"
import { Goal, GoalParameters } from "../interfaces/goal.interface"
import { GoalAdapter } from "../adapters/goal.adapter"

export default class GoalServices {
    createGoal(token: string, goal: Goal){
        return api.post("/goals/", GoalAdapter(goal, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    getGoalDetails(token: string, goalId: string){
        return api.get("/goals/" + goalId,{headers: {Authorization: `Bearer ${token}`}})
    }
    getAllGoals(token: string){
        return api.get("/goals/", {headers: {Authorization: `Bearer ${token}`}})
    }
    updateGoal(token: string, goal: Goal){
        return api.put("/goals/" + goal.id, GoalAdapter(goal, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    deleteGoal(token: string, goal: { id: number }){
        return api.delete("/goals/" + goal.id,{headers: {Authorization: `Bearer ${token}`}})
    }
    deleteManagerGoal(token: string, goal: { id: number }){
        return api.delete("/manager/goals/" + goal.id,{headers: {Authorization: `Bearer ${token}`}})
    }
    getManagerGoalDetails(token: string, goalId: string){
        return api.get("/manager/goals/" + goalId,{headers: {Authorization: `Bearer ${token}`}})
    }
    updateManagerGoal(token: string, goal: Goal){
        return api.put("/manager/goals/" + goal.id, GoalAdapter(goal, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    getAllManagerGoals(token: string, parameters: GoalParameters){
        let url : string = "/manager/goals?";
        for (const [key, value] of Object.entries(parameters)) {
            url += `${key}=${value}&`
        }
        return api.get(url,{headers: {Authorization: `Bearer ${token}`}});
    }
}