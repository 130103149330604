import api from "../utils/http-common"
import {HRComment} from "../interfaces/hrComment.interface";

export default class HRCommentsServices {
    getHRComments(token: string, collaboratorId: number){
        return api.get("/hr_comments/?collaborator_id=" + collaboratorId, {headers: {Authorization: `Bearer ${token}`}})
    }
    createHRComment(token: string, data: HRComment){
        return api.post("/hr_comments/", data, {headers: {Authorization: `Bearer ${token}`}})
    }
}