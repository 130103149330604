import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import Header from '../components/header/header';
import CampaignAnswerDetails from '../components/campaignAnswerDetails';
import { CampaignAnswer as CampaignAnswerInterface} from '../interfaces/campaignAnswer.interface';
import {AppDispatch, RootState} from "../store/store";
import Dashboard from "./dashboard";
import {useEffect} from "react";
import {getCampaignAnswerDetails} from "../store/reducers/campaignAnswersReducer";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function ViewCampaignAnswerDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.campaignAnswers.status);

    useEffect(() => {
        if(params.campaignAnswerId){
            dispatch(getCampaignAnswerDetails(params.campaignAnswerId));
        }
    }, [dispatch]);
    const campaignAnswer = useSelector((state: RootState) => state.campaignAnswers.campaignAnswers.find((campaignAnswers : CampaignAnswerInterface) => campaignAnswers.id == params.campaignAnswerId));
    if (status !== "error") {
        return (
            <>
                <SidebarTalent />
                <main>
                    <Header title={t("conversation.h1")}/>
                    {campaignAnswer &&
                        <CampaignAnswerDetails campaignAnswer={campaignAnswer}/>
                    }
                </main>
            </>
        );
    } else {
        return <Dashboard />;
    }
}