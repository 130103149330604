import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {Link} from 'react-router-dom';
import { Goal }  from '../interfaces/goal.interface';
import styles from "./goalcard.module.css";
import ModeIcon from "@mui/icons-material/Mode";
import VisibilityIcon from '@mui/icons-material/Visibility';

interface GoalProps {
    goal: Goal,
}

const GoalCard: React.SFC<GoalProps> = ({goal}) => {
    const { t } = useTranslation();
    let status = "common.Draft"
    if(goal.status){
        status = "common." + goal.status.toLowerCase();
    }
    let date = new Date(JSON.parse(goal.completionDate));
    if (goal.last_modified){
        date = new Date(goal.last_modified);
    }


    return (
        <Fragment>
            <li>
                <a href={"/goals/" + goal.id} className={styles.LineLink}>
                    <div className={styles.Title}>
                        <p>{goal.title}</p>
                    </div>
                    <div className={styles.Status}>
                        <div className={goal.status}></div>
                        {t(status)}
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Actions}>
                        <Link to={"/goals/" + goal.id} className={styles.link}>
                            {goal.status === "Draft" &&
                                <ModeIcon />
                            }
                            {goal.status != "Draft" &&
                                <VisibilityIcon />
                            }
                        </Link>
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default GoalCard;