import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ActiveUser from "../../interfaces/activeUser.interface";
import ILogin from "../../interfaces/login.interface";
import ISignUp from "../../interfaces/signup.interface";
import UserServices from "../../services/user.service";
import axios from "axios";
import {RootState} from "../store";

interface ValidationErrors {
    email: [string]
    password1: [string]
    password2: [string]
    non_field_errors: [string]
    password: [string]
    detail: string
    new_password1: [string]
    new_password2: [string]
    token: [string]
}
interface SuccessMessage {
    detail: string
}

const initialState = {
    activeUser: {} as ActiveUser,
    status: 'idle',
    error: {} as ValidationErrors,
    success: {} as SuccessMessage
}
const userService : UserServices = new UserServices();

export const userLogin = createAsyncThunk<
    any,
    ILogin,
    {
        rejectValue: ValidationErrors
    }
    >(
    'activeUser/login',
    async (data: ILogin, thunkApi) => {
        let activeUser = {
            id: 0,
            isAuthenticated: false,
            token: "",
            refresh: "",
            username: "",
            email: "",
            isManager: false,
            isHR: false,
            name: "",
            firstName: "",
            lastName: "",
            avatar: "",
            email_preferences: "ALL",
            modules_choices: ["all"],
            organization:0,
            head_company:""
        } as ActiveUser
        try {
            const response = await userService.login(data)
            if (response.status == 200) {
                let activeUserData = await userService.getActiveUser(response.data.access)
                activeUser = {
                    id: activeUserData.data.id,
                    isAuthenticated: true,
                    token: response.data.access,
                    refresh: response.data.refresh,
                    username: activeUserData.data.username,
                    email: activeUserData.data.email,
                    isManager: activeUserData.data.is_manager,
                    isHR: activeUserData.data.role === "HR",
                    name: activeUserData.data.first_name + " " + activeUserData.data.last_name,
                    firstName: activeUserData.data.first_name,
                    lastName: activeUserData.data.last_name,
                    avatar: activeUserData.data.avatar,
                    email_preferences: activeUserData.data.email_preferences,
                    modules_choices: activeUserData.data.modules_choices,
                    organization: activeUserData.data.organization,
                    head_company: activeUserData.data.head_company
                } as ActiveUser
            }
            return activeUser
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })


export const userSignUp = createAsyncThunk<
    any,
    ISignUp,
    {
        rejectValue: ValidationErrors
    }
    >(
    'activeUser/signup',
    async (data: ISignUp, thunkApi) => {
        let activeUser = {
            id: 0,
            isAuthenticated: false,
            token: "",
            refresh: "",
            username: "",
            email: "",
            isManager: false,
            isHR: false,
            name: "",
            firstName: "",
            lastName: "",
            avatar: "",
            email_preferences: "ALL",
            modules_choices: ["all"],
            organization: 0,
            head_company: ""
        } as ActiveUser
        try {
            const response = await axios.all([userService.signup(data),userService.login({email:data.email, password: data.password1})])
            let activeUserData = await userService.getActiveUser(response[1].data.access)
            activeUser = {
                id: activeUserData.data.id,
                isAuthenticated: true,
                token: response[1].data.access,
                refresh: response[1].data.refresh,
                username: activeUserData.data.username,
                email: activeUserData.data.email,
                isManager: activeUserData.data.is_manager,
                isHR: activeUserData.data.role === "HR",
                name: activeUserData.data.first_name + " " + activeUserData.data.last_name,
                firstName: activeUserData.data.first_name,
                lastName: activeUserData.data.last_name,
                avatar: activeUserData.data.avatar,
                email_preferences: activeUserData.data.email_preferences,
                modules_choices: activeUserData.data.modules_choices,
                organization: activeUserData.data.organization,
                head_company: activeUserData.data.head_company
            } as ActiveUser
            return activeUser
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const updateProfile = createAsyncThunk<
    any,
    { id:number, firstName:string, lastName:string, avatar:any, lang?:string, email_preferences?:string },
    {
        rejectValue: ValidationErrors
    }
    >(
    'activeUser/updateProfile',
    async (data: { id:number, firstName:string, lastName:string, avatar:any, lang?:string, email_preferences?:string}, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let activeUserData = { id:0, firstName:"", lastName:"", avatar:"", email_preferences:""}
        let userFormData = new FormData();
        if(data.avatar && data.avatar != state.activeUser.activeUser.avatar){
            userFormData.append("avatar", data.avatar, data.avatar.name);
        }
        if(data.lang && data.lang != state.activeUser.activeUser.avatar){
            userFormData.append("lang", data.lang);
        }
        if(data.email_preferences && data.email_preferences != state.activeUser.activeUser.email_preferences){
            userFormData.append("email_preferences", data.email_preferences)
        }
        userFormData.append("firstName", data.firstName);
        userFormData.append("lastName", data.lastName);

        try {
            const response = await userService.updateActiveUser(state.activeUser.activeUser.token, {id:data.id, userFormData})
            if (response.status == 200) {
                const newUser = await userService.getActiveUser(state.activeUser.activeUser.token)
                activeUserData = newUser.data
            }
            return activeUserData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const logout = createAsyncThunk(
    'activeUser/logout',
    async (data: null, thunkApi) => {
        const state = thunkApi.getState() as RootState
        await userService.logout(state.activeUser.activeUser.token)
        return {} as ActiveUser
    })

export const updateToken = createAsyncThunk(
    'activeUser/updateToken',
    async(data: string) => {
        return data
    }
)

export const passwordForgotten = createAsyncThunk(
    'activeUser/passwordForgotten',
    async(data: string) => {
        const response = await userService.passwordForgotten(data)
        return response.data
    }
)

export const resetPassword = createAsyncThunk<
    any,
    { uid:string, token:string, password:string, password2:string },
    { rejectValue: ValidationErrors }>(
    'activeUser/resetPassword',
    async(data: { uid:string, token:string, password:string, password2:string }, thunkApi) => {
        try {
            const response = await userService.resetPassword(data.uid, data.token, data.password, data.password2)
            return response.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    }
)

export const changePassword = createAsyncThunk<
    any,
    { password:string, password2:string },
    { rejectValue: ValidationErrors }>(
    'activeUser/changePassword',
    async(data: {password:string, password2:string }, thunkApi) => {
        const state = thunkApi.getState() as RootState
        try {
            const response = await userService.changePassword(state.activeUser.activeUser.token, data.password, data.password2)
            return response.data
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    }
)

const activeUserSlice = createSlice({
    name: "activeUser",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(userLogin.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(userLogin.fulfilled, (state, action) => {
            state.status = "success"
            state.activeUser = action.payload
        })
        .addCase(userLogin.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload!
        })
        .addCase(userSignUp.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(userSignUp.fulfilled, (state, action) => {
            state.status = "success"
            state.activeUser = action.payload
        })
        .addCase(userSignUp.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload!
        })
        .addCase(updateProfile.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(updateProfile.fulfilled, (state, action) => {
            state.status = "success"
            let activeUser = state.activeUser
            activeUser.firstName = action.payload.first_name
            activeUser.lastName = action.payload.last_name
            activeUser.avatar = action.payload.avatar
            activeUser.email_preferences = action.payload.email_preferences
            state.activeUser = activeUser
        })
        .addCase(updateProfile.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload!
        })
        .addCase(logout.fulfilled, (state, action) => {
            state.status = "success"
            state.activeUser = action.payload
        })
        .addCase(updateToken.pending, (state, action) => {
            state.status = "idle"
        })
        .addCase(updateToken.fulfilled, (state, action) => {
            state.status = "success"
            state.activeUser.token = action.payload
        })
        .addCase(updateToken.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(resetPassword.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
            state.status = "success"
        })
        .addCase(resetPassword.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload!
        })
        .addCase(changePassword.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(changePassword.fulfilled, (state, action) => {
            state.status = "success"
            state.success = action.payload!
        })
        .addCase(changePassword.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload!
        })
    },
});

export default activeUserSlice.reducer;