import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import CampaignServices from "../../services/campaign.service";
import {Campaign, CampaignParameters} from "../../interfaces/campaign.interface";
import {CampaignAdapter, CampaignAdapterList} from "../../adapters/campaigns.adapter";

interface ValidationErrors {}

const initialState = {
    campaigns: [] as Campaign[],
    status: "idle",
    error: {} as ValidationErrors,
    count: 0,
    limit: 8,
    offset: 0
};

const campaignService : CampaignServices = new CampaignServices();
    
export const getAllCampaigns = createAsyncThunk(
    'campaigns/allCampaigns',
    async (parameters: CampaignParameters, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let campaignData = {campaigns:[] as Campaign[], count: 0, offset:0}
        try {
            const response = await campaignService.getAllCampaigns(state.activeUser.activeUser.token, parameters)
            if (response.status == 200) {
                campaignData.campaigns = CampaignAdapterList(response.data.results, "in")
                campaignData.count = response.data.count
                if (parameters.offset){
                    campaignData.offset = parameters.offset;
                }
            }
            return campaignData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getCampaignDetails = createAsyncThunk(
    'campaigns/getDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let campaignData = {} as Campaign
        try {
            const response = await campaignService.getCampaignDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                return CampaignAdapter(response.data, "in") as Campaign
            }
            return campaignData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })


const campaignsSlice = createSlice({
    name: "campaigns",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCampaigns.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllCampaigns.fulfilled, (state, action) => {
            state.campaigns = action.payload.campaigns
            state.count = action.payload.count
            state.offset = action.payload.offset
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getAllCampaigns.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(getCampaignDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getCampaignDetails.fulfilled, (state, action) => {
            state.campaigns.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getCampaignDetails.rejected, (state, action) => {
            state.status = "error"
        })
    }
});

export default campaignsSlice.reducer;