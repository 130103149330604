import api from "../utils/http-common"
import {EntryParameters} from "../interfaces/entry.interface";

export default class EntriesServices {
    getEntries(token: string, parameters:EntryParameters){
        let url = "/entries/?"
        for (const [key, value] of Object.entries(parameters)) {
            url += `${key}=${value}&`
        }
        return api.get(url, {headers: {Authorization: `Bearer ${token}`}})
    }
}