import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { Goal, GoalParameters } from "../../interfaces/goal.interface";
import GoalServices from "../../services/goal.service";
import {GoalAdapter, GoalAdapterList} from "../../adapters/goal.adapter";
import {RootState} from '../store';

interface ValidationErrors {
    collaborator_from: [string]
    details: [string]
    title: [string]
    completion_date: [string]
    milestones: [string]
}

const initialState = {
    goals: [] as Goal[],
    status: "idle",
    error:  {} as ValidationErrors,
    count: 0,
    limit: 8,
    offset: 0
};

const goalService : GoalServices = new GoalServices();

export const createGoal = createAsyncThunk(
    'goals/create',
    async (data: Goal, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let goalData = {} as Goal
        try {
            const response = await goalService.createGoal(state.activeUser.activeUser.token, data)
            if (response.status == 201) {
                return GoalAdapter(response.data, "in") as Goal
            }
            return goalData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getGoalDetails = createAsyncThunk(
    'goals/getDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let goalData = {} as Goal
        try {
            const response = await goalService.getGoalDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                goalData = GoalAdapter(response.data, "in") as Goal
            }
            return goalData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const getAllGoals = createAsyncThunk(
    'goals/allGoals',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let goalData = [] as Goal[]
        try {
            const response = await goalService.getAllGoals(state.activeUser.activeUser.token)
            if (response.status == 200) {
                goalData = GoalAdapterList(response.data.results, "in")
            }
            return goalData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const getAllManagerGoals = createAsyncThunk(
    'goals/allManagerGoals',
    async (parameters: GoalParameters, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let goalData = {goals:[] as Goal[], count: 0, offset:0}
        try {

            const response = await goalService.getAllManagerGoals(state.activeUser.activeUser.token, parameters)
            if (response.status == 200) {
                goalData.goals = GoalAdapterList(response.data.results, "in")
                goalData.count = response.data.count
                if (parameters.offset){
                    goalData.offset = parameters.offset;
                }
            }
            return goalData;
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const updateGoal = createAsyncThunk(
    'goals/updateGoal',
    async (data: Goal, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let goalData = {} as Goal
        try {
            const response = await goalService.updateGoal(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                return GoalAdapter(response.data, "in") as Goal
            }
            return goalData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const getManagerGoalDetails = createAsyncThunk(
    'goals/getManagerDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let goalData = {} as Goal
        try {
            const response = await goalService.getManagerGoalDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                goalData = GoalAdapter(response.data, "in") as Goal
            }
            return goalData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const updateManagerGoal = createAsyncThunk(
    'goals/updateManagerGoal',
    async (data: Goal, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let goalData = {} as Goal
        try {
            const response = await goalService.updateManagerGoal(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                goalData = GoalAdapter(response.data, "in") as Goal
            }
            return goalData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const deleteGoal = createAsyncThunk(
    'goals/deleteGoal',
    async (data: { id:number }, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let goalId = data.id
        try {
            const response = await goalService.deleteGoal(state.activeUser.activeUser.token, data)
            if (response.status == 204 || response.status == 202) {
                return goalId
            }
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const deleteManagerGoal = createAsyncThunk(
    'goals/deleteManagerGoal',
    async (data: { id:number }, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let goalId = data.id
        try {
            const response = await goalService.deleteManagerGoal(state.activeUser.activeUser.token, data)
            if (response.status == 204 || response.status == 202) {
                return goalId
            }
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

const goalSlice = createSlice({
    name: "goals",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createGoal.fulfilled, (state, action) => {
            state.status = "success"
            state.goals.push(action.payload)
            state.error = {} as ValidationErrors
        })
        .addCase(createGoal.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(createGoal.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(getGoalDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getGoalDetails.fulfilled, (state, action) => {
            state.goals.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getGoalDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(getAllGoals.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllGoals.fulfilled, (state, action) => {
            state.goals = action.payload
            state.status = "success"
        })
        .addCase(getAllGoals.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(updateGoal.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(updateGoal.fulfilled, (state, action) => {
            const index = state.goals.findIndex((goal) => goal.id == action.payload.id)
            state.goals[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(updateGoal.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(updateManagerGoal.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(updateManagerGoal.fulfilled, (state, action) => {
            const index = state.goals.findIndex((goal) => goal.id == action.payload.id)
            state.goals[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(updateManagerGoal.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(getManagerGoalDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getManagerGoalDetails.fulfilled, (state, action) => {
            state.goals.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getManagerGoalDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(deleteGoal.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(deleteGoal.fulfilled, (state, action) => {
            state.goals.filter((goal) => goal.id != action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(deleteGoal.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(deleteManagerGoal.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(deleteManagerGoal.fulfilled, (state, action) => {
            state.goals.filter((goal) => goal.id != action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(deleteManagerGoal.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(getAllManagerGoals.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllManagerGoals.fulfilled, (state, action) => {
            state.goals = action.payload.goals
            state.count = action.payload.count
            state.offset = action.payload.offset
            state.status = "success"
        })
        .addCase(getAllManagerGoals.rejected, (state, action) => {
            state.status = "error"
        })
    },
});

export default goalSlice.reducer;