import * as React from "react";
import {Text} from "../../interfaces/campaignAnswer.interface"
import styles from '../campaignAnswer.module.css';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface TextProps {
    answer: Text
    managerAnswer: Text
    isManager: boolean
    changeHandler: Function
    status: "Draft" | "SharedByCollaborator" | "SharedByManager" | "SharedByBoth" | "ValidatedByManager" | "ValidatedByBoth",
    readonly?: boolean
}

const TextComponent: React.SFC<TextProps> = ({answer, readonly, changeHandler, managerAnswer, status, isManager}) => {
    const { t } = useTranslation();
    const [userAnswer, setUserAnswer] = useState<string>(answer.answer);
    const [managerAnswerLocal, setManagerAnswer] = useState<string>(managerAnswer.answer);

    useEffect(() => {
        if(!isManager){
            changeHandler(userAnswer)
        }
    }, [userAnswer])

    useEffect(() => {
        if(isManager){
            changeHandler(managerAnswerLocal)
        }
    }, [managerAnswerLocal])

    return (
        <div className={styles.titleInput}>
            <label htmlFor={answer.title}>{answer.title}</label>
            {answer.visibility === "Both" ?
                <>
                    {(status === "Draft" || (status === "SharedByCollaborator" && !isManager ) || (status === "SharedByManager" && isManager)) &&
                        <>
                            {isManager ?
                                <input
                                    name={answer.title}
                                    value={managerAnswerLocal}
                                    readOnly={readonly}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setManagerAnswer(event.target.value)
                                        }}>
                                </input>
                                :
                                <input
                                    name={answer.title}
                                    value={userAnswer}
                                    readOnly={readonly}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserAnswer(event.target.value)
                                        }}>
                                </input>
                            }
                        </>
                    }
                    {status === "SharedByCollaborator" && isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <input
                                    name={answer.title}
                                    value={userAnswer}
                                    readOnly={true}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserAnswer(event.target.value)
                                        }}>
                                </input>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <input
                                    name={answer.title}
                                    value={managerAnswerLocal}
                                    readOnly={readonly}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setManagerAnswer(event.target.value)
                                        }}>
                                </input>
                            </div>
                        </div>
                    }
                    {status === "SharedByManager" && !isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <input
                                    name={answer.title}
                                    value={userAnswer}
                                    readOnly={readonly}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserAnswer(event.target.value)
                                        }}>
                                </input>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <input
                                    name={answer.title}
                                    value={managerAnswerLocal}
                                    readOnly={true}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setManagerAnswer(event.target.value)
                                        }}>
                                </input>
                            </div>
                        </div>
                    }
                    {(status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <input
                                    name={answer.title}
                                    value={userAnswer}
                                    readOnly={readonly || isManager}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUserAnswer(event.target.value)
                                        }}>
                                </input>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <input
                                    name={answer.title}
                                    value={managerAnswerLocal}
                                    readOnly={readonly || !isManager}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setManagerAnswer(event.target.value)
                                        }}>
                                </input>
                            </div>
                        </div>
                    }
                </>
                :
                <>
                    {answer.visibility === "Manager" ?
                        <>
                            {((status === "SharedByManager" && !isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.manager")}</h5>
                            }
                            <input
                                name={answer.title}
                                value={managerAnswerLocal}
                                readOnly={readonly}
                                onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setManagerAnswer(event.target.value)
                                    }}>
                            </input>
                        </>
                        :
                        <>
                            {((status === "SharedByCollaborator" && isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.collaborator")}</h5>
                            }
                            <input
                                name={answer.title}
                                value={userAnswer}
                                readOnly={readonly}
                                onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserAnswer(event.target.value)
                                    }}>
                            </input>
                        </>
                    }
                </>
            }
        </div>
    );
};
export default TextComponent;