import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { Feedback } from "../../interfaces/feedback.interface";
import FeedbackServices from "../../services/feedback.service";
import {RootState} from "../store";
import { FeedbackAdapterList, FeedbackAdapter } from "../../adapters/feedback.adapter";

interface ValidationErrors {
    collaborator_to: [string]
    details: [string]
    title: [string]
    feedback_date: [string]
}

const initialState = {
    feedbacks: [] as Feedback[],
    status: "idle",
    error: {} as ValidationErrors
};

const feedbackService : FeedbackServices = new FeedbackServices();

export const createFeedback = createAsyncThunk(
    'feedbacks/create',
    async (data: Feedback, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let feedbackData = {} as Feedback
        try {
            const response = await feedbackService.createFeedback(state.activeUser.activeUser.token, data)
            if (response.status == 201) {
                return FeedbackAdapter(response.data, "in")
            }
            return feedbackData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getAllFeedbacks = createAsyncThunk(
    'feedbacks/allFeedbacks',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let feedbackData = {} as Feedback[]
        try {
            const response = await feedbackService.getAllFeedbacks(state.activeUser.activeUser.token)
            if (response.status == 200) {
                feedbackData = FeedbackAdapterList(response.data.results, "in")
            }
            return feedbackData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getFeedbackDetails = createAsyncThunk(
    'feedbacks/getDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let feedbackData = {} as Feedback
        try {
            const response = await feedbackService.getFeedbackDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                return FeedbackAdapter(response.data, "in") as Feedback
            }
            return feedbackData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const updateFeedback = createAsyncThunk(
    'feedbacks/update',
    async (data: Feedback, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let feedbackData = {} as Feedback
        try {
            const response = await feedbackService.updateFeedback(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                feedbackData = FeedbackAdapter(response.data, "in")
            }
            return feedbackData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const deleteFeedback = createAsyncThunk(
    'feedbacks/delete',
    async (data: {id:number}, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const feedbackId = data.id
        try {
            const response = await feedbackService.deleteFeedback(state.activeUser.activeUser.token, data)
            if (response.status == 204 || response.status == 202) {
                return feedbackId
            }
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

const feedbackSlice = createSlice({
    name: "feedbacks",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createFeedback.fulfilled, (state, action) => {
            state.feedbacks.push(action.payload)
            state.error = {} as ValidationErrors
            state.status = "success"
        })
        builder.addCase(createFeedback.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(createFeedback.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(deleteFeedback.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(deleteFeedback.fulfilled, (state, action) => {
            state.feedbacks.filter((feedback) => feedback.id != action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(deleteFeedback.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(getAllFeedbacks.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getAllFeedbacks.fulfilled, (state, action) => {
            state.feedbacks = action.payload
            state.status = "success"
        })
        builder.addCase(getAllFeedbacks.rejected, (state, action) => {
            state.status = "error"
        })
        builder.addCase(getFeedbackDetails.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(getFeedbackDetails.fulfilled, (state, action) => {
            state.feedbacks.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(getFeedbackDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        builder.addCase(updateFeedback.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(updateFeedback.fulfilled, (state, action) => {
            const index = state.feedbacks.findIndex((feedback) => feedback.id == action.payload.id)
            state.feedbacks[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        builder.addCase(updateFeedback.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
    }
});

export default feedbackSlice.reducer;