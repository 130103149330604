import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Button from '../components/button';
import Milestone from './milestone';
import Progress from '../components/progress';
import { Goal as GoalInterface, Milestone as MilestoneInterface } from '../interfaces/goal.interface';
import styles from './goaldetails.module.css';
import {AppDispatch, RootState} from "../store/store";
import {Navigate} from "react-router-dom";
import HeaderLogo from "../assets/goal-header-logo.svg";
import {
    updateManagerGoal,
    deleteManagerGoal,
} from "../store/reducers/goalsReducer";
import CustomAlerts from "./customAlerts";
import * as React from "react";
import { ToolTips } from "./toolTips";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import addLogo from "../assets/page-add.svg"
import addHoverLogo from "../assets/page-add-hover.svg"
import validateLogo from "../assets/page-validate.svg"
import validateHoverLogo from "../assets/page-validate-hover.svg"
import shareHoverLogo from "../assets/page-share.svg"
import shareLogo from "../assets/page-share-hover.svg"
import deleteLogo from "../assets/page-delete.svg"
import deleteHoverLogo from "../assets/page-delete-hover.svg"
import AvatarIcon from "./avatar";
import {getTeamMembers} from "../store/reducers/teamReducer";
import Collaborator from "../interfaces/collaborator.interface";
import CollaboratorSelectOption from "./collaboratorSelectOption";

interface GoalProps {
    goal: GoalInterface
}

const GoalDetailsManager: React.FunctionComponent<GoalProps> = ({goal}) => {
    const { t } = useTranslation();
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date(JSON.parse(goal.completionDate)));
    const [goalTitle, setGoalTitle] = useState<string>(goal.title);
    const [goalDetails, setGoalDetails] = useState<string>(goal.details);
    const [milestones, setMilestones] = useState<MilestoneInterface[]>(goal.milestones);
    const [redirect, setRedirect] = useState<string>("");
    const goalStatus = useSelector((state: RootState) => state.goals.status);
    const errors = useSelector((state: RootState) => state.goals.error);
    const dispatch = useDispatch<AppDispatch>();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);
    const [focusMilestone, setFocusMilestone] = useState<boolean>(false)

    useEffect(() => {
        if(focusMilestone){
            const lastMilestone = document.getElementById("milestones")!.lastElementChild as HTMLElement
            const input = lastMilestone.querySelector('input[type=\"text\"]') as HTMLInputElement
            input!.focus()
            setFocusMilestone(false)
        }
    }, [focusMilestone])

    useEffect(() =>{
        dispatch(getTeamMembers())
    }, [dispatch])
    const members = useSelector((state: RootState) => state.team.members)
    const member = members.find(Collaborator => Collaborator.id === goal.collaborator_from) as Collaborator

    if(redirect === "close"){
        window.history.back();
    }
    if(goalStatus === "success") {
        if(redirect) {
            return <Navigate to="/manager/goals"/>;
        }
    }

    // manager peut modifier le goal
    let readonly = false;
    // if (goal.status === "Draft" || goal.status === "Shared" || goal.status === "Active"){
    //     if(activeUser.id === goal.author){
    //         readonly = false;
    //     }
    // }

    let status = "Draft"
    if (goal.status) {
        status = "common." + goal.status.toLowerCase()
    }

    return (
        <>
            <section className={styles.modalForm}>
                <header>
                    <div className={styles.entryTitle}>
                        <img src={HeaderLogo}></img>
                        <h1>{t("common.goal")}</h1>
                    </div>
                    {member &&
                        <CollaboratorSelectOption collaborator={member} displayName={true}/>
                    }
                    <div className={styles.Status}>
                        <span className={goal.status}></span>
                        <p>{t(status)}</p>
                    </div>
                </header>
                <form className={styles.form}>
                    <div className={styles.titleInput}>
                        <label htmlFor="title">{t("goal.title")}</label>
                        <input
                            name="title"
                            placeholder="Your goal"
                            onChange={(e) => setGoalTitle(e.target.value)}
                            defaultValue={goalTitle}
                            readOnly={readonly}
                            type="text">
                        </input>
                    </div>
                    {goalStatus === "error" && errors.title &&
                        <CustomAlerts errors={errors.title} />
                    }
                    <label htmlFor="content" className={styles.tooltipHandler}>{t("goal.content")}
                        <ToolTips text={t("goal.helpContent")} parent={document.getElementById("SMART")} />
                    </label>
                    <p className={styles.SMART}
                        id="SMART">{t("goal.helpTitle")}</p>
                    <textarea
                        name="content"
                        onChange={(e) => setGoalDetails(e.target.value)}
                        defaultValue={goalDetails}
                        readOnly={readonly}>
                    </textarea>
                    {goalStatus === "error" && errors.details &&
                        <CustomAlerts errors={errors.details}/>
                    }
                    <div className={styles.Milestones}>
                        <label>{t("goal.milestones")}</label>
                        <Progress progression={goal.completion}/>
                        <ul id={"milestones"}>
                            { milestones.map(
                                (milestone: MilestoneInterface, index) =>
                                    <Milestone
                                        key={milestone.id}
                                        milestone={milestone}
                                        readonly={readonly}
                                        isManager={activeUser.id != goal.collaborator_from}
                                        changeHandler={
                                            (newMilestone: MilestoneInterface) => {
                                                let newMilestones = [...milestones]
                                                newMilestones[index] = newMilestone;
                                                setMilestones(newMilestones)
                                            }}
                                        deleteHandler={
                                            () => {
                                                let newMilestones = [...milestones]
                                                newMilestones.splice(index, 1);
                                                setMilestones(newMilestones)
                                        }}
                                        enterKey={() => {
                                            setMilestones(milestones => [...milestones, {id: "Tmp"+ milestones.length, title:"", completed:false}])
                                            setFocusMilestone(true)
                                        }}
                                        saveHandler={() => {return null}}
                                        goalId={goal.id}
                                    />
                            )}
                            {goalStatus === "error" && errors.milestones &&
                                <CustomAlerts errors={[t("goal.blankError")]}/>
                            }
                        </ul>
                        {!readonly &&
                            <Button classCss={"secondary-button"} content={t("buttons.addItem")} submit={() => {
                                setMilestones(milestones => [...milestones, {id: "Tmp"+ milestones.length, title:"", completed:false}])
                                setFocusMilestone(true)
                            }}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                        }
                    </div>
                    <label>{t("goal.date")}</label>
                    <div className={styles.DateInput}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                openTo="month"
                                readOnly={readonly}
                                views={['year', 'month', 'day']}
                                value={completionDate}
                                onChange={(newDate) => {
                                    setCompletionDate(newDate);
                                }}
                                renderInput={(params) => {
                                    let newParams = {...params}
                                    if(newParams.inputProps){
                                        newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                    }
                                    return(<TextField {...newParams}/>)}}
                            />
                        </LocalizationProvider>
                    </div>
                    {goalStatus === "error" && errors.completion_date &&
                        <CustomAlerts errors={errors.completion_date}/>
                    }
                </form>
                <div className={styles.buttonContainer}>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.close")}
                            submit={
                                () => {
                                    setRedirect("close")
                                }
                            }
                            icon={closeLogo}
                            iconHover={closeHoverLogo}/>
                    {goal.status == "Draft" && !readonly &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.delete")}
                            submit={
                                () => {
                                    dispatch(
                                        deleteManagerGoal({id: goal.id!})
                                    )
                                    setRedirect("return")
                                }
                            }
                            icon={deleteLogo}
                            iconHover={deleteHoverLogo}/>
                    }
                    {(goal.status == "Active" || goal.status == "Achieved") &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.modify")}
                            submit={
                                () => {
                                    dispatch(
                                        updateManagerGoal({
                                            id: goal.id,
                                            title: goal.title,
                                            collaborator_from: goal.collaborator_from,
                                            status: goal.status,
                                            details: goal.details,
                                            completionDate: goal.completionDate,
                                            milestones: milestones,
                                            completion: goal.completion
                                        })
                                    )
                                    setRedirect("return")
                                }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                    }
                    {(goal.status == "Draft" || goal.status == "Shared") &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.modify")}
                            submit={
                                () => {
                                    dispatch(
                                        updateManagerGoal({
                                            id: goal.id,
                                            title: goalTitle,
                                            collaborator_from: goal.collaborator_from,
                                            status: goal.status,
                                            details: goalDetails,
                                            completionDate: JSON.stringify(completionDate),
                                            milestones: milestones,
                                            completion: goal.completion
                                        })
                                    )
                                    setRedirect("return")
                                }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                    }
                    {goal.status == "Draft" &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.share")}
                            submit={
                                () => {
                                    dispatch(
                                        updateManagerGoal({
                                            id: goal.id,
                                            title: goalTitle,
                                            collaborator_from: goal.collaborator_from,
                                            status: "Shared",
                                            details: goalDetails,
                                            completionDate: JSON.stringify(completionDate),
                                            milestones: milestones,
                                            completion: goal.completion
                                        })
                                    )
                                    setRedirect("allGoals");
                                }
                            }
                            icon={shareLogo}
                            iconHover={shareHoverLogo}/>
                    }
                    {goal.status == "Shared" && goal.collaborator_from != activeUser.id &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.validate")}
                            submit={
                                () => {
                                    dispatch(
                                        updateManagerGoal({
                                            id: goal.id,
                                            title: goal.title,
                                            collaborator_from: goal.collaborator_from,
                                            status: "Active",
                                            details: goal.details,
                                            completionDate: goal.completionDate,
                                            milestones: goal.milestones,
                                            completion: goal.completion
                                        })
                                    )
                                    setRedirect("allGoals")
                                }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                    }
                </div>
            </section>
        </>
    );
}

export default GoalDetailsManager;