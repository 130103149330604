import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {PerformanceReview, PerformanceReviewParameters} from "../../interfaces/performanceReview.interface";
import PerformanceReviewServices from "../../services/performanceReview.service";
import {PerformanceReviewAdapterList, PerformanceReviewAdapter} from "../../adapters/performanceReviews.adapter";

interface ValidationErrors {}

const initialState = {
    performanceReviews: [] as PerformanceReview[],
    status: "idle",
    error: {} as ValidationErrors,
    count: 0,
    limit: 8,
    offset: 0
};

const performanceReviewService : PerformanceReviewServices = new PerformanceReviewServices();
    
export const getAllPerformanceReviews = createAsyncThunk(
    'performanceReviews/allPerformanceReviews',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let performanceReviewsData = [] as PerformanceReview[]
        try {
            const response = await performanceReviewService.getAllPerformanceReviews(state.activeUser.activeUser.token)
            if (response.status == 200) {
                performanceReviewsData = PerformanceReviewAdapterList(response.data.results, "in")
            }
            return performanceReviewsData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getPerformanceReviewDetails = createAsyncThunk(
    'performanceReviews/getDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let performanceReviewData = {} as PerformanceReview
        try {
            const response = await performanceReviewService.getPerformanceReviewDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                performanceReviewData = PerformanceReviewAdapter(response.data, "in") as PerformanceReview
            }
            return performanceReviewData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getManagerPerformanceReviewDetails = createAsyncThunk(
    'performanceReviews/getManagerDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let performanceReviewData = {} as PerformanceReview
        try {
            const response = await performanceReviewService.getManagerPerformanceReviewDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                performanceReviewData = PerformanceReviewAdapter(response.data, "in") as PerformanceReview
            }
            return performanceReviewData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const getAllManagerPerformanceReviews = createAsyncThunk(
    'performanceReviews/allManagerReviews',
    async (parameters: PerformanceReviewParameters, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let performanceReviewData = {performanceReviews:[] as PerformanceReview[], count: 0, offset:0}
        try {

            const response = await performanceReviewService.getAllManagerPerformanceReviews(state.activeUser.activeUser.token, parameters)
            if (response.status == 200) {
                performanceReviewData.performanceReviews = PerformanceReviewAdapterList(response.data.results, "in")
                performanceReviewData.count = response.data.count
                if (parameters.offset){
                    performanceReviewData.offset = parameters.offset;
                }
            }
            return performanceReviewData;
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const updateManagerPerformanceReview = createAsyncThunk(
    'performanceReviews/updateManager',
    async (data: PerformanceReview, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let performanceReviewData = {} as PerformanceReview
        try {
            const response = await performanceReviewService.updateManagerPerformanceReview(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                performanceReviewData = PerformanceReviewAdapter(response.data, "in") as PerformanceReview
            }
            return performanceReviewData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as ValidationErrors);
        }
    })

export const getAllHRPerformanceReviews = createAsyncThunk(
    'performanceReviews/allHRReviews',
    async (parameters: PerformanceReviewParameters, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let performanceReviewData = {performanceReviews:[] as PerformanceReview[], count: 0, offset:0}
        try {

            const response = await performanceReviewService.getAllHRPerformanceReviews(state.activeUser.activeUser.token, parameters)
            if (response.status == 200) {
                performanceReviewData.performanceReviews = PerformanceReviewAdapterList(response.data.results, "in")
                performanceReviewData.count = response.data.count
                if (parameters.offset){
                    performanceReviewData.offset = parameters.offset;
                }
            }
            return performanceReviewData;
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const getHRPerformanceReviewDetails = createAsyncThunk(
    'performanceReviews/getHRDetails',
    async (data: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let performanceReviewData = {} as PerformanceReview
        try {
            const response = await performanceReviewService.getHRPerformanceReviewDetails(state.activeUser.activeUser.token, data)
            if (response.status == 200) {
                performanceReviewData = PerformanceReviewAdapter(response.data, "in") as PerformanceReview
            }
            return performanceReviewData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

const performanceReviewSlice = createSlice({
    name: "performanceReviews",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllPerformanceReviews.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllPerformanceReviews.fulfilled, (state, action) => {
            state.performanceReviews = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getAllPerformanceReviews.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(getPerformanceReviewDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getPerformanceReviewDetails.fulfilled, (state, action) => {
            state.performanceReviews.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getPerformanceReviewDetails.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(getManagerPerformanceReviewDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getManagerPerformanceReviewDetails.fulfilled, (state, action) => {
            state.performanceReviews.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getManagerPerformanceReviewDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(getAllManagerPerformanceReviews.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllManagerPerformanceReviews.fulfilled, (state, action) => {
            state.performanceReviews = action.payload.performanceReviews
            state.count = action.payload.count
            state.offset = action.payload.offset
            state.status = "success"
        })
        .addCase(getAllManagerPerformanceReviews.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(updateManagerPerformanceReview.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(updateManagerPerformanceReview.fulfilled, (state, action) => {
            const index = state.performanceReviews.findIndex((performanceReview) => performanceReview.id == action.payload.id)
            state.performanceReviews[index] = action.payload
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(updateManagerPerformanceReview.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
        .addCase(getAllHRPerformanceReviews.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getAllHRPerformanceReviews.fulfilled, (state, action) => {
            state.performanceReviews = action.payload.performanceReviews
            state.count = action.payload.count
            state.offset = action.payload.offset
            state.status = "success"
        })
        .addCase(getAllHRPerformanceReviews.rejected, (state, action) => {
            state.status = "error"
        })
        .addCase(getHRPerformanceReviewDetails.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getHRPerformanceReviewDetails.fulfilled, (state, action) => {
            state.performanceReviews.push(action.payload)
            state.status = "success"
            state.error = {} as ValidationErrors
        })
        .addCase(getHRPerformanceReviewDetails.rejected, (state, action) => {
            state.status = "error"
            state.error = action.payload! as ValidationErrors
        })
    }
});

export default performanceReviewSlice.reducer;