import { useTranslation } from "react-i18next";
import styles from "./hrCampaignList.module.css";
import dots from "../assets/dots.svg";
import * as React from "react";
import HeaderLogo from "../assets/campaign-header-logo.svg";
import {Campaign, CampaignStats, Organization} from "../interfaces/campaign.interface";
import HRCampaignDetailCard from "./HRCampaignDetailCard";
import Button from "./button";
import downloadLogo from "../assets/download.svg";
import downloadHoverLogo from "../assets/download-selected.svg";
import CampaignServices from "../services/campaign.service";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";

interface CampaignProps {
    campaign: Campaign
}

export const HRCampaignDetails: React.SFC<CampaignProps> = ({ campaign }) => {
    const { t } = useTranslation();
    const campaignService : CampaignServices = new CampaignServices();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)

    let status = "Draft"
    if (campaign.status) {
        status = "common." + campaign.status.toLowerCase()
    }

    const getPDFData = async () => {
        const response = await campaignService.downloadCampaignAnswers(activeUser.token, campaign.id!.toString())
        console.log(response)
        // let type = "application/pdf"
        // if(response.headers["content-type"] !== "application/pdf"){
        //     type = "application/zip"
        // }
        let filename = campaign.name + ".zip"
        const url: Blob = new Blob([response.data], {type: response.headers["content-type"]});
        const blobUrl = window.URL.createObjectURL(url);
        const templink: any = document.createElement("a");
        templink.setAttribute("download", filename);
        templink.href = blobUrl;
        document.body.appendChild(templink);
        templink.click();
        templink.parentNode.removeChild(templink);
    }

    return (
        <div className={styles.List}>
            <div className={styles.pageTitle}>
                <div className={styles.pageTitle}>
                    <img src={HeaderLogo} className={styles.conversationIcon}/>
                    <h1>
                        {campaign.name}
                    </h1>
                </div>
                <div>
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.download")}
                        submit={() => {getPDFData()}}
                        icon={downloadLogo}
                        iconHover={downloadHoverLogo}/>
                </div>
                <div className={styles.Status}>
                    <span className={campaign.status}></span>
                    <p>{t(status)}</p>
                </div>
            </div>
            <div className={styles.Header}>
                <div className={styles.headerDetailTitle}>{t("common.organization")}<img src={dots} /></div>
                <div className={styles.headerDetailStat}>{t("common.draft")}<img src={dots} /></div>
                <div className={styles.headerDetailStat}>{t("common.sharedbycollaborator")}<img src={dots} /></div>
                <div className={styles.headerDetailStat}>{t("common.sharedbymanager")}<img src={dots} /></div>
                <div className={styles.headerDetailStat}>{t("common.sharedbyboth")}<img src={dots} /></div>
                <div className={styles.headerDetailStat}>{t("common.validatedbymanager")}<img src={dots} /></div>
                <div className={styles.headerDetailStat}>{t("common.validatedbyboth")}<img src={dots} /></div>
            </div>
            <div className={styles.Sharing}>
                {campaign.stats.map(
                    (stats: CampaignStats, index) =>
                        <HRCampaignDetailCard
                            key={index}
                            stats={stats}
                            organizations={campaign.organizations}
                        />
                )}
            </div>
        </div>
    );
}