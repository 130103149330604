import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DashboardHRServices from "../../services/dashboardHR.service";
import ManagerStats from "../../interfaces/manager.interface";
import {RootState} from "../store";
import {ManagerStatsAdapterList} from "../../adapters/managersStats.adapter";

const initialState = {
    status: 'idle',
    error: {} as any,
    dashboardHRData : {
        engagement: {
            less_committed: 0,
            committed: 0,
            very_committed: 0,
        },
        goalCompletion: 0,
        entriesCount: {
            goals:0,
            requests:0,
            feedbacks:0,
            experiences:0,
            conversations:0
        },
        performance_review_stats: {
            above: 0,
            average: 0,
            below: 0
        },
        performance_review_status: {
            draft: 0,
            shared: 0,
        }
    },
    managersStats: [] as ManagerStats[],
}
const dashboardHRService : DashboardHRServices = new DashboardHRServices();

export const getHRDashboardData = createAsyncThunk(
    'dashboardHR/hrdata',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let dashboardHRData = initialState.dashboardHRData
        try {
            const response = await dashboardHRService.getHRDashboardData(state.activeUser.activeUser.token)
            if (response.status == 200) {
                dashboardHRData = {
                    engagement: response.data.engagement,
                    goalCompletion: response.data.goal_completion,
                    entriesCount: response.data.entries_count,
                    performance_review_stats: response.data.performance_review_stats,
                    performance_review_status: response.data.performance_review_status
                }
            }
            return dashboardHRData
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

export const getManagersStats = createAsyncThunk(
    'dashboardHR/managersStats',
    async (data: undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let managersStats = {} as any
        try {
            const response = await dashboardHRService.getManagersStats(state.activeUser.activeUser.token)
            if (response.status == 200) {
                managersStats = ManagerStatsAdapterList(response.data.results, "in")
            }
            return managersStats
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    }
)


const dashboardHRSlice = createSlice({
    name: "dashboardHR",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getHRDashboardData.fulfilled, (state, action) => {
            state.dashboardHRData = action.payload
            state.status = "success"
        })
        .addCase(getHRDashboardData.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getHRDashboardData.rejected, (state, action) => {
            state.error = action.payload!
            state.status = "error"
        })
        .addCase(getManagersStats.fulfilled, (state, action) => {
            state.managersStats = action.payload
            state.status = "success"
        })
        .addCase(getManagersStats.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getManagersStats.rejected, (state, action) => {
            state.error = action.payload!
            state.status = "error"
        })
    },
});

export default dashboardHRSlice.reducer;