import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Button from '../components/button';
import {Experience as ExperienceInterface} from '../interfaces/experience.interface';
import styles from './goaldetails.module.css';
import {AppDispatch, RootState} from "../store/store";
import { getAllGoals} from "../store/reducers/goalsReducer";
import {
    updateExperience,
    updateGoalExperience,
    updateManagerExperience,
    deleteExperience
} from "../store/reducers/experiencesReducer";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {Navigate} from "react-router-dom";
import CustomAlerts from "./customAlerts";
import * as React from "react";
import HeaderLogo from "../assets/experience-header-logo.svg";
import { ToolTips } from "./toolTips";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import validateLogo from "../assets/page-validate.svg"
import validateHoverLogo from "../assets/page-validate-hover.svg"
import shareHoverLogo from "../assets/page-share.svg"
import shareLogo from "../assets/page-share-hover.svg"
import deleteLogo from "../assets/page-delete.svg"
import deleteHoverLogo from "../assets/page-delete-hover.svg"
import PopUpSharedExperience from "./PopUpSharedExperience";
import AvatarIcon from "./avatar";
import {getTeamMembers} from "../store/reducers/teamReducer";
import Collaborator from "../interfaces/collaborator.interface";
import CollaboratorSelectOption from "./collaboratorSelectOption";


interface ExperienceProps {
    experience: ExperienceInterface
}

const ExperienceDetails: React.SFC<ExperienceProps> = ({experience}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date(JSON.parse(experience.completionDate)));
    const [title, setTitle] = useState<string>(experience.title);
    const [experienceDetails, setExperienceDetails] = useState<string>(experience.details);
    const [reflection, setReflection] = useState<string>(experience.reflection);
    const [redirect, setRedirect] = useState<string>("");
    const goals = useSelector((state: RootState) => state.goals.goals);
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);
    const experienceStatus = useSelector((state: RootState) => state.experiences.status);
    const errors = useSelector((state: RootState) => state.experiences.error);
    const [openPopUp, setOpenPopUp] = useState(false);

    useEffect(() => {
        dispatch(getAllGoals())
        if(experience.author === activeUser.id){
            dispatch(getTeamMembers())
        }
    }, [dispatch])

    let readonly = true;
    if (experience.status === "Draft" || experience.status === "Shared"){
        if(activeUser.id === experience.author){
            readonly = false;
        }
    }

    const members = useSelector((state: RootState) => state.team.members)
    const member = members.find(Collaborator => Collaborator.id === experience.author) as Collaborator


    if(experienceStatus === "success") {
        if(redirect === "allExp") {
            return <Navigate to="/experiences"/>;
        } else if(redirect === "return"){
            window.history.back();
        } else if(redirect === "newGoal"){
            return <Navigate to="/goals/new"/>;
        } else if(redirect === "newExp"){
            window.location.reload();
        }
    }
    if(redirect === "close"){
        window.history.back();
    }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if (direction) {
            setRedirect(direction);
        } else {
            setRedirect("return");
        }
    }

    let goal = ""
    if(experience.goal){
        goal = experience.goal.toString()
    }
    
    let status = "Draft"
    if (experience.status) {
        status = "common." + experience.status.toLowerCase()
        if(experience.status === "Shared" && experience.author != activeUser.id){
            dispatch(
                updateManagerExperience({
                    id: experience.id,
                    title: experience.title,
                    status: "Reviewed",
                    goal: experience.goal,
                    details: experience.details,
                    completionDate: experience.completionDate,
                    reflection: experience.reflection,
                    author: experience.author
                })
            )
        }
    }

    return (
        <section className={styles.modalForm}>
            <header>
                <div className={styles.entryTitle}>
                    <img src={HeaderLogo}></img>
                    <h1>{t("common.experience")}</h1>
                </div>
                {member && activeUser.id !== experience.author &&
                    <CollaboratorSelectOption collaborator={member} displayName={true}/>
                }
                <div className={styles.Status}>
                    <span className={experience.status}></span>
                    <p>{t(status)}</p>
                </div>
            </header>
            <form className={styles.form}>
                <div className={styles.titleInput}>
                    <label htmlFor="title">{t("experience.title")}</label>
                    <input
                        name="title"
                        readOnly={readonly}
                        placeholder="Your experience"
                        onChange={(e) => setTitle(e.target.value)}
                        defaultValue={experience.title}
                        type="text">
                    </input>
                </div>
                {experienceStatus === "error" && errors.title &&
                    <CustomAlerts errors={errors.title}/>
                }
                <label htmlFor="goal-label">{t("experience.goal")} <span className={"optional"}>{t("common.optional")}</span></label>
                <div className={styles.Input}>
                    <Select
                        labelId="goal-label"
                        id="goal-select"
                        readOnly={readonly}
                        value={goal}
                        label="Goal"
                        onChange={(event: SelectChangeEvent) => {
                            dispatch(
                                updateGoalExperience({id: experience.id!, goal: parseInt(event.target.value)})
                            )
                        }}
                    >
                        {
                            goals.map(
                                (goal) => {
                                    return <MenuItem key={goal.id} value={goal.id} disabled={goal.id === experience.goal}>{goal.title}</MenuItem>
                                }
                            )
                        }
                    </Select>
                </div>
                {experienceStatus === "error" && errors.goal &&
                    <CustomAlerts errors={errors.goal}/>
                }
                <label className={styles.tooltipHandler}>{t("experience.actions")}
                <ToolTips text={t("experience.helpContent")} parent={document.getElementById("STAR")} /></label>
                    <p className={styles.STAR} 
                    id="STAR">{t("experience.helpTitle")}</p>
                <textarea
                    name="content"
                    readOnly={readonly}
                    onChange={(e) => setExperienceDetails(e.target.value)}
                    defaultValue={experienceDetails}>
                </textarea>
                <label htmlFor="reflection">{t("experience.reflection")}</label>
                <textarea
                    name="reflection"
                    readOnly={readonly}
                    onChange={(e) => setReflection(e.target.value)}
                    defaultValue={experience.reflection}>
                </textarea>
                <label>{t("experience.date")} <span className={"optional"}>{t("common.optional")}</span></label>
                <div className={styles.DateInput}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            disableFuture
                            openTo="day"
                            readOnly={readonly}
                            views={['year', 'month', 'day']}
                            value={completionDate}
                            onChange={(newDate) => {
                                setCompletionDate(newDate);
                            }}
                            renderInput={(params) => {
                                let newParams = {...params}
                                if(newParams.inputProps){
                                    newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                }
                                return(<TextField {...newParams}/>)}}
                        />
                    </LocalizationProvider>
                </div>
            </form>
            <div className={styles.buttonContainer}>
                <Button
                    classCss={"secondary-button"}
                    content={t("buttons.close")}
                    submit={
                        () => {
                            setRedirect("close")
                        }
                    }
                    icon={closeLogo}
                    iconHover={closeHoverLogo}/>
                {experience.status == "Draft" && experience.author === activeUser.id &&
                    <Button
                        classCss={"secondary-button"}
                        content={t("buttons.delete")}
                        submit={
                            () => {
                                dispatch(
                                    deleteExperience({id: experience.id!})
                                )
                                setRedirect("return")
                            }
                        }
                        icon={deleteLogo}
                        iconHover={deleteHoverLogo}
                        />
                }
                {experience.status == "Draft" && experience.author == activeUser.id &&
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.save")}
                        submit={
                            () => {
                            dispatch(
                                updateExperience({
                                id: experience.id,
                                title: title,
                                status: "Draft",
                                goal: experience.goal,
                                details: experienceDetails,
                                completionDate: JSON.stringify(completionDate),
                                reflection
                                })
                            )
                            setRedirect("return")
                        }
                    }
                    icon={validateLogo}
                    iconHover={validateHoverLogo} />
                }
                {experience.status == "Shared" && experience.author == activeUser.id &&
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.save")}
                        submit={
                            () => {
                            dispatch(
                                updateExperience({
                                id: experience.id,
                                title: title,
                                status: "Shared",
                                goal: experience.goal,
                                details: experienceDetails,
                                completionDate: JSON.stringify(completionDate),
                                reflection
                                })
                            )
                            setRedirect("return")
                        }
                    }
                    icon={validateLogo}
                    iconHover={validateHoverLogo} />
                }
                {experience.status == "Draft" &&
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.share")}
                        submit={
                            () => {
                                dispatch(
                                    updateExperience({
                                        id: experience.id,
                                        title: title,
                                        status: "Shared",
                                        goal: experience.goal,
                                        details: experienceDetails,
                                        completionDate: JSON.stringify(completionDate),
                                        reflection
                                    })
                                )
                                setOpenPopUp(true);
                            }
                        }
                        icon={shareLogo}
                        iconHover={shareHoverLogo}/>
                }
                {experience.status == "Reviewed" && experience.author != activeUser.id && !readonly &&
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.modify")}
                        submit={
                            () => {
                                dispatch(
                                    updateManagerExperience({
                                        id: experience.id,
                                        title: experience.title,
                                        status: experience.status,
                                        goal: experience.goal,
                                        details: experience.details,
                                        completionDate: experience.completionDate,
                                        reflection: experience.reflection,
                                        author: experience.author
                                    })
                                )
                                setRedirect("return")
                            }
                        }
                        icon={validateLogo}
                        iconHover={validateHoverLogo} />
                }
                {experienceStatus !== "error" &&
                    <PopUpSharedExperience
                        visible={openPopUp}
                        closeHandler={HandleClosePopUp} />
                }
            </div>
        </section>
    );
}

export default ExperienceDetails;