import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Button from '../components/button';
import Milestone from './milestone';
import Progress from '../components/progress';
import { Goal as GoalInterface, Milestone as MilestoneInterface } from '../interfaces/goal.interface';
import styles from './goaldetails.module.css';
import {AppDispatch, RootState} from "../store/store";
import {Navigate} from "react-router-dom";
import HeaderLogo from "../assets/goal-header-logo.svg";
import {
    updateGoal,
    deleteGoal,
} from "../store/reducers/goalsReducer";
import CustomAlerts from "./customAlerts";
import * as React from "react";
import { ToolTips } from "./toolTips";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import addLogo from "../assets/page-add.svg"
import addHoverLogo from "../assets/page-add-hover.svg"
import validateLogo from "../assets/page-validate.svg"
import validateHoverLogo from "../assets/page-validate-hover.svg"
import shareHoverLogo from "../assets/page-share.svg"
import shareLogo from "../assets/page-share-hover.svg"
import deleteLogo from "../assets/page-delete.svg"
import deleteHoverLogo from "../assets/page-delete-hover.svg"
import PopUpSharedGoal from "./PopUpSharedGoal";

interface GoalProps {
    goal: GoalInterface
}

const GoalDetails: React.FunctionComponent<GoalProps> = ({goal}) => {
    const { t } = useTranslation();
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date(JSON.parse(goal.completionDate)));
    const [goalTitle, setGoalTitle] = useState<string>(goal.title);
    const [goalDetails, setGoalDetails] = useState<string>(goal.details);
    const [milestones, setMilestones] = useState<MilestoneInterface[]>(goal.milestones);
    const [redirect, setRedirect] = useState<string>("");
    const goalStatus = useSelector((state: RootState) => state.goals.status);
    const errors = useSelector((state: RootState) => state.goals.error);
    const dispatch = useDispatch<AppDispatch>();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);
    const [openPopUp, setOpenPopUp] = useState(false);
    const [experienceLink, setExperienceLink] = useState<string>("/experiences/new");
    const [focusMilestone, setFocusMilestone] = useState<boolean>(false)

    useEffect(() => {
        if(focusMilestone){
            const lastMilestone = document.getElementById("milestones")!.lastElementChild as HTMLElement
            const input = lastMilestone.querySelector('input[type=\"text\"]') as HTMLInputElement
            input!.focus()
            setFocusMilestone(false)
        }
    }, [focusMilestone])

    if(redirect === "close"){
        window.history.back();
    }
    if(goalStatus === "success") {
        if(redirect === "allGoals") {
            return <Navigate to="/goals"/>;
        } else if(redirect === "newGoal") {
            return <Navigate to="/goals/new"/>;
        } else if(redirect === "newExp") {
            return <Navigate to="/experiences/new"/>;
        } else if(redirect === "newExpMilestone"){
            return <Navigate to={experienceLink}/>;
        }
    }

    let readonly = false;
    // if(activeUser.id === goal.collaborator_from){
    //     readonly = false;
    // }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if(direction){
            setRedirect(direction);
        }
    }
    let status = "Draft"
    if (goal.status) {
        status = "common." + goal.status.toLowerCase()
    }

    const saveHandler: Function = (experienceLink? : string) => {
        dispatch(
            updateGoal({
                id: goal.id,
                title: goal.title,
                status: goal.status,
                details: goal.details,
                completionDate: JSON.stringify(completionDate),
                collaborator_from: goal.collaborator_from,
                milestones: milestones,
                completion: goal.completion
            })
        )
        if(experienceLink){
            setExperienceLink(experienceLink);
            setRedirect("newExpMilestone");
        }
    }

    return (
        <>
            <section className={styles.modalForm}>
                <header>
                    <div className={styles.entryTitle}>
                        <img src={HeaderLogo}></img>
                        <h1>{t("common.goal")}</h1>
                    </div>
                    <div className={styles.Status}>
                        <span className={goal.status}></span>
                        <p>{t(status)}</p>
                    </div>
                </header>
                <form className={styles.form}>
                    <div className={styles.titleInput}>
                        <label htmlFor="title">{t("goal.title")}</label>
                        <input
                            name="title"
                            placeholder="Your goal"
                            onChange={(e) => setGoalTitle(e.target.value)}
                            defaultValue={goalTitle}
                            readOnly={readonly}
                            type="text">
                        </input>
                    </div>
                    {goalStatus === "error" && errors.title &&
                        <CustomAlerts errors={errors.title} />
                    }
                    <label htmlFor="content" className={styles.tooltipHandler}>{t("goal.content")}
                        <ToolTips text={t("goal.helpContent")} parent={document.getElementById("SMART")} /></label>
                    <p className={styles.SMART}
                        id="SMART">{t("goal.helpTitle")}</p>
                    <textarea
                        name="content"
                        onChange={(e) => setGoalDetails(e.target.value)}
                        defaultValue={goalDetails}
                        readOnly={readonly}>
                    </textarea>
                    {goalStatus === "error" && errors.details &&
                        <CustomAlerts errors={errors.details}/>
                    }
                    <div className={styles.Milestones}>
                        <label>{t("goal.milestones")}</label>
                        <Progress progression={goal.completion}/>
                        <ul id={"milestones"}>
                            { milestones.map(
                                (milestone: MilestoneInterface, index) =>
                                    <Milestone
                                        key={milestone.id}
                                        milestone={milestone}
                                        readonly={readonly}
                                        isManager={activeUser.id != goal.collaborator_from}
                                        changeHandler={
                                            (newMilestone: MilestoneInterface) => {
                                                let newMilestones = [...milestones]
                                                newMilestones[index] = newMilestone;
                                                setMilestones(newMilestones)
                                            }}
                                        deleteHandler={
                                            () => {
                                                let newMilestones = [...milestones]
                                                newMilestones.splice(index, 1);
                                                setMilestones(newMilestones)
                                        }}
                                        enterKey={() => {
                                            setMilestones(milestones => [...milestones, {id: "Tmp"+ milestones.length, title:"", completed:false}])
                                            setFocusMilestone(true)
                                        }}
                                        saveHandler={saveHandler}
                                        goalId={goal.id}
                                    />
                            )}
                            {goalStatus === "error" && errors.milestones &&
                                <CustomAlerts errors={[t("goal.blankError")]}/>
                            }
                        </ul>
                        {!readonly &&
                            <Button classCss={"secondary-button"} content={t("buttons.addItem")} submit={() => {
                                setMilestones(milestones => [...milestones, {id: "Tmp"+ milestones.length, title:"", completed:false}])
                                setFocusMilestone(true)
                            }}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                        }
                    </div>
                    <label>{t("goal.date")}</label>
                    <div className={styles.DateInput}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                openTo="month"
                                readOnly={readonly}
                                views={['year', 'month', 'day']}
                                value={completionDate}
                                onChange={(newDate) => {
                                    setCompletionDate(newDate);
                                }}
                                renderInput={(params) => {
                                    let newParams = {...params}
                                    if(newParams.inputProps){
                                        newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                    }
                                    return(<TextField {...newParams}/>)}}
                            />
                        </LocalizationProvider>
                    </div>
                    {goalStatus === "error" && errors.completion_date &&
                        <CustomAlerts errors={errors.completion_date}/>
                    }
                </form>
                <div className={styles.buttonContainer}>
                    <Button
                        classCss={"secondary-button"}
                        content={t("buttons.close")}
                        submit={
                            () => {
                                setRedirect("close")
                            }
                        }
                        icon={closeLogo}
                        iconHover={closeHoverLogo}/>
                    {goal.status === "Draft" &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.delete")}
                            submit={
                                () => {
                                    dispatch(
                                        deleteGoal({id: goal.id!})
                                    )
                                    setRedirect("allGoals")
                                }
                            }
                            icon={deleteLogo}
                            iconHover={deleteHoverLogo}/>
                    }
                    {goal.status !== "Active" && goal.status !== "Achieved" && goal.author === activeUser.id &&
                        <Button
                        classCss={"primary-button"}
                        content={t("buttons.save")}
                        submit={
                                () => {
                                    dispatch(
                                        updateGoal({
                                            id: goal.id,
                                            title: goalTitle,
                                            collaborator_from: goal.collaborator_from,
                                            status: goal.status,
                                            details: goalDetails,
                                            completionDate: JSON.stringify(completionDate),
                                            milestones: milestones,
                                            completion: goal.completion
                                        })
                                    )
                                    setRedirect("allGoals")
                                }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo} />

                    }
                    {(goal.status === "Active" || goal.status === "Achieved") && !readonly &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.modify")}
                            submit={
                                () => {
                                    dispatch(
                                        updateGoal({
                                            id: goal.id,
                                            title: goal.title,
                                            collaborator_from: goal.collaborator_from,
                                            status: goal.status,
                                            details: goal.details,
                                            completionDate: goal.completionDate,
                                            milestones: milestones,
                                            completion: goal.completion
                                        })
                                    )
                                    setRedirect("allGoals")
                                }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                    }
                    {goal.status === "Draft" &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.share")}
                            submit={
                                () => {
                                    dispatch(
                                        updateGoal({
                                            id: goal.id,
                                            title: goalTitle,
                                            collaborator_from: goal.collaborator_from,
                                            status: "Shared",
                                            details: goalDetails,
                                            completionDate: JSON.stringify(completionDate),
                                            milestones: milestones,
                                            completion: goal.completion
                                        })
                                    )
                                    setOpenPopUp(true);
                                }
                            }
                            icon={shareLogo}
                            iconHover={shareHoverLogo}/>
                    }
                </div>
                {goalStatus !== "error" &&
                    <PopUpSharedGoal
                        visible={openPopUp}
                        isManager={false}
                        closeHandler={HandleClosePopUp} />
                }
            </section>
        </>
    );
}

export default GoalDetails;