import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import {Link} from 'react-router-dom';
import styles from "./goalcard.module.css";
import { Feedback }  from '../interfaces/feedback.interface';
import ModeIcon from "@mui/icons-material/Mode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";

interface FeedbackProps {
    feedback: Feedback,
}

const FeedbackCard: React.SFC<FeedbackProps> = ({feedback}) => {
    const { t } = useTranslation();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)
    let status = "common.Draft"
    let classStatus = "Draft"
    if (feedback.status) {
        if(feedback.collaborator === activeUser.id){
            status = "common.received"
            classStatus = "Received"
        } else {
            status = "common." + feedback.status.toLowerCase()
            classStatus = feedback.status
        }
    }
    let date = new Date()
    if(feedback.last_modified){
        date = new Date(feedback.last_modified)
    } else if (feedback.feedbackDate){
        date = new Date(JSON.parse(feedback.feedbackDate))
    }

    return (
        <Fragment>
            <li>
                <a href={"/feedbacks/" + feedback.id} className={styles.LineLink}>
                    <div className={styles.Title}>
                        <p>{feedback.title}</p>
                    </div>
                    <div className={styles.Status}>
                        <div className={classStatus}></div>
                        {t(status)}
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Actions}>
                        <Link to={"/feedbacks/" + feedback.id} className={styles.link}>
                            {feedback.status === "Draft" &&
                                <ModeIcon />
                            }
                            {feedback.status != "Draft" &&
                                <VisibilityIcon />
                            }
                        </Link>
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default FeedbackCard;