import {useState} from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Header from '../components/header/header';
import Button from '../components/button';
import styles from './addgoal.module.css';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {createRequest} from "../store/reducers/requestReducer";
import {Navigate} from "react-router-dom";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import CustomAlerts from "../components/customAlerts";
import HeaderLogo from "../assets/request-header-logo.svg";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import validateLogo from "../assets/page-validate.svg"
import validateHoverLogo from "../assets/page-validate-hover.svg"
import shareLogo from "../assets/page-share.svg"
import shareHoverLogo from "../assets/page-share-hover.svg"
import PopUpSharedRequest from "../components/PopUpSharedRequest";


export default function AddRequest() {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>('');
    const [details, setDetails] = useState<string>('');
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date());
    const [redirect, setRedirect] = useState<string>("");
    const requestStatus = useSelector((state: RootState) => state.requests.status);
    const errors = useSelector((state: RootState) => state.requests.error);
    const dispatch = useDispatch<AppDispatch>();
    const [openPopUp, setOpenPopUp] = useState(false);

    if(requestStatus === "success") {
        if(redirect === "allRequest") {
            return <Navigate to="/requests" />;
        } else if(redirect === "newRequest") {
            window.location.reload();
        } else if(redirect === "newExp") {
            return <Navigate to="/experiences/new" />;
        }
    }
    if(redirect === "close"){
        window.history.back();
    }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if (direction) {
            setRedirect(direction);
        } else {
            setRedirect("allRequest");
        }
    }


    return (
        <>
            <SidebarTalent />
            <main>
                <Header title={t("request.h1")}/>
                <section className={styles.modalForm}>
                    <header>
                    <img src={HeaderLogo}></img>
                    <h1>{t("common.request")}</h1>
                    </header>
                    <form className={styles.form}>
                        <div className={styles.titleInput}>
                            <label htmlFor="title">{t("request.title")}</label>
                            <input
                                name="title"
                                placeholder={t("request.placeholder")}
                                onChange={(event) => {setTitle(event.target.value)}}>
                            </input>
                        </div>
                        {requestStatus === "error" && errors.title &&
                            <CustomAlerts errors={errors.title}/>
                        }
                        <label htmlFor="request">{t("request.content")}</label>
                        <textarea
                            name="request"
                            placeholder={t("request.placeholderDetails")}
                            onChange={(event) => {setDetails(event.target.value)}}>
                        </textarea>
                        {requestStatus === "error" && errors.details &&
                            <CustomAlerts errors={errors.details}/>
                        }
                        <label>{t("request.date")}</label>
                        <div className={styles.DateInput}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disablePast
                                    openTo="month"
                                    views={['year', 'month', 'day']}
                                    value={completionDate}
                                    onChange={(newDate) => {
                                        setCompletionDate(newDate);
                                    }}
                                    renderInput={(params) => {
                                        let newParams = {...params}
                                        if(newParams.inputProps){
                                            newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                        }
                                        return(<TextField {...newParams}/>)}}
                                />
                            </LocalizationProvider>
                        </div>
                    </form>
                    <div className={styles.buttonContainer}>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.close")}
                            submit={
                                () => {
                                    setRedirect("close")
                                }
                            }
                            icon={closeLogo}
                            iconHover={closeHoverLogo}/>
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.save")}
                            submit={
                                ()=> {
                                    dispatch(
                                        createRequest({
                                            title,
                                            status: "Draft",
                                            details,
                                            completionDate: JSON.stringify(completionDate)
                                        })
                                    )
                                    setRedirect("allRequest")
                                }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.share")}
                            submit={
                                ()=> {
                                    dispatch(
                                        createRequest({
                                            title,
                                            status: "Shared",
                                            details,
                                            completionDate: JSON.stringify(completionDate)
                                        })
                                    )
                                    setOpenPopUp(true);
                                }
                            }
                            icon={shareLogo}
                            iconHover={shareHoverLogo}/>
                            {requestStatus !== "error" &&
                                <PopUpSharedRequest
                                    visible={openPopUp}
                                    closeHandler={HandleClosePopUp} />
                            }
                    </div>
                </section>
            </main>
        </>
    );
}