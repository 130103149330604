import { useTranslation } from "react-i18next";
import styles from "./managerConversationsList.module.css";
import sharingEmptyStage from "../assets/sharing-emptystage.png";
import dots from "../assets/dots.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import * as React from "react";
import { useEffect, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import reviewIconPage from '../assets/appreciation-icon-page.svg';
import HRPerformanceReviewCard from "./HRPerformanceReviewCard";
import {getAllHRPerformanceReviews} from "../store/reducers/performanceReviewsReducer";
import {PerformanceReview, PerformanceReviewParameters} from "../interfaces/performanceReview.interface";

interface ParametersProps {
    parameters: PerformanceReviewParameters
}

export const HRPerformanceReviewsList: React.SFC<ParametersProps> = ({ parameters }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getAllHRPerformanceReviews(parameters));
    }, [dispatch]);

    const reviews = useSelector((state: RootState) => state.performanceReviews);
    const reviewsList = reviews.performanceReviews;
    const reviewsOffset = reviews.offset;
    const reviewsCount = reviews.count;

    const [offset, setOffset] = useState(reviewsOffset);
    const limit : number  = parameters.limit || 8;

    useEffect(() => {
        setOffset(reviews.offset)
    }, [reviews])

    return (
        <div className={styles.List} id="Review">
            <div className={styles.pageTitle}>
                <img src={reviewIconPage} className={styles.goalIcon}/>
                <h1>
                    {t("common.reviews")}
                </h1>
            </div>
            {reviewsCount > 0
                ?
                <>
                    <div className={styles.Header}>
                        <div className={styles.headerTalent}>{t("common.talent")}<img src={dots} /></div>
                        <div className={styles.headerTitle}>{t("common.evaluation")}<img src={dots} /></div>
                        <div className={styles.headerDate}>{t("common.date")}<img src={dots} /></div>
                        <div className={styles.headerStatus}>{t("common.status")}<img src={dots} /></div>
                    </div>
                    <div className={styles.Sharing}>
                        {reviewsList.map(
                            (review: PerformanceReview, index) =>
                                <HRPerformanceReviewCard
                                    key={index}
                                    review={review}
                                />
                        )}
                    </div>
                    <div className={styles.EntryPagination}>
                        {offset + 1} - {offset + limit <= reviewsCount ? offset + limit : reviewsCount} {t("common.on")} {reviewsCount}
                        {offset != 0
                            ? <ChevronLeftIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset-limit
                                        dispatch(getAllHRPerformanceReviews(newParameters))
                                    }
                                }/>
                            : <ChevronLeftIcon/>
                        }
                        {offset + limit < reviewsCount
                            ? <ChevronRightIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset+limit
                                        dispatch(
                                            getAllHRPerformanceReviews(newParameters)
                                        )
                                    }
                                }/>
                            : <ChevronRightIcon/>
                        }
                    </div>
                </>
                :
                <div className={styles.EmptyStage}>
                    <img src={sharingEmptyStage} />
                    <p>{t("reviews.emptyList")}</p>
                </div>
            }
        </div>
    );
}