import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import styles from "./managerConversationCard.module.css";
import {useDispatch, useSelector} from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { getTeamMembers } from "../store/reducers/teamReducer";
import { Avatar } from "@mui/material";
import {stringAvatar} from "../utils/avatar";
import noAvatar from "../assets/avatar.svg"
import { Goal } from "../interfaces/goal.interface";


interface GoalProps {
    goal: Goal
}

const ManagerGoalCard: React.SFC<GoalProps> = ({ goal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    let status = "Draft"
    if (goal.status) {
        status = "common." + goal.status.toLowerCase()
    }
    let date = new Date()
    if (goal.last_modified){
        date = new Date(goal.last_modified)
    } else if (goal.completionDate) {
        date = new Date(JSON.parse(goal.completionDate))
    }
    
    useEffect(() =>{
        dispatch(getTeamMembers())
    }, [dispatch])
    const members = useSelector((state: RootState) => state.team.members)
    const member = members.find(Collaborator => Collaborator.id === goal.collaborator_from)

    let titreGoal = goal.title;
    if (titreGoal.length > 60) {
        titreGoal = titreGoal.substring(0,60) + "..."
    }

    let fullName = ""
    if (member) {
        if (member.first_name) {
            fullName += member.first_name
        }
        if (member.last_name) {
            fullName = fullName + " " + member.last_name
        }
    }

    return (
        <Fragment>
            <li>
                <a href={"/manager/goals/" + goal.id} className={styles.LineLink}>
                    <div className={styles.Collaborator}>
                        {member && member.avatar &&
                            <img src={process.env.REACT_APP_BACK_END_URL + "media/" + member.avatar} />
                        }
                        {member && !member.avatar &&
                            <Avatar {...stringAvatar(fullName)} />
                        }
                        {!member &&
                            <img src={noAvatar} />
                        }
                    </div>
                    <div className={styles.Title}>
                        <p>{titreGoal}</p>
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Status}>
                        <span className={goal.status}></span>
                        {t(status)}
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default ManagerGoalCard;