import * as React from "react";
import styles from './viewManagerConversation.module.css';
import SidebarHR from "../components/sidebarHR";
import HeaderManager from "../components/header/headerManager";
import {HRCampaignDetails} from "../components/HRCampaignDetails";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {useEffect} from "react";
import {getCampaignDetails} from "../store/reducers/campaignsReducer";
import {useParams} from "react-router-dom";
import Dashboard from "./dashboard";
import {Campaign} from "../interfaces/campaign.interface";

export default function ViewHRCampaignDetails() {
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.campaigns.status)

    useEffect(() => {
        if(params.campaignId) {
            dispatch(getCampaignDetails(params.campaignId));
        }
    }, [dispatch]);

    const campaign = useSelector((state: RootState) => state.campaigns.campaigns.find((campaign: Campaign) => campaign.id == params.campaignId));

    if(status !== "error") {
        return (
            <>
                <SidebarHR />
                <main>
                    <HeaderManager />
                    <section className={styles.section} >
                        {campaign &&
                            <HRCampaignDetails campaign={campaign} />
                        }
                    </section>
                </main>
            </>
        );
    }
    else {
        return <Dashboard/>
    }
}