import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Button from '../components/button';
import {Request as RequestInterface} from '../interfaces/request.interface';
import styles from './goaldetails.module.css';
import {AppDispatch, RootState} from "../store/store";
import {updateManagerRequest, updateRequest, deleteRequest} from "../store/reducers/requestReducer";
import {Navigate} from "react-router-dom";
import CustomAlerts from "./customAlerts";
import * as React from "react";
import HeaderLogo from "../assets/request-header-logo.svg";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import validateLogo from "../assets/page-validate.svg"
import validateHoverLogo from "../assets/page-validate-hover.svg"
import shareHoverLogo from "../assets/page-share.svg"
import shareLogo from "../assets/page-share-hover.svg"
import deleteLogo from "../assets/page-delete.svg"
import deleteHoverLogo from "../assets/page-delete-hover.svg"
import PopUpSharedRequest from "./PopUpSharedRequest";
import {getTeamMembers} from "../store/reducers/teamReducer";
import Collaborator from "../interfaces/collaborator.interface";
import AvatarIcon from "./avatar";
import CollaboratorSelectOption from "./collaboratorSelectOption";

interface RequestProps {
    request: RequestInterface
}

const RequestDetails: React.SFC<RequestProps> = ({request}) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>(request.title);
    const [details, setDetails] = useState<string>(request.details);
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date(JSON.parse(request.completionDate)));
    const requestStatus = useSelector((state: RootState) => state.requests.status);
    const errors = useSelector((state: RootState) => state.requests.error);
    const [redirect, setRedirect] = useState<string>("");
    const dispatch = useDispatch<AppDispatch>();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);
    const [openPopUp, setOpenPopUp] = useState(false);

    useEffect(() =>{
        if(activeUser.id !== request.author){
            dispatch(getTeamMembers())
        }
    }, [dispatch])
    const members = useSelector((state: RootState) => state.team.members)
    const member = members.find(Collaborator => Collaborator.id === request.author) as Collaborator

    let readonly = true;
    if (request.status === "Draft" || request.status === "Shared"){
        if(activeUser.id == request.author){
            readonly = false;
        }
    }

    if(redirect === "close"){
        window.history.back();
    }
    if(requestStatus === "success") {
        if(redirect === "allRequest") {
            return <Navigate to="/requests" />;
        } else if(redirect === "newRequest") {
            window.location.reload();
        } else if(redirect === "newExp") {
            return <Navigate to="/experiences/new" />;
        } else if(redirect === "return"){
            if(request.author == activeUser.id){
                return <Navigate to="/requests/"/>;
            } else {
                return <Navigate to="/manager/requests/"/>;
            }
        }
    }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if (direction) {
            setRedirect(direction);
        } else {
            setRedirect("return");
        }
    }

    let status = "Draft"
    if (request.status) {
        status = "common." + request.status.toLowerCase()
    }

    return (
        <>
            <section className={styles.modalForm}>
                <header>
                    <div className={styles.entryTitle}>
                        <img src={HeaderLogo}></img>
                        <h1>{t("common.request")}</h1>
                    </div>
                    {member && activeUser.id !== request.author &&
                        <CollaboratorSelectOption collaborator={member} displayName={true}/>
                    }
                    <div className={styles.Status}>
                        <span className={request.status}></span>
                        <p>{t(status)}</p>
                    </div>
                </header>
                <form className={styles.form}>
                    <div className={styles.titleInput}>
                        <label htmlFor="title">{t("request.title")}</label>
                        <input
                            name="title"
                            placeholder="Your Request"
                            readOnly={readonly}
                            defaultValue={title}
                            onChange={(event) => {setTitle(event.target.value)}}
                        >
                        </input>
                    </div>
                    {requestStatus === "error" && errors.title &&
                        <CustomAlerts errors={errors.title}/>
                    }
                    <label htmlFor="request">{t("request.content")}</label>
                    <textarea
                        name="request"
                        readOnly={readonly}
                        onChange={(event) => {setDetails(event.target.value)}}
                        defaultValue={details}>
                    </textarea>
                    {requestStatus === "error" && errors.details &&
                        <CustomAlerts errors={errors.details}/>
                    }
                    <label>{t("request.date")}</label>
                    <div className={styles.DateInput}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                disablePast
                                openTo="month"
                                readOnly={readonly}
                                views={['year', 'month', 'day']}
                                value={completionDate}
                                onChange={(newDate) => {
                                    setCompletionDate(newDate);
                                }}
                                renderInput={(params) => {
                                    let newParams = {...params}
                                    if(newParams.inputProps){
                                        newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                    }
                                    return(<TextField {...newParams}/>)}}
                            />
                        </LocalizationProvider>
                    </div>
                </form>
                <div className={styles.buttonContainer}>
                    <Button
                        classCss={"secondary-button"}
                        content={t("buttons.close")}
                        submit={
                            () => {
                                setRedirect("close")
                            }
                        }
                        icon={closeLogo}
                        iconHover={closeHoverLogo}/>
                    {request.status == "Draft" &&request.author === activeUser.id &&
                        <>
                            <Button
                                classCss={"secondary-button"}
                                content={t("buttons.delete")}
                                submit={
                                    () => {
                                        dispatch(
                                            deleteRequest({id: request.id!})
                                        )
                                        setRedirect("return")
                                    }
                                }
                                icon={deleteLogo}
                                iconHover={deleteHoverLogo}/>
                            <Button
                                classCss={"primary-button"}
                                content={t("buttons.save")}
                                submit={
                                    ()=> {
                                        dispatch(
                                            updateRequest(
                                                {
                                                    id: request.id,
                                                    title,
                                                    status: request.status,
                                                    details,
                                                    completionDate: JSON.stringify(completionDate)
                                                })
                                        )
                                        setRedirect("return")
                                    }
                                }
                                icon={validateLogo}
                                iconHover={validateHoverLogo}/>
                            <Button
                                classCss={"primary-button"}
                                content={t("buttons.share")}
                                submit={
                                    () => {
                                        dispatch(
                                            updateRequest(
                                                {
                                                    id: request.id,
                                                    title,
                                                    status: "Shared",
                                                    details,
                                                    completionDate: JSON.stringify(completionDate)
                                                })
                                        )
                                        setOpenPopUp(true);
                                    }
                                }
                                icon={shareLogo}
                                iconHover={shareHoverLogo}/>
                        </>
                }
                {request.status == "Shared" && request.author != activeUser.id &&
                    <>
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.accept")}
                            submit={
                                ()=> {
                                    dispatch(
                                        updateManagerRequest(
                                            {
                                                id: request.id,
                                                title: request.title,
                                                status: "Accepted",
                                                details: request.details,
                                                completionDate: request.completionDate,
                                                author: request.author
                                            })
                                    )
                                    setRedirect("return")
                                }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.reject")}
                            submit={
                                ()=> {
                                    dispatch(
                                        updateManagerRequest(
                                            {
                                                id: request.id,
                                                title: request.title,
                                                status: "Rejected",
                                                details: request.details,
                                                completionDate: request.completionDate,
                                                author: request.author
                                            })
                                    )
                                    setRedirect("return")
                                }
                            }
                            icon={closeLogo}
                            iconHover={closeHoverLogo}/>
                    </>
                }
                </div>
                {requestStatus !== "error" &&
                    <PopUpSharedRequest
                        visible={openPopUp}
                        closeHandler={HandleClosePopUp} />
                }
            </section>
        </>
    );
}

export default RequestDetails;