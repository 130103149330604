import * as React from "react";
import styles from "./collaboratorSelect.module.css";
import Collaborator from "../interfaces/collaborator.interface";
import {Avatar} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {stringAvatar} from "../utils/avatar";
import noAvatar from "../assets/avatar.svg";

interface CollaboratorSelectProps {
    collaborator: Collaborator,
    displayName?: boolean,
}

const CollaboratorSelectOption: React.SFC<CollaboratorSelectProps> = ({collaborator, displayName}) => {
    let fullName = ""
    if(collaborator){
        if (collaborator.first_name) {
            fullName += collaborator.first_name
        }
        if (collaborator.last_name) {
            fullName = fullName + " " + collaborator.last_name
        }
    }

    return (
        <div className={styles.AvatarSelect}>
            <Tooltip title={fullName} arrow>
                <div className={styles.Avatar}>
                        {collaborator && collaborator.avatar &&
                            <img src={process.env.REACT_APP_BACK_END_URL + "media/" + collaborator.avatar.replace('/media/', '')} />
                        }
                        {collaborator && !collaborator.avatar &&
                            <Avatar {...stringAvatar(fullName)} />
                        }
                        {!collaborator &&
                            <img src={noAvatar} />
                        }

                </div>
            </Tooltip>
            {displayName &&
                fullName
            }
        </div>
    );
};
export default CollaboratorSelectOption;