import {Link, Navigate, useSearchParams} from "react-router-dom";
import styles from "./login.module.css";
import {useTranslation} from "react-i18next";
import Button from "../components/button";
import {useState} from "react";
import {userLogin} from "../store/reducers/activeUserReducer";
import {AppDispatch, RootState} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import background from "../assets/login-bg.png";
import CustomAlerts from "../components/customAlerts";
import papirusLeaf from "../assets/papirus-leaf.png";
import logo from "../assets/logo-white.png";
import * as React from "react";
import eyeIcon from "../assets/eye-icon.svg";
import eyeIconUsed from "../assets/eye-icon-show.svg";


export default function Login() {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [redirect, setRedirect] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const loginStatus = useSelector((state: RootState) => state.activeUser.status);
    const errors = useSelector((state: RootState) => state.activeUser.error);
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [passwordInputType, setPasswordInputType] = useState<"password" | "text">("password")
    const nextPage = searchParams.get("next");

    if (redirect && loginStatus === "success" && activeUser.isAuthenticated){
        if(nextPage){
            return <Navigate to={nextPage} />;
        }else {
            return <Navigate to="/" />;
        }
    }

    return (
        <main className={styles.loginMain} style={{backgroundImage: `url(${background})`}}>
            <div>
                <img src={papirusLeaf} />
                <form>
                    <h2>{t("common.loginTitle")}</h2>
                    <input
                        name="email"
                        placeholder="obiwan@kenobi.com"
                        autoComplete="username"
                        onChange={
                            (e) => setEmail(e.target.value)
                        }>
                    </input>
                    {loginStatus === "error" && errors.email &&
                        <CustomAlerts errors={errors.email}/>
                    }
                    <p className={styles.password}>
                        <input
                            id="passwordInput"
                            name="password"
                            placeholder="*******"
                            type={passwordInputType}
                            autoComplete="current-password"
                            onChange={
                                (e) => setPassword(e.target.value)
                            }>
                        </input>
                        {showPassword ?
                            <img
                                src={eyeIconUsed}
                                className={styles.passwordShowed}
                                onClick={() => {
                                    setShowPassword(false)
                                    setPasswordInputType("password")
                                }}/>
                            :
                            <img
                                src={eyeIcon}
                                className={styles.passwordHidden}
                                onClick={() => {
                                    setShowPassword(true)
                                    setPasswordInputType("text")
                                }}/>
                        }
                    </p>
                    {loginStatus === "error" && errors.password &&
                        <span className={styles.loginError}>
                            <CustomAlerts errors={errors.password}/>
                        </span>
                    }
                    {loginStatus === "error" && errors.detail &&
                        <span className={styles.loginError}>
                            <CustomAlerts errors={[t("common.loginError")]}/>
                        </span>
                    }
                    <Button
                        classCss={"secondary-button"}
                        content={t("buttons.login")}
                        submit={
                            () => {
                                dispatch(userLogin({email: email, password: password}))
                                setRedirect(true)
                            }
                        }/>
                </form>
                <Link className={styles.forgot} to={"/password-forgotten"}>Forgot your password?</Link>
            </div>
            <h1>{t("common.loginCatchphrase")}<img src={logo}/></h1>
        </main>
    );
}