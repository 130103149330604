import * as React from 'react';
import { useTranslation } from "react-i18next";
import styles from './sidebar.module.css';
import Logo from '../assets/logo-full-white.png';
import {Link, NavLink} from "react-router-dom";
import dasbhboardIconActive from "../assets/dashboard-icon-selected.svg";
import dasbhboardIcon from "../assets/dashboard-icon.svg";
import conversationIconActive from "../assets/conversation-icon-selected.svg";
import conversationIcon from "../assets/conversation-icon.svg";
import goalLogo from "../assets/goal-icon.svg";
import goalActiveLogo from "../assets/goal-icon-selected.svg";
import experienceLogo from "../assets/experience-icon.svg";
import experienceActiveLogo from "../assets/experience-icon-selected.svg";
import requestLogo from "../assets/request-icon.svg";
import requestActiveLogo from "../assets/request-icon-selected.svg";
import campaignLogo from "../assets/campaign-icon.svg";
import campaignActiveLogo from "../assets/campaign-icon-selected.svg";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import reviewActiveLogo from "../assets/appreciation-icon-selected.svg";
import reviewLogo from "../assets/appreciation-icon.svg";

export function SidebarTalent() {
    const {t} = useTranslation();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)

    return (
        <aside className={styles.sidebar}>
            <figure className={styles.logo}>
                <Link to="/">
                    <img src={Logo} />
                </Link>
            </figure>
            {activeUser.modules_choices.includes("all") &&
                <NavLink to="/personal-dashboard" className={styles.menuItem}>
                    <img className={"activeIcon"} src={dasbhboardIconActive} />
                    <img className={"icon"} src={dasbhboardIcon} />
                    <span>{t('header.dashboard')}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("conversation")) &&
                <NavLink to="/conversations/" className={styles.menuItem}>
                    <img className={"activeIcon"} src={conversationIconActive}/>
                    <img className={"icon"} src={conversationIcon}/>
                    <span>{t("header.conversations")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("goal")) &&
                <NavLink to="/goals/" className={styles.menuItem}>
                    <img className={"activeIcon"} src={goalLogo}/>
                    <img className={"icon"} src={goalActiveLogo}/>
                    <span>{t("header.goals")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("experience")) &&
                <NavLink to="/experiences/" className={styles.menuItem}>
                    <img className={"activeIcon"} src={experienceLogo}/>
                    <img className={"icon"} src={experienceActiveLogo}/>
                    <span>{t("header.experiences")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("feedback")) &&
                <NavLink to="/feedbacks/" className={styles.menuItem}>
                    <img className={"activeIcon"} src={conversationIconActive}/>
                    <img className={"icon"} src={conversationIcon}/>
                    <span>{t("header.feedbacks")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("request")) &&
                <NavLink to="/requests/" className={styles.menuItem}>
                    <img className={"activeIcon"} src={requestLogo}/>
                    <img className={"icon"} src={requestActiveLogo}/>
                    <span>{t("header.requests")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("appreciation")) &&
                <NavLink to="/performance-reviews/" className={styles.menuItem}>
                    <img className={"activeIcon"} src={reviewActiveLogo}/>
                    <img className={"icon"} src={reviewLogo}/>
                    <span>{t("header.appreciations")}</span>
                </NavLink>
            }
            {(activeUser.modules_choices.includes("all") || activeUser.modules_choices.includes("campaign")) &&
                <NavLink to="/campaign-answers/" className={styles.menuItem}>
                    <img className={"activeIcon"} src={campaignActiveLogo}/>
                    <img className={"icon"} src={campaignLogo}/>
                    <span>{t("header.campaigns")}</span>
                </NavLink>
            }
        </aside>
    );
}
