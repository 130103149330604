import {useDispatch, useSelector} from 'react-redux';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {AppDispatch, RootState} from '../store/store';
import Header from '../components/header/header';
import RequestCard from '../components/requestCard';
import styles from './allgoals.module.css';
import { Request } from "../interfaces/request.interface";
import {useEffect} from "react";
import {getAllRequests} from "../store/reducers/requestReducer";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import { ToolTips } from '../components/toolTips';


export default function AllRequests() {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getAllRequests());
    }, [dispatch]);
    const requests = useSelector((state: RootState) => state.requests.requests)

    return (
        <>
            <SidebarTalent />
            <main>
                <Header />
                <div className={styles.headerButton}>
                    <Link to="/requests/new" className={"primary-button"}>{t("buttons.addRequest")}</Link>
                </div>
                <section className={styles.section}>
                    <h1>{t("header.requests")}
                    <ToolTips text={t("request.tooltips")} parent={document.getElementById("allRequests")}/></h1>
                    <div className={styles.Table} id="allRequests">
                        <header>
                            <div className={styles.Title}>{t("common.title")}</div>
                            <div className={styles.Status}>{t("common.status")}</div>
                            <div className={styles.Date}>{t("common.completionDate")}</div>
                            <div className={styles.Actions}>{t("common.actions")}</div>
                        </header>
                        <ul>
                            { requests.map(
                                (request: Request, index) =>
                                    <RequestCard
                                        key={index}
                                        request={request}
                                    />
                            )}
                        </ul>
                    </div>
                </section>
            </main>
        </>
    );
}