import {useSelector} from 'react-redux';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {RootState} from '../store/store';
import HeaderManager from '../components/header/headerManager';
import styles from './team.module.css';
import miniPortrait from "../assets/anthony.png";
import miniPortraitMarie from "../assets/marie.png";
import miniPortraitFranck from "../assets/franck.png";
import * as React from "react";
import Donut from "../components/donut";


export default function YourTeam() {
    const { t } = useTranslation();
    const collaborators = useSelector((state: RootState) => state.collaborators.collaborators)

    return (
        <>
            <HeaderManager />
            <main>
                <div className={styles.headerButton}>
                    <Link to="" className={"primary-button"}>{t("buttons.addTeamMember")}</Link>
                </div>
                <section className={styles.section}>
                    <h1>{t("header.team")}</h1>
                    <div className={styles.Table}>
                        <header>
                            <div>{t("common.collaborator")}</div>
                            <div>{t("common.goal")}</div>
                            <div>{t("common.experience")}</div>
                            <div>{t("common.request")}</div>
                        </header>
                        <ul>
                            <li>
                                <div className={styles.Collaborator}>
                                    <img className={styles.profileImg} src={miniPortrait} />
                                    <p>{collaborators[0].username}</p>
                                </div>
                                <div>
                                    <div className={styles.donut}>
                                        <Donut classCss={styles.baseDonut} value={100} size={100}/>
                                        <Donut classCss={styles.donutGraph} value={50} size={100}/>
                                        <span className={styles.donutValue}>50%</span>
                                    </div>
                                </div>
                                <div>3</div>
                                <div>1</div>
                            </li>
                            <li>
                                <div className={styles.Collaborator}>
                                    <img className={styles.profileImg} src={miniPortraitMarie} />
                                    <p>{collaborators[1].username}</p>
                                </div>
                                <div>
                                    <div className={styles.donut}>
                                        <Donut classCss={styles.baseDonut} value={100} size={100}/>
                                        <Donut classCss={styles.donutGraph} value={66} size={100}/>
                                        <span className={styles.donutValue}>66%</span>
                                    </div>
                                </div>
                                <div>5</div>
                                <div>0</div>
                            </li>
                            <li>
                                <div className={styles.Collaborator}>
                                    <img className={styles.profileImg} src={miniPortraitFranck} />
                                    <p>{collaborators[2].username}</p>
                                </div>
                                <div>
                                    <div className={styles.donut}>
                                        <Donut classCss={styles.baseDonut} value={100} size={100}/>
                                        <Donut classCss={styles.donutGraph} value={80} size={100}/>
                                        <span className={styles.donutValue}>80%</span>
                                    </div>
                                </div>
                                <div>7</div>
                                <div>2</div>
                            </li>
                        </ul>
                    </div>
                </section>
            </main>
        </>
    );
}