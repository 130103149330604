import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import ExperienceDetails from '../components/experienceDetails';
import { Experience as ExperienceInterface} from '../interfaces/experience.interface';
import {AppDispatch, RootState} from "../store/store";
import {useEffect} from "react";
import {getManagerExperienceDetails} from "../store/reducers/experiencesReducer";
import DashboardManager from "./dashboardManager";
import {Sidebar} from "../components/sidebar";
import * as React from "react";
import { useTranslation } from "react-i18next";
import HeaderManager from "../components/header/headerManager";

export default function ViewManagerExperienceDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.experiences.status)

    useEffect(() => {
        if(params.experienceId){
            dispatch(getManagerExperienceDetails(params.experienceId));
        }
    }, [dispatch]);
    const experience = useSelector((state: RootState) => state.experiences.experiences.find((experience : ExperienceInterface) => experience.id == params.experienceId));

    if (status != "error") {
        return (
            <>
                <Sidebar />
                <main>
                    <HeaderManager title={t("experience.h1")}/>
                    {experience &&
                        <ExperienceDetails experience={experience}/>
                    }
                </main>
            </>
        );
    }
    return <DashboardManager />;

}