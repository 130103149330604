import {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {
    BooleanTemplate, SelectTemplate,
    CampaignAnswer as CampaignAnswerInterface,
    Information, Integer, MultipleChoice, Star,
    Text, Textarea,
    Title, GoalTemplate, FutureGoalTemplate, PastTraining, FutureTraining, SkillTemplate
} from '../interfaces/campaignAnswer.interface';
import styles from './campaignAnswer.module.css';
import * as React from "react";
import HeaderLogo from "../assets/campaign-header-logo.svg";
import TitleComponent from "./templates/title";
import InformationComponent from "./templates/information";
import TextComponent from "./templates/text";
import TextareaComponent from "./templates/textarea";
import GoalTemplateComponent from "./templates/goalTemplate";
import SkillTemplateComponent from "./templates/skillTemplate";
import FutureGoalTemplateComponent from "./templates/futureGoalTemplate";
import SelectComponent from "./templates/selectTemplate";
import PastTrainingComponent from "./templates/pastTraining";
import FutureTrainingComponent from "./templates/futureTraining";
import BooleanComponent from "./templates/booleanComponent";
import IntegerComponent from "./templates/integerComponent";
import ListTemplateComponent from "./templates/ListComponent";
import DateTemplateComponent from "./templates/dateTemplate";
import validateHoverLogo from "../assets/page-validate.svg";
import validateLogo from "../assets/page-validate-hover.svg";
import shareHoverLogo from "../assets/page-share.svg";
import shareLogo from "../assets/page-share-hover.svg";
import downloadLogo from "../assets/download.svg";
import downloadHoverLogo from "../assets/download-selected.svg";
import Button from "./button";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {updateCampaignAnswerDetails} from "../store/reducers/campaignAnswersReducer";
import MultiSelectComponent from "./templates/multiSelectTemplate";
import CampaignAnswerServices from "../services/campaignAnswer.service";
import downloadBlack from "../assets/download-black.svg";

const campaignAnswerService : CampaignAnswerServices = new CampaignAnswerServices();

interface CampaignAnswerProps {
    campaignAnswer: CampaignAnswerInterface
}

const CampaignAnswerDetails: React.FunctionComponent<CampaignAnswerProps> = ({campaignAnswer}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)
    const isManager = useSelector((state: RootState) => campaignAnswer.managers!.includes(state.activeUser.activeUser.id));
    const [collaboratorAnswers, setCollaboratorAnswers] = useState<any[]>(campaignAnswer.answer_collaborator!.template)
    const [managerAnswer, setManagerAnswer] = useState<any[]>(campaignAnswer.answer_manager!.template)

    const checkDisplay = (answer: any) => {
        let display = false
        if(isManager){
            if(answer.visibility === "Manager"){
                display = true
            } else if(campaignAnswer.status === "SharedByCollaborator" || campaignAnswer.status === "SharedByBoth" || campaignAnswer.status === "ValidatedByManager" || campaignAnswer.status === "ValidatedByBoth"){
                display = true
            }
        } else {
            if(answer.visibility === "Collaborator"){
                display = true
            } else if(campaignAnswer.status === "SharedByManager" || campaignAnswer.status === "SharedByBoth" || campaignAnswer.status === "ValidatedByManager" || campaignAnswer.status === "ValidatedByBoth") {
                display = true
            }
        }
        if(answer.visibility === "Both"){
            display = true
        }
        return display
    }

    let readonly = true;
    if (campaignAnswer.status !== "ValidatedByBoth"){
        readonly = false;
    }

    let status = "Draft"
    if (campaignAnswer.status) {
        status = "common." + campaignAnswer.status.toLowerCase()
    }

    const getPDFData = async () => {
        const response = await campaignAnswerService.downloadCampaignAnswer(activeUser.token, campaignAnswer.id!.toString())
        let type = "application/pdf"
        if(response.headers["content-type"] !== "application/pdf"){
            type = "application/zip"
        }
        let filename = campaignAnswer.campaign + " - " + activeUser.name
        if (type === "application/pdf"){
            filename += ".pdf"
        }else if (type === "application/zip"){
            filename += ".zip"
        }
        const url: Blob = new Blob([response.data], {type: type});
        const blobUrl = window.URL.createObjectURL(url);
        const templink: any = document.createElement("a");
        templink.setAttribute("download", filename);
        templink.href = blobUrl;
        document.body.appendChild(templink);
        templink.click();
        templink.parentNode.removeChild(templink);
    }

    useEffect(() => {
        const debouncedSave = setTimeout(() => {
            if(campaignAnswer.answer_collaborator!.template !== collaboratorAnswers && campaignAnswer.status !== "ValidatedByBoth" && campaignAnswer.status !== "ValidatedByManager"){
                dispatch(updateCampaignAnswerDetails(
                    {
                        id: campaignAnswer.id,
                        collaborator: campaignAnswer.collaborator,
                        status: campaignAnswer.status,
                        answer_collaborator: {template: collaboratorAnswers as [Title | Information | Text | Textarea | SelectTemplate | MultipleChoice | BooleanTemplate | Integer | Star]},
                    }))
            }
        }, 5000)
        return () => clearTimeout(debouncedSave)
    }, [collaboratorAnswers])

    return (
        <>
            <section className={styles.modalForm}>
                <header>
                    <div className={styles.entryTitle}>
                        <img src={HeaderLogo}></img>
                        <h1>{campaignAnswer.campaign}</h1>
                        {(activeUser.organization === 20 || activeUser.head_company === "Wivetix Services") &&
                            <a className={styles.userGuide} href="https://drive.google.com/uc?export=download&id=1bWfMeQ-ByhWuEO6H2jPoIRFRuDXKs21r" download>
                                <img src={downloadBlack}/>{t("campaign.interviewGuide")}
                            </a>
                        }
                    </div>
                    <div className={styles.Status}>
                        <span className={campaignAnswer.status}></span>
                        <p>{t(status)}</p>
                    </div>
                </header>
                <form className={styles.form}>
                    {collaboratorAnswers.map((collaboratorAnswer, index) => {
                        if (collaboratorAnswer.condition){
                            if(collaboratorAnswer.condition.position === "external"){
                                if(collaboratorAnswer.condition.value === "Wivetix Services"){
                                    if(activeUser.organization !== 20 && activeUser.organization !== 11){
                                        return <></>
                                    }
                                }
                            } else {
                                let checkValue = collaboratorAnswers.find(answer => answer.position === collaboratorAnswer.condition.position)
                                if(checkValue.answer !== collaboratorAnswer.condition.value){
                                    return <></>
                                }
                            }
                        }
                        if (collaboratorAnswer.type === "Title" && checkDisplay(collaboratorAnswer)) {
                            return <TitleComponent
                                answer={collaboratorAnswer}
                                key={index}/>
                        } else if (collaboratorAnswer.type === "Information" && checkDisplay(collaboratorAnswer)) {
                            return <InformationComponent
                                answer={collaboratorAnswer}
                                key={index}/>
                        } else if (collaboratorAnswer.type === "Text" && checkDisplay(collaboratorAnswer)){
                            return <TextComponent
                                key={index}
                                answer={collaboratorAnswer}
                                managerAnswer={managerAnswer.find(answer => answer.position === collaboratorAnswer.position)}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...collaboratorAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if (collaboratorAnswer.type === "Textarea" && checkDisplay(collaboratorAnswer)){
                            return <TextareaComponent
                                key={index}
                                answer={collaboratorAnswer}
                                managerAnswer={managerAnswer.find(answer => answer.position === collaboratorAnswer.position)}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...collaboratorAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if (collaboratorAnswer.type === "Goal" && checkDisplay(collaboratorAnswer)){
                            return <GoalTemplateComponent
                                key={index}
                                answer={collaboratorAnswer}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if (collaboratorAnswer.type === "FutureGoal" && checkDisplay(collaboratorAnswer)){
                            return <FutureGoalTemplateComponent
                                key={index}
                                answer={collaboratorAnswer}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        }else if (collaboratorAnswer.type === "Skill" && checkDisplay(collaboratorAnswer)) {
                            return <SkillTemplateComponent
                                key={index}
                                answer={collaboratorAnswer}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if (collaboratorAnswer.type === "PastTraining" && checkDisplay(collaboratorAnswer)) {
                            return <PastTrainingComponent
                                key={index}
                                answer={collaboratorAnswer}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if (collaboratorAnswer.type === "FutureTraining" && checkDisplay(collaboratorAnswer)) {
                            return <FutureTrainingComponent
                                key={index}
                                answer={collaboratorAnswer}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if (collaboratorAnswer.type === "Select" && checkDisplay(collaboratorAnswer)) {
                            return <SelectComponent
                                key={index}
                                answer={collaboratorAnswer}
                                managerAnswer={managerAnswer.find(answer => answer.position === collaboratorAnswer.position)}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...collaboratorAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if (collaboratorAnswer.type === "Boolean" && checkDisplay(collaboratorAnswer)) {
                            return <BooleanComponent
                                key={index}
                                answer={collaboratorAnswer}
                                managerAnswer={managerAnswer.find(answer => answer.position === collaboratorAnswer.position)}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...collaboratorAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if (collaboratorAnswer.type === "Integer" && checkDisplay(collaboratorAnswer)) {
                            return <IntegerComponent
                                key={index}
                                answer={collaboratorAnswer}
                                managerAnswer={managerAnswer.find(answer => answer.position === collaboratorAnswer.position)}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...collaboratorAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        }
                        else if (collaboratorAnswer.type === "MultipleChoice" && checkDisplay(collaboratorAnswer)) {
                            return <MultiSelectComponent
                                key={index}
                                answer={collaboratorAnswer}
                                managerAnswer={managerAnswer.find(answer => answer.position === collaboratorAnswer.position)}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...collaboratorAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if(collaboratorAnswer.type === "List" && checkDisplay(collaboratorAnswer)){
                            return <ListTemplateComponent
                                key={index}
                                answer={collaboratorAnswer}
                                managerAnswer={managerAnswer.find(answer => answer.position === collaboratorAnswer.position)}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: any[]) => {
                                        let newAnswer = {...collaboratorAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        } else if (collaboratorAnswer.type === "Date" && checkDisplay(collaboratorAnswer)) {
                            return <DateTemplateComponent
                                key={index}
                                answer={collaboratorAnswer}
                                managerAnswer={managerAnswer.find(answer => answer.position === collaboratorAnswer.position)}
                                status={campaignAnswer.status}
                                isManager={isManager}
                                readonly={readonly}
                                changeHandler={
                                    (newAnswerContent: string) => {
                                        let newAnswer = {...collaboratorAnswer}
                                        newAnswer.answer = newAnswerContent
                                        let newAnswers = [...collaboratorAnswers]
                                        newAnswers[index] = newAnswer
                                        setCollaboratorAnswers(newAnswers)
                                    }}/>
                        }
                        // else if (collaboratorAnswer.type === "Star") {
                        //     return <div className={styles.titleInput} key={index}>
                        //         <label htmlFor="title">{collaboratorAnswer.title}</label>
                        //     </div>
                        // }
                    })}
                </form>
                <div className={styles.buttonContainer}>
                    {(campaignAnswer.status === "Draft" || campaignAnswer.status === "SharedByCollaborator" || campaignAnswer.status === "SharedByManager" || campaignAnswer.status === "SharedByBoth") &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.save")}
                            submit={() => {
                                dispatch(updateCampaignAnswerDetails(
                                    {
                                        id: campaignAnswer.id,
                                        collaborator: campaignAnswer.collaborator,
                                        status: campaignAnswer.status,
                                        answer_collaborator: {template: collaboratorAnswers as  [Title | Information | Text | Textarea | SelectTemplate | MultipleChoice | BooleanTemplate | Integer | Star]} ,
                                    }
                                ))
                            }}
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                    }
                    {(campaignAnswer.status === "Draft" || campaignAnswer.status === "SharedByManager") &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.share")}
                            submit={() => {
                                dispatch(updateCampaignAnswerDetails(
                                    {
                                        id: campaignAnswer.id,
                                        collaborator: campaignAnswer.collaborator,
                                        status: "SharedByCollaborator",
                                        answer_collaborator: {template: collaboratorAnswers as  [Title | Information | Text | Textarea | SelectTemplate | MultipleChoice | BooleanTemplate | Integer | Star | GoalTemplate | FutureGoalTemplate | PastTraining | FutureTraining | SkillTemplate]} ,
                                    }
                                ))
                            }}
                            icon={shareLogo}
                            iconHover={shareHoverLogo}/>
                    }
                    {campaignAnswer.status === "ValidatedByManager" &&
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.validate")}
                            submit={() => {
                                dispatch(updateCampaignAnswerDetails(
                                    {
                                        id: campaignAnswer.id,
                                        collaborator: campaignAnswer.collaborator,
                                        status: "ValidatedByBoth",
                                        answer_collaborator: {template: collaboratorAnswers as  [Title | Information | Text | Textarea | SelectTemplate | MultipleChoice | BooleanTemplate | Integer | Star | GoalTemplate | FutureGoalTemplate | PastTraining | FutureTraining | SkillTemplate]} ,
                                    }
                                ))
                            }}
                            icon={validateHoverLogo}
                            iconHover={validateLogo}/>
                    }
                    <Button
                        classCss={"primary-button"}
                        content={t("buttons.download")}
                        submit={() => {getPDFData()}}
                        icon={downloadLogo}
                        iconHover={downloadHoverLogo}/>
                </div>
            </section>
        </>
    );
}

export default CampaignAnswerDetails;


