import * as React from "react";
import {ImprovementArea} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";

interface ImprovementAreaProps {
    improvementArea: ImprovementArea
    changeHandler: Function
    readonly?: boolean
}

const ImprovementAreaComponent: React.SFC<ImprovementAreaProps> = ({improvementArea, readonly, changeHandler}) => {
    const { t } = useTranslation();
    const [improvement, setImprovement] = useState<string>(improvementArea.improvement)
    const [meansOfAction, setMeansOfAction] = useState<string>(improvementArea.meansOfAction)
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date(JSON.parse(improvementArea.expectedDate)));

    useEffect(() => {
        let newAnswer = {
            improvement: improvement,
            meansOfAction: meansOfAction,
            expectedDate: JSON.stringify(completionDate),
        } as ImprovementArea
        changeHandler(newAnswer)
    }, [improvement, meansOfAction, completionDate])

    return (
        <>
            <label htmlFor="improvement">{t("skill.improvement")}</label>
            <input
                type="text"
                name="improvement"
                defaultValue={improvement}
                readOnly={readonly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setImprovement(event.target.value)
                }}/>
            <div className={styles.goalLine}>
                <div className={styles.goalLine_firstItem}>
                    <label htmlFor="meansOfAction">{t("skill.meansOfAction")}</label>
                    <input
                        type="text"
                        name="meansOfAction"
                        defaultValue={meansOfAction}
                        readOnly={readonly}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setMeansOfAction(event.target.value)
                        }}/>
                </div>
                <div className={styles.goalLine_secondItem}>
                    <label>{t("skill.date")}</label>
                    <div className={styles.DateInput}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                openTo="month"
                                readOnly={readonly}
                                views={['year', 'month', 'day']}
                                value={completionDate}
                                onChange={(newDate) => {
                                    setCompletionDate(newDate);
                                }}
                                renderInput={(params) => {
                                    let newParams = {...params}
                                    if(newParams.inputProps){
                                        newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                    }
                                    return(<TextField {...newParams}/>)}}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ImprovementAreaComponent;