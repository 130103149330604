import {Conversation} from "../interfaces/conversation.interface";

export const ConversationAdapter = (conversation: any, direction: "in" | "out") => {
    if (direction === "out"){
        let adaptedConversation = conversation
        if (conversation.status == "Draft"){
            adaptedConversation.status = "DRF"
        } else if (conversation.status == "Shared") {
            adaptedConversation.status = "SHD"
        }
        if (conversation.type == "milestone"){
            adaptedConversation.conversation_type = "MLS"
        } else if (conversation.type == "monthly"){
            adaptedConversation.conversation_type = "MTH"
        } else if (conversation.type == "quarterly"){
            adaptedConversation.conversation_type = "QTY"
        } else if (conversation.type == "annually"){
            adaptedConversation.conversation_type = "ANL"
        }
        adaptedConversation.collaborator_from = conversation.author
        adaptedConversation.collaborator_to = conversation.collaborator
        adaptedConversation.completion_date = JSON.parse(conversation.completionDate)
        return adaptedConversation
    } else {
        let adaptedConversation = conversation as Conversation
        if (conversation.status == "DRF"){
            adaptedConversation.status = "Draft"
        } else if (conversation.status == "SHD") {
            adaptedConversation.status = "Shared"
        }
        if (conversation.conversation_type == "MLS"){
            adaptedConversation.type = "milestone"
        } else if (conversation.conversation_type == "MTH"){
            adaptedConversation.type = "monthly"
        } else if (conversation.conversation_type == "QTY"){
            adaptedConversation.type = "quarterly"
        } else if (conversation.conversation_type == "ANL"){
            adaptedConversation.type = "annually"
        }
        adaptedConversation.author = conversation.collaborator_from
        adaptedConversation.collaborator = conversation.collaborator_to
        adaptedConversation.completionDate = JSON.stringify(conversation.completion_date)
        return adaptedConversation
    }
}

export const ConversationAdapterList = (conversations: any, direction: "in" | "out") => {
    conversations.forEach((conversation: any) => ConversationAdapter(conversation, direction))
    if (direction === "in"){
        return conversations as Conversation[]
    } else {
        return conversations
    }
}