import { useTranslation } from "react-i18next";
import styles from "./hrCampaignList.module.css";
import sharingEmptyStage from "../assets/sharing-emptystage.png";
import dots from "../assets/dots.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import * as React from "react";
import { useEffect, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HeaderLogo from "../assets/campaign-header-logo.svg";
import {CampaignParameters, Campaign} from "../interfaces/campaign.interface";
import {getAllCampaigns} from "../store/reducers/campaignsReducer";
import HRCampaignCard from "./HRCampaignCard";

interface ParametersProps {
    parameters: CampaignParameters
}

export const HRCampaignsList: React.SFC<ParametersProps> = ({ parameters }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getAllCampaigns(parameters));
    }, [dispatch]);

    const campaigns = useSelector((state: RootState) => state.campaigns);
    const campaignsList = campaigns.campaigns;
    const campaignsOffset = campaigns.offset;
    const campaignsCount = campaigns.count;

    const [offset, setOffset] = useState(campaignsOffset);
    const limit : number  = parameters.limit || 8;

    useEffect(() => {
        setOffset(campaigns.offset)
    }, [campaigns])

    return (
        <div className={styles.List}>
            <div className={styles.pageTitle}>
                <div className={styles.pageTitle}>
                    <img src={HeaderLogo} className={styles.conversationIcon}/>
                    <h1>
                        {t("common.campaign")}
                    </h1>
                </div>
            </div>
            {campaignsCount > 0
                ?
                <>
                    <div className={styles.Header}>
                        <div className={styles.headerTitle}>{t("common.title")}<img src={dots} /></div>
                        <div className={styles.headerStat}>{t("common.draft")}<img src={dots} /></div>
                        <div className={styles.headerStat}>{t("common.shared")}<img src={dots} /></div>
                        <div className={styles.headerStat}>{t("common.validated")}<img src={dots} /></div>
                        <div className={styles.headerDate}>{t("common.deadline")}<img src={dots} /></div>
                        <div className={styles.headerStatus}>{t("common.status")}<img src={dots} /></div>
                    </div>
                    <div className={styles.Sharing}>
                        {campaignsList.map(
                            (campaign: Campaign, index) =>
                                <HRCampaignCard
                                    key={index}
                                    campaign={campaign}
                                />
                        )}
                    </div>
                    <div className={styles.EntryPagination}>
                        {offset + 1} - {offset + limit <= campaignsCount ? offset + limit : campaignsCount} {t("common.on")} {campaignsCount}
                        {offset != 0
                            ? <ChevronLeftIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset-limit
                                        dispatch(getAllCampaigns(newParameters))
                                    }
                                }/>
                            : <ChevronLeftIcon/>
                        }
                        {offset + limit < campaignsCount
                            ? <ChevronRightIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset+limit
                                        dispatch(
                                            getAllCampaigns(newParameters)
                                        )
                                    }
                                }/>
                            : <ChevronRightIcon/>
                        }
                    </div>
                </>
                :
                <div className={styles.EmptyStage}>
                    <img src={sharingEmptyStage} />
                    <p>{t("campaign.emptyList")}</p>
                </div>
            }
        </div>
    );
}