import api from "../utils/http-common"


export default class NotificationsServices {
    getAllNotifications(token: string){
        return api.get("/notifications/", {headers: {Authorization: `Bearer ${token}`}})
    }
    deleteAllNotifications(token: string){
        return api.get("/notifications/?clear_all=True", {headers: {Authorization: `Bearer ${token}`}})
    }
    deleteNotification(token: string, id: number){
        return api.delete("/notifications/" + id, {headers: {Authorization: `Bearer ${token}`}})
    }
}