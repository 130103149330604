import { Sidebar } from "../components/sidebar";
import * as React from "react";
import styles from './viewManagerConversation.module.css';
import HeaderManager from "../components/header/headerManager";
import {ManagerPerformanceReviewsList} from "../components/ManagerPerformanceReviewsList";


export default function AllManagerPerformanceReviews() {
    return (
        <>
            <Sidebar />
            <main>
                <HeaderManager/>
                <section className={styles.section} >
                    <ManagerPerformanceReviewsList parameters={{ limit: 8}} />
                </section>
            </main>
        </>
    );
}