import CircularProgress from '@mui/material/CircularProgress';
import DonutProps from '../interfaces/donut.interface'
import {useEffect, useRef, useState} from "react";

const Donut: React.SFC<DonutProps> = ({
                                          value,
                                          size,
                                          classCss,
                                      }) => {

    const donutRef = useRef<HTMLElement | null>(null);
    const [width, setWidth] = useState<number>(0);

    const resize = () => {
        if(donutRef.current){
            const newWidth = donutRef.current.clientHeight;
            setWidth(newWidth);
        }
    };

    useEffect(() => {
        resize()
    }, [donutRef]);

    useEffect(() => {
        window.addEventListener("resize", resize);
    }, []);

    let leftProperty = "calc(50% - " + width/2 + "px)"

    return (
        <CircularProgress
            ref={donutRef}
            style={{width:width, left:leftProperty}}
            size={`${size}%`}
            value={value}
            thickness={6}
            variant="determinate"
            color="inherit"
            className={classCss}
        />
    );
};
export default Donut;