import * as React from 'react';
import { useTranslation } from "react-i18next";
import styles from './header.module.css';
import NavMenu from "./navMenu";
import titleIcon from "../../assets/title-icon.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface headerProps {
    title?: string
}

const Header: React.SFC<headerProps> = ({ title }) => {
    const { t } = useTranslation();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)

    return (
        <header className={styles.header}>
            {title !== undefined &&
                <h1>
                    {title}
                    <img src={titleIcon} />
                </h1>
            }
            {title === undefined &&
                <h1>
                    {t("dashboard.welcome", { name: activeUser.firstName })}
                    <img src={titleIcon} />
                </h1>
            }
            <NavMenu managerHeader={false} hrHeader={false}/>
        </header>
    );
}
export default Header;