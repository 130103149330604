import {PerformanceReview} from "../interfaces/performanceReview.interface";

export const PerformanceReviewAdapter = (performanceReview: any, direction: "in" | "out") => {
    if (direction === "out"){
        let adaptedPerformanceReview = performanceReview
        if (performanceReview.status === "Draft"){
            adaptedPerformanceReview.status = "DRF"
        } else if (performanceReview.status === "Shared") {
            adaptedPerformanceReview.status = "SHD"
        }
        if (performanceReview.evaluation === "Above Expectations"){
            adaptedPerformanceReview.evaluation = "ABOVE"
        } else if (performanceReview.evaluation === "Meets Expectations") {
            adaptedPerformanceReview.evaluation = "AVERAGE"
        } else if (performanceReview.evaluation === "Below Expectations") {
            adaptedPerformanceReview.evaluation = "BELOW"
        }


        return adaptedPerformanceReview
    } else {
        let adaptedPerformanceReview = performanceReview as PerformanceReview
        if (performanceReview.status === "DRF"){
            adaptedPerformanceReview.status = "Draft"
        } else if (performanceReview.status === "SHD") {
            adaptedPerformanceReview.status = "Shared"
        }
        if (performanceReview.evaluation === "ABOVE"){
            adaptedPerformanceReview.evaluation = "Above Expectations"
        } else if (performanceReview.evaluation === "AVERAGE") {
            adaptedPerformanceReview.evaluation = "Meets Expectations"
        } else if (performanceReview.evaluation === "BELOW") {
            adaptedPerformanceReview.evaluation = "Below Expectations"
        }

        return adaptedPerformanceReview
    }
}

export const PerformanceReviewAdapterList = (performanceReviews: any, direction: "in" | "out") => {
    performanceReviews.forEach((conversation: any) => PerformanceReviewAdapter(conversation, direction))
    if (direction === "in"){
        return performanceReviews as PerformanceReview[]
    } else {
        return performanceReviews
    }
}