import * as React from "react";
import {FutureGoalTemplate} from "../../interfaces/campaignAnswer.interface"
import styles from "../goaldetails.module.css";
import {useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";

interface FutureGoalTemplateProps {
    answer: FutureGoalTemplate
    changeHandler: Function
    readonly?: boolean
}

const FutureGoalTemplateComponent: React.SFC<FutureGoalTemplateProps> = ({answer, readonly, changeHandler}) => {
    const { t } = useTranslation();
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date(JSON.parse(answer.completionDate)));
    const [title, setTitle] = useState<string>(answer.title)
    const [kpi, setKpi] = useState<string>(answer.kpi)
    const [weighting, setWeighting] = useState<number>(answer.weighting)
    const [meansOfAction, setMeansOfAction] = useState<string>(answer.meansOfAction)
    const [comment, setComment] = useState<string>(answer.comment)

    useEffect(() => {
        let newAnswer = {
            comment: comment,
            completionDate: JSON.stringify(completionDate),
            kpi: kpi,
            position: answer.position,
            meansOfAction: meansOfAction,
            title: title,
            weighting: weighting,
            type: answer.type
        } as FutureGoalTemplate
        changeHandler(newAnswer)
    }, [completionDate, title, meansOfAction, kpi, comment, weighting])

    return (
        <div className={styles.titleInput + " " + styles.goalTemplate}>
            <h4>{t("common.goal")}</h4>
            <div className={styles.goalLine}>
                <div className={styles.goalLine_firstItem}>
                    <label htmlFor="title">{t("goal.title")}</label>
                    <input
                        type="text"
                        name="title"
                        defaultValue={title}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setTitle(event.target.value)
                        }}/>
                </div>
                <div className={styles.goalLine_secondItem}>
                    <label htmlFor="weighting">{t("goal.weighting")}</label>
                    <input
                        type="number"
                        name="weighting"
                        defaultValue={weighting}
                        min="0"
                        max="100"
                        step="5"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setWeighting(parseInt(event.target.value))
                        }}/>
                </div>
            </div>
            <label htmlFor="kpi">{t("goal.kpi")}</label>
            <input
                type="text"
                name="kpi"
                defaultValue={kpi}
                readOnly={readonly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setKpi(event.target.value)
                }}/>
            <label htmlFor="realisation">{t("goal.meansOfAction")}</label>
            <textarea
                name="realisation"
                defaultValue={meansOfAction}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setMeansOfAction(event.target.value)
                    }}>
            </textarea>
            <label htmlFor="comment">{t("goal.comment")}</label>
            <textarea
                name="comment"
                defaultValue={comment}
                readOnly={readonly}
                onChange={
                    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setComment(event.target.value)
                    }}>
            </textarea>
            <label>{t("goal.date")}</label>
            <div className={styles.DateInput}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disablePast
                        openTo="month"
                        readOnly={readonly}
                        views={['year', 'month', 'day']}
                        value={completionDate}
                        onChange={(newDate) => {
                            setCompletionDate(newDate);
                        }}
                        renderInput={(params) => {
                            let newParams = {...params}
                            if(newParams.inputProps){
                                newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                            }
                            return(<TextField {...newParams}/>)}}
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
};
export default FutureGoalTemplateComponent;