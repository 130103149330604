import api from "../utils/http-common"
import {CampaignParameters} from "../interfaces/campaign.interface";

export default class CampaignServices {
    getAllCampaigns(token: string, parameters: CampaignParameters) {
        let url: string = "/campaigns/?";
        for (const [key, value] of Object.entries(parameters)) {
            url += `${key}=${value}&`
        }
        return api.get(url, {headers: {Authorization: `Bearer ${token}`}})
    }
    getCampaignDetails(token: string, campaignId: string) {
        return api.get("/campaigns/" + campaignId, {headers: {Authorization: `Bearer ${token}`}})
    }
    downloadCampaignAnswers(token: string, campaignId: string) {
        return api.get("/answers/pdf?display=download&campaign=" + campaignId, {
            headers: {Authorization: `Bearer ${token}`},
            responseType: "arraybuffer"
        })
    }
}