import { useState } from 'react';
import Button from './button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import styles from "./popUp.module.css";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import addLogo from "../assets/page-add.svg";
import addHoverLogo from "../assets/page-add-hover.svg";
import success from "../assets/experienceSuccess.png";
import { Navigate } from 'react-router-dom';
import closeModal from "../assets/close-modal.svg";

interface popUpProps {
    visible: boolean;
    closeHandler: Function;
}

const PopUpSharedExperience: React.SFC<popUpProps> = ({ visible, closeHandler }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(visible);
    const [loading, setLoading] = useState<boolean>(true);

    if (open !== visible) {
        setOpen(visible);
    }

    const handleClose = () => {
        closeHandler();
    };

    const saveAndCloseHandler = (direction: string) => {
        closeHandler(direction);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={"popUp"}
            >
                <img className={styles.closeModal} src={closeModal} onClick={handleClose}/>
                <DialogTitle id="alert-dialog-title">
                    <figure className={styles.figure}>
                        <img src={success} onLoad={() => setLoading(false)} />
                    </figure>
                    {t("experience.successTitle")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("experience.successPhrase")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={styles.popUpButtons}>
                    <Button
                        classCss="secondary-button"
                        content={t("buttons.addGoal")}
                        submit={() => { saveAndCloseHandler("newGoal") }}
                        icon={addLogo}
                        iconHover={addHoverLogo} />
                    <Button
                        classCss="primary-button"
                        content={t("buttons.addAnotherExperience")}
                        submit={() => { saveAndCloseHandler("newExp") }}
                        icon={addHoverLogo}
                        iconHover={addLogo} />
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default PopUpSharedExperience;