import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {AppDispatch, RootState} from "../store/store";
import DashboardManager from "./dashboardManager";
import {Sidebar} from "../components/sidebar";
import {getTalentDetails} from "../store/reducers/teamReducer";
import TalentDetails from "../components/talentDetails";
import * as React from "react";


export default function ViewTalentDetails() {
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.team.status);
    const [checkCall, setCheckCall] = useState<boolean>(false);

    if(!checkCall){
        dispatch(getTalentDetails(params.talentId!));
        setCheckCall(true);
    }

    useEffect(() => {
        setCheckCall(false)
    }, [dispatch]);

    //duplicate the useEffect because of a bug in production if with one useEffect and the two deps (dispatch and params)
    useEffect(() => {
        setCheckCall(false)
    }, [params]);

    const talent = useSelector((state: RootState) => state.team.memberDetails);
    if (status != "error" && talent) {
        return (
            <>
                <Sidebar />
                <TalentDetails talent={talent}/>
            </>
        );
    } else {
        return <DashboardManager />;
    }
}