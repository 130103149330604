import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Button from '../components/button';
import styles from './addgoal.module.css';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {createConversation} from "../store/reducers/conversationsReducer";
import MenuItem from "@mui/material/MenuItem";
import Collaborator from "../interfaces/collaborator.interface";
import {Sidebar} from "../components/sidebar";
import * as React from "react";
import CustomAlerts from "../components/customAlerts";
import PopUpSharedConversation from "../components/PopUpSharedConversation";
import HeaderLogo from "../assets/feedback-header-logo.svg";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import validateLogo from "../assets/page-validate.svg";
import validateHoverLogo from "../assets/page-validate-hover.svg";
import shareLogo from "../assets/page-share.svg";
import shareHoverLogo from "../assets/page-share-hover.svg";
import {Navigate} from "react-router-dom";
import {getTeamMembers} from "../store/reducers/teamReducer";
import HeaderManager from "../components/header/headerManager";
import CollaboratorSelectOption from "../components/collaboratorSelectOption";


export default function AddConversation() {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>('');
    const [details, setDetails] = useState<string>('');
    const [type, setType] = useState<string>(' ');
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date());
    const [collaborator, setCollaborator] = useState<number>(0);
    const [redirect, setRedirect] = useState<string>("");
    const conversationStatus = useSelector((state: RootState) => state.conversations.status);
    const errors = useSelector((state: RootState) => state.conversations.error);
    const [openPopUp, setOpenPopUp] = useState(false);

    const handleCollaboratorChange = (event: SelectChangeEvent) => {
        setCollaborator(parseInt(event.target.value));
    };

    const handleTypeChange = (event: SelectChangeEvent) => {
        setType(event.target.value);
    };

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getTeamMembers())
    }, [dispatch])
    const collaborators = useSelector((state: RootState) => state.team.members);

    if(conversationStatus === "success") {
        if(redirect === "newConversation") {
            window.location.reload();
        } else if(redirect === "return"){
            window.history.back();
        } else if(redirect === "newGoal"){
            return <Navigate to="/manager/goals/new"/>;
        }
    }
    if(redirect === "close"){
        window.history.back();
    }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if (direction) {
            setRedirect(direction);
        } else {
            setRedirect("return");
        }
    }

    return (
        <>
            <Sidebar />
            <main>
                <HeaderManager title={t("conversation.h1")}/>
                <section className={styles.modalForm}>
                    <header>
                    <img src={HeaderLogo}></img>
                    <h1>{t("common.conversation")}</h1>
                    </header>
                    <form className={styles.form}>
                        <label htmlFor="person-label">{t("conversation.person")}</label>
                        <div className={styles.Input}>
                            <Select
                                labelId="person-label"
                                id="person-select"
                                value={collaborator.toString()}
                                label="Person"
                                onChange={handleCollaboratorChange}
                                renderValue={collaborator !== 0 ? undefined : () => <span className={styles.placeholder}>{t("conversation.selectTalent")}</span>}
                            >
                                {
                                    collaborators.map(
                                        (collaborator: Collaborator) => {
                                            return <MenuItem key={collaborator.id} value={collaborator.id}>
                                                        <CollaboratorSelectOption collaborator={collaborator} displayName={true}/>
                                                    </MenuItem>
                                        }
                                    )
                                }
                            </Select>
                        </div>
                        {conversationStatus === "error" && errors.collaborator_to &&
                            <CustomAlerts errors={[t("conversation.collaboratorError")]}/>
                        }
                        <label htmlFor="type-label">{t("conversation.type")}</label>
                        <div className={styles.Input}>
                            <Select
                                labelId="type-label"
                                id="type-select"
                                value={type}
                                label="Type"
                                onChange={handleTypeChange}
                                renderValue={type !== " " ? undefined : () => <span className={styles.placeholder}>{t("conversation.selectType")}</span>}
                            >
                                <MenuItem key={"monthly"} value={"monthly"}>{t("conversation.monthly")}</MenuItem>
                                <MenuItem key={"quarterly"} value={"quarterly"}>{t("conversation.quarterly")}</MenuItem>
                                <MenuItem key={"annually"} value={"annually"}>{t("conversation.annually")}</MenuItem>
                                <MenuItem key={"milestone"} value={"milestone"}>{t("conversation.milestone")}</MenuItem>
                            </Select>
                        </div>
                        {conversationStatus === "error" && errors.conversation_type &&
                            <CustomAlerts errors={errors.conversation_type}/>
                        }
                        <div className={styles.titleInput}>
                            <label htmlFor="title">{t("conversation.title")}</label>
                            <input
                                name="title"
                                type="text"
                                placeholder={t("conversation.placeholder")}
                                onChange={
                                    (e) => setTitle(e.target.value)
                                }>
                            </input>
                        </div>
                        {conversationStatus === "error" && errors.title &&
                            <CustomAlerts errors={errors.title}/>
                        }
                        <label htmlFor="conversation">{t("conversation.content")}</label>
                        <textarea
                            name="conversation"
                            placeholder={t("conversation.placeholderDetails")}
                            onChange={
                                (e) => setDetails(e.target.value)
                            }>
                        </textarea>
                        {conversationStatus === "error" && errors.details &&
                            <CustomAlerts errors={errors.details}/>
                        }
                        <label>{t("conversation.date")}</label>
                        <div className={styles.DateInput}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disableFuture
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={completionDate}
                                    onChange={(newDate) => {
                                        setCompletionDate(newDate);
                                    }}
                                    renderInput={(params) => {
                                        let newParams = {...params}
                                        if(newParams.inputProps){
                                            newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                        }
                                        return(<TextField {...newParams}/>)}}
                                />
                            </LocalizationProvider>
                        </div>
                        {conversationStatus === "error" && errors.completion_date &&
                            <CustomAlerts errors={errors.completion_date}/>
                        }
                    </form>
                    <div className={styles.buttonContainer}>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.close")}
                            submit={
                                () => {
                                    setRedirect("close")
                                }
                            }
                            icon={closeLogo}
                            iconHover={closeHoverLogo}/>
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.save")}
                            submit={() => {
                                dispatch(
                                    createConversation({
                                        title,
                                        type,
                                        status: "Draft",
                                        details,
                                        collaborator,
                                        completionDate: JSON.stringify(completionDate)
                                    })
                                )
                                setRedirect("return")
                            }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo}/>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.share")}
                            submit={() => {
                                dispatch(
                                    createConversation({
                                        title,
                                        type,
                                        status: "Shared",
                                        details,
                                        collaborator,
                                        completionDate: JSON.stringify(completionDate)
                                    })
                                )
                                setOpenPopUp(true)
                            }
                            }
                            icon={shareLogo}
                            iconHover={shareHoverLogo}/>
                            {conversationStatus !== "error" &&
                                <PopUpSharedConversation
                                    visible={openPopUp}
                                    closeHandler={HandleClosePopUp} />
                            }
                    </div>
                </section>
            </main>
        </>
    );
}