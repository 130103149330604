import * as React from "react";
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface EvaluationProps {
    evaluation: {
        criteria: string,
        indicator: string,
    }
    changeHandler: Function
    readonly?: boolean
}

const EvaluationComponent: React.SFC<EvaluationProps> = ({evaluation, readonly, changeHandler}) => {
    const { t } = useTranslation();
    const [criteria, setCriteria] = useState<string>(evaluation.criteria)
    const [indicator, setIndicator] = useState<string>(evaluation.indicator)

    useEffect(() => {
        let newAnswer = {
            criteria: criteria,
            indicator: indicator,
        }
        changeHandler(newAnswer)
    }, [criteria, indicator])

    return (
        <div className={styles.goalLine}>
            <div className={styles.goalLine_firstItem}>
                <label htmlFor="criteria">{t("goal.criteria")}</label>
                <input
                    type="text"
                    name="criteria"
                    defaultValue={criteria}
                    readOnly={readonly}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setCriteria(event.target.value)
                    }}/>
            </div>
            <div className={styles.goalLine_secondItem}>
                <label htmlFor="indicator">{t("goal.indicator")}</label>
                <input
                    type="text"
                    name="indicator"
                    defaultValue={indicator}
                    readOnly={readonly}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setIndicator(event.target.value)
                    }}/>
            </div>
        </div>
    );
};
export default EvaluationComponent;