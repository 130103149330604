import { Sidebar } from "../components/sidebar";
import * as React from "react";
import styles from './viewManagerConversation.module.css';
import { ManagerGoalsList } from "../components/ManagerGoalsList";
import HeaderManager from "../components/header/headerManager";

export default function AllManagerGoals() {
    return (
        <>
            <Sidebar />
            <main>
                <HeaderManager/>
                <section className={styles.section} >
                    <ManagerGoalsList parameters={{ limit: 8, deleted: "False" }} />
                </section>
            </main>
        </>
    );
}