import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import Header from '../components/header/header';
import {AppDispatch, RootState} from "../store/store";
import Dashboard from "./dashboard";
import {useEffect} from "react";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {PerformanceReview} from "../interfaces/performanceReview.interface";
import {getPerformanceReviewDetails} from "../store/reducers/performanceReviewsReducer";
import PerformanceReviewDetails from "../components/performanceReviewDetails";

export default function ViewPerformanceReviewDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.performanceReviews.status);

    useEffect(() => {
        if(params.performanceReviewId){
            dispatch(getPerformanceReviewDetails(params.performanceReviewId));
        }
    }, [dispatch]);
    const performanceReview = useSelector((state: RootState) => state.performanceReviews.performanceReviews.find((performanceReview : PerformanceReview) => performanceReview.id == params.performanceReviewId));

    if (status != "error") {
        return (
            <>
                <SidebarTalent />
                <main>
                    <Header title={t("conversation.h1")}/>
                    {performanceReview &&
                        <PerformanceReviewDetails performanceReview={performanceReview}/>
                    }
                </main>
            </>
        );
    } else {
        return <Dashboard />;
    }
}