import { Fragment } from "react";

const CustomAlerts: React.SFC<{errors: [string]}> = ({errors}) => {
        return (
            <Fragment>
                { errors.map(
                    (error: string) =>
                        <span key={error} className={"error"}>
                            {error}
                        </span>
                )}
            </Fragment>
        );
    }

export default CustomAlerts