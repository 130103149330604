import {useSelector} from "react-redux";
import { RootState} from "../store/store";
import Dashboard from "./dashboard";
import DashboardManager from "./dashboardManager";
import DashboardHR from "./dashboardHR";
import AllManagerCampaignAnswers from "./allManagerCampaignAnswers";
import AllCampaignAnswers from "./allCampaignAnswers";
import Login from "./Login";
import AllHRCampaigns from "./allHRCampaigns";

export default function Homepage() {
    let activeUser = useSelector((state: RootState) => state.activeUser.activeUser)
    if(activeUser){
        if(activeUser.modules_choices){
            if (activeUser.modules_choices.includes("all")){
                if (activeUser.isHR) {
                    return <DashboardHR/>
                }
                else if (activeUser.isManager) {
                    return <DashboardManager/>
                }
                else {
                    return <Dashboard/>;
                }
            } else if (activeUser.modules_choices.includes("campaign")){
                if (activeUser.isHR) {
                    return <AllHRCampaigns/>
                } else if (activeUser.isManager) {
                    return <AllManagerCampaignAnswers/>
                } else {
                    return <AllCampaignAnswers/>
                }
            }
        }
    }

    return <Login/>;
}