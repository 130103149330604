import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Link } from 'react-router-dom';
import styles from "./goalcard.module.css";
import { Conversation } from '../interfaces/conversation.interface';
import ModeIcon from "@mui/icons-material/Mode";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface ConversationProps {
    conversation: Conversation,
}

const ConversationCard: React.SFC<ConversationProps> = ({ conversation }) => {
    const { t } = useTranslation();
    let status = "Draft"
    if (conversation.status) {
        status = "common." + conversation.status.toLowerCase()
    }
    let date = new Date()
    if (conversation.last_modified) {
        date = new Date(conversation.last_modified)
    } else if (conversation.completionDate) {
        date = new Date(JSON.parse(conversation.completionDate))
    }

    return (
        <Fragment>
            <li>
                <a href={"/conversations/" + conversation.id} className={styles.LineLink}>
                    <div className={styles.Title}>
                        <p>{conversation.title}</p>
                    </div>
                    <div className={styles.Status}>
                        <div className={conversation.status}></div>
                        {t(status)}
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Actions}>
                        <Link to={"/conversations/" + conversation.id} className={styles.link}>
                            {conversation.status === "Draft" &&
                                <ModeIcon />
                            }
                            {conversation.status != "Draft" &&
                                <VisibilityIcon />
                            }
                        </Link>
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default ConversationCard;