import styles from "./progress.module.css";
import {Fragment} from "react";

interface ProgressProps {
    progression: number,
}


const Progress: React.SFC<ProgressProps> = ({progression}) => {


    return (
            <Fragment>
                <div className={styles.progress}>
                    <span>{progression.toFixed(0)}%</span>
                    <progress max="100" value={progression}></progress>
                </div>
            </Fragment>
        )
};
export default Progress;