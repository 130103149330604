import { useTranslation } from "react-i18next";
import styles from "./managerConversationsList.module.css";
import sharingEmptyStage from "../assets/sharing-emptystage.png";
import dots from "../assets/dots.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import * as React from "react";
import { useEffect, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link, useParams } from "react-router-dom";
import { ToolTips } from "./toolTips";
import { Goal, GoalParameters } from "../interfaces/goal.interface";
import goalIconPage from '../assets/goal-header-logo.svg';
import newIcon from '../assets/new-white.svg'
import newIconHover from '../assets/new-orange.svg'
import { getAllManagerGoals } from "../store/reducers/goalsReducer";
import ManagerGoalCard from "./ManagerGoalsCard";

interface ParametersProps {
    parameters: GoalParameters
}

export const ManagerGoalsList: React.SFC<ParametersProps> = ({ parameters }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getAllManagerGoals(parameters));
    }, [dispatch]);

    const goals = useSelector((state: RootState) => state.goals);
    const goalsList = goals.goals;
    const goalsOffset = goals.offset;
    const goalsCount = goals.count;

    const [offset, setOffset] = useState(goalsOffset);
    const limit : number  = parameters.limit || 8;

    useEffect(() => {
        setOffset(goals.offset)
    }, [goals])



    return (
        <div className={styles.List} id="Goal">
            <div className={styles.pageTitle}>
                <img src={goalIconPage} className={styles.goalIcon}/>
                <h1>
                    {t("common.goals")}
                    <ToolTips text={t("goal.tooltips")} parent={document.getElementById("Goal")}/>
                </h1>
                <Link to="/manager/goals/new" className={styles.addButton}>
                    <img src={newIcon} className={styles.addButtonIcon}/>
                    <img src={newIconHover} className={styles.addButtonIconHover}/>
                    {t("buttons.add")}
                </Link>
            </div>
            {goalsCount > 0
                ?
                <>
                    <div className={styles.Header}>
                        <div className={styles.headerTalent}>{t("common.talent")}<img src={dots} /></div>
                        <div className={styles.headerTitle}>{t("common.title")}<img src={dots} /></div>
                        <div className={styles.headerDate}>{t("common.date")}<img src={dots} /></div>
                        <div className={styles.headerStatus}>{t("common.status")}<img src={dots} /></div>
                    </div>
                    <div className={styles.Sharing}>
                        {goalsList.map(
                            (goal: Goal, index) =>
                                <ManagerGoalCard
                                    key={index}
                                    goal={goal}
                                />
                        )}
                    </div>
                    <div className={styles.EntryPagination}>
                        {offset + 1} - {offset + limit <= goalsCount ? offset + limit : goalsCount} {t("common.on")} {goalsCount}
                        {offset != 0
                            ? <ChevronLeftIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset-limit
                                        dispatch(getAllManagerGoals(newParameters))
                                    }
                                }/>
                            : <ChevronLeftIcon/>
                        }
                        {offset + limit < goalsCount
                            ? <ChevronRightIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset+limit
                                        dispatch(
                                            getAllManagerGoals(newParameters)
                                        )
                                    }
                                }/>
                            : <ChevronRightIcon/>
                        }
                    </div>
                </>
                :
                <div className={styles.EmptyStage}>
                    <img src={sharingEmptyStage} />
                    <p>{t("goal.emptyList")}</p>
                </div>
            }
        </div>
    );
}