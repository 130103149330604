import api from "../utils/http-common"
import {CampaignAnswer, CampaignAnswerParameters} from "../interfaces/campaignAnswer.interface";
import {CampaignAnswerAdapter} from "../adapters/campaignAnswers.adapter";

export default class CampaignAnswerServices {
    getAllCampaignAnswers(token: string) {
        return api.get("/answers/", {headers: {Authorization: `Bearer ${token}`}})
    }

    getCampaignAnswerDetails(token: string, campaignAnswerId: string) {
        return api.get("/answers/" + campaignAnswerId, {headers: {Authorization: `Bearer ${token}`}})
    }

    updateCampaignAnswerDetails(token: string, campaignAnswer: CampaignAnswer) {
        return api.put("/answers/" + campaignAnswer.id, CampaignAnswerAdapter(campaignAnswer, "out"), {headers: {Authorization: `Bearer ${token}`}})
    }

    getAllManagerCampaignAnswers(token: string, parameters: CampaignAnswerParameters) {
        let url: string = "/manager/answers/?";
        for (const [key, value] of Object.entries(parameters)) {
            url += `${key}=${value}&`
        }
        return api.get(url, {headers: {Authorization: `Bearer ${token}`}})
    }

    getManagerCampaignAnswerDetails(token: string, campaignAnswerId: string) {
        return api.get("/manager/answers/" + campaignAnswerId, {headers: {Authorization: `Bearer ${token}`}})
    }

    updateManagerCampaignAnswerDetails(token: string, campaignAnswer: CampaignAnswer) {
        return api.put("/manager/answers/" + campaignAnswer.id, CampaignAnswerAdapter(campaignAnswer, "out"), {headers: {Authorization: `Bearer ${token}`}})
    }

    downloadCampaignAnswer(token: string, campaignAnswerId: string) {
        return api.get("/answers/pdf?display=download&answer=" + campaignAnswerId, {
            headers: {Authorization: `Bearer ${token}`},
            responseType: "arraybuffer"
        })
    }
}