import {Experience} from "../interfaces/experience.interface";

export const ExperienceAdapter = (experience: any, direction: "in" | "out") => {
    if (direction === "out"){
        let adaptedExperience = experience
        if (experience.status == "Draft"){
            adaptedExperience.status = "DRF"
        } else if (experience.status == "Shared") {
            adaptedExperience.status = "SHD"
        } else if (experience.status == "Reviewed") {
            adaptedExperience.status = "RVD"
        }
        if(adaptedExperience.goal == 0){
            delete adaptedExperience.goal
        }
        adaptedExperience.collaborator_from = experience.author
        adaptedExperience.experience_date = JSON.parse(experience.completionDate)
        return adaptedExperience
    } else {
        let adaptedExperience = experience as Experience
        if (experience.status == "DRF"){
            adaptedExperience.status = "Draft"
        } else if (experience.status == "SHD") {
            adaptedExperience.status = "Shared"
        } else if (experience.status == "RVD") {
            adaptedExperience.status = "Reviewed"
        }
        adaptedExperience.author = experience.collaborator_from
        adaptedExperience.completionDate = JSON.stringify(experience.experience_date)
        return adaptedExperience
    }
}

export const ExperienceAdapterList = (experiences: any, direction: "in" | "out") => {
    experiences.forEach((experience: any) => ExperienceAdapter(experience, direction))
    if (direction === "in"){
        return experiences as Experience[]
    } else {
        return experiences
    }
}