import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import EntryServices from "../../services/entries.service";
import {Entry, EntryParameters} from "../../interfaces/entry.interface";
import {RootState} from "../store";

const initialState = {
    status: 'idle',
    error: {} as any,
    entries : [] as Entry[],
    count: 0,
    limit: 4,
    offset: 0,
}
const entryService : EntryServices = new EntryServices();

export const getEntries = createAsyncThunk(
    'entries/getEntries',
    async (parameters: EntryParameters, thunkApi) => {
        const state = thunkApi.getState() as RootState
        let entries = {entries: [] as Entry[], count: 0, offset:0}
        try {
            const response = await entryService.getEntries(state.activeUser.activeUser.token, parameters)
            if (response.status == 200) {
                entries.entries = response.data.results
                entries.count = response.data.count
                if (parameters.offset){
                    entries.offset = parameters.offset
                }
            }
            return entries
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return thunkApi.rejectWithValue(err.response.data as any);
        }
    })

const entriesSlice = createSlice({
    name: "entries",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEntries.fulfilled, (state, action) => {
            state.entries = action.payload.entries
            state.count = action.payload.count
            state.offset = action.payload.offset
            state.status = "success"
        })
        .addCase(getEntries.pending, (state, action) => {
            state.status = "loading"
        })
        .addCase(getEntries.rejected, (state, action) => {
            state.error = action.payload!
            state.status = "error"
        })
    },
});

export default entriesSlice.reducer;