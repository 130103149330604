import { Fragment } from "react";
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Request }  from '../interfaces/request.interface';
import styles from "./goalcard.module.css";
import ModeIcon from "@mui/icons-material/Mode";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface RequestProps {
    request: Request,
}

const RequestCard: React.SFC<RequestProps> = ({request}) => {
    const { t } = useTranslation();
    const status = "common." + request.status.toLowerCase()
    let date = new Date(JSON.parse(request.completionDate))
    if(request.last_modified){
        date = new Date(request.last_modified)
    }

    return (
        <Fragment>
            <li>
                <a href={"/requests/" + request.id} className={styles.LineLink}>
                    <div className={styles.Title}>
                        <p>{request.title}</p>
                    </div>
                    <div className={styles.Status}>
                        <div className={request.status}></div>
                        {t(status)}
                    </div>
                    <div className={styles.Date}>{t("dates.completionDate", { date: date })}</div>
                    <div className={styles.Actions}>
                        <Link to={"/requests/" + request.id} className={styles.link}>
                            {request.status === "Draft" &&
                                <ModeIcon />
                            }
                            {request.status != "Draft" &&
                                <VisibilityIcon />
                            }
                        </Link>
                    </div>
                </a>
            </li>
        </Fragment>
    );
};
export default RequestCard;