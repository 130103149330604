import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import './i18n';
import { store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import {persistStore} from "redux-persist";
import setupInterceptor from "./services/token.service";
import Dashboard from './pages/dashboard';
import DashboardManager from "./pages/dashboardManager";
import AddGoal from './pages/addGoal';
import AddExperience from './pages/addExperience';
import AddFeedback from "./pages/addFeedback";
import AddRequest from "./pages/addRequest";
import AllGoals from "./pages/allGoals";
import ViewGoalDetails from './pages/ViewGoalDetails';
import AllExperiences from "./pages/allExperiences";
import ViewExperienceDetails from "./pages/ViewExperienceDetails";
import AllFeedbacks from "./pages/allFeedbacks";
import ViewFeedbackDetails from "./pages/ViewFeedbackDetails";
import AllRequests from "./pages/allRequests";
import ViewRequestDetails from "./pages/ViewRequestDetails";
import YourTeam from './pages/team';
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PasswordForgotten from "./pages/PasswordForgotten";
import PasswordReset from "./pages/PasswordReset";
import ViewProfile from "./pages/ViewProfile";
import ViewTalentDetails from "./pages/ViewTalentDetails";
import ViewManagerGoalDetails from "./pages/ViewManagerGoalDetails";
import ViewManagerExperienceDetails from "./pages/ViewManagerExperienceDetails";
import ViewManagerRequestDetails from "./pages/ViewManagerRequestDetails";
import PasswordChange from "./pages/PasswordChange";
import AllConversations from "./pages/allConversations";
import AddConversation from "./pages/addConversation";
import ViewConversationDetails from "./pages/ViewConversationDetails";
import ViewManagerConversationDetails from "./pages/ViewManagerConversationDetails";
import AllManagerConversations from "./pages/AllManagerConversations";
import AllManagerGoals from './pages/AllManagerGoals';
import AddGoalManager from "./pages/addGoalManager";
import AllManagerRequests from "./pages/AllManagerRequests";
import Support from "./components/support";
import AllManagerExperiences from "./pages/AllManagerExperiences";
import AllCampaignAnswers from "./pages/allCampaignAnswers";
import ViewCampaignAnswerDetails from "./pages/ViewCampaignAnswerDetails";
import AllManagerCampaignAnswers from "./pages/allManagerCampaignAnswers";
import ViewManagerCampaignAnswerDetails from "./pages/ViewManagerCampaignAnswerDetails";
import DashboardHR from "./pages/dashboardHR";
import SupportIVC from "./components/supportIVC";
import AllHRCampaigns from "./pages/allHRCampaigns";
import ViewTeamManagerDetails from "./pages/ViewTeamManagerDetails";
import AllManagerPerformanceReviews from "./pages/AllManagerPerformanceReviews";
import ViewManagerPerformanceReviewDetails from "./pages/ViewManagerPerformanceReviewDetails";
import ViewHRCampaignDetails from "./pages/ViewHRCampaignDetails";
import AllPerformanceReviews from "./pages/allPerformanceReviews";
import ViewPerformanceReviewDetails from "./pages/ViewPerformanceReviewDetails";
import AllHRPerformanceReviews from "./pages/AllHRPerformanceReviews";
import ViewHRPerformanceReviewDetails from "./pages/ViewHRPerformanceReviewDetails";


export let persistor = persistStore(store)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App />}/>
                    <Route path="personal-dashboard/" element={<Dashboard/>}/>
                    <Route path="goals/" element={<AllGoals/>}/>
                    <Route path="goals/new" element={<AddGoal/>}/>
                    <Route path="goals/:goalId" element={<ViewGoalDetails/>}/>
                    <Route path="experiences/" element={<AllExperiences/>}/>
                    <Route path="experiences/new" element={<AddExperience/>}/>
                    <Route path="experiences/:experienceId" element={<ViewExperienceDetails/>}/>
                    <Route path="feedbacks/" element={<AllFeedbacks/>}/>
                    <Route path="feedbacks/new" element={<AddFeedback/>}/>
                    <Route path="feedbacks/:feedbackId" element={<ViewFeedbackDetails/>}/>
                    <Route path="requests/" element={<AllRequests/>}/>
                    <Route path="requests/new" element={<AddRequest/>}/>
                    <Route path="requests/:requestId" element={<ViewRequestDetails/>}/>
                    <Route path="conversations/" element={<AllConversations/>}/>
                    <Route path="conversations/:conversationId" element={<ViewConversationDetails/>}/>
                    <Route path="performance-reviews/" element={<AllPerformanceReviews/>}/>
                    <Route path="performance-reviews/:performanceReviewId" element={<ViewPerformanceReviewDetails/>}/>
                    <Route path="campaign-answers/" element={<AllCampaignAnswers/>}/>
                    <Route path="campaign-answers/:campaignAnswerId" element={<ViewCampaignAnswerDetails/>}/>
                    <Route path="manager-dashboard/" element={<DashboardManager/>}/>
                    <Route path="team/member/:talentId" element={<ViewTalentDetails/>}/>
                    <Route path="manager/goals/" element={<AllManagerGoals/>}/>
                    <Route path="manager/goals/:goalId" element={<ViewManagerGoalDetails/>}/>
                    <Route path="manager/goals/new" element={<AddGoalManager/>}/>
                    <Route path="manager/experiences/" element={<AllManagerExperiences/>}/>
                    <Route path="manager/experiences/:experienceId" element={<ViewManagerExperienceDetails/>}/>
                    <Route path="manager/requests/" element={<AllManagerRequests/>}/>
                    <Route path="manager/requests/:requestId" element={<ViewManagerRequestDetails/>}/>
                    <Route path="manager/conversations/:conversationId" element={<ViewManagerConversationDetails/>}/>
                    <Route path="manager/conversations" element={<AllManagerConversations/>}/>
                    <Route path="manager/conversations/new" element={<AddConversation/>}/>
                    <Route path="manager/campaign-answers/" element={<AllManagerCampaignAnswers/>}/>
                    <Route path="manager/campaign-answers/:campaignAnswerId" element={<ViewManagerCampaignAnswerDetails/>}/>
                    <Route path="manager/performance-reviews" element={<AllManagerPerformanceReviews/>}/>
                    <Route path="manager/performance-reviews/:performanceReviewId" element={<ViewManagerPerformanceReviewDetails/>}/>
                    <Route path="hr-dashboard/" element={<DashboardHR/>}/>
                    <Route path="hr-dashboard/campaigns/" element={<AllHRCampaigns/>}/>
                    <Route path="hr-dashboard/campaigns/:campaignId" element={<ViewHRCampaignDetails/>}/>
                    <Route path="hr-dashboard/performance-reviews/" element={<AllHRPerformanceReviews/>}/>
                    <Route path="hr-dashboard/performance-reviews/:performanceReviewId" element={<ViewHRPerformanceReviewDetails/>}/>
                    <Route path="hr-dashboard/managers/:managerId" element={<ViewTeamManagerDetails/>}/>
                    <Route path="your-team" element={<YourTeam/>}/>
                    <Route path="login" element={<Login/>}/>
                    <Route path="signup" element={<Signup/>}/>
                    <Route path="password-forgotten" element={<PasswordForgotten/>}/>
                    <Route path="password-reset/:uid/:token" element={<PasswordReset/>}/>
                    <Route path="profile" element={<ViewProfile/>}/>
                    <Route path="profile/password-change" element={<PasswordChange/>}/>
                    <Route path="*" element={<App />}/>
                </Routes>
            </BrowserRouter>
            <SupportIVC />
            <Support />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

setupInterceptor(store);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
