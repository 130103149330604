import * as React from "react";
import {Title} from "../../interfaces/campaignAnswer.interface"

interface TitleProps {
    answer : Title
}


const TitleComponent: React.SFC<TitleProps> = ({answer}) => {
    return (
        <h3>{answer.title}</h3>
    );
};
export default TitleComponent;