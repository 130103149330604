import ButtonProps from '../interfaces/button.interface';
import styles from './button.module.css';

const Button: React.SFC<ButtonProps> = ({classCss, content, submit, icon, iconHover}) => {

    const css = classCss;
    const click = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        submit()
    }


    return (
        <button className={css} onClick={click}>
            {icon && iconHover &&
                <>
                    <img src={icon} className={styles.icon} />
                    <img src={iconHover} className={styles.iconHover} />
                </>
            }
            {icon && !iconHover &&
                <>
                    <img src={icon} className={styles.icon} />
                    <img src={icon} className={styles.iconHover} />
                </>
            }
            <span className={styles.content}>{content}</span>
        </button>
    );
};
export default Button;