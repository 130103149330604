import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import ContactSupport from '@mui/icons-material/ContactSupport';

const SupportIVC: React.SFC<any> = () => {
    const {t} = useTranslation();
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)

    let link = ""
    if(activeUser){
        if(activeUser.organization) {
            if(activeUser.organization === 20 || activeUser.head_company === "Wivetix Services"){
                if(activeUser.isManager){
                    link = "https://drive.google.com/uc?export=download&id=1lO1iGXhlKqluJsntBJ9Xg61hbzp_MvEh"
                }
                else {
                    link = "https://drive.google.com/uc?export=download&id=10pFq0WysaUVtD33uUhXzvDQTdJCdSrZ2"
                }
            }
        }
    }

    if(link !== ""){
        return (
            <a href={link} download className={"supportIVCButton"}>
                <ContactSupport/>
            </a>
        );
    }else {
        return (
            <></>
        );
    }
};
export default SupportIVC;