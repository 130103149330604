import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "../pages/dashboard.module.css";
import RadarIcon from "@mui/icons-material/Radar";
import {Link} from "react-router-dom";
import {Goal as GoalInterface} from "../interfaces/goal.interface";


interface GoalsProps {
    goals: GoalInterface[]
}

const GoalsList: React.SFC<GoalsProps> = ({goals}) => {
    const {t} = useTranslation();
    if (goals.length > 0){
        return (
            <Fragment>
                {
                    goals.map((goal: GoalInterface) => {
                        const date = new Date(JSON.parse(goal.completionDate));
                        const status = "common." + goal.status.toLowerCase();
                        let details = goal.details;
                        if (details.length > 60) {
                            details = details.substring(0,60) + "..."
                        }
                        let title = goal.title;
                        if (title.length > 25) {
                            title = title.substring(0,25) + "..."
                        }
                        return (
                            <article key={goal.id} className={styles.itemCard}>
                                <Link to={"/goals/" + goal.id}>
                                    <div className={goal.status}></div>
                                    <div>
                                        <h3><RadarIcon />{title}</h3>
                                        <span className={styles.date}>{t("dates.completionDate", {date: date})}</span>
                                    </div>
                                    <div>
                                        <p>{details}</p>
                                        <div className={styles.detailedStatus}>
                                            {t(status)}
                                        </div>
                                    </div>
                                </Link>
                            </article>
                        )
                    })
                }
            </Fragment>
        )
    } else {
        return (
            <p>{t("dashboard.noGoal")}</p>
        )
    }
}


export default GoalsList;