import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import {combineReducers} from "redux";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import goalSlice from './reducers/goalsReducer';
import experienceSlice from './reducers/experiencesReducer';
import feedbackSlice from './reducers/feedbacksReducer';
import requestSlice from './reducers/requestReducer';
import collaboratorSlice from './reducers/collaboratorReducer';
import activeUserSlice from './reducers/activeUserReducer';
import dashboardSlice from './reducers/dashboardReducer';
import teamSlice from "./reducers/teamReducer";
import dashboardManagerSlice from "./reducers/dashboardManagerReducer";
import entriesSlice from "./reducers/entryReducer";
import conversationsSlice from "./reducers/conversationsReducer";
import notificationsSlice from "./reducers/notificationsReducer";
import campaignAnswersSlice from "./reducers/campaignAnswersReducer";
import HRCommentsSlice from "./reducers/HRCommentsReducer";
import campaignsSlice from "./reducers/campaignsReducer";
import organizationSlice from "./reducers/organizationReducer";
import dashboardHRSlice from "./reducers/dashboardHRReducer";
import performanceReviewSlice from "./reducers/performanceReviewsReducer";

const persistConfigActiveuser = {
    key: 'activeUser',
    version: 1,
    storage,
}

const persistConfigTeamMembers = {
    key: 'team',
    version: 1,
    storage,
}

const persistConfigOrganizationManagers = {
    key: 'organization',
    version: 1,
    storage,
}

const reducers = combineReducers({
    goals: goalSlice,
    experiences: experienceSlice,
    feedbacks: feedbackSlice,
    requests: requestSlice,
    collaborators: collaboratorSlice,
    dashboard: dashboardSlice,
    dashboardManager: dashboardManagerSlice,
    entries: entriesSlice,
    conversations: conversationsSlice,
    notifications: notificationsSlice,
    campaignAnswers: campaignAnswersSlice,
    campaigns: campaignsSlice,
    hrComments: HRCommentsSlice,
    dashboardHR: dashboardHRSlice,
    performanceReviews: performanceReviewSlice,
    organization: persistReducer(persistConfigOrganizationManagers, organizationSlice),
    team: persistReducer(persistConfigTeamMembers, teamSlice),
    activeUser: persistReducer(persistConfigActiveuser, activeUserSlice),
})


export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

function selectAccessToken(state: RootState) {
    return state.activeUser.activeUser.token
}
function selectRefreshToken(state: RootState){
    return state.activeUser.activeUser.refresh
}
export function getAccessToken() {
    return selectAccessToken(store.getState())
}
export function getRefreshToken() {
    return selectRefreshToken(store.getState())
}

store.subscribe(getAccessToken);
store.subscribe(getRefreshToken);