import * as React from "react";
import styles from './viewManagerConversation.module.css';
import SidebarHR from "../components/sidebarHR";
import HeaderManager from "../components/header/headerManager";
import {HRPerformanceReviewsList} from "../components/HRPerformanceReviewsList";

export default function AllHRPerformanceReviews() {
    return (
        <>
            <SidebarHR />
            <main>
                <HeaderManager/>
                <section className={styles.section} >
                    <HRPerformanceReviewsList parameters={{ limit: 8}} />
                </section>
            </main>
        </>
    );
}