import { useState } from 'react';
import Button from './button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import styles from "./popUp.module.css";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import addLogo from "../assets/page-add.svg"
import addHoverLogo from "../assets/page-add-hover.svg"
import success from "../assets/goalSuccess.png";
import { Navigate } from 'react-router-dom';
import closeModal from "../assets/close-modal.svg";

interface popUpProps {
    visible : boolean;
    closeHandler : Function;
    saveHandler : Function;
    experienceLink : string;
}

const PopUpCompletedMilestone: React.SFC<popUpProps> = ({visible, closeHandler, saveHandler, experienceLink}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(visible);
    const [loading, setLoading] = useState<boolean>(true);

    if(open !== visible){
        setOpen(visible);
    }

    const handleClose = () => {
        closeHandler();
    };

    const saveAndAddExperienceHandler = () => {
        saveHandler(experienceLink);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={"popUp"}>
                <img className={styles.closeModal} src={closeModal} onClick={handleClose}/>
                <DialogTitle id="alert-dialog-title">
                <figure className={styles.figure}>
                    <img src={success} onLoad={() => setLoading(false)}/>
                </figure>
                        {t("milestonePopUp.title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("milestonePopUp.content")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={styles.popUpButtons}>
                    <Button
                        classCss="secondary-button"
                        content={t("buttons.later")} 
                        submit={handleClose}
                        icon={closeLogo}
                        iconHover={closeHoverLogo}/>
                    <Button
                        classCss="primary-button"
                        content={t("buttons.yes")} 
                        submit={saveAndAddExperienceHandler}
                        icon={addHoverLogo}
                        iconHover={addLogo}/>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default PopUpCompletedMilestone;