import api from "../utils/http-common"
import {Experience, ExperienceParameters} from "../interfaces/experience.interface"
import { ExperienceAdapter } from "../adapters/experience.adapter"

export default class ExperienceServices {
    createExperience(token: string, experience: Experience){
        return api.post("/experiences/", ExperienceAdapter(experience, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    getExperienceDetails(token: string, experienceId: string){
        return api.get("/experiences/" + experienceId,{headers: {Authorization: `Bearer ${token}`}})
    }
    getAllExperiences(token: string){
        return api.get("/experiences/", {headers: {Authorization: `Bearer ${token}`}})
    }
    updateExperience(token: string, experience: Experience){
        return api.put("/experiences/" + experience.id, ExperienceAdapter(experience, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
    deleteExperience(token: string, experience: { id: number }){
        return api.delete("/experiences/" + experience.id, {headers: {Authorization: `Bearer ${token}`}})
    }
    getAllManagerExperiences(token: string, parameters: ExperienceParameters){
        let url : string = "/manager/experiences?";
        for (const [key, value] of Object.entries(parameters)) {
            url += `${key}=${value}&`
        }
        return api.get(url,{headers: {Authorization: `Bearer ${token}`}});
    }
    getManagerExperienceDetails(token: string, experienceId: string){
        return api.get("/manager/experiences/" + experienceId,{headers: {Authorization: `Bearer ${token}`}})
    }
    updateManagerExperience(token: string, experience: Experience){
        return api.put("/manager/experiences/" + experience.id, ExperienceAdapter(experience, "out"),{headers: {Authorization: `Bearer ${token}`}})
    }
}