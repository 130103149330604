import { useTranslation } from "react-i18next";
import {CollaboratorDetails} from '../interfaces/collaborator.interface';
import styles from './talentDetails.module.css';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import titleIcon from "../assets/title-icon.png";
import ProfileMenu from "./header/profileMenu";
import {Entries} from "./entries";
import talentEngagementEmptyStage from "../assets/talent-engagement-emptystage.png";
import Avatar from "@mui/material/Avatar";
import {stringAvatar} from "../utils/avatar";
import HRCommentsComponent from "./HRComments";
import {AppDispatch, RootState} from "../store/store";
import {getHRComments} from "../store/reducers/HRCommentsReducer";

interface CollaboratorProps {
    talent: CollaboratorDetails
}

const TalentDetails: React.SFC<CollaboratorProps> = ({talent}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [getComments, setComments] = useState<boolean>(false)

    useEffect(() =>{
        dispatch(getHRComments(talent.info.id))
    }, [dispatch, talent])
    useEffect(() => {
        if(getComments === true){
            dispatch(getHRComments(talent.info.id))
            setComments(false)
        }
    }, [getComments])

    let fullName = ""
    if(talent.info.first_name){
        fullName += talent.info.first_name
    }
    if(talent.info.last_name){
        fullName = fullName + " " + talent.info.last_name
    }

    const HRComments = useSelector((state: RootState) => state.hrComments.HRComments)
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser)
    
    return (
        <main className={styles.mainManager}>
            <header className={styles.header}>
                <div className={styles.name}>
                    <h1>
                        {talent.info.first_name} {talent.info.last_name}
                        <img src={titleIcon} />
                    </h1>
                    <div>
                        <p>{talent.info.job_title}</p>
                        <p>{t("talentDetails.seniority", {count:talent.info.seniority})}</p>
                    </div>
                </div>
                <nav>
                    <ProfileMenu managerHeader={true} hrHeader={false}/>
                </nav>
            </header>
            <div className={styles.Container}>
                <section className={styles.LeftSide}>
                    {/*<div className={styles.card} id="dashboardManagerReviews">*/}
                    {/*    <h3>{t("dashboardManager.reviews&discussion")}*/}
                    {/*            /!**<ToolTips text={t("dashboardManager.toolTips.reviews")} parent={document.getElementById("dashboardManagerReviews")}/>*!/</h3>*/}
                    {/*    <div className={styles.emptyStage}>*/}
                    {/*        <img src={talentEngagementEmptyStage}/>*/}
                    {/*        <p>{t("dashboardManager.talentEngagementEmptyStage")}</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Entries parameters={{collaborator_id: talent.info.id, limit: 4}}/>
                    {activeUser.isHR &&
                        <HRCommentsComponent HRcomments={HRComments} collaboratorId={talent.info.id}
                                             updateComments={(update: boolean) => {
                                                 setComments(update)
                                             }}/>
                    }
                </section>
                <section className={styles.RightSide}>
                    <figure className={styles.avatar} >
                        {talent.info.avatar &&
                            <img src={process.env.REACT_APP_BACK_END_URL + "media/"+ talent.info.avatar}/>
                        }
                        {!talent.info.avatar &&
                            <Avatar {...stringAvatar(fullName)} />
                        }
                    </figure>
                    <div className={styles.card}>
                        <p className={styles.content}><span>{talent.goals.goalsCount}</span> {t("common.goals")}</p>
                        <span className={styles.separator}></span>
                        <p className={styles.content}><span>{talent.experiences.experiencesCount}</span> {t("common.experiences")}</p>
                        <span className={styles.separator}></span>
                        <p className={styles.content}><span>{talent.feedbacks}</span> {t("common.feedbacks")}</p>
                        <span className={styles.separator}></span>
                        <p className={styles.content}><span>{talent.requests}</span> {t("common.requests")}</p>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.graphContainer}>
                            <div className={styles.progress}>
                                <span>{talent.goals.goalsAchievement.toFixed(0)}</span>%
                                <progress max="100" value={talent.goals.goalsAchievement}></progress>
                            </div>
                            <p>{t("common.goalsProgression")}</p>
                        </div>
                        <span className={styles.separator}></span>
                        <div className={styles.graphContainer}>
                            <div className={styles.progress}>
                                <span>{talent.experiences.experiencesCount}</span>/5
                                <progress max="5" value={talent.experiences.experiencesCount}></progress>
                            </div>
                            <p>{t("common.experiencesQuarter")}</p>
                        </div>

                    </div>
                </section>
            </div>
        </main>
    );
}

export default TalentDetails;