import { useTranslation } from "react-i18next";
import styles from "./entries.module.css";
import sharingEmptyStage from "../assets/sharing-emptystage.png";
import dots from "../assets/dots.svg";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import * as React from "react";
import {useEffect, useState} from "react";
import {getEntries} from "../store/reducers/entryReducer";
import EntryCard from "./entryCard";
import {Entry, EntryParameters} from "../interfaces/entry.interface";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ToolTips } from "./toolTips";

interface ParametersProps {
    parameters: EntryParameters
}

export const Entries: React.SFC<ParametersProps> = ({parameters}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(4);

    useEffect(() => {
        dispatch(getEntries(parameters));
    }, [dispatch, parameters.collaborator_id]);

    const entries = useSelector((state: RootState) => state.entries);

    useEffect(() => {
        setOffset(entries.offset)
        setLimit(entries.limit)
    }, [entries])

    return (
        <div className={styles.card} id="Entries">
            <h3>{t("dashboardManager.sharing")}
                            {/**<ToolTips text={t("dashboardManager.toolTips.sharing")} parent={document.getElementById("Entries")}/>*/}
            </h3>
            {entries.count > 0
                ?
                <>
                    <div className={styles.EntryHeader}>
                        <div>{t("common.talent")}<img src={dots} /></div>
                        <div>{t("common.type")}<img src={dots} /></div>
                        <div>{t("common.date")}<img src={dots}/></div>
                        <div>{t("common.status")}<img src={dots}/></div>
                    </div>
                    <div className={styles.Sharing}>
                        { entries.entries.map((entry: Entry, index) =>
                            <EntryCard entry={entry} key={index}/>
                        )}
                    </div>
                    <div className={styles.EntryPagination}>
                        {offset + 1} - {offset + limit <= entries.count ? offset + limit : entries.count} {t("common.on")} {entries.count}
                        {offset != 0
                            ? <ChevronLeftIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset-limit
                                        dispatch(getEntries(newParameters))
                                    }
                                }/>
                            : <ChevronLeftIcon/>
                        }
                        {offset + limit < entries.count
                            ? <ChevronRightIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset+limit
                                        dispatch(
                                            getEntries(newParameters)
                                        )
                                    }
                                }/>
                            : <ChevronRightIcon/>
                        }
                    </div>
                </>
                :
                <div className={styles.EmptyStage}>
                    <img src={sharingEmptyStage}/>
                    <p>{t("dashboardManager.sharingEmptyStage")}</p>
                </div>
            }
        </div>
    );
}