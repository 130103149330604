import React, {Fragment, useState} from 'react';
import { useTranslation } from "react-i18next";
import styles from './tooltips.module.css';
import toolTipsIcon from '../assets/tooltipsicon.png';
import closeTooltip from '../assets/closetooltips.png';
import fondToolTips from '../assets/papirus-leaf.png';


interface toolTipsProps {
    text: string,
    parent: HTMLElement | null
}

export const ToolTips: React.SFC<toolTipsProps> = (props) => {
    const [toolTipCheck, setToolTipCheck] = useState<boolean>(false);
    const { t } = useTranslation();
    const text: string = props.text;

    let ttwidth: number | null | undefined = 400;
    let ttheight: string | null | undefined = "auto";
    let ttxPosition: number | null | undefined = 100;
    let ttyPosition: number | null | undefined = 15;

    if (!(props.parent === null || props.parent === undefined)) {
        if (props.parent.offsetLeft + 405 < document.documentElement.offsetWidth- 200) {
            ttxPosition = props.parent.offsetLeft + 5;
        } else {
            ttxPosition = props.parent.offsetLeft + props.parent.offsetWidth - 429;
        }
        if (props.parent.offsetTop + 150 - 24 < document.documentElement.offsetHeight - 20) {
            ttyPosition = props.parent.offsetTop + 5;
        } else {
            ttyPosition = props.parent.offsetTop + props.parent.offsetHeight - 150;
            ttheight = (150-24).toString().concat("px");
        }
    }

    const toolTipsStyle = {
        width: ttwidth.toString().concat("px"),
        height: ttheight,
        left: ttxPosition.toString().concat("px"),
        top: ttyPosition.toString().concat("px"),
    }
    return (
        <Fragment>
            <input
                type="image"
                src={toolTipsIcon}
                className={styles.tooltipsIcon}
                onClick={
                    (e) => {
                        e.preventDefault();
                        document.getElementById("closeTooltip")?.click();
                        setToolTipCheck(true);
                    }}
            />
            {toolTipCheck &&
                <div className={styles.tooltips} style={toolTipsStyle} id="tooltip">
                    <img src={fondToolTips} className={styles.fondToolTips}></img>
                    <input
                        type="image"
                        src={closeTooltip}
                        className={styles.closetooltips}
                        id="closeTooltip"
                        onClick={
                            (e) => {
                                e.preventDefault();
                                setToolTipCheck(false)
                            }}
                    />
                    <div className={styles.tooltipsContent}>
                        <p className={styles.tooltipsText}>{text}</p>
                    </div>
                </div>
            }
        </Fragment>
    );
}