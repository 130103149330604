import * as React from "react";
import {DateTemplate} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";

interface DateTemplateProps {
    answer: DateTemplate
    managerAnswer: DateTemplate
    isManager: boolean
    changeHandler: Function
    status: "Draft" | "SharedByCollaborator" | "SharedByManager" | "SharedByBoth" | "ValidatedByManager" | "ValidatedByBoth",
    readonly?: boolean
}

const DateTemplateComponent: React.FunctionComponent<DateTemplateProps> = ({answer, readonly, changeHandler, managerAnswer, status, isManager}) => {
    const { t } = useTranslation();
    let date = answer.answer !== "" ? new Date(JSON.parse(answer.answer)) : new Date();
    const [userAnswer, setUserAnswer] = useState<Date | null>(date);
    const [managerAnswerLocal, setManagerAnswer] = useState<Date | null>(date);

    useEffect(() => {
        if(!isManager){
            changeHandler(JSON.stringify(userAnswer))
        }
    }, [userAnswer])

    useEffect(() => {
        if(isManager){
            changeHandler(JSON.stringify(managerAnswerLocal))
        }
    }, [managerAnswerLocal])


    return (
        <div className={styles.titleInput}>
            <label htmlFor={answer.title}>{answer.title}</label>
            {answer.visibility === "Both" ?
                <>
                    {(status === "Draft" || (status === "SharedByCollaborator" && !isManager ) || (status === "SharedByManager" && isManager)) &&
                        <>
                            {isManager ?
                                <div className={styles.DateInput}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            openTo="month"
                                            readOnly={readonly}
                                            views={['year', 'month', 'day']}
                                            value={managerAnswerLocal}
                                            onChange={(newDate) => {
                                                setManagerAnswer(newDate);
                                            }}
                                            renderInput={(params) => {
                                                let newParams = {...params}
                                                if(newParams.inputProps){
                                                    newParams.inputProps.value  = t("dates.completionDate", { date: managerAnswerLocal })
                                                }
                                                return(<TextField {...newParams}/>)}}
                                        />
                                    </LocalizationProvider>
                                </div>
                                :
                                <div className={styles.DateInput}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            openTo="month"
                                            readOnly={readonly}
                                            views={['year', 'month', 'day']}
                                            value={userAnswer}
                                            onChange={(newDate) => {
                                                setUserAnswer(newDate);
                                            }}
                                            renderInput={(params) => {
                                                let newParams = {...params}
                                                if(newParams.inputProps){
                                                    newParams.inputProps.value  = t("dates.completionDate", { date: userAnswer })
                                                }
                                                return(<TextField {...newParams}/>)}}
                                        />
                                    </LocalizationProvider>
                                </div>
                            }
                        </>
                    }
                    {status === "SharedByCollaborator" && isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <div className={styles.DateInput}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            openTo="month"
                                            readOnly={readonly}
                                            views={['year', 'month', 'day']}
                                            value={userAnswer}
                                            onChange={(newDate) => {
                                                setUserAnswer(newDate);
                                            }}
                                            renderInput={(params) => {
                                                let newParams = {...params}
                                                if(newParams.inputProps){
                                                    newParams.inputProps.value  = t("dates.completionDate", { date: userAnswer })
                                                }
                                                return(<TextField {...newParams}/>)}}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <div className={styles.DateInput}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            openTo="month"
                                            readOnly={readonly}
                                            views={['year', 'month', 'day']}
                                            value={managerAnswerLocal}
                                            onChange={(newDate) => {
                                                setManagerAnswer(newDate);
                                            }}
                                            renderInput={(params) => {
                                                let newParams = {...params}
                                                if(newParams.inputProps){
                                                    newParams.inputProps.value  = t("dates.completionDate", { date: managerAnswerLocal })
                                                }
                                                return(<TextField {...newParams}/>)}}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                    }
                    {status === "SharedByManager" && !isManager &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <div className={styles.DateInput}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            openTo="month"
                                            readOnly={readonly}
                                            views={['year', 'month', 'day']}
                                            value={userAnswer}
                                            onChange={(newDate) => {
                                                setUserAnswer(newDate);
                                            }}
                                            renderInput={(params) => {
                                                let newParams = {...params}
                                                if(newParams.inputProps){
                                                    newParams.inputProps.value  = t("dates.completionDate", { date: userAnswer })
                                                }
                                                return(<TextField {...newParams}/>)}}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <div className={styles.DateInput}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            openTo="month"
                                            readOnly={readonly}
                                            views={['year', 'month', 'day']}
                                            value={managerAnswerLocal}
                                            onChange={(newDate) => {
                                                setManagerAnswer(newDate);
                                            }}
                                            renderInput={(params) => {
                                                let newParams = {...params}
                                                if(newParams.inputProps){
                                                    newParams.inputProps.value  = t("dates.completionDate", { date: managerAnswerLocal })
                                                }
                                                return(<TextField {...newParams}/>)}}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                    }
                    {(status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                        <div className={styles.answerSeparator}>
                            <div>
                                <h5>{t("common.collaborator")}</h5>
                                <div className={styles.DateInput}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            openTo="month"
                                            readOnly={readonly}
                                            views={['year', 'month', 'day']}
                                            value={userAnswer}
                                            onChange={(newDate) => {
                                                setUserAnswer(newDate);
                                            }}
                                            renderInput={(params) => {
                                                let newParams = {...params}
                                                if(newParams.inputProps){
                                                    newParams.inputProps.value  = t("dates.completionDate", { date: userAnswer })
                                                }
                                                return(<TextField {...newParams}/>)}}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div>
                                <h5>{t("common.manager")}</h5>
                                <div className={styles.DateInput}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            openTo="month"
                                            readOnly={readonly}
                                            views={['year', 'month', 'day']}
                                            value={managerAnswerLocal}
                                            onChange={(newDate) => {
                                                setManagerAnswer(newDate);
                                            }}
                                            renderInput={(params) => {
                                                let newParams = {...params}
                                                if(newParams.inputProps){
                                                    newParams.inputProps.value  = t("dates.completionDate", { date: managerAnswerLocal })
                                                }
                                                return(<TextField {...newParams}/>)}}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                    }
                </>
                :
                <>
                    {answer.visibility === "Manager" ?
                        <>
                            {((status === "SharedByManager" && !isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.manager")}</h5>
                            }
                            <div className={styles.DateInput}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        openTo="month"
                                        readOnly={readonly}
                                        views={['year', 'month', 'day']}
                                        value={managerAnswerLocal}
                                        onChange={(newDate) => {
                                            setManagerAnswer(newDate);
                                        }}
                                        renderInput={(params) => {
                                            let newParams = {...params}
                                            if(newParams.inputProps){
                                                newParams.inputProps.value  = t("dates.completionDate", { date: managerAnswerLocal })
                                            }
                                            return(<TextField {...newParams}/>)}}
                                    />
                                </LocalizationProvider>
                            </div>
                        </>
                        :
                        <>
                            {((status === "SharedByCollaborator" && isManager) || status === "SharedByBoth" || status ===  "ValidatedByManager" || status === "ValidatedByBoth") &&
                                <h5>{t("common.collaborator")}</h5>
                            }
                            <div className={styles.DateInput}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        openTo="month"
                                        readOnly={readonly}
                                        views={['year', 'month', 'day']}
                                        value={userAnswer}
                                        onChange={(newDate) => {
                                            setUserAnswer(newDate);
                                        }}
                                        renderInput={(params) => {
                                            let newParams = {...params}
                                            if(newParams.inputProps){
                                                newParams.inputProps.value  = t("dates.completionDate", { date: userAnswer })
                                            }
                                            return(<TextField {...newParams}/>)}}
                                    />
                                </LocalizationProvider>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    );
};
export default DateTemplateComponent;