import styles from "../pages/dashboardManager.module.css";
import { EngagementCollaborator } from "../interfaces/collaborator.interface";
import * as React from "react";
import AvatarIcon from "./avatar";

interface TalentEngagementListProps {
    members: EngagementCollaborator[],
}

const TalentEngagementList: React.SFC<TalentEngagementListProps> = ({members}) => {
    let talentCount = members.length;
    let maxDisplay = 9

    return (
        <div className={styles.engagementCollaborators}>
            {members.map(
                (member: EngagementCollaborator, index) => {
                    if (talentCount > maxDisplay && maxDisplay == index + 1){
                        return <AvatarIcon member={member.info} tooManyAvatar={true} talentNumber={talentCount - maxDisplay + 1} key={index}/>
                    } else if (index < maxDisplay){
                        return <AvatarIcon member={member.info} key={index}/>
                    }
                }
            )}
        </div>
    );
};
export default TalentEngagementList;