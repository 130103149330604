import {Feedback} from "../interfaces/feedback.interface";

export const FeedbackAdapter = (feedback: any, direction: "in" | "out") => {
    if (direction === "out"){
        let adaptedFeedback = feedback
        if (feedback.status == "Draft"){
            adaptedFeedback.status = "DRF"
        } else if (feedback.status == "Received") {
            adaptedFeedback.status = "RCD"
        } else if (feedback.status == "Sent") {
            adaptedFeedback.status = "SNT"
        }
        adaptedFeedback.feedback_date = JSON.parse(feedback.feedbackDate)
        if (feedback.collaborator != 0){
            adaptedFeedback.collaborator_to = feedback.collaborator
        }
        return adaptedFeedback
    } else {
        let adaptedFeedback = feedback as Feedback
        if (feedback.status == "DRF"){
            adaptedFeedback.status = "Draft"
        } else if (feedback.status == "RCD") {
            adaptedFeedback.status = "Received"
        } else if (feedback.status == "SNT") {
            adaptedFeedback.status = "Sent"
        }
        adaptedFeedback.feedbackDate = JSON.stringify(feedback.feedback_date)
        adaptedFeedback.collaborator = feedback.collaborator_to
        adaptedFeedback.author = feedback.collaborator_from
        return adaptedFeedback
    }
}

export const FeedbackAdapterList = (feedbacks: any, direction: "in" | "out") => {
    feedbacks.forEach((goal: any) => FeedbackAdapter(goal, direction))
    if (direction === "in"){
        return feedbacks as Feedback[]
    } else {
        return feedbacks
    }
}