import * as React from "react";
import {
    FutureGoalTemplate,
    GoalTemplate,
    ListComponent,
    PastTraining,
    FutureTraining,
    SkillTemplate,
    PastTrainingCondat,
    GoalCondatTemplate,
    FutureTrainingCondat
} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {Fragment, useEffect, useState} from "react";
import GoalTemplateComponent from "./goalTemplate";
import FutureGoalTemplateComponent from "./futureGoalTemplate";
import SkillTemplateComponent from "./skillTemplate";
import PastTrainingComponent from "./pastTraining";
import FutureTrainingComponent from "./futureTraining";
import addLogo from "../../assets/page-add.svg";
import addHoverLogo from "../../assets/page-add-hover.svg";
import Button from "../button";
import {useTranslation} from "react-i18next";
import PastTrainingCondatComponent from "./pastTrainingCondat";
import GoalCondatComponent from "./goalCondatTemplate";
import FutureTrainingCondatComponent from "./futureTrainingCondat";


interface ListComponentProps {
    answer: ListComponent
    changeHandler: Function
    managerAnswer: ListComponent
    isManager: boolean
    status: "Draft" | "SharedByCollaborator" | "SharedByManager" | "SharedByBoth" | "ValidatedByManager" | "ValidatedByBoth"
    readonly?: boolean
}

const ListTemplateComponent: React.FunctionComponent<ListComponentProps> = ({answer, readonly, changeHandler, isManager, status, managerAnswer}) => {
    const { t } = useTranslation();
    const [userAnswers, setUserAnswers] = useState<any[]>(() => {
        if(answer.visibility === "Manager"){
            return managerAnswer.answer
        } else if (answer.visibility === "Collaborator") {
            return answer.answer
        } else {
            return []
        }
    })

    useEffect(() => {
        changeHandler(userAnswers)
    }, [userAnswers])

    if ((answer.visibility !== "Manager" && isManager) || (answer.visibility === "Manager" && !isManager)){
        readonly = true
    }

    return (
        <Fragment>
            {userAnswers.map((listAnswer, index) => {
                if(listAnswer.type === "Goal"){
                    return (<GoalTemplateComponent
                        key={index}
                        answer={listAnswer}
                        readonly={readonly}
                        changeHandler={
                            (newAnswerContent: GoalTemplate) => {
                                let newAnswer = newAnswerContent
                                let newAnswers = [...userAnswers]
                                newAnswers[index] = newAnswer
                                setUserAnswers(newAnswers)
                            }}/>)
                }
                else if (answer.model === "FutureGoal"){
                    return <FutureGoalTemplateComponent
                        key={index}
                        answer={listAnswer}
                        readonly={readonly}
                        changeHandler={
                            (newAnswerContent: FutureGoalTemplate) => {
                                let newAnswer = newAnswerContent
                                let newAnswers = [...userAnswers]
                                newAnswers[index] = newAnswer
                                setUserAnswers(newAnswers)
                            }}/>
                }else if (answer.model === "Skill") {
                    return <SkillTemplateComponent
                        key={index}
                        answer={listAnswer}
                        readonly={readonly}
                        changeHandler={
                            (newAnswerContent: SkillTemplate) => {
                                let newAnswer = newAnswerContent
                                let newAnswers = [...userAnswers]
                                newAnswers[index] = newAnswer
                                setUserAnswers(newAnswers)
                            }}/>
                } else if (answer.model === "PastTraining") {
                    return <PastTrainingComponent
                        key={index}
                        answer={listAnswer}
                        readonly={readonly}
                        changeHandler={
                            (newAnswerContent: PastTraining) => {
                                let newAnswer = newAnswerContent
                                let newAnswers = [...userAnswers]
                                newAnswers[index] = newAnswer
                                setUserAnswers(newAnswers)
                            }}/>
                } else if (answer.model === "FutureTraining") {
                    return <FutureTrainingComponent
                        key={index}
                        answer={listAnswer}
                        readonly={readonly}
                        changeHandler={
                            (newAnswerContent: FutureTraining) => {
                                let newAnswer = newAnswerContent
                                let newAnswers = [...userAnswers]
                                newAnswers[index] = newAnswer
                                setUserAnswers(newAnswers)
                            }}/>
                } else if (answer.model === "PastTrainingCondat") {
                    return <PastTrainingCondatComponent
                        key={index}
                        answer={listAnswer}
                        readonly={readonly}
                        changeHandler={
                            (newAnswerContent: PastTrainingCondat) => {
                                let newAnswer = newAnswerContent
                                let newAnswers = [...userAnswers]
                                newAnswers[index] = newAnswer
                                setUserAnswers(newAnswers)
                            }}/>
                } else if (answer.model === "GoalCondat") {
                    return <GoalCondatComponent
                        key={index}
                        answer={listAnswer}
                        readonly={readonly}
                        changeHandler={
                            (newAnswerContent: GoalCondatTemplate) => {
                                let newAnswer = newAnswerContent
                                let newAnswers = [...userAnswers]
                                newAnswers[index] = newAnswer
                                setUserAnswers(newAnswers)
                            }}/>
                } else if (answer.model === "FutureTrainingCondat") {
                    return <FutureTrainingCondatComponent
                        key={index}
                        answer={listAnswer}
                        readonly={readonly}
                        changeHandler={
                            (newAnswerContent: FutureTrainingCondat) => {
                                let newAnswer = newAnswerContent
                                let newAnswers = [...userAnswers]
                                newAnswers[index] = newAnswer
                                setUserAnswers(newAnswers)
                            }}/>
                }
            })}
            {!readonly &&
                <div className={styles.listButton}>
                    {answer.model === "Goal" &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.addGoal")}
                            submit={() => {
                                setUserAnswers(
                                    allAnswers => [...allAnswers, {
                                        comment: "",
                                        completionDate: JSON.stringify(new Date()),
                                        completion: 0,
                                        weighting:0,
                                        kpi: "",
                                        realisation: "",
                                        title: "",
                                        type: "Goal",
                                    } as GoalTemplate]
                                )}}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                    }
                    {answer.model === "FutureGoal" &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.addGoal")}
                            submit={() => {
                                setUserAnswers(
                                    allAnswers => [...allAnswers, {
                                        comment: "",
                                        completionDate: JSON.stringify(new Date()),
                                        kpi: "",
                                        weighting:0,
                                        meansOfAction: "",
                                        title: "",
                                        type: "FutureGoal",
                                    } as FutureGoalTemplate]
                                )}}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                    }
                    {answer.model === "Skill" &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.addSkill")}
                            submit={() => {
                                setUserAnswers(
                                    allAnswers => [...allAnswers, {
                                        skill: "",
                                        level: "",
                                        improvementAreas: [
                                            {
                                                improvement: "",
                                                meansOfAction: "",
                                                expectedDate: JSON.stringify(new Date()),
                                            }
                                        ],
                                        type: "Skill"
                                    } as SkillTemplate]
                                )}}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                    }
                    {answer.model === "PastTraining" &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.addTraining")}
                            submit={() => {
                                setUserAnswers(
                                    allAnswers => [...allAnswers, {
                                        title: "",
                                        target: "",
                                        result: "",
                                        observations: "",
                                        type: "PastTraining",
                                    } as PastTraining]
                                )}}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                    }
                    {answer.model === "FutureTraining" &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.addTraining")}
                            submit={() => {
                                setUserAnswers(
                                    allAnswers => [...allAnswers, {
                                        title: "",
                                        target: "",
                                        type: "FutureTraining",
                                    } as FutureTraining]
                                )}}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                    }
                    {answer.model === "PastTrainingCondat" &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.addTraining")}
                            submit={() => {
                                setUserAnswers(
                                    allAnswers => [...allAnswers, {
                                        title: "",
                                        result: "",
                                        satisfaction: "",
                                        type: "PastTrainingCondat",
                                    } as PastTrainingCondat]
                                )}}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                    }
                    {answer.model === "GoalCondat" &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.addGoal")}
                            submit={() => {
                                setUserAnswers(
                                    allAnswers => [...allAnswers, {
                                        title: "",
                                        category: "",
                                        completionDate: JSON.stringify(new Date()),
                                        description: "",
                                        meansOfAction: "",
                                        evaluations: [{
                                                criteria:"",
                                                indicator:""
                                            }],
                                        type: "GoalCondat",
                                    } as GoalCondatTemplate]
                                )}}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                    }
                    {answer.model === "FutureTrainingCondat" &&
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.addTraining")}
                            submit={() => {
                                setUserAnswers(
                                    allAnswers => [...allAnswers, {
                                        organism:"",
                                        title:"",
                                        goal:"",
                                        program:"",
                                        cost:"",
                                        origin:"",
                                        opinion:"",
                                        priority:"",
                                        duration:"",
                                        cpf:"",
                                        comment:"",
                                        type: "FutureTrainingCondat",
                                    } as FutureTrainingCondat]
                                )}}
                            icon={addLogo}
                            iconHover={addHoverLogo}/>
                    }
                </div>
            }
        </Fragment>
    );
};
export default ListTemplateComponent;