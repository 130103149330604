import * as React from 'react';
import { useTranslation } from "react-i18next";
import styles from './header.module.css';
import Logo from '../../assets/logo-full-white.png';
import {Link } from "react-router-dom";

export default function HeaderNotConnected() {
    const {t} = useTranslation();
    return (
        <header className={styles.header}>
            <figure className={styles.logo}>
                <Link to="/">
                    <img src={Logo} />
                </Link>
            </figure>
            <nav>
                <Link className={styles.navButton} to="https://papirus.app">
                    {t('header.website')}
                </Link>
                <Link className={styles.navButton} to="/login">
                    {t('header.login')}
                </Link>
            </nav>
        </header>
    );
}