import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import Header from '../components/header/header';
import RequestDetails from '../components/requestDetails';
import { Request as RequestInterface} from '../interfaces/request.interface';
import {AppDispatch, RootState} from "../store/store";
import Dashboard from "./dashboard";
import {useEffect} from "react";
import {getRequestDetails} from "../store/reducers/requestReducer";
import {SidebarTalent} from "../components/sidebarTalent";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function ViewRequestDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.requests.status)

    useEffect(() => {
        if(params.requestId){
            dispatch(getRequestDetails(params.requestId));
        }
    }, [dispatch]);

    const request = useSelector((state: RootState) => state.requests.requests.find((request : RequestInterface) => request.id == params.requestId));
    
    if (status != "error") {
        return (
            <>
                <SidebarTalent />
                <main>
                    <Header title={t("request.h1")}/>
                    {request &&
                        <RequestDetails request={request}/>
                    }
                </main>
            </>
        );
    } else {
        return <Dashboard />;
    }
}