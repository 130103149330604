import * as React from "react";
import {FutureTraining, ImprovementArea, SkillTemplate} from "../../interfaces/campaignAnswer.interface"
import styles from "../campaignAnswer.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ImprovementAreaComponent from "./improvementArea";
import Button from "../button";
import addLogo from "../../assets/page-add.svg";
import addHoverLogo from "../../assets/page-add-hover.svg";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface SkillTemplateProps {
    answer: SkillTemplate
    changeHandler: Function
    readonly?: boolean
}

const SkillTemplateComponent: React.SFC<SkillTemplateProps> = ({answer, readonly, changeHandler}) => {
    const { t } = useTranslation();
    const [skill, setSkill] = useState<string>(answer.skill)
    const [level, setLevel] = useState<string>(answer.level)
    const [improvementAreas, setImprovementAreas] = useState<ImprovementArea[]>(answer.improvementAreas)

    useEffect(() => {
        let newAnswer = {
            skill: skill,
            level: level,
            improvementAreas: improvementAreas,
            position: answer.position,
            type: answer.type
        } as SkillTemplate
        changeHandler(newAnswer)
    }, [skill, level, improvementAreas])


    return (
        <div className={styles.titleInput + " " + styles.goalTemplate}>
            <div className={styles.goalLine}>
                <div className={styles.goalLine_firstItem}>
                    <label htmlFor="skill">{t("common.mastery")}</label>
                    <input
                        type="text"
                        name="skill"
                        defaultValue={skill}
                        readOnly={readonly}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSkill(event.target.value)
                        }}/>
                </div>
                <div className={styles.goalLine_secondItem}>
                    <label htmlFor="level">{t("skill.level")}</label>
                    <div className={styles.Input}>
                        <Select
                            value={level}
                            readOnly={readonly}
                            onChange={(event: SelectChangeEvent) => {setLevel(event.target.value)}}>
                            <MenuItem value="5: Remarquable">5: Remarquable</MenuItem>
                            <MenuItem value="4: Excellent">4: Excellent</MenuItem>
                            <MenuItem value="3: Efficace">3: Efficace</MenuItem>
                            <MenuItem value="2: Développement">2: Développement</MenuItem>
                            <MenuItem value="1: Inférieur aux normes">1: Inférieur aux normes</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
            {improvementAreas.map((improvementArea: ImprovementArea, index) => {
                return <ImprovementAreaComponent
                    improvementArea={improvementArea}
                    changeHandler={
                        (newImprovementArea: ImprovementArea) => {
                            let newImprovement = newImprovementArea
                            let newImprovements = [...improvementAreas]
                            newImprovements[index] = newImprovement
                            setImprovementAreas(newImprovements)
                        }}
                    readonly={readonly}
                    key={index}/>
            })}
            {!readonly &&
                <div className={styles.listButton}>
                    <Button
                        classCss={"secondary-button"}
                        content={t("buttons.addImprovementArea")}
                        submit={() => {
                            setImprovementAreas(
                                improvementAreas => [...improvementAreas, {
                                    improvement: "",
                                    meansOfAction: "",
                                    expectedDate: JSON.stringify(new Date()),
                                } as ImprovementArea]
                            )}}
                        icon={addLogo}
                        iconHover={addHoverLogo}/>
                </div>
            }
        </div>
    );
};
export default SkillTemplateComponent;