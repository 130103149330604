import { useTranslation } from "react-i18next";
import styles from "./managerConversationsList.module.css";
import sharingEmptyStage from "../assets/sharing-emptystage.png";
import dots from "../assets/dots.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import * as React from "react";
import { useEffect, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ToolTips } from "./toolTips";
import { Experience, ExperienceParameters } from "../interfaces/experience.interface";
import experienceIconPage from '../assets/experience-header-logo.svg';
import {getAllManagerExperiences} from "../store/reducers/experiencesReducer";
import ManagerExperienceCard from "./ManagerExperienceCard";

interface ParametersProps {
    parameters: ExperienceParameters
}

export const ManagerExperiencesList: React.SFC<ParametersProps> = ({ parameters }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getAllManagerExperiences(parameters));
    }, [dispatch]);

    const experiences = useSelector((state: RootState) => state.experiences);
    const experiencesList = experiences.experiences;
    const experiencesOffset = experiences.offset;
    const experiencesCount = experiences.count;

    const [offset, setOffset] = useState(experiencesOffset);
    const limit : number  = parameters.limit || 8;

    useEffect(() => {
        setOffset( experiences.offset)
    }, [ experiences])



    return (
        <div className={styles.List} id="Goal">
            <div className={styles.pageTitle}>
                <img src={experienceIconPage} className={styles.goalIcon}/>
                <h1>
                    {t("common.experiences")}
                    {/*<ToolTips text={t("requests.tooltips")} parent={document.getElementById("Goal")}/>*/}
                </h1>
            </div>
            {experiencesCount > 0
                ?
                <>
                    <div className={styles.Header}>
                        <div className={styles.headerTalent}>{t("common.talent")}<img src={dots} /></div>
                        <div className={styles.headerTitle}>{t("common.title")}<img src={dots} /></div>
                        <div className={styles.headerDate}>{t("common.date")}<img src={dots} /></div>
                        <div className={styles.headerStatus}>{t("common.status")}<img src={dots} /></div>
                    </div>
                    <div className={styles.Sharing}>
                        {experiencesList.map(
                            (experience: Experience, index) =>
                                <ManagerExperienceCard
                                    key={index}
                                    experience={experience}
                                />
                        )}
                    </div>
                    <div className={styles.EntryPagination}>
                        {offset + 1} - {offset + limit <= experiencesCount ? offset + limit : experiencesCount} {t("common.on")} {experiencesCount}
                        {offset != 0
                            ? <ChevronLeftIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset-limit
                                        dispatch(getAllManagerExperiences(newParameters))
                                    }
                                }/>
                            : <ChevronLeftIcon/>
                        }
                        {offset + limit < experiencesCount
                            ? <ChevronRightIcon
                                className={styles.active}
                                onClick={
                                    () => {
                                        let newParameters = parameters
                                        newParameters.offset = offset+limit
                                        dispatch(
                                            getAllManagerExperiences(newParameters)
                                        )
                                    }
                                }/>
                            : <ChevronRightIcon/>
                        }
                    </div>
                </>
                :
                <div className={styles.EmptyStage}>
                    <img src={sharingEmptyStage} />
                    <p>{t("experience.emptyList")}</p>
                </div>
            }
        </div>
    );
}