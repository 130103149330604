import { Sidebar } from "../components/sidebar";
import * as React from "react";
import styles from './viewManagerConversation.module.css';
import { ManagerConversationsList } from "../components/ManagerConversationsList";
import HeaderManager from "../components/header/headerManager";

export default function AllManagerConversations() {
    return (
        <>
            <Sidebar />
            <main>
                <HeaderManager />
                <section className={styles.section} >
                    <ManagerConversationsList parameters={{ limit: 8 }} />
                </section>
            </main>
        </>
    );
}