import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Header from '../components/header/header';
import Button from '../components/button';
import Milestone from '../components/milestone';
import { Milestone as MilestoneInterface } from '../interfaces/goal.interface';
import styles from './addgoal.module.css';
import { AppDispatch, RootState } from "../store/store";
import { createGoal } from '../store/reducers/goalsReducer';
import { SidebarTalent } from "../components/sidebarTalent";
import * as React from "react";
import CustomAlerts from "../components/customAlerts";
import HeaderLogo from "../assets/goal-header-logo.svg";
import { ToolTips } from "../components/toolTips";
import closeLogo from "../assets/page-close.svg";
import closeHoverLogo from "../assets/page-close-hover.svg";
import addLogo from "../assets/page-add.svg"
import addHoverLogo from "../assets/page-add-hover.svg"
import validateLogo from "../assets/page-validate.svg"
import validateHoverLogo from "../assets/page-validate-hover.svg"
import shareLogo from "../assets/page-share.svg"
import shareHoverLogo from "../assets/page-share-hover.svg"
import PopUpSharedGoal from "../components/PopUpSharedGoal";


export default function AddGoal() {
    const { t } = useTranslation();
    const [milestones, setMilestones] = useState<MilestoneInterface[]>([])
    const [completionDate, setCompletionDate] = useState<Date | null>(new Date());
    const [goalTitle, setGoalTitle] = useState<string>("");
    const [goalDetails, setGoalDetails] = useState<string>("");
    const [redirect, setRedirect] = useState<string>("");
    const goalStatus = useSelector((state: RootState) => state.goals.status);
    const errors = useSelector((state: RootState) => state.goals.error);
    const [openPopUp, setOpenPopUp] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const [experienceLink, setExperienceLink] = useState<string>("/experiences/new");
    const [focusMilestone, setFocusMilestone] = useState<boolean>(false)
    const activeUser = useSelector((state: RootState) => state.activeUser.activeUser);

    useEffect(() => {
        if(focusMilestone){
            const lastMilestone = document.getElementById("milestones")!.lastElementChild as HTMLElement
            const input = lastMilestone.querySelector('input[type=\"text\"]') as HTMLInputElement
            input!.focus()
            setFocusMilestone(false)
        }
    }, [focusMilestone])

    if(goalStatus === "success") {
        if(redirect === "allGoals") {
            return <Navigate to="/goals"/>;
        } else if(redirect === "newGoal") {
            window.location.reload();
        } else if(redirect === "newExp") {
            return <Navigate to="/experiences/new"/>;
        } else if(redirect === "newExpMilestone"){
            return <Navigate to={experienceLink}/>;
        }
    }
    if(redirect === "close"){
        window.history.back();
    }

    const HandleClosePopUp = (direction?: string) => {
        setOpenPopUp(false);
        if(direction){
            setRedirect(direction);
        }else{
            setRedirect("allGoals");
        }
    }

    return (
        <>
            <SidebarTalent />
            <main>
                <Header title={t("goal.h1")}/>
                <section className={styles.modalForm}>
                    <header>
                        <img src={HeaderLogo}></img>
                        <h1>{t("common.goal")}</h1>
                    </header>
                    <form className={styles.form}>
                        <div className={styles.titleInput}>
                            <label htmlFor="title">{t("goal.title")}</label>
                            <input
                                name="title"
                                placeholder={t("goal.placeholder")}
                                onChange={(e) => setGoalTitle(e.target.value)}
                                type="text">
                            </input>
                        </div>
                        {goalStatus === "error" && errors.title &&
                            <CustomAlerts errors={errors.title} />
                        }
                        <label htmlFor="content" className={styles.tooltipHandler}>
                            {t("goal.content")}
                            <ToolTips text={t("goal.helpContent")} parent={document.getElementById("SMART")} />
                        </label>
                        <p className={styles.SMART}
                            id="SMART">{t("goal.helpTitle")}</p>
                        <textarea
                            name="content"
                            placeholder={t("goal.placeholderDetails")}
                            onChange={(e) => setGoalDetails(e.target.value)}>
                        </textarea>
                        {goalStatus === "error" && errors.details &&
                            <CustomAlerts errors={errors.details} />
                        }
                        <div className={styles.Milestones}>
                            <label>{t("goal.milestones")}</label>
                            <ul id={"milestones"}>
                                { milestones.map(
                                    (milestone: MilestoneInterface, index) =>
                                        <Milestone
                                            key={milestone.id}
                                            milestone={milestone}
                                            readonly={false}
                                            isManager={false}
                                            goalId={undefined}
                                            changeHandler={
                                                (newMilestone: MilestoneInterface) => {
                                                    let newMilestones = [...milestones]
                                                    newMilestones[index] = newMilestone;
                                                    setMilestones(newMilestones)
                                                }}
                                            deleteHandler={() => {
                                                let newMilestones = [...milestones]
                                                newMilestones.splice(index, 1);
                                                setMilestones(newMilestones)
                                            }}
                                            enterKey={() => {
                                                setMilestones(milestones => [...milestones, {id: "Tmp"+ milestones.length, title:"", completed:false}])
                                                setFocusMilestone(true)
                                            }}
                                        />
                                )}
                                {goalStatus === "error" && errors.milestones &&
                                    <CustomAlerts errors={[t("goal.blankError")]}/>
                                }
                            </ul>
                            <Button classCss={"secondary-button"} content={t("buttons.addItem")} submit={() => {
                                    setMilestones(milestones => [...milestones, {id: "Tmp"+ milestones.length, title:"", completed:false}])
                                    setFocusMilestone(true)
                                }}
                                icon={addLogo}
                                iconHover={addHoverLogo} />
                        </div>
                        <label>{t("goal.date")}</label>
                        <div className={styles.DateInput}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    openTo="month"
                                    views={['year', 'month', 'day']}
                                    value={completionDate}
                                    onChange={(newDate) => {
                                        setCompletionDate(newDate);
                                    }}
                                    renderInput={(params) => {
                                        let newParams = {...params}
                                        if(newParams.inputProps){
                                            newParams.inputProps.value  = t("dates.completionDate", { date: completionDate })
                                        }
                                        return(<TextField {...newParams}/>)}}
                                />
                            </LocalizationProvider>
                        </div>
                        {goalStatus === "error" && errors.completion_date &&
                            <CustomAlerts errors={errors.completion_date} />
                        }
                    </form>
                    <div className={styles.buttonContainer}>
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.close")}
                            submit={
                                () => {
                                    setRedirect("close")
                                }
                            }
                            icon={closeLogo}
                            iconHover={closeHoverLogo} />
                        <Button
                            classCss={"primary-button"}
                            content={t("buttons.save")}
                            submit={
                                () => {
                                    dispatch(
                                        createGoal({
                                            title: goalTitle,
                                            collaborator_from: activeUser.id,
                                            status: "Draft",
                                            details: goalDetails,
                                            completionDate: JSON.stringify(completionDate),
                                            milestones: milestones,
                                            completion: 0
                                        })
                                    )
                                    setRedirect("allGoals")
                                }
                            }
                            icon={validateLogo}
                            iconHover={validateHoverLogo} />
                        <Button
                            classCss={"secondary-button"}
                            content={t("buttons.share")}
                            submit={
                                () => {
                                    dispatch(
                                        createGoal({
                                            title: goalTitle,
                                            collaborator_from: activeUser.id,
                                            status: "Shared",
                                            details: goalDetails,
                                            completionDate: JSON.stringify(completionDate),
                                            milestones: milestones,
                                            completion: 0
                                        })
                                    )
                                    setOpenPopUp(true);
                                }
                            }
                            icon={shareLogo}
                            iconHover={shareHoverLogo} />
                        {goalStatus !== "error" &&
                            <PopUpSharedGoal
                                visible={openPopUp}
                                isManager={false}
                                closeHandler={HandleClosePopUp} />
                        }
                    </div>
                </section>
            </main>
        </>
    );
}