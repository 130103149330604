import api from "../utils/http-common";
import {getAccessToken, getRefreshToken} from "../store/store";
import {updateToken} from "../store/reducers/activeUserReducer";

const setup = (store: any) => {
    // api.interceptors.request.use(
    //     (config) => {
    //         console.log(config)
    //         if (config.url !== "/auth/token/obtain/"){
    //             const token = getAccessToken();
    //             if (token) {
    //                 config.headers!["Authorization"] = `Bearer ${token}`;
    //             }
    //         }
    //         return config;
    //     },
    //     (error) => {
    //         return Promise.reject(error);
    //     }
    // );
    const { dispatch } = store;
    api.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== "/auth/token/obtain/" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry && originalConfig.url !== "/auth/token/refresh/") {
                    originalConfig._retry = true;
                    try {
                        const refreshToken = getRefreshToken();
                        const response = await api.post("/auth/token/refresh/", {
                            refresh: refreshToken
                        });
                        if(response.status == 201){
                            const { accessToken } = response.data;
                            dispatch(updateToken(accessToken));
                            return api(originalConfig);
                        }
                        else {
                            window.location.replace("/login?next=" + originalConfig.url)
                        }
                    } catch (_error) {
                        window.location.replace("/login?next=" + originalConfig.url)
                        return Promise.reject(_error);
                    }
                } else if (err.response.status === 401 && originalConfig._retry) {
                    window.location.replace("/login?next=" + originalConfig.url)
                }
            }
            return Promise.reject(err);
        }
    );
};
export default setup;