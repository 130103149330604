import api from "../utils/http-common"
import ILogin from "../interfaces/login.interface";
import ISignUp from "../interfaces/signup.interface";

export default class UserServices {
    signup(data: ISignUp){
        return api.post("/auth/register/", data)
    }
    login(data: ILogin){
        return api.post("/auth/token/obtain/", data)
    }
    logout(token: string){
        return api.post("/auth/logout/", {}, {headers: {Authorization: `Bearer ${token}`}})
    }
    passwordForgotten(email:string){
        return api.post("/auth/password/reset/", {email: email})
    }
    resetPassword(uid:string, token:string, password:string, password2:string){
        return api.post("/auth/password/reset/confirm/" + uid + "/" + token + "/", {new_password1:password, new_password2:password2, token: token, uid:uid})
    }
    changePassword(token:string, password:string, password2:string){
        return api.post("/auth/password/change/", {new_password1:password, new_password2:password2}, {headers: {Authorization: `Bearer ${token}`}})
    }
    getActiveUser(token: string){
        return api.get("/auth/get-infos/", {headers: {Authorization: `Bearer ${token}`}})
    }
    updateActiveUser(token: string, data: { id:number, userFormData: FormData}){
        return api.put("/profile/" + data.id, data.userFormData,{headers: {Authorization: `Bearer ${token}`, "content-type": "multipart/form-data"}})
    }
    getCollaboratorsList(token: string){
        return api.get("/collaborators/", {headers: {Authorization: `Bearer ${token}`}})
    }
}